import { Form } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../components/Button'
import { Modal } from '../../../components/Modal'
import { appActions } from '../../../features/app'
import { fSchemaActions } from '../../../features/formulaSchema'
import OtherTestedPackagingForm from './OtherTestedPackagesForm'
import { ModalBodyContainer } from './styled'

const OtherTestedPackagingModal: FC<any> = ({
  btnLabel,
  btnIcon = 'PlusCircleOutlined',
  onBtnClick,
  modalKey,
  onSave,
  disabled = false,
  onUpdate = () => {},
  data = {}
}) => {
  const dispatch = useDispatch()
  const [submittable, setSubmittable] = useState(false)

  const closeModalHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
    dispatch(fSchemaActions.resetOtherTestedPackaging())
  }
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)
  const handleFinish = (values: any) => {
    onSave({ ...values, formulaSchemaId: data.formulaSchemaId, id: data.id })
    dispatch(appActions.closeModal({ modalKey }))
  }
  useEffect(() => {
    form.validateFields(['weightLossTest', 'testResult', 'testStartDate']).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [values, form])

  return (
    <Modal
      isSmall={true}
      btnName={btnLabel}
      disabled={disabled}
      btnType="link"
      btnIcon={btnIcon}
      modalKey={modalKey}
      title=""
      footer={[
        <Button key="cancel" label="Cancel" onClick={closeModalHandler} />,
        <Button
          key="save"
          label="Save"
          disabled={!submittable}
          onClick={() => form.submit()}
        />
      ]}
      btnClicked={onBtnClick}
      centered
      size="large"
    >
      <ModalBodyContainer>
        <OtherTestedPackagingForm
          onFinish={handleFinish}
          data={data}
          isEditing={true}
          onUpdate={onUpdate}
          formInstance={form}
        />
      </ModalBodyContainer>
    </Modal>
  )
}

export default OtherTestedPackagingModal
