import styled, { css } from 'styled-components'
import { IPageContentProps } from './types'

export const PageContentContainer = styled.section<IPageContentProps>`
  position: relative;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: column;
  width: 100%;
  height: ${({ footerHeight }) => `calc(100% - ${footerHeight}px)`};
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-size: 100% 100%;
      background-repeat: no-repeat;
    `};
  padding: 0;
  padding-top: ${({ headerHeight }) => `${headerHeight}px`};
  overflow: auto;
`
export const ContentWrapper = styled.div<{ centered: boolean }>`
  ${({ centered }) => css`
    display: ${centered ? 'flex' : 'block'};
    height: 100%;
    overflow: auto;
  `}
  padding: 10px 5%;
  // NOTE - display block won't take these properties
  justify-content: center;
  align-items: center;
`
