import styled from 'styled-components'

export const buttonWrapperHeight = 44
export const ButtonWrapper = styled.div`
  height: ${buttonWrapperHeight}px;
  width: 100%;
  padding: 5px 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const TagsWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  > div {
    overflow-y: unset;
    width: auto;
  }
`
