/* eslint-disable no-loop-func */
import { isEqual } from 'lodash'
import { IColorRGB, IGetRandomRGBListArgs } from './types'

export const generateRGBString = (rgb: IColorRGB, alpha: number) => {
  const { r = 0, g = 0, b = 0 } = rgb
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const getRandomRGB = () => {
  const r = Math.floor(Math.random() * 256)
  const g = Math.floor(Math.random() * 256)
  const b = Math.floor(Math.random() * 256)
  return { r, g, b }
}

export const getRandomRGBList: (arg: IGetRandomRGBListArgs) => IColorRGB[] = ({
  quantity,
  excludeList = []
}) => {
  const colorList: IColorRGB[] = []

  for (let i = 0; i < quantity; i++) {
    let newColor: IColorRGB
    let isDuplicate = false
    let isToExlude = false
    do {
      newColor = getRandomRGB()
      isDuplicate = colorList.some(
        ({ r, g, b }) => r === newColor.r && g === newColor.g && b === newColor.b
      )
      isToExlude = excludeList.some((color) => isEqual(color, newColor))
    } while (isDuplicate || isToExlude)
    colorList.push(newColor)
  }
  return colorList
}
