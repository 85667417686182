import { TABLE_DATA_TYPE } from '../../Table/constants'
import { ITableConfig } from '../../Table/types'

export const PRODUCT_ATTACHMENTS_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  url: {
    dataPath: 'url',
    hide: true
  },
  contentType: {
    label: 'Content Type',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'attachmentContentType.name'
  },
  name: { label: 'Name', dataType: TABLE_DATA_TYPE.TEXT, dataPath: 'name' },
  description: {
    label: 'Description',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'description'
  },
  SAPCodes: {
    label: 'Sap Code',
    dataType: TABLE_DATA_TYPE.MODAL_LIST,
    dataPath: 'SAPCodes'
  },
  version: {
    label: 'Version',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'version'
  },
  createdAt: {
    label: 'Created At',
    dataType: TABLE_DATA_TYPE.DATE,
    dataPath: 'createdAt'
  },
  createdBy: {
    label: 'Created By',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'createdBy'
  }
}
export const PRODUCT_MARKETING_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  url: {
    dataPath: 'url',
    hide: true
  },
  name: {
    label: 'Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'name'
  },
  version: {
    label: 'Version',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'version'
  },
  createdBy: {
    label: 'Created By',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'createdBy'
  },
  createdAt: {
    label: 'Created At',
    dataType: TABLE_DATA_TYPE.DATE,
    dataPath: 'createdAt'
  }
}

export const ATTACHMENTS_TABLE_CONFIG = {
  contentType: {
    label: 'Content Type',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'contentType'
  },
  name: { label: 'Name', dataType: TABLE_DATA_TYPE.TEXT, dataPath: 'file.name' },
  description: {
    label: 'Description',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'description'
  },
  SAPCodes: {
    label: 'Sap Code',
    dataType: TABLE_DATA_TYPE.MODAL_LIST,
    dataPath: 'SAPCodes'
  },
  version: {
    label: 'Version',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'version'
  },
  date: {
    label: 'Date',
    dataType: TABLE_DATA_TYPE.DATE,
    dataPath: 'date'
  },
  status: {
    label: 'Status',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'status'
  },
  author: {
    label: 'Author',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'author'
  }
}

export const ATTACHMENTS_IN_DETAIL_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  contentType: {
    label: 'Content Type',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'attachmentContentType.name',
    showSortable: true
  },
  name: {
    label: 'Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'name',
    showSortable: true
  },
  description: {
    label: 'Description',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'description'
  },
  SAPCodes: {
    label: 'Sap Code',
    dataType: TABLE_DATA_TYPE.MODAL_LIST,
    dataPath: 'SAPCodes'
  },
  version: {
    label: 'Version',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'version',
    showSortable: true
  },
  status: {
    label: 'Status',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'status',
    showSortable: true
  },
  author: {
    label: 'Author',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'createdBy'
  },
  children: {
    dataPath: 'otherVersions'
  },
  url: {
    dataPath: 'url'
  },
  attachmentContentType: {
    dataPath: 'attachmentContentType'
  },
  attachmentContentTypeId: {
    dataPath: 'attachmentContentTypeId'
  }
}

export const ATTACHMENTS_IN_MASTER_SPECIFICATIONS_TABLE_CONFIG: ITableConfig = {
  id: { dataPath: 'id', hide: true },
  title: { label: 'Title', dataType: TABLE_DATA_TYPE.TEXT, dataPath: 'title' },
  description: {
    label: 'Description',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'description'
  },
  status: { label: 'Status', dataType: TABLE_DATA_TYPE.TEXT, dataPath: 'status' },
  category: { dataPath: 'category', hide: true },
  subcategory: { dataPath: 'subcategory', hide: true },
  completed: { dataPath: 'completed', hide: true }
}

export const ATTACH_MANAGE_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  idDbPackage: {
    label: 'ID Db Package',
    dataPath: 'idDbPackage',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  name: {
    label: 'Name',
    dataPath: 'name',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  type: {
    label: 'Type',
    dataPath: 'type',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  sapCodeEnabled: {
    label: 'Sap Code Enabled',
    dataPath: 'sapCodeEnabled',
    dataType: TABLE_DATA_TYPE.BOOL
  }
}

export const SUPPLIER_MANAGE_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  SAPCodes: {
    label: 'SAP Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'SAPCodes'
  },
  name: {
    label: 'Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'name'
  },
  locality: {
    label: 'Locality',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'locality'
  },
  country: {
    label: 'Country',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'country'
  },
  groupName: {
    label: 'Group Supplier Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'groupName'
  }
}
