import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
import moment from 'moment'
import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import { DATE_FORMAT } from '../../components/Form/RangeDatePicker/contants'
import { orderDataExtractor } from '../../utils/orderHelpers'
import { appSliceActions } from '../app/reducer'
import { getStoreProductById } from '../storeProducts/api'
import { orderListActions } from './actions'
import {
  getOrder,
  fetchLatestOrder,
  searchOrders,
  rejectOrder,
  acceptOrder,
  proposeOrder,
  processOrder
} from './api'
import { ORDER_LIST_TABS, orderListActionTypes } from './constants'
import {
  IGetOrderDetailsActionArg,
  IOrderData,
  IOrderItem,
  IOrdersResultSearch,
  IPagination,
  ISearchOrdersActionArg
} from './models'
import { orderListSliceActions } from './reducer'
import {
  selectOrderListFilters,
  selectOrderListPagination,
  selectOrderSelected,
  selectSelectedTab
} from './selectors'

function* searchOrderListSaga({ payload }: PayloadAction<ISearchOrdersActionArg>) {
  const { loader } = payload
  yield put(appSliceActions.setLoader({ type: loader, value: true }))
  try {
    const selectedTab: string = yield select(selectSelectedTab)
    const filters: Record<string, any> = yield select(selectOrderListFilters)
    const { page: selectedPage, pageSize: selectedPageSize }: IPagination = yield select(
      selectOrderListPagination
    )

    const createdDateFrom = moment(
      get(filters, 'createdDate.createdDateFrom', ''),
      DATE_FORMAT
    )

    const createdDateTo = moment(
      get(filters, 'createdDate.createdDateTo', ''),
      DATE_FORMAT
    ).add(1, 'day')

    const fullfillmentDateFrom = moment(
      get(filters, 'fullfillmentDate.fullfillmentDateFrom', ''),
      DATE_FORMAT
    )
    const fullfillmentDateTo = moment(
      get(filters, 'fullfillmentDate.fullfillmentDateTo', ''),
      DATE_FORMAT
    ).add(1, 'day')

    const order = get(payload, 'order', '')
    const field = get(payload, 'field', '')

    const orderKeys: any = {
      ascend: 'asc',
      descend: 'desc'
    }

    const requestBody: Record<string, any> = {
      status: selectedTab,
      notes: get(filters, 'notes', ''),
      ownerEmail: get(filters, 'owner', ''),
      addressedTo: get(filters, 'addressedTo', ''),
      createdDateFrom: createdDateFrom,
      createdDateTo: createdDateTo,
      fullfillmentDateFrom: fullfillmentDateFrom,
      fullfillmentDateTo: fullfillmentDateTo,
      pageSize: selectedPageSize,
      page: selectedPage,
      orderCode: get(filters, 'orderCode', '')
    }

    if (order && field) {
      const orderByField =
        field === 'ownerFullname'
          ? [{ ownerSurname: orderKeys[order] }, { ownerName: orderKeys[order] }]
          : [{ [field]: orderKeys[order] }]

      requestBody['orderBy'] = orderByField
    }

    const searchResult: IOrdersResultSearch = yield call(searchOrders, requestBody)
    const { results, totalPages, page, pageSize, total } = searchResult

    yield put(orderListSliceActions.setPagination({ totalPages, page, pageSize, total }))

    yield put(orderListSliceActions.setData({ data: results }))
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: loader, value: false }))
  }
}

function* changeTabAndSearch({ payload }: AnyAction) {
  try {
    const { activeStatus } = payload
    yield put(orderListSliceActions.resetData())
    yield put(orderListSliceActions.resetFilters())
    yield put(orderListSliceActions.resetPagination())
    const selectedTab = ORDER_LIST_TABS[+activeStatus]
    yield put(orderListSliceActions.setSelectedTab(selectedTab.value))
    yield put(orderListActions.searchOrders({ loader: 'section-order-list' }))
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  }
}
function* applyFilterAndSearch() {
  try {
    yield put(orderListSliceActions.applyTempFilter())
    yield put(orderListSliceActions.resetTempFilters())
    yield put(orderListActions.searchOrders({ loader: 'section-order-list' }))
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  }
}

function* removeFilterAndSearch({ payload }: AnyAction) {
  try {
    if (payload.path.includes('createdDate')) {
      payload.path = 'createdDate'
    }
    if (payload.path.includes('fullfillmentDate')) {
      payload.path = 'fullfillmentDate'
    }
    yield put(orderListSliceActions.removeFilter(payload.path))
    yield put(orderListActions.searchOrders({ loader: 'section-order-list' }))
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  }
}

function* changePageAndSearch({ payload }: AnyAction) {
  try {
    const { page }: IPagination = payload
    yield put(orderListSliceActions.setPagination({ page }))
    yield put(orderListActions.searchOrders({ loader: 'section-order-list' }))
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  }
}

function* fetchOrderDetailsSaga({ payload }: PayloadAction<IGetOrderDetailsActionArg>) {
  const { loader } = payload
  yield put(appSliceActions.setLoader({ type: loader, value: true }))
  try {
    const { id } = payload
    const order: IOrderData = yield call(getOrder, id)
    yield put(orderListSliceActions.setOrder({ data: order }))
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: loader, value: false }))
  }
}

function* getLatestOrderSaga() {
  try {
    const lastForwarded: IOrderData = yield call(fetchLatestOrder, 'FORWARDED')
    const lastRefused: IOrderData = yield call(fetchLatestOrder, 'REFUSED')
    const lastProcessed: IOrderData = yield call(fetchLatestOrder, 'PROCESSED')

    yield put(orderListSliceActions.setLatestForwarded({ data: lastForwarded }))
    yield put(orderListSliceActions.setLatestRefused({ data: lastRefused }))
    yield put(orderListSliceActions.setLatestProcessed({ data: lastProcessed }))
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  }
}

function* rejectOrderSaga({ payload }: AnyAction) {
  yield put(appSliceActions.setLoader({ type: 'page', value: true }))
  try {
    const { id, reasonData } = payload
    yield call(rejectOrder, id, reasonData)
    yield put(
      orderListSliceActions.setSuccess({
        title: 'You have refused the order',
        message:
          'Refusal order has been sent.\nYou can continue to view the order in the list of refused orders'
      })
    )
  } catch (err: any) {
    yield put(orderListSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'page', value: false }))
  }
}

function* acceptOrderSaga({
  payload
}: AnyAction | PayloadAction<{ id: string; acceptNote?: string }>) {
  yield put(appSliceActions.setLoader({ type: 'page', value: true }))
  try {
    const { id, acceptNote } = payload
    const resp: Record<string, any> = yield call(acceptOrder, id, acceptNote)
    const { status } = resp
    if (status === 'ACCEPTED') {
      yield put(
        orderListSliceActions.setSuccess({
          title: 'You have accepted the order',
          message:
            'You can continue to view the order in the list of accepted orders from which you can transform the order into a fulfilled one'
        })
      )
    } else {
      throw Error('Something went wrong')
    }
  } catch (err: any) {
    yield put(orderListSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'page', value: false }))
  }
}

function* proposeOrderSaga({ payload }: AnyAction) {
  yield put(appSliceActions.setLoader({ type: 'page', value: true }))
  try {
    const { orderData } = payload
    const { id } = orderData
    yield call(proposeOrder, id, orderData)
    yield put(
      orderListSliceActions.setSuccess({
        title: 'Your order has been forwarded',
        message: 'You can continue to view the order in the list of forwarded orders'
      })
    )
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'page', value: false }))
  }
}

function* processOrderSaga({
  payload
}: AnyAction | PayloadAction<{ id: string; processNote?: string }>) {
  yield put(appSliceActions.setLoader({ type: 'page', value: true }))
  try {
    const { id, processNote } = payload
    const resp: Record<string, any> = yield call(processOrder, id, processNote)
    const { status } = resp
    if (status === 'PROCESSED') {
      yield put(
        orderListSliceActions.setSuccess({
          title: 'You have processed the order',
          message:
            'You can continue to view the order in the list of processed orders from which you can transform the order into a fulfilled one'
        })
      )
    } else {
      throw Error('Something went wrong')
    }
  } catch (err: any) {
    yield put(orderListSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'page', value: false }))
  }
}

function* checkAvailabilitySaga() {
  yield put(appSliceActions.setLoader({ type: 'modal', value: true }))
  try {
    const orderData: IOrderData = yield select(selectOrderSelected)
    const { orderItems } = orderDataExtractor(orderData)
    const products: IOrderItem[] = yield all(
      orderItems.map(({ orderableItemId }: any) =>
        call(getStoreProductById, orderableItemId)
      )
    )
    const newOrderitems = orderItems.map((data) => ({
      ...data,
      item: products.find((prod) => prod.id === (data.item?.id || ''))
    }))
    yield put(
      orderListSliceActions.setTempOrder({
        data: { ...orderData, orderItems: newOrderitems }
      })
    )
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'modal', value: false }))
  }
}

export default function* orderListSaga() {
  yield takeLatest(orderListActionTypes.SEARCH_ORDERS, searchOrderListSaga)
  yield takeLatest(orderListActionTypes.APPLY_FILTERS_AND_SEARCH, applyFilterAndSearch)
  yield takeLatest(orderListActionTypes.REMOVE_FILTER_AND_SEARCH, removeFilterAndSearch)
  yield takeLatest(orderListActionTypes.CHANGE_TAB_AND_SEARCH, changeTabAndSearch)
  yield takeLatest(orderListActionTypes.CHANGE_PAGE_AND_SEARCH, changePageAndSearch)
  yield takeLatest(orderListActionTypes.FETCH_ORDER_DETAILS, fetchOrderDetailsSaga)
  yield takeLatest(orderListActionTypes.FETCH_LATEST_ORDER, getLatestOrderSaga)
  yield takeLatest(orderListActionTypes.CONFIRM_REJECT_ORDER, rejectOrderSaga)
  yield takeLatest(orderListActionTypes.CONFIRM_ACCEPT_ORDER, acceptOrderSaga)
  yield takeLatest(orderListActionTypes.CONFIRM_PROPOSE_ORDER, proposeOrderSaga)
  yield takeLatest(orderListActionTypes.CONFIRM_PROCESS_ORDER, processOrderSaga)
  yield takeLatest(
    orderListActionTypes.CHECK_ITEM_AVAILABILITY_FOR_PROPOSE,
    checkAvailabilitySaga
  )
}
