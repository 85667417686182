import styled from 'styled-components'

export const ProductSpiderChartContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  .radar-chart-container {
    width: 55%;
    canvas {
      height: 100% !important;
      width: auto !important;
    }
  }
  .chart-legend-container {
    padding-top: 40px;
    margin-left: 135px;
  }
`

export const LegendProductName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
