import { ShoppingOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import intercosDigitalBeautyLogo from '../../assets/images/intercos-digital-beauty-logo.png'
import storeLogo from '../../assets/images/store-logo.png'
import vaultLogo from '../../assets/images/vault-logo.png'
import wikipackLogo from '../../assets/images/wikipack-logo.png'
import { cartSliceActions } from '../../features/cart'
import { getCartItemsCount } from '../../features/cart/selectors'
import { selectPermissions } from '../../features/users/selectors'
import { AppRoutes, HOME_LINKS } from '../../pages/constants'
import { routeVisibilityHelper } from '../../utils'
import { Logo } from '../Logo'
import HeaderMenu from './HeaderMenu'
import {
  CartItemsIndicator,
  IconWrapper,
  StyledAvatar,
  StyledContentHeader,
  StyledHeader
} from './styled'
import { IHeaderProps } from './types'

const Header: FC<IHeaderProps> = ({ className }) => {
  const { pathname } = useLocation()
  const permissions = useSelector(selectPermissions)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const showCart = routeVisibilityHelper(AppRoutes.STORE_CART, permissions)
  const isCart = pathname.includes('cart')
  const cartItemsCount = useSelector(getCartItemsCount)

  const goToCart = () => {
    dispatch(cartSliceActions.clearSuccess())
    navigate(AppRoutes.STORE_CART)
  }

  const getLogoUrl = () => {
    if (pathname.includes('vault/administration')) return intercosDigitalBeautyLogo
    if (pathname.includes('vault')) return vaultLogo
    if (pathname.includes('wikipack')) return wikipackLogo
    if (pathname.includes('store')) return storeLogo
    return intercosDigitalBeautyLogo
  }

  const filteredRoutes = HOME_LINKS.filter(({ path }) => {
    const isVisible = routeVisibilityHelper(path, permissions)
    return isVisible
  })

  const menuItems = filteredRoutes.map(({ path, label }) => ({
    danger: false,
    disabled: false,
    key: path,
    label: label
  }))

  return (
    <StyledHeader className={className}>
      <Logo logoUri={getLogoUrl()} />
      <StyledContentHeader>
        <HeaderMenu menus={menuItems} />
        {showCart && (
          <IconWrapper onClick={goToCart} showCart={isCart}>
            <ShoppingOutlined />
            {!!cartItemsCount && (
              <CartItemsIndicator>{cartItemsCount}</CartItemsIndicator>
            )}
          </IconWrapper>
        )}
        <StyledAvatar />
      </StyledContentHeader>
    </StyledHeader>
  )
}

export default Header
