import styled from 'styled-components'

export const ProductScatterContainer = styled.div`
  width: 100%;
  padding: 0 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  .scatter-chart-container {
    padding-top: 5px;
    width: 90%;
  }
  .chart-legend-container {
    padding: 0 30px;
    margin-top: 30px;
    width: 100%;
    max-width: none;
    .chart-legend-cards-container {
      display: flex;
      flex-wrap: wrap;
      .chart-legend-card {
        width: 320px;
        margin-right: 16px;
        margin-top: 8px;
      }
    }
  }
`
export const LegendProdName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
