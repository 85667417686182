import { Tabs } from 'antd'
import { get } from 'lodash'
import { FC, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Title } from '../../components/Title'
import { OrderListContent, PageContent } from '../../containers'
import { appSliceActions } from '../../features/app'
import { selectAppError } from '../../features/app/selectors'
import { orderListActions } from '../../features/orderList/actions'
import {
  LATEST_ORDER_COOKIE_NAME,
  ORDER_LIST_TABS
} from '../../features/orderList/constants'
import { ORDER_STATUS_LIST, TOrderStatus } from '../../features/orderList/models'
import {
  selectLatestForwarded,
  selectLatestProcessed,
  selectLatestRefused,
  selectSelectedTab
} from '../../features/orderList/selectors'
import { selectPermissions, selectUserEmail } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'

const StoreOrderList: FC = () => {
  const [cookies, setCookie] = useCookies()
  const userPermission = useSelector(selectPermissions)
  const userEmail = useSelector(selectUserEmail)
  const dispatch = useDispatch()
  const currentTab = useSelector(selectSelectedTab)
  const appError = useSelector(selectAppError)

  const latestForwarded = useSelector(selectLatestForwarded)
  const latestRefused = useSelector(selectLatestRefused)
  const latestProcessed = useSelector(selectLatestProcessed)

  const isSalaCampioniAndAdmin =
    userPermission.is?.salaCampioni || userPermission.is?.admin

  const openLastOrderPopup = (status: TOrderStatus, latestOrderCode?: string) => {
    if (latestOrderCode) {
      let message = `Your last order ${latestOrderCode} has been refused. Go to Refused, propose changes and send again`
      if (status === 'FORWARDED')
        message = `A new order ${latestOrderCode} has been forwarded. Go to Forwarded to handle it`
      if (status === 'PROCESSED')
        message = `A new order ${latestOrderCode} has been processed.`
      dispatch(
        appSliceActions.openPopUp({
          key: 'last-order-advice',
          title: 'A New Order!',
          message
        })
      )
    }
  }

  const checkLatestOrder = () => {
    const latestOrderCookie = get(cookies, `${LATEST_ORDER_COOKIE_NAME}`, {})

    // is user is salaCampioni, check among all forwarded orders
    if (isSalaCampioniAndAdmin) {
      // check latest forwarded order
      const lateForwardedCKUpdateDate = get(
        latestOrderCookie,
        `${ORDER_STATUS_LIST.FORWARDED}.updatedDate`,
        ''
      )

      // if no cookie or different update date, update cookie
      if (
        !Object.keys(latestOrderCookie).length ||
        lateForwardedCKUpdateDate !== latestForwarded.updatedDate
      ) {
        setCookie(
          LATEST_ORDER_COOKIE_NAME,
          {
            ...latestOrderCookie,
            [ORDER_STATUS_LIST.FORWARDED]: {
              updatedDate: latestForwarded.updatedDate
            }
          },
          {
            expires: new Date('01/01/2200'),
            path: '/'
          }
        )
        openLastOrderPopup(ORDER_STATUS_LIST.FORWARDED, latestForwarded.orderCode)
      }
    }

    // if user is not admin, checks for refused or processed orders
    if (!isSalaCampioniAndAdmin) {
      // check latest refused order
      const lateRefusedCKUpdateDate = get(
        latestOrderCookie,
        `${ORDER_STATUS_LIST.REFUSED}.updatedDate`,
        ''
      )

      if (
        !Object.keys(latestOrderCookie).length ||
        lateRefusedCKUpdateDate !== latestRefused.updatedDate
      ) {
        setCookie(
          LATEST_ORDER_COOKIE_NAME,
          {
            ...latestOrderCookie,
            [ORDER_STATUS_LIST.REFUSED]: {
              updatedDate: latestRefused.updatedDate
            }
          },
          {
            expires: new Date('01/01/2200'),
            path: '/'
          }
        )
        if (latestRefused.ownerEmail === userEmail) {
          openLastOrderPopup(ORDER_STATUS_LIST.REFUSED, latestRefused.orderCode)
        }
      }

      // check latest processed order
      const lateProcessedCKUpdateDate = get(
        latestOrderCookie,
        `${ORDER_STATUS_LIST.PROCESSED}.updatedDate`,
        ''
      )

      if (
        !Object.keys(latestOrderCookie).length ||
        lateProcessedCKUpdateDate !== latestProcessed.updatedDate
      ) {
        setCookie(
          LATEST_ORDER_COOKIE_NAME,
          {
            ...latestOrderCookie,
            [ORDER_STATUS_LIST.PROCESSED]: {
              updatedDate: latestProcessed.updatedDate
            }
          },
          {
            expires: new Date('01/01/2200'),
            path: '/'
          }
        )
        if (latestProcessed.ownerEmail === userEmail) {
          openLastOrderPopup(ORDER_STATUS_LIST.PROCESSED, latestProcessed.orderCode)
        }
      }
    }
  }

  useEffect(() => {
    checkLatestOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSalaCampioniAndAdmin])

  useEffect(() => {
    dispatch(orderListActions.searchOrders({ loader: 'section-order-list' }))
    dispatch(orderListActions.getLatestOrder())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeTabHandler = (activeStatus: string) => {
    dispatch(orderListActions.changeTab({ activeStatus }))
  }

  const activeKey = ORDER_LIST_TABS.reduce((acc, { value }, idx) => {
    if (value === currentTab) acc = idx
    return acc
  }, 0)

  return (
    <GenericLayout error={appError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Title title="Order List" variantTitle="h3" />
        <Tabs onTabClick={changeTabHandler} activeKey={`${activeKey}`}>
          {ORDER_LIST_TABS.map(({ label, value }, index) => (
            <Tabs.TabPane tab={label} key={`${index}`}>
              <OrderListContent type={value} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </PageContent>
    </GenericLayout>
  )
}

export default StoreOrderList
