import { get } from 'lodash'
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import {
  canUserEditOtherTestedPackagingForFormulaSchema,
  canUserEditRegulatory,
  canUserEditTestedClaimForFormulaSchema,
  canUserReadRegulatory,
  canUserSeeOtherTestedPackagingForFormulaSchema,
  canUserSeeTestedClaimForFormulaSchema
} from '../../auth-msal'
import {
  Button,
  FormulaSchemaTable,
  SectionTitle,
  Table,
  TooltipBtn
} from '../../components'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Divider } from '../../components/Divider'
import { FSCHEMA_DETAIL_FORMULA } from '../../components/FormulaSchema/FormulaSchemaTable/constants'
import { Loader } from '../../components/Loader'
import RegulatoryCheckInfo from '../../components/Product/RegulatoryCheckInfo'
import { TOP_RIGHT } from '../../components/Table/constants'
import TestedClaimModal from '../../components/TestedClaim/TestedClaimModal'
import { PageContent } from '../../containers'
import FSchemaDetailSection from '../../containers/FormulaSchema/FSchemaDetailSection'
import { appActions, appSliceActions } from '../../features/app'
import { fSchemaActions } from '../../features/formulaSchema'

import {
  selectFSchemaError,
  selectFSchemaIsLoading,
  selectFormulaDetail,
  selectOtherTestedPackaging,
  selectTestedClaim
} from '../../features/formulaSchema/selectors'
import { selectFormulaTempDetail } from '../../features/formulaSchema/selectors'
import { selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { columnsGeneratorHelper } from '../../utils'
import { fileSaver } from '../../utils/fileSaver'
import { refactorProductOtherTestedPackaging } from '../../utils/productHelpers'
import { AppRoutes } from '../constants'
import { OTHER_TESTED_PACKAGING_COLUMNS, TESTED_CLAIM_COLUMNS } from './constants'
import OtherTestedPackagingModal from './OtherTestedPackagingModal'
import { ButtonWrapper } from './styled'
import { IFSchemaDetailProps } from './types'

const baseUrl = process.env.REACT_APP_BASE_URL_IMG_THUMBNAIL

const FSchemaDetail: FC<IFSchemaDetailProps> = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [isOnEdit, setIsOnEdit] = useState(false)
  const fSchemaError = useSelector(selectFSchemaError)
  const fSchemaDetail = useSelector(selectFormulaDetail)
  const fSchemaTempDetail = useSelector(selectFormulaTempDetail)
  const isLoading = useSelector(selectFSchemaIsLoading)
  const userRoles = useSelector(selectUserRole)
  const otherTestedPackaging = useSelector(selectOtherTestedPackaging)
  const testedClaim = useSelector(selectTestedClaim)

  const [searchParams] = useSearchParams()
  const section = searchParams.get('section')
  const industrialCodeSecRef = useRef<HTMLDivElement>(null)

  const data = isOnEdit ? fSchemaTempDetail : fSchemaDetail
  const formulaCodes = fSchemaDetail?.formula.map((formula) => ({
    label: formula.formulaCodeDEVEX || formula.labReferenceCode,
    value: formula.formulaCodeDEVEX || formula.labReferenceCode,
    id: formula.id
  }))

  const closeModalHandler = (modalKey: string) => {
    dispatch(appActions.closeModal({ modalKey }))
  }

  const columns = columnsGeneratorHelper(OTHER_TESTED_PACKAGING_COLUMNS)
  const otherTestedPackagingData = get(data, 'otherTestedPackaging') || []
  const refactoredOtherTestedPackaging = refactorProductOtherTestedPackaging({
    dataList: otherTestedPackagingData
  })

  const columnsTestedClaim = columnsGeneratorHelper(TESTED_CLAIM_COLUMNS)

  const actionConfigOtherTested = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }: any) => {
      if (canUserEditOtherTestedPackagingForFormulaSchema(userRoles))
        return (
          <>
            <OtherTestedPackagingModal
              onUpdate={updateOtherTestedPackaging}
              onSave={(formData: any) => {
                closeModalHandler(`editOTPModal-${data?.id}`)
                patchOtherTestedPackaging(formData)
              }}
              btnIcon="FormOutlined"
              isEditing={isOnEdit}
              onBtnClick={() => {
                dispatch(fSchemaActions.setOtherTestedPackaging(data))
              }}
              isCreating={false}
              disabled={!canUserEditOtherTestedPackagingForFormulaSchema(userRoles)}
              modalKey={`editOTPModal-${data?.id}`}
              data={data}
            />
            <TooltipBtn
              tooltipLabel="Delete"
              hasIcon
              disabled={!canUserEditOtherTestedPackagingForFormulaSchema(userRoles)}
              iconName="DeleteOutlined"
              message="Do you want to delete this row?"
              onConfirm={() => {
                dispatch(fSchemaActions.deleteOtherTestedPackaging(data.id))
              }}
              btnVariant="link"
            />
          </>
        )
      else return null
    }
  }

  const actionConfigTestedClaim = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }: any) => {
      if (canUserEditTestedClaimForFormulaSchema(userRoles))
        return (
          <>
            <Button
              iconName="DownloadOutlined"
              variant="link"
              onClick={() => fileSaver(`${baseUrl}${data?.attachment?.url}`)}
            />
            <TestedClaimModal
              onUpdate={updateTestedClaim}
              onSave={(formData: any) => {
                closeModalHandler(`testedClaim-${data.id}`)
                patchTestedClaim(formData)
              }}
              btnIcon="FormOutlined"
              disabled={!canUserEditTestedClaimForFormulaSchema(userRoles)}
              isEditing={isOnEdit}
              onBtnClick={() => dispatch(fSchemaActions.setTestedClaim(data))}
              isCreating={false}
              modalKey={`testedClaim-${data.id}`}
              data={data}
              formulaCodes={formulaCodes}
            />
            <TooltipBtn
              tooltipLabel="Delete"
              hasIcon
              disabled={!canUserEditTestedClaimForFormulaSchema(userRoles)}
              iconName="DeleteOutlined"
              message="Do you want to delete this row?"
              onConfirm={() => {
                dispatch(fSchemaActions.deleteTestedClaim(data.id))
              }}
              btnVariant="link"
            />
          </>
        )
      else
        return (
          <Button
            iconName="DownloadOutlined"
            variant="link"
            onClick={() => fileSaver(`${baseUrl}${data?.attachment?.url}`)}
          />
        )
    }
  }

  useEffect(() => {
    dispatch(fSchemaActions.getTestedClaimTaxonomy())
    if (id) {
      dispatch(fSchemaActions.getFSchemaDetail({ id }))
    }

    return () => {
      dispatch(fSchemaActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (industrialCodeSecRef.current && section === 'industrialCode') {
      industrialCodeSecRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industrialCodeSecRef])

  const updateHandler = (key: string, value: any) => {
    dispatch(fSchemaActions.updateTempDetail({ key, value }))
  }

  const updateOtherTestedPackaging = (key: string, value: any) => {
    dispatch(fSchemaActions.updateOtherTestedPackaging({ key, value }))
  }

  const updateTestedClaim = (key: string, value: any) => {
    dispatch(fSchemaActions.updateTestedClaim({ key, value }))
  }

  const addOtherTestedPackaging = (formData: any) => {
    dispatch(fSchemaActions.addOtherTestedPackaging({ data: formData }))
  }

  const addTestedClaim = (formData: any, attachment: any) => {
    dispatch(fSchemaActions.addTestedClaim({ data: formData, attachment }))
  }

  const patchOtherTestedPackaging = (formData: any) => {
    dispatch(fSchemaActions.patchOtherTestedPackaging({ data: formData }))
  }

  const patchTestedClaim = (formData: any) => {
    dispatch(fSchemaActions.patchTestedClaim({ data: formData }))
  }

  const openPopupHandler = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: 'Are you sure you want to save the changes?',
        key: 'formula-save-confirm',
        message:
          "Please remember that by confirming, the last Update Date present on the product page will be updated with today's date and that a notification will be sent to the BU of reference",
        footer: [
          <Button
            key="cancel"
            label="Cancel"
            onClick={() => dispatch(appSliceActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="confirm"
            label="Confirm"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              setIsOnEdit(false)
              dispatch(fSchemaActions.updateFSchema())
            }}
          />
        ]
      })
    )
  }

  const canEditRegulatory = canUserEditRegulatory(userRoles)

  const canSeeRegulatory = canUserReadRegulatory(userRoles)

  return (
    <GenericLayout error={fSchemaError}>
      <PageContent headerHeight={headerHeight} footerHeight={footerHeight}>
        <Breadcrumb
          items={[
            {
              name: 'Formula Schema',
              href: AppRoutes.VAULT_FORMULA_SCHEMA_RESULT
            },
            {
              name: `Product detail ${get(data, 'formulaSchemaCode', '-')}`
            }
          ]}
        />
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <Divider disableBorder />
            <SectionTitle
              value={`Formula Schema ${get(data, 'formulaSchemaCode', '')}`}
              isBold
              addon={
                canEditRegulatory && (
                  <ButtonWrapper>
                    {isOnEdit ? (
                      <>
                        <Button
                          label="Cancel"
                          variant="ghost"
                          onClick={() => setIsOnEdit(false)}
                        />
                        <Button label="Save" onClick={openPopupHandler} />
                      </>
                    ) : (
                      <Button
                        className="fschmea-edit"
                        label="Edit"
                        onClick={() => {
                          setIsOnEdit(true)
                          dispatch(fSchemaActions.setTempDetail())
                        }}
                      />
                    )}
                  </ButtonWrapper>
                )
              }
            />
            <Divider disableBorder />
            <SectionTitle value="Formula Schema Details" />
            <Divider />
            <FSchemaDetailSection data={data} />
            <Divider disableBorder />
            {canSeeRegulatory && (
              <>
                <SectionTitle value="Regulatory" />
                <Divider />
                <RegulatoryCheckInfo
                  data={data as Record<string, any>}
                  isEditing={isOnEdit}
                  onUpdate={updateHandler}
                />
              </>
            )}
            <Divider disableBorder />
            <div ref={industrialCodeSecRef}>
              <SectionTitle value="Industrial Code Details" />
              <FormulaSchemaTable
                dataList={data?.formula || []}
                tableConfig={FSCHEMA_DETAIL_FORMULA}
                isEditing={canEditRegulatory && !isOnEdit}
                hasEditBtn={canEditRegulatory && !isOnEdit}
                applyRegVisibleRules
              />
            </div>
            {canUserSeeOtherTestedPackagingForFormulaSchema(userRoles) && (
              <>
                <br />
                <Divider disableBorder />
                <SectionTitle
                  value="Other Tested Packaging"
                  addon={
                    canUserEditOtherTestedPackagingForFormulaSchema(userRoles) && (
                      <OtherTestedPackagingModal
                        onUpdate={updateOtherTestedPackaging}
                        onSave={(formData: any) => {
                          closeModalHandler('newOTPModal')
                          addOtherTestedPackaging(formData)
                        }}
                        btnLabel="Add"
                        isEditing={isOnEdit}
                        isCreating={true}
                        modalKey="newOTPModal"
                        data={otherTestedPackaging}
                        onBtnClick={() =>
                          dispatch(fSchemaActions.resetOtherTestedPackaging())
                        }
                      />
                    )
                  }
                />
                <Divider />
                <Table
                  enablePagination={false}
                  paginationPosition={TOP_RIGHT}
                  rowKey="id"
                  columns={columns}
                  items={refactoredOtherTestedPackaging}
                  actionColumnConfig={actionConfigOtherTested}
                  isLoading={isLoading}
                />
              </>
            )}

            {canUserSeeTestedClaimForFormulaSchema(userRoles) && (
              <>
                <br />
                <Divider disableBorder />
                <SectionTitle
                  value="Tested Claim"
                  addon={
                    canUserEditTestedClaimForFormulaSchema(userRoles) && (
                      <TestedClaimModal
                        onUpdate={updateTestedClaim}
                        onSave={(formData: any, attachment: any) => {
                          closeModalHandler('newTestedClaimModal')
                          addTestedClaim(formData, attachment)
                        }}
                        btnLabel="Add"
                        isEditing={isOnEdit}
                        isCreating={true}
                        modalKey="newTestedClaimModal"
                        formulaCodes={formulaCodes}
                        data={testedClaim}
                        onBtnClick={() => dispatch(fSchemaActions.resetTestedClaim())}
                      />
                    )
                  }
                />
                <Divider />
                <Table
                  enablePagination={false}
                  paginationPosition={TOP_RIGHT}
                  rowKey="id"
                  columns={columnsTestedClaim}
                  items={get(data, 'testedExtensClaims') || []}
                  actionColumnConfig={actionConfigTestedClaim}
                  isLoading={isLoading}
                />
              </>
            )}
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default FSchemaDetail
