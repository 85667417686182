import { IDefaultColor } from './types'

export const defaultColorList: IDefaultColor[] = [
  { label: 'magenta', rgb: { r: 255, g: 0, b: 255 }, hex: '#ff00ff' },
  { label: 'red', rgb: { r: 255, g: 0, b: 0 }, hex: '#ff0000' },
  { label: 'brown', rgb: { r: 88, g: 57, b: 39 }, hex: '#a52a2a' },
  { label: 'orange', rgb: { r: 255, g: 165, b: 0 }, hex: '#ffa500' },
  { label: 'yellowgreen', rgb: { r: 154, g: 205, b: 50 }, hex: '#9acd32' },
  { label: 'darkgreen', rgb: { r: 0, g: 100, b: 0 }, hex: '#006400' },
  { label: 'cornflowerblue', rgb: { r: 100, g: 149, b: 237 }, hex: '#6495ed' },
  { label: 'blue', rgb: { r: 0, g: 0, b: 255 }, hex: '#0000ff' },
  { label: 'blueviolet', rgb: { r: 138, g: 43, b: 226 }, hex: '#8a2be2' },
  { label: 'violet', rgb: { r: 238, g: 130, b: 238 }, hex: '#ee82ee' }
]

export const defaultColorListRGB = defaultColorList.map(({ rgb }) => rgb)
