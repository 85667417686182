import { createAction } from '@reduxjs/toolkit'
import { SortOrder } from 'antd/lib/table/interface'
import { orderListActionTypes } from './constants'
import {
  IGetOrderDetailsActionArg,
  IOrderData,
  IReasonApiData,
  ISearchOrdersActionArg
} from './models'

export const orderListActions = {
  searchOrders: createAction<ISearchOrdersActionArg>(orderListActionTypes.SEARCH_ORDERS),
  applyFilterAndSearch: createAction(orderListActionTypes.APPLY_FILTERS_AND_SEARCH),
  changeTab: createAction<{}>(orderListActionTypes.CHANGE_TAB_AND_SEARCH),
  changePage: createAction<{ page: number; field: string; order: SortOrder }>(
    orderListActionTypes.CHANGE_PAGE_AND_SEARCH
  ),
  getOrderDetails: createAction<IGetOrderDetailsActionArg>(
    orderListActionTypes.FETCH_ORDER_DETAILS
  ),
  getLatestOrder: createAction(orderListActionTypes.FETCH_LATEST_ORDER),
  confirmReject: createAction<{ id: string; reasonData: IReasonApiData }>(
    orderListActionTypes.CONFIRM_REJECT_ORDER
  ),
  confirmAccept: createAction<{ id: string; acceptNote?: string }>(
    orderListActionTypes.CONFIRM_ACCEPT_ORDER
  ),
  confirmProcess: createAction<{ id: string; processNote?: string }>(
    orderListActionTypes.CONFIRM_PROCESS_ORDER
  ),
  confirmPropose: createAction<{ orderData: Partial<IOrderData> }>(
    orderListActionTypes.CONFIRM_PROPOSE_ORDER
  ),
  checkAvailabilityInPropose: createAction(
    orderListActionTypes.CHECK_ITEM_AVAILABILITY_FOR_PROPOSE
  ),
  cancelFilter: createAction<{ path: string }>(
    orderListActionTypes.REMOVE_FILTER_AND_SEARCH
  )
}
