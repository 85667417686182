import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { isSalaCampioni } from '../../auth-msal'
import { SectionTitle } from '../../components'
import { Breadcrumb } from '../../components/Breadcrumb'
import { OrderDetailsContent, PageContent } from '../../containers'
import Success from '../../containers/Success'
import { orderListActions } from '../../features/orderList/actions'
import { orderListSliceActions } from '../../features/orderList/reducer'
import {
  selectOrderError,
  selectOrderSelected,
  selectOrderSuccess
} from '../../features/orderList/selectors'
import { selectPermissions, selectUserEmail } from '../../features/users/selectors'

import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'
import AcceptModal from './AcceptModal'
import ProcessModal from './ProcessModal'
import ProposeModal from './ProposeModal'
import RejectModal from './RejectModal'
import { ActionsWrapper, TitleWrapper } from './styled'

const OrderDetails: FC = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const successInfo = useSelector(selectOrderSuccess)
  const orderData = useSelector(selectOrderSelected)
  const userEmail = useSelector(selectUserEmail)
  const userPermissions = useSelector(selectPermissions)
  const orderError = useSelector(selectOrderError)

  const orderStatus = orderData.status || ''

  const ownerEmail = orderData.ownerEmail || ''
  const ownerRoles = orderData.ownerRoles?.filter((s) => s) || []

  useEffect(() => {
    if (id) {
      dispatch(orderListActions.getOrderDetails({ id, loader: 'page' }))
    }
    return () => {
      dispatch(orderListSliceActions.clearSuccess())
      dispatch(orderListSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isAdmin = userPermissions.is?.admin
  const isUserSalaCampioni = userPermissions.is?.salaCampioni
  const isSalesMarketBu = userPermissions.is?.salesMarketBu

  const isOwnerSalaCampioni = isSalaCampioni(ownerRoles)

  const canAccept = isUserSalaCampioni || isAdmin
  const canReject = isUserSalaCampioni || isAdmin
  const canProcess = isUserSalaCampioni || isAdmin
  const canPropose =
    (isSalesMarketBu && userEmail === ownerEmail) ||
    (isUserSalaCampioni && isOwnerSalaCampioni) ||
    (isAdmin && userEmail === ownerEmail)

  return (
    <GenericLayout error={orderError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {successInfo ? (
          <Success
            data={successInfo}
            buttons={[
              {
                label: 'Back to Order list',
                onClick: () => navigate(AppRoutes.STORE_ORDERS),
                variant: 'ghost'
              },
              {
                label: 'Back to home',
                onClick: () => navigate(AppRoutes.HOME),
                iconName: 'HomeOutlined'
              }
            ]}
          />
        ) : (
          <>
            <Breadcrumb
              items={[
                { name: 'ORDER LIST', href: '/store/orders', back: true },
                { name: `ORDER DETAILS: ${orderData.orderCode || '-'}` }
              ]}
            />
            <TitleWrapper>
              <SectionTitle value="Order List" isBold />
              <ActionsWrapper>
                {orderStatus === 'REFUSED' && canPropose && <ProposeModal />}
                {orderStatus === 'FORWARDED' && (
                  <>
                    {canReject && <RejectModal />}
                    {canAccept && <AcceptModal />}
                  </>
                )}
                {orderStatus === 'ACCEPTED' && canProcess && <ProcessModal />}
              </ActionsWrapper>
            </TitleWrapper>
            <OrderDetailsContent />
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default OrderDetails
