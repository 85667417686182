import { TOrderListTablesConfig } from '../../../features/orderList/models'
import { AppRoutes } from '../../../pages/constants'
import { TABLE_DATA_TYPE } from '../../Table/constants'
import { ITableConfig } from '../../Table/types'

export const ORDERLIST_TABLE_CONFIG: ITableConfig = {
  id: {
    label: 'Order ID',
    dataType: TABLE_DATA_TYPE.LINK,
    pathname: AppRoutes.STORE_ORDER_DETAILS,
    dataPath: 'id',
    newTab: true,
    hide: true
  },
  orderCode: {
    label: 'Order Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'orderCode',
    showSortable: true
  },
  createdDate: {
    label: 'Date of request',
    dataPath: 'createdDate',
    dataType: TABLE_DATA_TYPE.DATE,
    showSortable: true
  },
  fullfillmentDate: {
    label: 'Request date of fullfilment',
    dataPath: 'fullfillmentDate',
    dataType: TABLE_DATA_TYPE.DATE,
    showSortable: true
  },
  addressedTo: {
    label: 'Client/Event/Other',
    dataPath: 'addressedTo',
    dataType: TABLE_DATA_TYPE.TEXT,
    showSortable: true
  },
  additionalNote: {
    label: 'Destination Notes',
    dataPath: 'additionalNote',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  ownerFullName: {
    label: 'User of the request',
    dataPath: '',
    dataType: TABLE_DATA_TYPE.TEXT,
    showSortable: true
  },
  ownerName: {
    dataPath: 'ownerName',
    hide: true
  },
  ownerSurname: {
    dataPath: 'ownerSurname',
    hide: true
  }
}

export const ORDERLIST_STATUS_TABLE_CONFIG: TOrderListTablesConfig = {
  FORWARDED: ORDERLIST_TABLE_CONFIG,
  ACCEPTED: ORDERLIST_TABLE_CONFIG,
  PROCESSED: ORDERLIST_TABLE_CONFIG,
  REFUSED: ORDERLIST_TABLE_CONFIG
}
