import { Form } from 'antd'
import { get } from 'lodash'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { InfoCell, SectionTitle } from '../../components'
import { BoxWrapper } from '../../components/BoxWrapper'
import { ColorPreview } from '../../components/ColorPreview'
import { ColorType } from '../../components/ColorPreview/types'
import { Divider } from '../../components/Divider'
import {
  applicationAreaDrpDwn,
  collectionDropDown,
  colorDropDown,
  storeProdTypeToForm
} from '../../features/storeProducts/constants'
import { IImageData, IStoreProd } from '../../features/storeProducts/model'
import { getStoreProdDropdownByKey } from '../../features/storeProducts/selectors'
import { selectPermissions } from '../../features/users/selectors'
import { AppRoutes } from '../../pages/constants'
import {
  checkDisableFieldInUpdate,
  entityFieldVisibileByType
} from '../../utils/entityHelper'
import { FlexColorWrapper } from './styled'
import { IProductDetailProps } from './types'

const ProductDetailContent: FC<IProductDetailProps> = ({
  data,
  isEditing = false,
  onUpdate = () => {}
}) => {
  const permissions = useSelector(selectPermissions)
  const typeValue = data.type

  const prodFormDropdown = useSelector(getStoreProdDropdownByKey('productForm'))
  const firstProductionOriginDropdown = useSelector(getStoreProdDropdownByKey('FPO'))
  const [isImageTouched, setImageTouched] = useState<boolean>(false)

  const filteredProdFormD = prodFormDropdown.filter(
    ({ value }: any) => !Object.values(storeProdTypeToForm).includes(value)
  )
  const canSeeField = permissions.storeProduct?.canSeeField
  const canSeeFSchemaDetail = permissions.vaultFormulaSchema?.canRead

  const checkEntityFieldVisibility = (key: keyof IStoreProd) => {
    const visibilityObj = canSeeField?.[key]
    // default to true to map only custom cases
    const fieldVisibility = visibilityObj ? visibilityObj?.onRead : true
    const isVisibleForOperation = isEditing ? visibilityObj?.onEdit : fieldVisibility

    return isVisibleForOperation && entityFieldVisibileByType({ type: typeValue!, key })
  }

  const checkDisableField = (key: keyof IStoreProd) => {
    return checkDisableFieldInUpdate({ data, key, type: typeValue! })
  }

  const updateHandler = (key: keyof IStoreProd, value: any) => {
    onUpdate(key, value)
  }

  const updateImageHandler = (data: IImageData) => {
    if (!isImageTouched) setImageTouched(true)
    updateHandler('image', data)
  }

  const isIndustrilCodeAndLabCodeLink =
    canSeeFSchemaDetail && data.formulaSchemaId && data.isFormulaSchemaConsistent
  const isFSchemaCodeLinkable = canSeeFSchemaDetail && data.formulaSchemaId

  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell
          value={data.image?.value || ''}
          label={'store-prod-edit'}
          dataType="image"
          inputType="upload"
          isEditing={isEditing}
          inputConfig={{
            fileKey: 'store-prod-edit',
            onChange: (imageData: Record<string, any>) => updateImageHandler(imageData),
            isUrl: !isImageTouched || !isEditing
          }}
        />
      </BoxWrapper>
      <Divider disableBorder />

      <SectionTitle value="General Data" />
      <Divider />
      {checkEntityFieldVisibility('productCode') && (
        <BoxWrapper enablePadding sm={1} md={1} lg={1}>
          <InfoCell label="Product Code" value={get(data, 'productCode', '')} />
        </BoxWrapper>
      )}
      {(checkEntityFieldVisibility('labCode') ||
        checkEntityFieldVisibility('industrialCode')) && (
        <BoxWrapper enablePadding>
          {checkEntityFieldVisibility('industrialCode') && (
            <InfoCell
              label="Industrial Code"
              value={data.industrialCode || ''}
              isEditing={isEditing}
              inputConfig={{
                onChange: (value: string) => updateHandler('industrialCode', value),
                disabled: checkDisableField('industrialCode')
              }}
              dataType={isIndustrilCodeAndLabCodeLink ? 'link' : 'string'}
              linkConfig={{
                path: `${AppRoutes.VAULT_FORMULA_SCHEMA}/${data.formulaSchemaId}?section=industrialCode`
              }}
            />
          )}

          {checkEntityFieldVisibility('labCode') && (
            <InfoCell
              label="Lab Code"
              value={data.labCode || ''}
              isEditing={isEditing}
              inputConfig={{
                onChange: (value: string) => updateHandler('labCode', value),
                disabled: checkDisableField('labCode')
              }}
              dataType={isIndustrilCodeAndLabCodeLink ? 'link' : 'string'}
              linkConfig={{
                path: `${AppRoutes.VAULT_FORMULA_SCHEMA}/${data.formulaSchemaId}?section=industrialCode`
              }}
            />
          )}
        </BoxWrapper>
      )}
      <BoxWrapper enablePadding>
        {checkEntityFieldVisibility('productForm') && (
          <InfoCell
            value={data.productForm || ''}
            label="Product Form"
            inputType="select"
            isEditing={isEditing}
            inputConfig={{
              items: filteredProdFormD,
              onChange: ({ value }: Record<string, any>) =>
                updateHandler('productForm', value),
              disabled: checkDisableField('productForm')
            }}
          />
        )}
        {checkEntityFieldVisibility('groupCode') && (
          <InfoCell
            label="Multipack Code"
            value={data.groupCode || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('groupCode', value),
              disabled: checkDisableField('groupCode')
            }}
          />
        )}
        {checkEntityFieldVisibility('color') && (
          <FlexColorWrapper isEditing={isEditing}>
            <InfoCell
              isEditing={isEditing}
              label="Color"
              value={data.color || ''}
              inputType="select"
              inputConfig={{
                items: colorDropDown,
                onChange: ({ value }: { value: string }) => {
                  updateHandler('color', value)
                },
                disabled: checkDisableField('color')
              }}
            />
            {data?.color && <ColorPreview color={(data?.color as ColorType) || null} />}
          </FlexColorWrapper>
        )}
        {checkEntityFieldVisibility('finish') && (
          <InfoCell
            label="Finish"
            value={data.finish || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('finish', value),
              disabled: checkDisableField('finish')
            }}
          />
        )}
        {checkEntityFieldVisibility('FPO') && (
          <InfoCell
            label="First Production Origin"
            value={data.FPO || ''}
            inputType="select"
            isEditing={isEditing}
            inputConfig={{
              onChange: ({ value }: { value: string }) => updateHandler('FPO', value),
              disabled: checkDisableField('FPO'),
              items: firstProductionOriginDropdown
            }}
          />
        )}
      </BoxWrapper>
      <SectionTitle value="Detailed Information" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkEntityFieldVisibility('applicationArea') && (
          <InfoCell
            label="Application Area"
            value={data.applicationArea || ''}
            isEditing={isEditing}
            inputType="select"
            inputConfig={{
              onChange: ({ value }: { value: string }) =>
                updateHandler('applicationArea', value),
              disabled: checkDisableField('applicationArea'),
              items: applicationAreaDrpDwn
            }}
          />
        )}
        {checkEntityFieldVisibility('productCategory') && (
          <InfoCell
            label="Product Category"
            value={data.productCategory || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('productCategory', value),
              disabled: checkDisableField('productCategory')
            }}
          />
        )}
        {checkEntityFieldVisibility('finalName') && (
          <InfoCell
            label="Final Name"
            value={data.finalName || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('finalName', value),
              disabled: checkDisableField('finalName')
            }}
          />
        )}
        {checkEntityFieldVisibility('panMold') && (
          <InfoCell
            label="PAN/MOLD"
            value={data.panMold || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('panMold', value),
              disabled: checkDisableField('panMold')
            }}
          />
        )}
        {checkEntityFieldVisibility('packagingRef') && (
          <InfoCell
            label="PK INTERCOS CODE"
            value={data.packagingRef || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('packagingRef', value),
              disabled: checkDisableField('packagingRef')
            }}
          />
        )}
        {checkEntityFieldVisibility('pmBU') && (
          <InfoCell
            label="PM BU"
            value={data.pmBU || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('pmBU', value),
              disabled: checkDisableField('pmBU')
            }}
          />
        )}
        {checkEntityFieldVisibility('noteBU') && (
          <InfoCell
            label="Note BU"
            value={data.noteBU || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('noteBU', value),
              disabled: checkDisableField('noteBU')
            }}
          />
        )}
      </BoxWrapper>
      <Divider disableBorder />
      <SectionTitle value="Formula Schema Data" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkEntityFieldVisibility('formulaSchemaCode') && (
          <InfoCell
            label="Formula Scheme"
            value={data.formulaSchemaCode || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('formulaSchemaCode', value),
              disabled: checkDisableField('formulaSchemaCode')
            }}
            dataType={isFSchemaCodeLinkable ? 'link' : 'string'}
            linkConfig={{
              path: `${AppRoutes.VAULT_FORMULA_SCHEMA}/${data.formulaSchemaId}`
            }}
          />
        )}
        {checkEntityFieldVisibility('launchYear') && (
          <InfoCell
            label="Launch Year"
            value={data.launchYear || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('launchYear', value),
              disabled: checkDisableField('launchYear')
            }}
          />
        )}
        {checkEntityFieldVisibility('collection') && (
          <InfoCell
            label="Collection"
            inputType="select"
            value={data.collection || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: ({ value }: { value: string }) =>
                updateHandler('collection', value),
              disabled: checkDisableField('collection'),
              items: collectionDropDown
            }}
          />
        )}
        {checkEntityFieldVisibility('family') && (
          <InfoCell
            label="Family "
            value={data.family || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('family', value),
              disabled: checkDisableField('family')
            }}
          />
        )}
      </BoxWrapper>
      <Divider disableBorder />
      <SectionTitle value="Logistic Data" />
      <Divider />
      <BoxWrapper enablePadding>
        {checkEntityFieldVisibility('colorNameOnLabel') && (
          <InfoCell
            label="Color in Label"
            value={data.colorNameOnLabel || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('colorNameOnLabel', value),
              disabled: checkDisableField('colorNameOnLabel')
            }}
          />
        )}
        {checkEntityFieldVisibility('labCodeInLabel') && (
          <InfoCell
            label="Lab Code in Label"
            value={data.labCodeInLabel || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('labCodeInLabel', value),
              disabled: checkDisableField('labCodeInLabel')
            }}
          />
        )}
        {checkEntityFieldVisibility('shakeWell') && (
          <InfoCell
            label="Shake Well"
            value={data.shakeWell || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('shakeWell', value),
              disabled: checkDisableField('shakeWell')
            }}
          />
        )}
        {checkEntityFieldVisibility('SPF') && (
          <InfoCell
            label="SPF"
            value={data.SPF || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('SPF', value),
              disabled: checkDisableField('SPF')
            }}
          />
        )}
        {checkEntityFieldVisibility('availableQuantity') && (
          <InfoCell label="Available quantity" value={data.availableQuantity || 0} />
        )}
        {checkEntityFieldVisibility('noteSR') && (
          <InfoCell
            label="Note SR"
            value={data.noteSR || ''}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: string) => updateHandler('noteSR', value),
              disabled: checkDisableField('noteSR')
            }}
          />
        )}
      </BoxWrapper>
    </Form>
  )
}

export default ProductDetailContent
