import { isEmpty } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { Loader, PopUp } from '../../components'
import { Footer } from '../../components/Footer'
import Header from '../../components/Header'
import { Error } from '../../containers'
import { selectIsPageLoading } from '../../features/app/selectors'
import { GenericLayoutContainer } from './styled'
import { IGenericLayoutProps } from './types'

const GenericLayout: FC<IGenericLayoutProps> = ({ children, error }) => {
  const isLoading = useSelector(selectIsPageLoading)

  const hasError = !isEmpty(error)

  return (
    <GenericLayoutContainer>
      <PopUp />
      <Header />
      {isLoading && <Loader />}
      {hasError ? <Error error={error} /> : <>{children}</>}
      <Footer />
    </GenericLayoutContainer>
  )
}

export default GenericLayout
