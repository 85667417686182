import {
  ADMIN_GROUP,
  AZ_DBPRODOTTO_ADMIN,
  AZ_DBPRODOTTO_BU,
  AZ_DBPRODOTTO_FS_READ,
  AZ_DBPRODOTTO_PACKAGING_EDIT,
  AZ_DBPRODOTTO_PRODUCT_EDIT,
  AZ_DBPRODOTTO_PRODUCT_READ,
  AZ_DBPRODOTTO_REGULATORY_READ,
  AZ_DBPRODOTTO_REGULATORY_RW,
  AZ_DBS_SALA_CAMPIONI_AGREGATE,
  AZ_DBS_SALES_MARKETING_BU_MERCATI,
  AZ_WIKIPACK_IE,
  AZ_WIKIPACK_MRKT,
  AZ_WIKIPACK_PKACCEVO,
  AZ_WIKIPACK_PKDEV,
  AZ_WIKIPACK_QC,
  AZ_WIKIPACK_RDF,
  AZ_WIKIPACK_SHUB,
  AZ_WIKIPACK_SRC,
  AZ_WIKIPACK_TCS,
  SOURCING_CN_GROUP,
  SOURCING_EU_GROUP,
  SOURCING_US_GROUP
} from '../auth-msal'
import { ICanSeeField, ICanSeeValues, IPermissions } from '../features/users/model'
import { AppRoutes } from '../pages/constants'

export const routeVisibilityHelper: (
  route: AppRoutes,
  permissions: IPermissions
) => boolean = (route, permissions) => {
  if (route === AppRoutes.VAULT_ADMINISTRATION) {
    return permissions.administration?.canReach || false
  }
  if (route === AppRoutes.VAULT_ADMINISTRATION_MANAGEMENT) {
    return permissions.administration?.canReach || false
  }
  if (route === AppRoutes.VAULT_ADMINISTRATION_ATTACHMENT) {
    return permissions.administration?.canReach || false
  }
  if (route === AppRoutes.VAULT_ADMINISTRATION_SUPPLIER) {
    return permissions.administration?.canReach || false
  }
  if (route === AppRoutes.VAULT_ADMINISTRATION_SUPPLIER_RESULT) {
    return permissions.administration?.canReach || false
  }
  if (route === AppRoutes.VAULT_PRODUCTS_SEARCH) {
    return permissions.vaultProduct?.canRead || false
  }
  if (route === AppRoutes.VAULT_PRODUCTS_RESULTS) {
    return permissions.vaultProduct?.canRead || false
  }
  if (route === AppRoutes.VAULT_PRODUCT_DETAILS) {
    return permissions.vaultProduct?.canRead || false
  }
  if (route === AppRoutes.VAULT_PRODUCT_CREATION) {
    return permissions.vaultProduct?.canCreate || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_RESULT) {
    return permissions.wikipackPackaging?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGE_CREATION) {
    return permissions.wikipackPackaging?.canCreate || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGE_CREATION_FLOW) {
    return permissions.wikipackPackaging?.canCreate || false
  }
  if (route === AppRoutes.VAULT_FORMULA_SCHEMA) {
    return permissions.vaultFormulaSchema?.canRead || false
  }
  if (route === AppRoutes.VAULT_FORMULA_SCHEMA_RESULT) {
    return permissions.vaultFormulaSchema?.canRead || false
  }
  if (route === AppRoutes.VAULT_FORMULA_SCHEMA_DETAILS) {
    return permissions.vaultFormulaSchema?.canRead || false
  }
  if (route === AppRoutes.VAULT_TOOLING_SEARCH) {
    return permissions.vaultTooling?.canRead || false
  }
  if (route === AppRoutes.VAULT_TOOLING_RESULTS) {
    return permissions.vaultTooling?.canRead || false
  }
  if (route === AppRoutes.VAULT_TOOLING_DETAILS) {
    return permissions.vaultTooling?.canRead || false
  }
  if (route === AppRoutes.VAULT_TOOLING_CREATION) {
    return permissions.vaultTooling?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING) {
    return permissions.wikipackPackaging?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_DETAILS) {
    return permissions.wikipackPackaging?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_MASTER_SPECIFICATIONS) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_PROJECT_SPECIFICATIONS) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_PROJECT_SPECIFICATIONS_NEW) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (
    route ===
    AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_PROJECT_SPECIFICATIONS_NEW_FROM_SEARCH
  ) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_MASTER_SPECIFICATIONS_NEW) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (
    route ===
    AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_MASTER_SPECIFICATIONS_NEW_FROM_SEARCH
  ) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_NEW) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_NEW_FROM_SEARCH) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS_NEW) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_DETAIL) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS_DETAIL) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_CHARACTERISTIC) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS_CHARACTERISTIC) {
    return permissions.wikipackMaster?.canRead || false
  }
  if (route === AppRoutes.WIKIPACK_PACKAGING_VARIANT) {
    return permissions.wikipackPackaging?.canRead || false
  }
  if (route === AppRoutes.STORE_PRODUCTS_SEARCH) {
    return permissions.storeProduct?.canRead || false
  }
  if (route === AppRoutes.STORE_PRODUCTS_DETAILS) {
    return permissions.storeProduct?.canRead || false
  }
  if (route === AppRoutes.STORE_CART) {
    return permissions.storeOrders?.canCreate || false
  }
  if (route === AppRoutes.STORE_PRODUCTS) {
    return permissions.storeProduct?.canRead || false
  }
  if (route === AppRoutes.STORE_ENTITY_CHILD) {
    return permissions.storeProduct?.canRead || false
  }
  if (route === AppRoutes.STORE_ENTITY_CREATION) {
    return permissions.storeProduct?.canCreate || false
  }
  if (route === AppRoutes.STORE_ORDERS) {
    return permissions.storeProduct?.canRead || false
  }
  if (route === AppRoutes.STORE_ORDER_DETAILS) {
    return permissions.storeOrders?.canRead || false
  }
  if (route === AppRoutes.TEMPLATES) {
    return window.location.origin.includes('localhost:3000')
  }
  return false
}

export const getVaultProductCanSeeValues: (roles: string[]) => ICanSeeValues = (
  roles
) => {
  const isBu =
    roles.includes(AZ_DBPRODOTTO_BU) &&
    roles.includes(AZ_DBPRODOTTO_PRODUCT_READ) &&
    roles.includes(AZ_DBPRODOTTO_FS_READ) &&
    !roles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
    !roles.includes(AZ_WIKIPACK_RDF) &&
    !roles.includes(AZ_WIKIPACK_TCS) &&
    !roles.includes(AZ_WIKIPACK_SHUB)
  const isBuReferents =
    roles.includes(AZ_DBPRODOTTO_BU) &&
    roles.includes(AZ_DBPRODOTTO_PRODUCT_EDIT) &&
    roles.includes(AZ_DBPRODOTTO_FS_READ)
  return {
    productStatus: [
      {
        value: 'READY_TO_GO',
        onCreate: true,
        onEdit:
          roles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN),
        onRead:
          roles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'LEVEL_2_IN_PROGRESS',
        onCreate: true,
        onEdit:
          roles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN),
        onRead:
          roles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'CLIENT_EXCLUSIVE',
        onCreate: true,
        onEdit:
          roles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN),
        onRead:
          roles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'ARCHIVE',
        onCreate: true,
        onEdit: isBu || isBuReferents || roles.includes(AZ_DBPRODOTTO_ADMIN),
        onRead: isBu || isBuReferents || roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'NOT_STABLE',
        onCreate: true,
        onEdit: isBu || isBuReferents || roles.includes(AZ_DBPRODOTTO_ADMIN),
        onRead: isBu || isBuReferents || roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'NOT_COMPATIBLE_OR_INDUSTRIALIZABLE',
        onCreate: true,
        onEdit: isBu || isBuReferents || roles.includes(AZ_DBPRODOTTO_ADMIN),
        onRead: isBu || isBuReferents || roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'WIP_INFORMATION',
        onCreate: true,
        onEdit: isBu || isBuReferents || roles.includes(AZ_DBPRODOTTO_ADMIN),
        onRead: isBu || isBuReferents || roles.includes(AZ_DBPRODOTTO_ADMIN)
      }
    ],
    attachments: [
      {
        value: 'technical drawing',
        onCreate: true,
        onEdit: true,
        onRead:
          roles.includes(AZ_WIKIPACK_RDF) ||
          roles.includes(AZ_WIKIPACK_PKDEV) ||
          roles.includes(AZ_WIKIPACK_SRC) ||
          roles.includes(AZ_WIKIPACK_QC) ||
          roles.includes(AZ_WIKIPACK_IE) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'areadeco',
        onCreate: true,
        onEdit: true,
        onRead:
          roles.includes(AZ_WIKIPACK_PKACCEVO) ||
          roles.includes(AZ_WIKIPACK_RDF) ||
          roles.includes(AZ_WIKIPACK_PKDEV) ||
          roles.includes(AZ_WIKIPACK_QC) ||
          roles.includes(AZ_WIKIPACK_SRC) ||
          roles.includes(AZ_WIKIPACK_TCS) ||
          roles.includes(AZ_WIKIPACK_IE) ||
          roles.includes(AZ_WIKIPACK_MRKT) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'why clean',
        onCreate: false,
        onEdit: false,
        onRead: false
      },
      {
        value: 'master specification',
        onCreate: false,
        onEdit: false,
        onRead: false
      },
      {
        value: 'project specification',
        onCreate: false,
        onEdit: false,
        onRead: false
      },
      {
        value: 'regulatory',
        onCreate: false,
        onEdit: false,
        onRead: false
      },
      {
        value: 'other',
        onCreate: false,
        onEdit: false,
        onRead: false
      },
      {
        value: 'tested claim',
        onCreate: false,
        onEdit: false,
        onRead: true
      },
      {
        value: 'homologation report',
        onCreate: false,
        onEdit: false,
        onRead: false
      }
    ]
  }
}

export const getVaultFSchemaCanSeeField: (roles: string[]) => ICanSeeField = (roles) => {
  return {
    countryCheckComplianceNotes: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    vegan: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    veganNotes: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    RSPO: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    microplasticFree: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    microplasticFreeNotes: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    siliconesFree: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    talcFree: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    pegFree: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    d5Free: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    silicaFree: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    GMO: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    paraben: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    cleanOfPreservatives: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    cleanOfMineralOils: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    },

    lastUpdate: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
        roles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
    }
  }
}

export const getWikipackVariantCanSeeValues: (roles: string[]) => ICanSeeValues = (
  roles
) => {
  return {
    attachments: [
      {
        value: 'technical drawing',
        onCreate: true,
        onEdit: true,
        onRead:
          roles.includes(AZ_WIKIPACK_RDF) ||
          roles.includes(AZ_WIKIPACK_PKDEV) ||
          roles.includes(AZ_WIKIPACK_SRC) ||
          roles.includes(AZ_WIKIPACK_QC) ||
          roles.includes(AZ_WIKIPACK_IE) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'areadeco',
        onCreate: true,
        onEdit: true,
        onRead:
          roles.includes(AZ_WIKIPACK_PKACCEVO) ||
          roles.includes(AZ_WIKIPACK_RDF) ||
          roles.includes(AZ_WIKIPACK_PKDEV) ||
          roles.includes(AZ_WIKIPACK_QC) ||
          roles.includes(AZ_WIKIPACK_SRC) ||
          roles.includes(AZ_WIKIPACK_TCS) ||
          roles.includes(AZ_WIKIPACK_IE) ||
          roles.includes(AZ_WIKIPACK_MRKT) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'why clean',
        onCreate: true,
        onEdit: true,
        onRead:
          roles.includes(AZ_WIKIPACK_PKACCEVO) ||
          roles.includes(AZ_WIKIPACK_RDF) ||
          roles.includes(AZ_WIKIPACK_PKDEV) ||
          roles.includes(AZ_WIKIPACK_QC) ||
          roles.includes(AZ_WIKIPACK_SRC) ||
          roles.includes(AZ_WIKIPACK_IE) ||
          roles.includes(AZ_WIKIPACK_MRKT) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'master specification',
        onCreate: true,
        onEdit: true,
        onRead:
          roles.includes(AZ_WIKIPACK_PKDEV) ||
          roles.includes(AZ_WIKIPACK_QC) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'project specification',
        onCreate: true,
        onEdit: true,
        onRead:
          roles.includes(AZ_WIKIPACK_PKDEV) ||
          roles.includes(AZ_WIKIPACK_QC) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'regulatory',
        onCreate: true,
        onEdit: true,
        onRead:
          roles.includes(AZ_WIKIPACK_PKDEV) ||
          roles.includes(AZ_WIKIPACK_TCS) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'other',
        onCreate: true,
        onEdit: true,
        onRead: roles.includes(AZ_WIKIPACK_PKDEV) || roles.includes(AZ_DBPRODOTTO_ADMIN)
      },
      {
        value: 'homologation report',
        onCreate: true,
        onEdit: true,
        onRead:
          roles.includes(AZ_WIKIPACK_PKDEV) ||
          roles.includes(AZ_WIKIPACK_QC) ||
          roles.includes(AZ_DBPRODOTTO_ADMIN)
      }
    ]
  }
}

export const getStoreProductCanSeeField: (roles: string[]) => ICanSeeField = (roles) => {
  return {
    productCode: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    productForm: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    finalName: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    labCode: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    groupCode: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    color: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    finish: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    FPO: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    applicationArea: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    packagingRef: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    formulaSchemaCode: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    industrialCode: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    launchYear: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    collection: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    family: {
      onCreate: true,
      onEdit: true,
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    noteSR: {
      onCreate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      onEdit:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE)
    },
    noteBU: {
      onCreate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      onEdit:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      onRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE)
    }
  }
}

export const checkUser: (roleList: string[], groupDefinition: string[]) => boolean = (
  roleList,
  groupDefinition
) => {
  return groupDefinition.every((role) => roleList.includes(role))
}

export const canUserExportTargetPrice: (arg: string[]) => boolean = (roleList) => {
  const isSourcingEU = checkUser(roleList, SOURCING_EU_GROUP)
  const isSourcingCN = checkUser(roleList, SOURCING_CN_GROUP)
  const isSourcingUS = checkUser(roleList, SOURCING_US_GROUP)
  const isAdmin = checkUser(roleList, ADMIN_GROUP)
  return isSourcingEU || isSourcingCN || isSourcingUS || isAdmin
}
