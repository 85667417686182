import { Popconfirm } from 'antd'
import { FC } from 'react'
import Button from '../Button'
import { ITooltipBtnProps } from './types'

const TooltipBtn: FC<ITooltipBtnProps> = ({
  btnLabel,
  btnCallback = () => {},
  hasIcon,
  iconName,
  onConfirm = () => {},
  message,
  btnVariant,
  enableTooltip = false,
  tooltipLabel,
  iconPosition,
  disabled = false
}) => {
  return (
    <Popconfirm title={message} onConfirm={onConfirm} okText="Yes" cancelText="No">
      <Button
        iconPosition={iconPosition}
        disabled={disabled}
        label={btnLabel}
        iconName={hasIcon ? iconName : ''}
        onClick={btnCallback}
        variant={btnVariant}
        tooltipLabel={tooltipLabel}
      />
    </Popconfirm>
  )
}

export default TooltipBtn
