import { TablePaginationConfig } from 'antd'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle, Table } from '../../components'
import { Divider } from '../../components/Divider'
import { SUPPLIER_MANAGE_TABLE_CONFIG } from '../../components/Packaging/AttachmentsTable/constants'
import { BOTTOM_RIGHT } from '../../components/Table/constants'
import { ITActionColumnConfig } from '../../components/Table/types'
import { PageContent } from '../../containers'
import {
  administrationActions,
  administrationSliceActions
} from '../../features/administration'
import {
  selectAdminError,
  selectSupplierList,
  selectSupplierPaginationsNew
} from '../../features/administration/selectors'
import { GenericLayout } from '../../layouts'
import { columnsGeneratorHelper, rowsGeneratorHelper } from '../../utils'
import { AppRoutes } from '../constants'
import { FooterActions, footerActionHeight } from '../CreationPackage/styled'

const SupplierResult: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const adminError = useSelector(selectAdminError)
  const supplierList = useSelector(selectSupplierList)
  const pagination = useSelector(selectSupplierPaginationsNew)

  const changePageHandler = (pageData: TablePaginationConfig) => {
    const { current } = pageData
    dispatch(
      administrationSliceActions.updateSupplierPagination({
        key: 'page',
        value: current || 1
      })
    )
    dispatch(administrationActions.searchSuppliers())
  }

  const backOnFiltersForm = () => {
    dispatch(administrationSliceActions.clearSupplierPagination())
    navigate(`${AppRoutes.VAULT_ADMINISTRATION}/supplier`)
  }

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      return (
        <Button
          label="Edit"
          variant="link"
          onClick={() =>
            navigate(`${AppRoutes.VAULT_ADMINISTRATION}/supplier/${data.id}`)
          }
        />
      )
    }
  }

  useEffect(() => {
    dispatch(administrationActions.searchSuppliers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { page, pageSize, total } = pagination

  const columns = columnsGeneratorHelper(SUPPLIER_MANAGE_TABLE_CONFIG)
  const dataRow = supplierList?.length
    ? rowsGeneratorHelper(supplierList, SUPPLIER_MANAGE_TABLE_CONFIG)
    : []

  return (
    <GenericLayout error={adminError}>
      <PageContent
        headerHeight={headerHeight}
        footerHeight={footerHeight + footerActionHeight}
      >
        <SectionTitle value="Administration" isBold />
        <Divider disableBorder />
        <SectionTitle value="Supplier Management" isBold />

        <Table
          pagination={{
            current: page,
            pageSize,
            total
          }}
          onChangePage={changePageHandler}
          enablePagination
          paginationPosition={BOTTOM_RIGHT}
          rowKey="id"
          columns={columns}
          items={dataRow}
          forceHeight
          actionColumnConfig={actionConfig}
        />

        <FooterActions>
          <Button label="Back" onClick={backOnFiltersForm} />
        </FooterActions>
      </PageContent>
    </GenericLayout>
  )
}

export default SupplierResult
