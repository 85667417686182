import { Form } from 'antd'
import { find, get } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, InfoCell, SectionTitle, Table, TooltipBtn } from '../../components'
import { Accordion } from '../../components/Accordion'
import { BoxWrapper } from '../../components/BoxWrapper'
import { Divider } from '../../components/Divider'
import { FlexSection } from '../../components/FlexSection'
import { TOP_RIGHT } from '../../components/Table/constants'
import { ITActionColumnConfig } from '../../components/Table/types'
import { appSliceActions } from '../../features/app'
import { masterSpecActions } from '../../features/masterSpec/actions'
import { IProjectSpecDetail } from '../../features/masterSpec/model'
import { masterSpecSliceActions } from '../../features/masterSpec/reducer'
import {
  selectIsLoading,
  selectSpecificationSections
} from '../../features/masterSpec/selectors'
import { PACKAGING_STATUS } from '../../features/packaging/constants'
import { SapCodeConcerned } from '../../features/packaging/model'
import { IImageData } from '../../features/storeProducts/model'
import {
  masterSpecStatus,
  statusOptions
} from '../../pages/MasterSpecificationDetail/constants'
import { createApplyAllSapToCharactersMessage } from '../../pages/ProjectSpecificationDetail/utils'
import { columnsGeneratorHelper } from '../../utils'
import AddProjectSpecCharacteristicModal from './AddProjectSpecCharacteristicModal'
import { PROJECT_SPEC_DETAIL_FORMULA } from './constants'
import { FooterContent } from './styled'
import { IProjectSpecificationDetailContentProps } from './types'
import { filterCharacteristics } from './utils'

const ProjectSpecificationDetailsContent: FC<IProjectSpecificationDetailContentProps> = ({
  isEditing,
  packageDetails,
  projectSpecDetails,
  characteristicList,
  toggleChanges = () => {}
}) => {
  const isLoading = useSelector(selectIsLoading)
  const specificationSections = useSelector(selectSpecificationSections)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSapCodeConcernedChange = (value: Partial<SapCodeConcerned>, index: number) => {
    const current = projectSpecDetails?.concernedSapCodes?.[index]
    const newSapCodeConcerned = { ...current, ...value } as SapCodeConcerned
    const updatedSapCodeConcerned = [...(projectSpecDetails?.concernedSapCodes || [])]
    updatedSapCodeConcerned.splice(index, 1, newSapCodeConcerned)
    onChange({ key: 'concernedSapCodes', value: updatedSapCodeConcerned })
  }

  const imageValue = get(packageDetails, 'image.value', '')
  const supplierName = get(packageDetails, 'testedSupplier.name', '')
  const supplierItemCode = get(packageDetails, 'testedSupplier.SAPCodes', '')
  const packageId = get(packageDetails, 'id', '')
  const specId = get(projectSpecDetails, 'id', '')
  const packageAvailable =
    get(packageDetails, 'status', '') === PACKAGING_STATUS.AVAILABLE

  const aestheticAndDecorationsId = find(
    specificationSections,
    (section) => section.code === 'A'
  )?.id

  const functionalAndDimensionalId = find(
    specificationSections,
    (section) => section.code === 'B'
  )?.id

  const aestheticAndDecorationList =
    filterCharacteristics(
      get(projectSpecDetails, 'projectSpecificationCharacteristics', []),
      aestheticAndDecorationsId || ''
    ) || []

  const functionalAndDimensionalList =
    filterCharacteristics(
      get(projectSpecDetails, 'projectSpecificationCharacteristics', []),
      functionalAndDimensionalId || ''
    ) || []

  const isSentToSap = projectSpecDetails?.status === masterSpecStatus.SENT_TO_SAP

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      return (
        <>
          <TooltipBtn
            tooltipLabel="Delete"
            hasIcon
            disabled={!isEditing}
            iconName="DeleteOutlined"
            message="Do you want to delete this row?"
            onConfirm={() => {
              deleteCharacteristicHandler(data.id)
            }}
            btnVariant="link"
          />
          <Button
            iconName="FormOutlined"
            variant="link"
            disabled={isEditing || !packageAvailable}
            onClick={() => {
              dispatch(masterSpecSliceActions.setNewProjectCharacteristic(data))
              navigate(
                `/wikipack/packaging/${packageId}/project-specifications/${specId}/characteristicEditor`
              )
            }}
          />
        </>
      )
    }
  }

  const columns = columnsGeneratorHelper(PROJECT_SPEC_DETAIL_FORMULA)

  const onChange = (input: { key: keyof IProjectSpecDetail; value: any }) => {
    toggleChanges(true)
    dispatch(masterSpecSliceActions.updateNewProjectSpecification(input))
  }

  const addCharacteristicHandler = (characteristic: any, sectionId: string) => {
    const characteristicToAdd = {
      ...characteristic,
      specificationSectionId: sectionId,
      sapCodes: characteristic.sapCodes || []
    }
    const payload: { key: keyof IProjectSpecDetail; value: any } = {
      key: 'projectSpecificationCharacteristics',
      value: [
        ...get(projectSpecDetails, 'projectSpecificationCharacteristics', []),
        characteristicToAdd
      ]
    }
    dispatch(masterSpecSliceActions.updateNewProjectSpecification(payload))
    toggleChanges(true)
  }

  const deleteCharacteristicHandler = (charId: string) => {
    const payload: { key: keyof IProjectSpecDetail; value: any } = {
      key: 'projectSpecificationCharacteristics',
      value: [
        ...get(projectSpecDetails, 'projectSpecificationCharacteristics', []).filter(
          (c) => c.id !== charId
        )
      ]
    }
    dispatch(masterSpecSliceActions.updateNewProjectSpecification(payload))
    toggleChanges(true)
  }

  const updateImageHandler = (data: IImageData) => {
    onChange({ key: 'thumbnailOverride', value: data })
  }

  const concernedSapCodes = projectSpecDetails?.concernedSapCodes || []
  const isApplyAllSapDisabled: boolean =
    !concernedSapCodes.length ||
    (!aestheticAndDecorationList.length && !functionalAndDimensionalList.length)

  const openApplyAllSapCode = () => {
    dispatch(
      appSliceActions.openPopUp({
        isOpen: true,
        key: 'apply_all_sap_codes',
        title: 'Apply all sap codes',
        message: createApplyAllSapToCharactersMessage({
          sapCodes: concernedSapCodes,
          status: projectSpecDetails.status!
        }),
        footer: [
          <FooterContent>
            <Button
              key="cancel"
              label="Cancel"
              onClick={() => {
                dispatch(appSliceActions.closePopUp())
              }}
              variant="ghost"
            />
            <Button
              key="Confirm"
              label="Confirm"
              onClick={() => {
                dispatch(
                  masterSpecActions.applyAllSapCodesToCharacters({
                    sapCodes: concernedSapCodes
                  })
                )
                dispatch(appSliceActions.closePopUp())
              }}
            />
          </FooterContent>
        ],
        isClosable: true
      })
    )
  }
  return (
    <Form layout="vertical">
      <SectionTitle value={'Basic Info'} />
      <Divider disableBorder={false} />
      <InfoCell
        value={get(projectSpecDetails, 'thumbnailOverride.url') || imageValue}
        label=""
        isEditing={isEditing}
        dataType="image"
        inputType="upload"
        inputConfig={{
          fileKey: 'project-spec-edit',
          isUrl: true,
          onChange: (imageData: any) => {
            updateImageHandler(imageData)
          }
        }}
      />
      <BoxWrapper columnCount={2} enablePadding>
        <InfoCell label="Supplier Name" value={supplierName} isEditing={false} />
        <InfoCell label="Supplier Item Code" value={supplierItemCode} isEditing={false} />
      </BoxWrapper>
      <BoxWrapper columnCount={3} enablePadding>
        <InfoCell
          label="Title"
          value={projectSpecDetails?.title || ''}
          isEditing={isEditing}
          inputConfig={{
            onChange: (value: string) => {
              onChange({ key: 'title', value })
            },
            max: 200,
            placeholder: 'Max 200 char'
          }}
        />
        <InfoCell
          label="Description"
          value={projectSpecDetails?.description || ''}
          isEditing={isEditing}
          inputConfig={{
            onChange: (value: string) => onChange({ key: 'description', value })
          }}
        />
        <InfoCell
          label="Sap Description"
          value={projectSpecDetails?.sapDescription || ''}
          isEditing={!isSentToSap && isEditing}
          inputConfig={{
            placeholder: 'Max 38 char',
            required: true,
            error: !get(projectSpecDetails, 'sapDescription', ''),
            onChange: (value: string) => {
              if (value.length <= 38)
                return onChange({ key: 'sapDescription', value: value })
            }
          }}
        />
        <InfoCell
          value={projectSpecDetails?.status || ''}
          label="Status"
          inputType="select"
          isEditing={isEditing && !isSentToSap}
          inputConfig={{
            items: statusOptions,
            onChange: ({ value }: Record<string, any>) =>
              onChange({ key: 'status', value })
          }}
        />
        <InfoCell
          label="Project Master Code"
          value={projectSpecDetails?.masterCode || ''}
          isEditing={isEditing}
          inputConfig={{
            onChange: (value: string) => onChange({ key: 'masterCode', value })
          }}
        />
        <InfoCell
          label="Customer Project Name"
          value={projectSpecDetails?.customerProjectName || ''}
          isEditing={isEditing}
          inputConfig={{
            onChange: (value: string) => onChange({ key: 'customerProjectName', value })
          }}
        />
        <InfoCell
          label="Nb Shade"
          value={projectSpecDetails?.nbShade || ''}
          isEditing={isEditing}
          inputConfig={{
            onChange: (value: string) => onChange({ key: 'nbShade', value })
          }}
        />
        <InfoCell
          label="Remarks"
          value={projectSpecDetails?.remarks || ''}
          isEditing={isEditing}
          inputConfig={{
            onChange: (value: string) => onChange({ key: 'remarks', value })
          }}
        />
      </BoxWrapper>
      <Divider disableBorder />
      <Accordion
        title="Sap Code Concerned"
        variant="h3"
        open={true}
        isDate={false}
        subtitleValue={''}
        subtitleLabel={''}
      >
        {concernedSapCodes?.map((sapCode, i) => (
          <BoxWrapper key={i} columnCount={2} enablePadding>
            <InfoCell
              label="Code"
              value={sapCode.code || ''}
              isEditing={isEditing}
              inputConfig={{
                required: true,
                error: !sapCode.code,
                onChange: (value: string) => onSapCodeConcernedChange({ code: value }, i)
              }}
            />
            <InfoCell
              label="Description"
              value={sapCode.description || ''}
              isEditing={isEditing}
              inputConfig={{
                onChange: (value: string) => {
                  onSapCodeConcernedChange({ description: value }, i)
                }
              }}
            />
            <InfoCell
              label="Sap Description"
              value={sapCode.sapDescription || ''}
              isEditing={false}
            />
            {isEditing && (
              <Button
                label="Delete"
                variant="link"
                iconName="DeleteOutlined"
                iconPosition="left"
                onClick={() =>
                  onChange({
                    key: 'concernedSapCodes',
                    value: projectSpecDetails?.concernedSapCodes?.filter(
                      (_, index) => index !== i
                    )
                  })
                }
              />
            )}
          </BoxWrapper>
        ))}

        <Button
          iconPosition="left"
          disabled={!isEditing}
          iconName="PlusCircleOutlined"
          variant="link"
          label="Add Sap Code Concerned"
          onClick={() =>
            onChange({
              key: 'concernedSapCodes',
              value: [...concernedSapCodes, {}] as SapCodeConcerned[]
            })
          }
        />
      </Accordion>
      <Divider disableBorder />
      <FlexSection flexDirection="column" gap={0}>
        {!isEditing && (
          <Button
            iconPosition="left"
            disabled={isApplyAllSapDisabled}
            iconName="PlusCircleOutlined"
            variant="link"
            label="Apply Sap Codes to all Characteristics"
            onClick={openApplyAllSapCode}
          />
        )}
      </FlexSection>

      <br />
      <br />
      <SectionTitle value="Aesthetics & Decorations" />
      <Divider disableBorder={false} />
      <Table
        enablePagination={false}
        paginationPosition={TOP_RIGHT}
        actionColumnConfig={actionConfig}
        rowKey="id"
        columns={columns}
        items={aestheticAndDecorationList}
        isLoading={isLoading}
      />
      <Divider disableBorder />
      <AddProjectSpecCharacteristicModal
        onAdd={(characteristic) =>
          addCharacteristicHandler(characteristic, aestheticAndDecorationsId || '')
        }
        isEditing={isEditing}
        btnLabel={'Add Characteristic'}
        characteristicList={characteristicList}
        sectionId={aestheticAndDecorationsId || ''}
        modalKey="master-spec-add-characteristic"
        readonlyFields={{ method: 'MP-036' }}
      />
      <br />
      <br />
      <br />
      <SectionTitle value="Functional & Dimensional" />
      <Divider disableBorder={false} />
      <Table
        enablePagination={false}
        paginationPosition={TOP_RIGHT}
        rowKey="id"
        columns={columns}
        items={functionalAndDimensionalList}
        actionColumnConfig={actionConfig}
        isLoading={isLoading}
      />
      <Divider disableBorder />
      <AddProjectSpecCharacteristicModal
        onAdd={(characteristic) =>
          addCharacteristicHandler(characteristic, functionalAndDimensionalId || '')
        }
        isEditing={isEditing}
        btnLabel={'Add Characteristic'}
        sectionId={functionalAndDimensionalId || ''}
        characteristicList={characteristicList}
        modalKey="master-spec-add-FD-characteristic"
      />
      <br />
      <br />
    </Form>
  )
}

export default ProjectSpecificationDetailsContent
