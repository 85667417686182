import { fileSaver } from '../../utils/fileSaver'
import { getImageThumb } from '../../utils/getImage'
import Button from '../Button'
import { Tooltip } from '../Tooltip'

type Props = {
  url?: string
}

export const ActionsAttachs = ({ url }: Props) => {
  const urlAttach = getImageThumb(url) || ''

  return (
    <>
      <Tooltip title="Download">
        <Button
          variant="link"
          onClick={() => fileSaver(urlAttach)}
          iconName="DownloadOutlined"
          size="large"
        />
      </Tooltip>
    </>
  )
}
