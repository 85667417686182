import { Form } from 'antd'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  canUserEditPackaging,
  canUserEditTargetPrice,
  canUserSeeTargetPrice
} from '../../../auth-msal'
import { PackageGeneralInfo } from '../../../components'
import ImageInfo from '../../../components/ImageInfo'
import { Loader } from '../../../components/Loader'
import TargetPrice from '../../../components/Packaging/TargetPrice'
import { administrationActions } from '../../../features/administration/actions'
import { packagingActions } from '../../../features/packaging'
import { getIsAlreadyVial, getIsLoading } from '../../../features/packaging/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import { checkFieldVisibilityByRoles } from '../../../utils'
import { IDetailInformationProps } from './types'

const DetailInformation: FC<IDetailInformationProps> = ({
  isEditing = false,
  imageFileKey,
  imageAlt = 'detail',
  data,
  isVial = false,
  isCategoryPreselected = false,
  updateData = () => {},
  isCreating = false,
  isMulti
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)
  const userRoles = useSelector(selectUserRole)
  const isAlreadyVial = useSelector(getIsAlreadyVial)
  useEffect(() => {
    if (isVial && isCategoryPreselected && !isAlreadyVial) {
      updateData('category', 'Vials')
    }
    dispatch(packagingActions.getCountriesOfOriginDropdown())
    dispatch(packagingActions.getTestedSupplier())
    dispatch(administrationActions.getGroupNames())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const canReadTargetPricePermssion = canUserSeeTargetPrice(userRoles)
  const canEditTargetPricePermission = canUserEditTargetPrice(userRoles)

  const isFieldVisible = (key: string) => {
    return checkFieldVisibilityByRoles({
      key,
      roles: userRoles,
      isOnCreate: isCreating && isEditing,
      isOnEdit: !isCreating && isEditing,
      isOnRead: !isCreating && !isEditing
    })
  }

  const canReadTargetPrice = canReadTargetPricePermssion && !isCreating && !isEditing
  const canWriteTargetPrice = canEditTargetPricePermission && !isCreating && isEditing
  const canEditPackage = canUserEditPackaging(userRoles)

  return (
    <Form layout="vertical">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isFieldVisible('image') && (
            <ImageInfo
              data={data}
              fileKey={imageFileKey}
              imageAlt={imageAlt}
              updateData={updateData}
              isEditing={isEditing}
              isCreating={isCreating}
            />
          )}
          <PackageGeneralInfo
            data={data}
            isEditing={isEditing && canEditPackage}
            updateData={updateData}
            categoryPreselected={isCategoryPreselected}
            isCreating={isCreating}
            isMulti={isMulti}
          />
          <br />
          {(canReadTargetPrice || canWriteTargetPrice) && (
            <TargetPrice
              data={data}
              isEditing={isEditing && canWriteTargetPrice}
              updateData={updateData}
              categoryPreselected={isCategoryPreselected}
              isCreating={isCreating}
              isMulti={isMulti}
            />
          )}
        </>
      )}
    </Form>
  )
}

export default DetailInformation
