import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import { storeActions, storeSliceActions } from '../../features/storeProducts'
import { IStoreProdChild } from '../../features/storeProducts/model'
import {
  getDetail,
  selectChildDetail,
  selectLoader,
  selectNewChild,
  selectStoreProdError
} from '../../features/storeProducts/selectors'
import { selectPermissions } from '../../features/users/selectors'

import { GenericLayout } from '../../layouts'
import { checkChildMantatoryFieldInUpdate } from '../../utils/entityHelper'
import { AppRoutes } from '../constants'
import DetailContent from './DetailContent'
import { ButtonsWrapper } from './styled'

const StoreProdChildDetail: FC = () => {
  const dispatch = useDispatch()
  const isStoreLoading = useSelector(selectLoader)
  const storeProdError = useSelector(selectStoreProdError)
  const parentDetail = useSelector(getDetail)
  const childData = useSelector(selectChildDetail)
  const newChildData = useSelector(selectNewChild)
  const permissions = useSelector(selectPermissions)
  const [isOnEdit, setIsOnEdit] = useState(false)

  useEffect(() => {
    if (id && parentId) {
      if (!parentDetail) {
        dispatch(storeActions.getDetail({ id: parentId }))
      }
      dispatch(storeActions.getChildDetail({ id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { parentId, id } = useParams()

  const canEdit = permissions.storeProduct?.canUpdate

  const data = isOnEdit ? newChildData : childData

  const editModeHandler = () => {
    setIsOnEdit(true)
    dispatch(storeSliceActions.setNewChildTemp())
  }

  const cancelHandler = () => {
    setIsOnEdit(false)
    dispatch(storeSliceActions.resetNew())
  }

  const updateChildHandler = () => {
    setIsOnEdit(false)
    dispatch(storeActions.updateStoreProdChild())
  }

  const deleteProdHandler = () => {
    if (id) {
      dispatch(storeActions.deleteStoreProdChild({ id }))
    }
  }

  const updateChildKeyHandler = (key: keyof IStoreProdChild, value: any) => {
    dispatch(storeSliceActions.updateNewChild({ key, value }))
  }

  const checkIfDisable = () => {
    return !checkChildMantatoryFieldInUpdate({
      data: newChildData,
      type: parentDetail?.productForm || ''
    })
  }

  return (
    <GenericLayout error={storeProdError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {isStoreLoading && <Loader />}
        <>
          <Breadcrumb
            items={[
              { name: 'Products', href: AppRoutes.STORE_PRODUCTS },
              {
                name: `${parentDetail?.productCode || '-'}`,
                href: `${AppRoutes.STORE_PRODUCTS_SEARCH}/${parentId}`
              },
              { name: `${childData?.finalName || '-'}` }
            ]}
          />

          <Divider disableBorder />
          <SectionTitle
            value="Component Detail"
            isBold
            addon={
              <ButtonsWrapper>
                {!isOnEdit ? (
                  <>
                    {canEdit && (
                      <Button label="Edit" variant="ghost" onClick={editModeHandler} />
                    )}
                  </>
                ) : (
                  <>
                    <Button label="Cancel" variant="text" onClick={cancelHandler} />
                    <Button label="Delete" variant="ghost" onClick={deleteProdHandler} />
                    <Button
                      label="Save"
                      onClick={updateChildHandler}
                      disabled={checkIfDisable()}
                    />
                  </>
                )}
              </ButtonsWrapper>
            }
          />
          <Divider disableBorder />
          <DetailContent
            data={data || {}}
            isEditing={isOnEdit}
            onUpdate={updateChildKeyHandler}
          />
        </>
      </PageContent>
    </GenericLayout>
  )
}

export default StoreProdChildDetail
