import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { canUserAddAttachment, userCanEditAttachment } from '../../../auth-msal'
import { Divider } from '../../../components/Divider'
import { FlexSection } from '../../../components/FlexSection'
import { AttachmentsTable } from '../../../components/Packaging'
import AttachmentFilterModal from '../../../components/Packaging/AttachmentFilterModal'
import AddAttachmentModal from '../../../components/Packaging/AttachmentsTable/AddAttachmentModal'
import { SyncButton } from '../../../components/SyncButton'
import { ActionsAttachs } from '../../../components/Table/attachments'
import { ITActionColumnConfig } from '../../../components/Table/types'
import { Text } from '../../../components/Text'
import { Tooltip } from '../../../components/Tooltip'
import { UpdateAttachmentModal } from '../../../components/UpdateAttachmentModal'
import { packagingSliceActions } from '../../../features/packaging'
import { selectAttachmentsFilter } from '../../../features/packaging/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import { TagsContainer } from '../../TagsContainer'
import { AddAttachmentVersionModal } from './AddAttachmentVersionModal'
import AddNewAttachmentModal from './AddNewAttachmentModal'
import { EmptyWrapper, TitleWrapper } from './styled'

import { IAttachmentsProps } from './types'

const Attachments: FC<IAttachmentsProps> = ({
  isEditing = false,
  updateData = () => {},
  dataList = [],
  deleteAttach,
  isCreating = false,
  tableConfig,
  isMulti
}) => {
  const userRoles = useSelector(selectUserRole)
  const attachmentsFilters = useSelector(selectAttachmentsFilter)
  const dispatch = useDispatch()

  const checkActionVisibility = () => {
    return userCanEditAttachment(userRoles)
  }

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    del: checkActionVisibility()
      ? {
          btnVariant: 'text',
          deleteCb: deleteAttach,
          hasIcon: true,
          message: 'Do you want to delete this attachment?'
        }
      : {},
    actionRenderer: ({ data }) => {
      const flowName = 'packagingDetail'
      const sagaOnSync = 'packaging/syncAttachment'
      return (
        <>
          {!isCreating && (
            <>
              {checkActionVisibility() && (
                <Tooltip title="Upload New Version">
                  <AddAttachmentVersionModal record={data} flowName={flowName} />
                </Tooltip>
              )}

              {checkActionVisibility() && (
                <Tooltip title="Publish">
                  <SyncButton sagaOnSync={sagaOnSync} record={data} flowName={flowName} />
                </Tooltip>
              )}
              {checkActionVisibility() && (
                <Tooltip title="Edit">
                  <UpdateAttachmentModal record={data} flowName={flowName} />
                </Tooltip>
              )}
              <ActionsAttachs url={data.url} />
            </>
          )}
        </>
      )
    }
  }

  const actionConfigInEditPage: ITActionColumnConfig = {
    actionRenderer: ({ data }) => {
      return (
        <>
          {!isCreating && (
            <>
              <ActionsAttachs url={data.url} />
            </>
          )}
        </>
      )
    }
  }

  const getbuttonVisibility = () => {
    const isVisibileByData = isEditing
    if (isCreating) {
      return isVisibileByData
    }
    return isVisibileByData && canUserAddAttachment(userRoles)
  }

  const isFilterApplied = Object.values(attachmentsFilters).some((val: string) => !!val)
  const hasFilterBtn = !isCreating && isEditing
  let filteredList = [...dataList]
  if (hasFilterBtn && isFilterApplied) {
    const filterName = get(attachmentsFilters, 'name', '').toLowerCase()
    const filterDesc = get(attachmentsFilters, 'description', '').toLowerCase()
    const filterSAPCodes = get(attachmentsFilters, 'SAPCodes', '').toLowerCase()
    filteredList = dataList.filter((attachment) => {
      const attachName = get(attachment, 'name', '').toLowerCase()
      const attachDataType = get(attachment, 'dataType', '').toLowerCase()
      const attachDescription = get(attachment, 'description', '').toLowerCase()
      const attachSAPCodes = attachment.SAPCodes || []
      const filterByName =
        !filterName ||
        attachName.includes(filterName) ||
        attachDataType.includes(filterName)
      const filterByDesc = !filterDesc || attachDescription.includes(filterDesc)
      const filterBySap = !filterSAPCodes || attachSAPCodes.includes(filterSAPCodes)
      return filterByName && filterByDesc && filterBySap
    })
  }

  return (
    <>
      <TitleWrapper>
        <Text text="Attachments" variant="h3" />
        {getbuttonVisibility() && isCreating && !!dataList.length && (
          <AddAttachmentModal
            btnIcon="PlusCircleOutlined"
            btnType="text"
            dataList={dataList}
            updateData={updateData}
            fileKey={`attachment_${Date.now()}`}
          />
        )}
      </TitleWrapper>

      {!isCreating && isEditing && (
        <>
          <FlexSection flexDirection={undefined}>
            <FlexSection flexDirection="row" justifyContent="space-between">
              <FlexSection flexDirection="row">
                <AttachmentFilterModal />
                <TagsContainer
                  tags={attachmentsFilters || null}
                  allowNoFilters
                  basePath={'attachmentsFilters'}
                  inner={false}
                  reducer={'packaging'}
                  flowName={'packagingDetail'}
                  onRemoveTag={(path) =>
                    dispatch(packagingSliceActions.cancelFilter({ path }))
                  }
                />
              </FlexSection>
              {getbuttonVisibility() && !!filteredList.length && (
                <AddNewAttachmentModal isMulti={isMulti} />
              )}
            </FlexSection>
          </FlexSection>
        </>
      )}

      <Divider />
      {(!!filteredList.length && isEditing) || !isEditing ? (
        <AttachmentsTable
          tableConfig={tableConfig}
          actionConfig={isEditing ? actionConfig : actionConfigInEditPage}
          dataList={filteredList}
          isEditing={isEditing}
          childActionConfig={{
            hasDelete: true
          }}
        />
      ) : (
        <EmptyWrapper>
          <Text text="There are no attachments" variant="h4" />
          {getbuttonVisibility() ? (
            isCreating ? (
              <AddAttachmentModal
                btnType="ghost"
                dataList={filteredList}
                updateData={updateData}
                btnLabel="Add new one"
                fileKey={`attachment_${Date.now()}`}
              />
            ) : (
              <AddNewAttachmentModal isMulti={isMulti} />
            )
          ) : (
            <></>
          )}
        </EmptyWrapper>
      )}
    </>
  )
}

export default Attachments
