import styled, { css } from 'styled-components'

export const buttonWrapperHeight = 44
export const ButtonWrapper = styled.div`
  height: ${buttonWrapperHeight}px;
  width: 100%;
  padding: 5px 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const FilterBtnWrapper = styled.div`
  width: 65px;
`

export const TagsWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  > div {
    overflow-y: unset;
    width: auto;
  }
`

export const BackButton = styled.div<{ isHover?: boolean; childDisabled?: boolean }>`
  background-color: ${({ theme, isHover }) =>
    theme.palette.primary[isHover ? 'dark' : 'main']};
  background-color: ${({ theme, isHover }) =>
    theme.palette.primary[isHover ? 'dark' : 'main']};
  height: 32px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-btn-ghost {
    ${({ childDisabled }) =>
      !childDisabled &&
      css`
        background-color: white;
      `}
  }
  cursor: pointer;
`

export const IconWrapper = styled.div`
  width: 30px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  margin-right: 4px;
`

const drawerWidth = 250
export const ContentWrapper = styled.div<{ isCollectorOpen: boolean }>`
  width: calc(100% - ${({ isCollectorOpen }) => (isCollectorOpen ? drawerWidth : 0)}px);
`

export const DrawerCollector = styled.div<{ isOpen: boolean }>`
  width: 250px;
  padding: 16px;
  padding-left: 50px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ChipsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  .custom-tag-container {
    max-width: 100%;
  }
  .custom-tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const TagWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  width: 100%;
`

export const ProductResultContainer = styled.div`
  display: flex;
`
