import { FC } from 'react'
import { useSelector } from 'react-redux'
import { canUserSeeFschemaData } from '../../../../auth-msal'
import Table from '../../../../components/Table'
import { ITActionColumnConfig } from '../../../../components/Table/types'
import { productStatusDropdown } from '../../../../features/product/constants'
import { selectUserRole } from '../../../../features/users/selectors'
import { AppRoutes } from '../../../../pages/constants'
import { columnsGeneratorHelper, warningMessageGenerator } from '../../../../utils'
import { filterFormulaByStatus } from '../../../../utils/formulaSchemaHelpers'
import { cleanAndFlat } from '../../../../utils/productHelpers'
import { PRODUCTS_RTG_TABLE_CONFIG } from './contants'
import { IProductsTableProps } from './types'

const ProductsTable: FC<IProductsTableProps> = ({
  dataList,
  isLoading,
  enablePagination = false,
  onChangePage = () => {},
  pageData = { page: 1, pageSize: 10, total: 10 },
  onSelect = () => {},
  selectedRow = [],
  exclusionConfig = {},
  enableRowSelect = false,
  disableHeaderSelect = false,
  labelOnSelect = '',
  selectLimit = null,
  paginationPosition = 'TOP_RIGHT'
}) => {
  const { page, pageSize, total } = pageData
  const userRoles = useSelector(selectUserRole)

  const columnGenerator = () => {
    let columnList = columnsGeneratorHelper(PRODUCTS_RTG_TABLE_CONFIG(labelOnSelect))
    return columnList
  }

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    detail: {
      destinationPath: AppRoutes.VAULT_PRODUCT_DETAILS,
      btnLabel: 'Details',
      btnVariant: 'text'
    }
  }

  const rowsGenerator = () => {
    return dataList.map(
      ({ productSides, warnings, warningsJSON, characteristics, status, ...args }) => {
        const formulasMapped = productSides.map((side: any) => side.formulas).flat()
        const filteredIndustrialCodes = filterFormulaByStatus(formulasMapped)
          .map((f) => f.formulaCodeDEVEX)
          .filter((code: any) => code !== null)

        const filteredLabReferenceCode = filterFormulaByStatus(formulasMapped).map(
          (f) => f.labReferenceCode
        )

        const formulaSchemaCodeVal =
          canUserSeeFschemaData(userRoles) || status !== 'LEVEL_2_IN_PROGRESS'
            ? productSides.map(({ formulaSchemaCode }: any) => formulaSchemaCode)
            : null

        const pdLaunchYearList = cleanAndFlat(productSides, 'pdLaunchYear')
        const launchYearList = cleanAndFlat(productSides, 'launchYear')
        const pdLaunchYear = pdLaunchYearList.length ? pdLaunchYearList : launchYearList

        const pdCollectionList = cleanAndFlat(productSides, 'pdCollection')
        const collectionList = cleanAndFlat(productSides, 'collection')
        const pdCollection = pdCollectionList.length ? pdCollectionList : collectionList

        return {
          ...args,
          characteristics,
          status: productStatusDropdown.find(({ value }) => value === status)?.name,
          image: args.thumbnail?.url,
          industrialCode: filteredIndustrialCodes,
          productCategory: cleanAndFlat(productSides, 'productCategory'),
          productForm: cleanAndFlat(productSides, 'productForm'),
          formulaSchemaCode: formulaSchemaCodeVal,
          labReferenceCode: filteredLabReferenceCode,
          packagingCode:
            productSides[0]?.packageVariant?.packageFamily?.sideCollector
              ?.packagingCode || '',
          pdLaunchYear: pdLaunchYear,
          pdCollection: pdCollection,
          format: cleanAndFlat(productSides, 'format'),
          productCodeWarn: warningMessageGenerator(warnings, warningsJSON),
          hasCharacteristics: Object.values(characteristics || {}).some(
            (value) => value !== null
          )
        }
      }
    )
  }

  const columns = columnGenerator()
  const dataRow = dataList.length ? rowsGenerator() : []
  return (
    <Table
      pagination={{
        current: page,
        pageSize,
        total
      }}
      onChangePage={onChangePage}
      enablePagination={enablePagination}
      paginationPosition={paginationPosition}
      rowKey="id"
      columns={columns}
      items={dataRow}
      actionColumnConfig={actionConfig}
      forceHeight
      isLoading={isLoading}
      enableRowSelect={enableRowSelect}
      onSelectRow={onSelect}
      selectedRows={selectedRow}
      excludeSelection={exclusionConfig}
      disableHeaderSelect={disableHeaderSelect}
      selectLimit={selectLimit}
    />
  )
}

export default ProductsTable
