import { AnyAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { productRefactorDataForSave, refactorTaxonomies } from '../../utils'
import { appSliceActions } from '../app/reducer'
import { FormulaSchemaDto } from '../formulaSchema/model'
import { getPackageId } from '../packaging/api'
import { PackagingDto } from '../packaging/model'
import { checkProductValidation } from '../products/api'
import { ToolingDto } from '../tooling/model'
import { selectUserEmail } from '../users/selectors'
import { createProductNew, getProductDynamicTaxonomies } from './api'
import {
  DYNAMIC_TAXONOMIES,
  PACKAGING_ID_TOOLING,
  PRODUCT_ADD_TOOLING,
  PRODUCT_CREATE,
  PRODUCT_DELETE_FSCHEMA,
  PRODUCT_FILTER_TAXONOMIES,
  PRODUCT_SELECT_FSCHEMA
} from './constants'
import { IDynamicFiltersPayload, ProductCreateDto } from './model'
import { prodSliceActions } from './reducer'
import { getProductCreate, selectSelectedTooling } from './selectors'

function* choosePackage({ payload: { flowName } }: AnyAction) {
  const selected: PackagingDto[] = yield select(
    (state) => state.product.temp.packaging.selected
  )
  yield put(prodSliceActions.setPackaging(selected))
  yield put(prodSliceActions.clearTemp())
}

function* chooseTooling({ payload: { flowName } }: AnyAction) {
  const selected: ToolingDto[] = yield select(
    (state) => state.product.temp.tooling.selected
  )
  const packagingTbd: PackagingDto = yield call(getPackageId, PACKAGING_ID_TOOLING)
  try {
    yield put(prodSliceActions.setTooling(selected))
    yield put(
      prodSliceActions.setPackaging([
        {
          ...packagingTbd,
          idPackaging: packagingTbd.id,
          variantCode: packagingTbd?.packagingCode
        }
      ])
    )
    yield put(prodSliceActions.clearTemp())
  } catch (e) {}
}

function* addTooling({ payload }: AnyAction) {
  yield put(prodSliceActions.setIsLoading(true))
  const { selected } = payload
  try {
    yield put(prodSliceActions.setTooling(selected))
  } catch (err: any) {
    yield put(prodSliceActions.setError(err))
  } finally {
    yield put(prodSliceActions.setIsLoading(false))
  }
}

function* chooseSchema({ payload: { flowName } }: AnyAction) {
  const selected: FormulaSchemaDto[] = yield select(
    (state) => state.product.temp.schema.selected
  )

  yield put(prodSliceActions.setFormulaCodesDevex(selected[0].formula))
  yield put(prodSliceActions.setSchema(selected))
  yield put(prodSliceActions.clearTemp())
}

function* setFSchema({ payload }: AnyAction) {
  const { idx, selected } = payload

  yield put(
    prodSliceActions.setFormulaCodes({ sideIdx: idx, formulas: selected.formula })
  )
  yield put(prodSliceActions.applySchema({ sideIdx: idx, fSchema: selected }))
  yield put(prodSliceActions.clearTemp())
}

function* deletePackage() {
  yield put(prodSliceActions.deletePackaging())
}

function* deleteSchemaSaga() {
  yield put(prodSliceActions.deleteSchema())
}

function* productCreateSaga() {
  yield put(prodSliceActions.setIsLoading(true))
  try {
    const toolings: Record<string, any>[] = yield select(selectSelectedTooling)

    const creationProduct: ProductCreateDto = yield select(getProductCreate)
    const author: string = yield select(selectUserEmail)
    const newProduct = productRefactorDataForSave(creationProduct, author)

    const { sides } = newProduct

    const productData = sides.map(
      ({ productCategory, formulaSchemaId, packageVariantId }: Record<string, any>) => ({
        productCategory,
        formulaSchemaId,
        packageVariantId
      })
    )
    const { matchingProduct, alreadyExists } = yield call(checkProductValidation, {
      productData
    })
    if (alreadyExists) {
      yield put(
        appSliceActions.openPopUp({
          isOpen: true,
          key: 'product-exists',
          title: 'The product you are trying to create already exists',
          message: `The same combination of Formula Schema, PK Intercos Code and Product category is present in Product: ${matchingProduct[0].productCode}`,
          isClosable: true
        })
      )
      return
    }

    var form_data = new FormData()

    if (toolings) {
      const [toolingInfo] = toolings
      newProduct.sides[0].toolingId = toolingInfo.id
    }

    form_data.append('product', JSON.stringify(newProduct))

    const imageKey = get(creationProduct, 'image.value', '')
    const marketingProfileKey = get(creationProduct, 'marketingProfile.value', '')

    if (imageKey) {
      form_data.append('image', window.tmpFiles[imageKey])
    }

    if (marketingProfileKey) {
      form_data.append('marketingProfile', window.tmpFiles[marketingProfileKey])
    }
    yield call(createProductNew, form_data)
    yield put(
      prodSliceActions.setSuccess({
        title: 'The product was created successfully!',
        message:
          'The ready to go product has been successfully created and saved in the RTG Products section.'
      })
    )
    if (imageKey && window.tmpFiles[imageKey]) {
      delete window.tmpFiles[imageKey]
    }
    if (marketingProfileKey && window.tmpFiles[marketingProfileKey]) {
      delete window.tmpFiles[marketingProfileKey]
    }
  } catch (err: any) {
    yield put(prodSliceActions.setError(err))
  } finally {
    yield put(prodSliceActions.setIsLoading(false))
  }
}

function* filterTaxonomiesSaga({ payload }: AnyAction) {
  const { filters } = payload
  try {
    yield put(prodSliceActions.setIsLoading(true))
    const dynamicFilters: IDynamicFiltersPayload = Object.entries(filters).reduce(
      (acc: any, [key, value]) => {
        if (DYNAMIC_TAXONOMIES.includes(key)) {
          acc[key] =
            key === 'packageType' ? ((value as string) || '').toUpperCase() : value || []
        }
        return acc
      },
      {}
    )

    const filteredTaxonomies: Record<string, any> = yield call(
      getProductDynamicTaxonomies,
      {
        ...dynamicFilters,
        status: filters['status'] ? filters['status'] : undefined
      } as IDynamicFiltersPayload
    )

    const dropDown = refactorTaxonomies(filteredTaxonomies)
    yield put(prodSliceActions.updateDropdown({ dropDown }))
  } catch (err: any) {
    // NOTE - better to don't populate error
    // to avoid bad user experience
  } finally {
    yield put(prodSliceActions.setIsLoading(false))
  }
}

export default function* productSaga() {
  yield takeLatest('product/choosePackage', choosePackage)
  yield takeLatest('product/chooseTooling', chooseTooling)
  yield takeLatest(PRODUCT_ADD_TOOLING, addTooling)
  yield takeLatest('product/chooseSchema', chooseSchema)
  yield takeLatest(PRODUCT_SELECT_FSCHEMA, setFSchema)
  yield takeLatest('product/deletePackage', deletePackage)
  yield takeLatest(PRODUCT_DELETE_FSCHEMA, deleteSchemaSaga)
  yield takeLatest(PRODUCT_CREATE, productCreateSaga)
  yield takeLatest(PRODUCT_FILTER_TAXONOMIES, filterTaxonomiesSaga)
}
