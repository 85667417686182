import { createAction } from '@reduxjs/toolkit'
import { SortOrder } from 'antd/lib/table/interface'
import {
  GET_PRODUCTS_SPIDER_CHART_CHARACTERISTICS,
  GET_SCATTER_CHARACTERISTICS,
  PRODUCTS_CHANGE_PAGE,
  PRODUCTS_DELETE,
  PRODUCTS_EXPORT_EXCEL,
  PRODUCTS_GET_DETAILS,
  PRODUCTS_GET_DETAILS_AND_OTHERS,
  PRODUCTS_INIT_TEMP,
  PRODUCTS_SEARCH,
  PRODUCTS_UPDATE
} from './constants'
import {
  IGetProductDetailActionPayload,
  IGetProductDetailAndOtherActionPayload,
  IGetSpiderChartCharacteristicsPayload
} from './model'

export const productsActions = {
  searchProducts: createAction(PRODUCTS_SEARCH),
  changePage: createAction<{ page: number; field: string; order: SortOrder }>(
    PRODUCTS_CHANGE_PAGE
  ),
  getDetails: createAction<IGetProductDetailActionPayload>(PRODUCTS_GET_DETAILS),
  getDetailsAndOthers: createAction<IGetProductDetailAndOtherActionPayload>(
    PRODUCTS_GET_DETAILS_AND_OTHERS
  ),
  initTemp: createAction(PRODUCTS_INIT_TEMP),
  updateProduct: createAction(PRODUCTS_UPDATE),
  deleteProduct: createAction(PRODUCTS_DELETE),
  exportExcel: createAction(PRODUCTS_EXPORT_EXCEL),
  getSpiderChartCharacteristics: createAction<IGetSpiderChartCharacteristicsPayload>(
    GET_PRODUCTS_SPIDER_CHART_CHARACTERISTICS
  ),
  getScatterCharacteristics: createAction(GET_SCATTER_CHARACTERISTICS)
}
