/**
 * @deprecated
 * TOP_RIGHT_AND_BOTTOM_RIGHT const is deprecated use 'TOP_RIGHT_AND_BOTTOM_RIGHT' string instead
 */
export const TOP_RIGHT_AND_BOTTOM_RIGHT = 'TOP_RIGHT_AND_BOTTOM_RIGHT'
/**
 * @deprecated
 * TOP_RIGHT const is deprecated use 'TOP_RIGHT' string instead
 */
export const TOP_RIGHT = 'TOP_RIGHT'
/**
 * @deprecated
 * BOTTOM_RIGHT const is deprecated use 'BOTTOM_RIGHT' string instead
 */
export const BOTTOM_RIGHT = 'BOTTOM_RIGHT'

export const PAGINATION_POSITIONS = [
  'TOP_RIGHT_AND_BOTTOM_RIGHT',
  'TOP_RIGHT',
  'TOP_LEFT',
  'BOTTOM_RIGHT'
] as const

export const TABLE_DATA_TYPE = {
  TEXT: 'text',
  ARRAY: 'array',
  DATE: 'date',
  BOOL: 'bool',
  NODE: 'node',
  MODAL_LIST: 'modalList',
  IMAGE: 'image',
  LINK: 'link',
  THREESTATE: 'threeState'
} as const
