import { DownOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { userGroupId } from '../../auth-msal'
import { appSliceActions } from '../../features/app'
import { packagingSliceActions } from '../../features/packaging'
import { userSliceActions } from '../../features/users/reducer'
import {
  selectPermissions,
  selectUserDisplayName,
  selectUserRole
} from '../../features/users/selectors'
import { AppRoutes } from '../../pages/constants'
import Button from '../Button'
import { CheckBoxList } from '../Form/CheckBoxList'
import { Modal } from '../Modal'
import { CheckboxWrapper, StyledDropdown } from './styled'
import { Avatar } from '.'
import './styled'

export const AvatarDropdown = () => {
  const currentRoles = useSelector(selectUserRole)
  const permissions = useSelector(selectPermissions)
  const { pathname } = useLocation()
  const [roles, setRoles] = useState<string[]>(currentRoles || [])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const devMode = process.env.REACT_APP_DEV_MODE === 'true'
  const displayName = useSelector(selectUserDisplayName)

  const changeRolesHandler = (value: CheckboxValueType[]) => {
    setRoles(value as string[])
  }

  const applyRolesHandler = () => {
    dispatch(userSliceActions.setUserRoles(roles))
    dispatch(userSliceActions.setPermissions({ roles }))
    dispatch(appSliceActions.setModalKey(undefined))
    dispatch(packagingSliceActions.setFilteredStatus({ roles: roles || [] }))
  }

  const items = Object.values(userGroupId)
    .map((v) => ({
      name: v,
      value: v
    }))
    .sort(({ name: nameA }, { name: nameB }) => {
      const nameAClean = nameA.replaceAll('-', '').replaceAll('.', '')
      const nameBClean = nameB.replaceAll('-', '').replaceAll('.', '')
      return nameAClean.localeCompare(nameBClean)
    })

  const filterItems = (section: string) =>
    pathname.includes(section) || pathname === AppRoutes.HOME

  const filterSupportByPermission = (type: 'vault' | 'store') =>
    permissions?.support ? permissions.support[type] : false

  const menu = (
    <Menu
      items={[
        {
          label: <div onClick={() => navigate('/logout')}>Logout</div>,
          key: 'logout'
        },
        {
          label: (
            <a href="mailto:info.digitalbeautyvault@intercos.com">
              Vault Contact Support
            </a>
          ),
          key: 'vault-support',
          disabled: !(filterSupportByPermission('vault') && filterItems('vault'))
        },
        {
          label: (
            <a href="mailto:info.digitalbeautystore@intercos.com">
              Store Contact Support
            </a>
          ),
          key: 'store-support',
          disabled: !(filterSupportByPermission('store') && filterItems('store'))
        }
      ]}
    />
  )
  return (
    <>
      <StyledDropdown overlay={menu} className="dropdown">
        <Link to="" onClick={(e) => e.preventDefault()}>
          <Avatar name={displayName} />
          <DownOutlined />
        </Link>
      </StyledDropdown>
      {devMode && (
        <Modal
          btnName="Select Group"
          title="Select a group"
          size="large"
          modalKey="modal-groups"
          btnType="link"
          footer={[
            <Button label="Apply" onClick={applyRolesHandler} variant="primary" />
          ]}
          centered
        >
          <CheckboxWrapper data-cy="select-group-checkbox-list">
            <CheckBoxList
              items={items}
              onChange={changeRolesHandler}
              placeholder="Select a role"
              required={false}
              value={roles}
            />
          </CheckboxWrapper>
        </Modal>
      )}
    </>
  )
}
