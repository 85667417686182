import { TABLE_DATA_TYPE } from '../../components/Table/constants'
import { ITableConfig } from '../../components/Table/types'

export const PROJECT_SPEC_DETAIL_FORMULA: ITableConfig = {
  sapCodesToShow: {
    label: 'Sap Codes',
    dataType: TABLE_DATA_TYPE.MODAL_LIST,
    dataPath: 'sapCodes'
  },
  controlCharacteristic: {
    label: 'Characteristic Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'controlCharacteristic'
  },
  overrideItemName: {
    label: 'Characteristic Description',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'overrideItemName'
  },
  method: {
    label: 'Method',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'method'
  },
  samplingProcedure: {
    label: 'Sampling Procedure',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'samplingProcedure'
  },
  lowerLimit: {
    label: 'Lower Limit',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'lowerLimit'
  },
  upperLimit: {
    label: 'Upper Limit',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'upperLimit'
  },
  measurementUnit: {
    label: 'Measurement Unit',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'measurementUnit',
    renderNode: (cell: any, row: any) => {
      return (
        row.characteristicMeasurementUnitRef?.description ||
        row.measurementUnitRef?.description ||
        '-'
      )
    }
  },
  qualityInspection: {
    label: 'INTERCOS Quality Inspection',
    dataType: TABLE_DATA_TYPE.BOOL,
    dataPath: 'qualityInspection'
  },
  masterHom: {
    label: 'INTERCOS Homologation',
    dataType: TABLE_DATA_TYPE.BOOL,
    dataPath: 'masterHom'
  },
  customizedDev: {
    label: 'INTERCOS Project Development',
    dataType: TABLE_DATA_TYPE.BOOL,
    dataPath: 'customizedDev'
  },
  supplierCoa: {
    label: 'SUPPLIER Certificate of Analysis',
    dataType: TABLE_DATA_TYPE.BOOL,
    dataPath: 'supplierCoa'
  }
}

export const PROJECT_SPEC_CHARACTERISTIC_SAP_CODES: ITableConfig = {
  code: {
    label: 'Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'code'
  }
}

export const CHARACTERISTIC_SEARCH_RESULT: ITableConfig = {
  controlCharacteristic: {
    label: 'Characteristic Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'controlCharacteristic'
  },
  itemName: {
    label: 'Characteristic Description',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'itemName'
  },
  method: {
    label: 'Method',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'method'
  },
  samplingProcedure: {
    label: 'Sampling Procedure',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'samplingProcedure'
  },
  catalog: {
    label: 'Catalog Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'catalog'
  },
  standard: {
    label: 'Catalog Description',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'standard'
  },
  measurementUnit: {
    label: 'Measurement Unit',
    dataType: TABLE_DATA_TYPE.NODE,
    dataPath: 'measurementUnit',
    renderNode: (cell: any, row: any) => {
      return (
        row.characteristicMeasurementUnitRef?.description ||
        row.measurementUnitRef?.description ||
        '-'
      )
    }
  }
}
