import { Form } from 'antd'
import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, InfoCell } from '../../../../components'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import { Modal } from '../../../../components/Modal'
import { appActions } from '../../../../features/app'
import {
  applicationAreaDrpDwn,
  collectionDropDown,
  colorDropDown
} from '../../../../features/storeProducts/constants'
import { IStoreProdChild } from '../../../../features/storeProducts/model'
import {
  checkChildFieldValidationByType,
  checkChildMantatoryFieldInCreate,
  checkChildRequiredFieldByType
} from '../../../../utils/entityHelper'
import { IAddChildModalProps } from './types'

const AddChildModal: FC<IAddChildModalProps> = ({
  modalKey,
  btnName,
  btnIcon,
  btnType,
  onConfirm = () => {},
  entityType
}) => {
  const dispatch = useDispatch()
  const [childData, setChildData] = useState<IStoreProdChild>({})

  const updateHandler = (key: keyof IStoreProdChild, value: any) => {
    setChildData({ ...childData, [key]: value })
  }

  const resetHandler = () => setChildData({})

  const closeModalHandler = () => dispatch(appActions.closeModal({ modalKey }))

  const confirmHandler = () => {
    closeModalHandler()
    onConfirm(childData)
  }

  const checkMantatoryField = () => {
    return !checkChildMantatoryFieldInCreate({
      data: childData,
      type: entityType
    })
  }

  const checkValidationHandler = (key: keyof IStoreProdChild) => {
    return checkChildFieldValidationByType({ data: childData, type: entityType, key })
  }

  const checkRequiredFields = (key: keyof IStoreProdChild) => {
    return checkChildRequiredFieldByType({ key, type: entityType })
  }

  return (
    <Modal
      modalKey={modalKey}
      btnName={btnName}
      btnIcon={btnIcon}
      btnType={btnType}
      title="Add component"
      size="medium"
      isSmall
      centered
      btnClicked={resetHandler}
      footer={[
        <Button
          key="cancel"
          label="Cancel"
          variant="ghost"
          onClick={closeModalHandler}
        />,
        <Button
          key="confirm"
          label="Confirm"
          onClick={confirmHandler}
          disabled={checkMantatoryField()}
        />
      ]}
    >
      <Form layout="vertical">
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <InfoCell
            label="Formula Schema"
            value={childData.formulaSchemaCode || ''}
            inputConfig={{
              onChange: (value: string) => updateHandler('formulaSchemaCode', value),
              required: checkRequiredFields('formulaSchemaCode'),
              error: checkValidationHandler('formulaSchemaCode')
            }}
            isEditing
          />
          <InfoCell
            label="Industrial Code"
            value={childData.industrialCode || ''}
            inputConfig={{
              onChange: (value: string) => updateHandler('industrialCode', value),
              required: checkRequiredFields('industrialCode'),
              error: checkValidationHandler('industrialCode')
            }}
            isEditing
          />
          <InfoCell
            label="Lab Code"
            value={childData.labCode || ''}
            inputConfig={{
              onChange: (value: string) => updateHandler('labCode', value),
              required: checkRequiredFields('labCode'),
              error: checkValidationHandler('labCode')
            }}
            isEditing
          />
          <InfoCell
            label="Final Name"
            value={childData.finalName || ''}
            inputConfig={{
              onChange: (value: string) => updateHandler('finalName', value),
              required: checkRequiredFields('finalName'),
              error: checkValidationHandler('finalName')
            }}
            isEditing
          />
          <InfoCell
            label="Application Area"
            inputType="select"
            value={childData.applicationArea || ''}
            inputConfig={{
              items: applicationAreaDrpDwn,
              onChange: ({ value = '' }: Record<string, any>) =>
                updateHandler('applicationArea', value),
              required: checkRequiredFields('applicationArea'),
              error: checkValidationHandler('applicationArea')
            }}
            isEditing
          />
          <InfoCell
            label="Product Category"
            value={childData.productCategory || ''}
            inputConfig={{
              onChange: (value: string) => updateHandler('productCategory', value),
              required: checkRequiredFields('productCategory'),
              error: checkValidationHandler('productCategory')
            }}
            isEditing
          />
          <InfoCell
            label="Color"
            value={childData.color || ''}
            inputType="select"
            inputConfig={{
              onChange: ({ value = '' }: Record<string, any>) =>
                updateHandler('color', value),
              required: checkRequiredFields('color'),
              items: colorDropDown,
              error: checkValidationHandler('color')
            }}
            isEditing
          />
          <InfoCell
            label="Finish"
            value={childData.finish || ''}
            inputConfig={{
              onChange: (value: string) => updateHandler('finish', value),
              required: checkRequiredFields('finish'),
              error: checkValidationHandler('finish')
            }}
            isEditing
          />
          <InfoCell
            label="Launch Year"
            value={childData.launchYear || ''}
            inputConfig={{
              onChange: (value: string) => updateHandler('launchYear', value),
              required: checkRequiredFields('launchYear'),
              error: checkValidationHandler('launchYear')
            }}
            isEditing
          />
          <InfoCell
            label="Collection"
            value={childData.collection || ''}
            inputType="select"
            inputConfig={{
              onChange: ({ value = '' }: Record<string, any>) =>
                updateHandler('collection', value),
              items: collectionDropDown,
              required: checkRequiredFields('collection'),
              error: checkValidationHandler('collection')
            }}
            isEditing
          />
          <InfoCell
            label="Family"
            value={childData.family || ''}
            inputConfig={{
              onChange: (value: string) => updateHandler('family', value),
              required: checkRequiredFields('family'),
              error: checkValidationHandler('family')
            }}
            isEditing
          />
          <InfoCell
            label="Note SR"
            value={childData.noteSR || ''}
            inputConfig={{
              onChange: (value: string) => updateHandler('noteSR', value),
              required: checkRequiredFields('noteSR'),
              error: checkValidationHandler('noteSR')
            }}
            isEditing
          />
        </BoxWrapper>
      </Form>
    </Modal>
  )
}

export default AddChildModal
