import styled, { css } from 'styled-components'

export const StyledInputNumber = styled.div<{
  hideSideButton?: boolean
}>`
  display: flex;
  .input {
    width: 100%;
    ${({ hideSideButton }) =>
      !hideSideButton &&
      css`
        border-radius: 0;
        max-width: 45px;
      `}
  }
  button {
    width: 40px;
    height: 40px;
    padding: 4px 0;
    font-size: 18px;
    border-radius: 0px !important;
  }
`
