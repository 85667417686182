import { Modal } from 'antd'
import styled, { css } from 'styled-components'
import { StyledModalProps, TModalSize } from './types'

export const StyledModal = styled(Modal)<StyledModalProps>`
  .ant-modal-content {
    height: ${({ isSmall }) => (isSmall ? 'max-content' : '60vh')};
    width: ${({ width }) => width};
    ${({ size }) =>
      size === 'xLarge' &&
      css`
        height: 90vh;
      `}
    ${({ hasFooter, size }) =>
      size === 'xLarge' &&
      hasFooter &&
      css`
         {
          top: -24px;
        }
      `}
  }

  ${({ title }) =>
    css`
      .ant-modal-body {
        height: calc(100% - ${title ? 55 : 0}px);
      }

      .ant-modal-footer {
        justify-content: flex-end;
        display: flex;
        button {
          margin: 0 5px;
        }
      }
    `}
`

export const LoaderWrapper = styled.div<{ size: TModalSize }>`
  min-width: 460px;
  min-height: 480px;
  ${({ size }) =>
    size === 'small' &&
    css`
      min-height: 180px;
    `}
`
