import { Form } from 'antd'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { appActions } from '../../../features/app'
import Button from '../../Button'
import { Modal } from '../../Modal'
import TestedClaimForm from '../TestedClaimForm'
import { ModalBodyContainer } from './styled'

const TestedClaimModal: FC<any> = ({
  btnLabel,
  btnIcon = 'PlusCircleOutlined',
  onBtnClick,
  modalKey,
  onSave,
  disabled = false,
  formulaCodes,
  onUpdate = () => {},
  data = {}
}) => {
  const dispatch = useDispatch()
  const closeModalHandler = () => dispatch(appActions.closeModal({ modalKey }))

  const handleFinish = (values: any) => {
    onSave({ ...values, formulaSchemaId: data.formulaSchemaId, id: data.id }, attachment)
    dispatch(appActions.closeModal({ modalKey }))
  }

  const [attachment, setAttachment] = useState(null)
  const [form] = Form.useForm()

  const save = () => {
    const fieldsToValidate = [
      'type',
      'testedCode',
      'claim',
      'testType',
      'claimResult',
      'reportDate',
      'testStatesCustomerName',
      'reportNumber',
      'testMethod'
    ]
    if (!data.attachmentId) fieldsToValidate.push('attachment')
    form.validateFields(fieldsToValidate).then(form.submit)
  }

  return (
    <Modal
      isSmall={true}
      btnName={btnLabel}
      disabled={disabled}
      btnType="link"
      btnIcon={btnIcon}
      modalKey={modalKey}
      title=""
      footer={[
        <Button key="cancel" label="Cancel" onClick={closeModalHandler} />,
        <Button key="save" label="Save" onClick={save} />
      ]}
      btnClicked={onBtnClick}
      centered
      size="large"
    >
      <ModalBodyContainer>
        <TestedClaimForm
          onFinish={handleFinish}
          data={data}
          isEditing={true}
          onUpdate={onUpdate}
          formulaCodes={formulaCodes}
          formInstance={form}
          setAttachment={setAttachment}
        />
      </ModalBodyContainer>
    </Modal>
  )
}

export default TestedClaimModal
