import { Tabs } from 'antd'
import { get } from 'lodash'
import React, { FC } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import AdditionalDataForm from '../../../components/Product/AdditionalDataGeneralForm'
import AdditionalDataSideForm from '../../../components/Product/AdditionalDataSideForm'
import { PACKAGE_TYPE } from '../../../features/packaging/model'
import { prodSliceActions } from '../../../features/product'
import { productStatusDropdownValues } from '../../../features/product/constants'
import {
  getProductCreate,
  selectSelectedPackage
} from '../../../features/product/selectors'
import { IAdditionalDataProps } from './types'

const AdditionalData: FC<IAdditionalDataProps> = () => {
  const dispatch = useDispatch()
  const productData = useSelector(getProductCreate)
  const selectedPackage = useSelector(selectSelectedPackage)
  const isMulti = get(selectedPackage, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI
  const sideDataList = productData?.sides || []

  const updateGeneralHandler = (key: string, value: any) => {
    dispatch(prodSliceActions.updateProduct({ key, value }))
  }

  const updateSideHandler = (sideIdx: number, key: string, value: any) => {
    const newSides = [...sideDataList]
    newSides[sideIdx] = {
      ...newSides[sideIdx],
      [key]: value
    }

    dispatch(prodSliceActions.updateProduct({ key: 'sides', value: newSides }))
  }
  return (
    <>
      <AdditionalDataForm
        data={productData}
        onUpdate={updateGeneralHandler}
        isCreating
        isMulti={productData?.sides?.length > 1}
      />
      {isMulti && (
        <Tabs>
          {sideDataList.map((sideData, sideIdx) => (
            <Tabs.TabPane tab={`Side ${sideIdx + 1}`} key={sideIdx}>
              <AdditionalDataSideForm
                data={sideData}
                onUpdate={(key, value) => updateSideHandler(sideIdx, key, value)}
                isMascara={
                  get(productData, 'businessSegment', '').toLowerCase() === 'mascara'
                }
                isReady={
                  get(productData, 'status', '') ===
                  productStatusDropdownValues.READY_TO_GO
                }
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
      {!isMulti && (
        <AdditionalDataSideForm
          data={sideDataList[0]}
          onUpdate={(key, value) => updateSideHandler(0, key, value)}
          isMascara={get(productData, 'businessSegment', '').toLowerCase() === 'mascara'}
          isReady={
            get(productData, 'status', '') === productStatusDropdownValues.READY_TO_GO
          }
          hideCollection
          hideLaunchYear
        />
      )}
    </>
  )
}

export default AdditionalData
