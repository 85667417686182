import { Form } from 'antd'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { InfoCell } from '../../../../components'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import { Loader } from '../../../../components/Loader'
import { Text } from '../../../../components/Text'
import { Error } from '../../../../containers'
import Success from '../../../../containers/Success'
import { cartSliceActions } from '../../../../features/cart'
import {
  selectCartError,
  selectCartLoader,
  selectCartSuccess
} from '../../../../features/cart/selectors'
import { AppRoutes } from '../../../constants'
import { IAddCartModalContentProps } from '../types'
import { AddCartModalContentContainer } from './styled'

const AddCartModalContent: FC<IAddCartModalContentProps> = ({
  onResetError,
  quantity,
  onUpdateQuantity,
  availableQuantity,
  onCloseModal
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cartLoader = useSelector(selectCartLoader)
  const cartError = useSelector(selectCartError)
  const cartSuccessInfo = useSelector(selectCartSuccess)

  if (cartLoader) return <Loader />

  if (!isEmpty(cartError)) return <Error customBack={onResetError} error={cartError} />

  const goToCart = () => {
    onCloseModal()
    dispatch(cartSliceActions.clearSuccess())
    navigate(AppRoutes.STORE_CART)
  }
  const backToResults = () => {
    onCloseModal()
    navigate(-1)
  }

  if (cartSuccessInfo)
    return (
      <Success
        data={cartSuccessInfo}
        buttons={[
          { label: 'Go to cart', onClick: goToCart },
          { label: 'Continue to navigate', onClick: backToResults, variant: 'ghost' }
        ]}
      />
    )

  const warningMessage = 'product no more available'

  return (
    <AddCartModalContentContainer>
      <Text text="Add to cart" variant="h3" bold />
      <Form layout="vertical">
        <BoxWrapper lg={3}>
          <InfoCell
            label="Set the quantity"
            value={quantity}
            inputType="number"
            isEditing
            inputConfig={{
              min: 1,
              onChange: onUpdateQuantity,
              max: availableQuantity,
              helperText: quantity > availableQuantity ? warningMessage : ''
            }}
          />
        </BoxWrapper>
      </Form>
    </AddCartModalContentContainer>
  )
}

export default AddCartModalContent
