import styled, { css } from 'styled-components'

export const ScatterPlotPdfContainer = styled.div<{ isVisible: boolean }>`
  width: 794px;
  padding: 45px;
  ${({ isVisible }) =>
    isVisible
      ? css`
          z-index: auto;
          position: static;
        `
      : css`
          z-index: -99999;
          position: fixed;
        `}
`
