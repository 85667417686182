import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useMemo } from 'react'
import TableWarning from '../Table/TableWarning'
import { Text } from '../Text'
import { Tooltip } from '../Tooltip'
import { StyledInfoBox, StyledLabelWrapper } from './styled'
import { BoxInfoProps } from './types'

export const BoxInfo = ({
  value,
  label,
  children,
  enableTooltip,
  title = '',
  position,
  isDate = false,
  isEditable = false,
  onClick = () => {},
  warnMessage = ''
}: BoxInfoProps) => {
  const textValue = useMemo(() => {
    if (children && !Array.isArray(children)) return null
    if (typeof value === 'boolean') return value ? 'Yes' : 'No'
    if (Array.isArray(value)) return value.join(', ') || '-'
    if (typeof value === 'string' && isDate) return moment(value).format('DD/MM/YYYY')
    if (!children || (Array.isArray(children) && !children.length)) return value || '-' // nel caso in cui arrivi un array vuoto
  }, [value, children, isDate])
  return (
    <StyledInfoBox isEditable={isEditable} onClick={onClick}>
      {enableTooltip ? (
        <StyledLabelWrapper>
          <Text className="label" text={label} variant="h5" />
          <Tooltip title={title} placement={position}>
            <InfoCircleOutlined />
          </Tooltip>
        </StyledLabelWrapper>
      ) : (
        <Text className="label" text={label} variant="h5" />
      )}
      <div className="value" data-cy={`field-${label}`}>
        {textValue ? <Text text={textValue} variant="h6" /> : null}
        {!!warnMessage && <TableWarning message={warnMessage} />}
        {children}
      </div>
    </StyledInfoBox>
  )
}
