import { Checkbox } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import {
  ExpandableConfig,
  FilterValue,
  SorterResult,
  TableRowSelection
} from 'antd/lib/table/interface'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { ScrollContext } from '../../context'
import { sorterHelper, useWindowSize } from '../../utils'
import { checkIfRowExcluded } from '../../utils/tableHelpers'
import { BoxInfo } from '../BoxInfo'
import { FlexSection } from '../FlexSection'
import ImagePreview from '../ImagePreview'
import { LinkTo } from '../LinkTo'
import { Modal } from '../Modal'
import { Text } from '../Text'
import { Tooltip } from '../Tooltip'
import ActionsColumn from './ActionsCol'
import {
  BOTTOM_RIGHT,
  TABLE_DATA_TYPE,
  TOP_RIGHT,
  TOP_RIGHT_AND_BOTTOM_RIGHT
} from './constants'
import {
  EmptyContentWrapper,
  TableContainer,
  ImagePreviewWrapper,
  TableCell
} from './styled'
import TableWarning from './TableWarning'
import {
  ColumnType,
  ICellConfig,
  ITableProps,
  ITActionColumnConfig,
  Position
} from './types'

const renderMap = {
  [TABLE_DATA_TYPE.TEXT]:
    ({ warnTextPath }: ICellConfig) =>
    (val: string, rec: Record<string, any>) => {
      const warnMessage = warnTextPath ? get(rec, warnTextPath, '') : ''
      return (
        <TableCell>
          {val || '-'}
          {!!warnMessage && <TableWarning message={warnMessage} />}
        </TableCell>
      )
    },
  [TABLE_DATA_TYPE.ARRAY]:
    ({ warnTextPath }: ICellConfig) =>
    (val: Record<string, string>[], rec: Record<string, any>) => {
      const warnMessage = warnTextPath ? get(rec, warnTextPath, '') : ''
      return (
        <TableCell>
          {val && Array.isArray(val) && !isEmpty(val) ? val.join(', ') : '-'}
          {!!warnMessage && <TableWarning message={warnMessage} />}
        </TableCell>
      )
    },
  [TABLE_DATA_TYPE.DATE]:
    ({ warnTextPath }: ICellConfig) =>
    (val: string, rec: Record<string, any>) => {
      const warnMessage = warnTextPath ? get(rec, warnTextPath, '') : ''
      return (
        <TableCell>
          {val ? moment(val).format('DD/MM/YYYY') : '-'}
          {!!warnMessage && <TableWarning message={warnMessage} />}
        </TableCell>
      )
    },
  [TABLE_DATA_TYPE.BOOL]:
    ({ warnTextPath }: ICellConfig) =>
    (val: boolean, rec: Record<string, any>) => {
      const warnMessage = warnTextPath ? get(rec, warnTextPath, '') : ''
      return (
        <TableCell>
          {val ? 'Yes' : 'No'}
          {!!warnMessage && <TableWarning message={warnMessage} />}
        </TableCell>
      )
    },
  [TABLE_DATA_TYPE.THREESTATE]:
    ({ warnTextPath }: ICellConfig) =>
    (val: boolean, rec: Record<string, any>) => {
      const warnMessage = warnTextPath ? get(rec, warnTextPath, '') : ''
      return (
        <TableCell>
          {val !== null ? (val ? 'Yes' : 'No') : '-'}
          {!!warnMessage && <TableWarning message={warnMessage} />}
        </TableCell>
      )
    },
  [TABLE_DATA_TYPE.MODAL_LIST]:
    ({ warnTextPath }: ICellConfig) =>
    (val: string[], record: Record<string, any>) => {
      const { id } = record
      const textSapCodes = val.slice(0, 4)
      const warnMessage = warnTextPath ? get(record, warnTextPath, '') : ''
      return (
        <TableCell>
          {val.length <= 4 ? (
            <>{val.length ? val.join(', ') : '-'}</>
          ) : (
            <>
              {textSapCodes.join(', ')}
              <Modal
                btnName="... Others"
                btnType="link"
                modalKey={`${id}-modal-sap-codes`}
                title=""
                isSmall={val.length < 200}
                size="small"
              >
                <FlexSection flexDirection="row" justifyContent="center">
                  <Text text="List of Sap Codes" variant="h4" />
                </FlexSection>
                <BoxInfo value={val} label="" />
              </Modal>
            </>
          )}
          {!!warnMessage && <TableWarning message={warnMessage} />}
        </TableCell>
      )
    },
  [TABLE_DATA_TYPE.NODE]:
    ({ renderNode }: { renderNode: (val?: any, rec?: Record<string, any>) => FC }) =>
    (val: any, rec: Record<string, any>) =>
      renderNode ? renderNode(val, rec) : '-',
  [TABLE_DATA_TYPE.IMAGE]:
    ({ hasBorder, warnTextPath }: ICellConfig, key: string) =>
    (rec: any, ...rest: any) => {
      const status = get(rest, '0.status') // add border based on status
      const warnMessage = warnTextPath ? get(rec, warnTextPath, '') : ''
      return (
        <TableCell>
          <ImagePreviewWrapper hasBorder={hasBorder} status={status}>
            <ImagePreview value={rec} alt={key} isUrl />
          </ImagePreviewWrapper>
          {!!warnMessage && <TableWarning message={warnMessage} />}
        </TableCell>
      )
    },
  [TABLE_DATA_TYPE.LINK]:
    ({ pathname, newTab, warnTextPath }: ICellConfig) =>
    (val: any, rec: any) => {
      const warnMessage = warnTextPath ? get(rec, warnTextPath, '') : ''
      return (
        <TableCell>
          {val ? (
            <>
              <LinkTo
                pathname={pathname || ''}
                value={rec}
                text={val || '-'}
                target={newTab ? '_blank' : undefined}
              />
              {!!warnMessage && <TableWarning message={warnMessage} />}
            </>
          ) : (
            '-'
          )}
        </TableCell>
      )
    }
} as Record<string, any>

export default function Table<T extends Record<string, any>>({
  items: initialItems = [],
  pagination = {},
  columns = [],
  size,
  enablePagination,
  forceHeight,
  rowKey = 'id',
  isSticky = false,
  paginationPosition = BOTTOM_RIGHT,
  onSelectRow,
  disableHeaderSelect,
  onChangePage,
  enableRowSelect = false,
  contentOverTableHeight = 0,
  isLoading = false,
  emptyBody,
  actionColumnConfig,
  isSingleSelect,
  selectedRows = [],
  childActionConfig,
  isChildSelect = false,
  expandableConfig,
  excludeSelection = {},
  selectLimit = null
}: ITableProps<T>) {
  const scrollContext = useContext(ScrollContext)
  const childHasDetail = get(childActionConfig, 'hasDetail', false)
  const childHasDelete = get(childActionConfig, 'hasDelete', false)

  const items = useMemo(() => {
    return initialItems.reduce<Record<string, any>[]>((acc, { children, ...row }) => {
      return children?.length
        ? [
            ...acc,
            {
              children: children.map((child: Record<string, any>) => ({
                ...child,
                isChild: true,
                hasDelete: childHasDelete,
                hasDetail: childHasDetail
              })),
              ...row
            }
          ]
        : [...acc, row]
    }, [])
  }, [initialItems, childHasDetail, childHasDelete])

  const hasSelectExclusion = !isEmpty(excludeSelection)

  const notSelectableItems = hasSelectExclusion
    ? items.filter((item) =>
        checkIfRowExcluded({ record: item, exclude: excludeSelection })
      )
    : []

  const tableRef = useRef<HTMLTableElement>(null)
  const [totalPaginationHeight, setTotalPaginationHeight] = useState(0)
  const { height } = useWindowSize()
  useEffect(() => {
    if (enablePagination && tableRef.current) {
      const paginationElementList = enablePagination
        ? Array.from(tableRef.current.getElementsByClassName('ant-pagination'))
        : []
      const paginationsHeight = paginationElementList.reduce((acc, element) => {
        if (element) {
          acc = acc + (element as HTMLElement).offsetHeight
        }
        return acc
      }, 0)
      setTotalPaginationHeight(paginationsHeight)
    }
  }, [enablePagination, columns])

  const tableHeaderFixed =
    headerHeight + footerHeight + totalPaginationHeight + contentOverTableHeight + 150
  const tableHeight = height ? height - tableHeaderFixed : 0

  const columnHider = useCallback((columns: Record<string, any>[]) => {
    const filteredByKeys = columns.filter(({ hide = false }) => !hide)
    return filteredByKeys
  }, [])

  const getColumn = useCallback(
    (columns: Record<string, any>[]) => {
      const fiteredCols = columnHider(columns)
      return fiteredCols.map((column: Record<string, any>) => {
        return {
          ...column,
          dataIndex: column.dataIndex.split('.'),
          children: undefined,
          width: column.width
            ? Number(column.width)
            : Math.max(150, column.title.length * 8.5) + (column.showSortable ? 40 : 0),
          ellipsis: get(column, 'enableEllipsis', false),
          render: renderMap[column.dataType]
            ? renderMap[column.dataType](column)
            : undefined,
          onFilter: column.showFilter
            ? (value: string | number | boolean, record: Record<string, any>) =>
                record.address.startsWith(value)
            : undefined,
          sorter: column.showSortable
            ? (a: T, b: T) => {
                return sorterHelper({
                  key: column.key,
                  values: {
                    aValue: a[column.dataIndex as any],
                    bValue: b[column.dataIndex as any]
                  }
                })
              }
            : undefined
        }
      })
    },
    [columnHider]
  )

  const getActions = (config: ITActionColumnConfig) => {
    const actionCol: ColumnType<any> = {
      fixed: 'right',
      title: config.columnTitle,
      width: `${config.width || 200}px`,
      render: (rec: Record<string, any>) => (
        <ActionsColumn rec={rec} actionConfig={config} />
      )
    }
    if (config.alignCenter) actionCol.align = 'center'
    return actionCol
  }

  const columnsWithFilters: ColumnType<any>[] = useMemo(() => {
    const cols: ColumnType<any>[] = []
    cols.push(...getColumn(columns))
    if (actionColumnConfig) {
      const actions = getActions(actionColumnConfig)
      cols.push(actions)
    }
    return cols
  }, [columns, actionColumnConfig, getColumn])

  const getPaginationPosition: () => Position[] = () => {
    switch (paginationPosition) {
      case 'TOP_RIGHT_AND_BOTTOM_RIGHT':
        return ['topRight', 'bottomRight']
      case 'TOP_RIGHT':
        return ['topRight']
      case 'BOTTOM_RIGHT':
        return ['bottomRight']
      case 'TOP_LEFT':
        return ['topLeft']
      default:
        return ['topRight', 'bottomRight']
    }
  }

  const onChangePageHandler = useCallback(
    (
      pageData: TablePaginationConfig,
      _: Record<string, FilterValue | null>,
      sorter: SorterResult<object> | SorterResult<any>[]
    ) => {
      scrollContext.scrollTop()
      const sortData = {
        field: get(sorter, 'field.0', ''),
        order: get(sorter, 'order', '')
      }
      if (onChangePage) {
        onChangePage(pageData, sortData)
      }
    },
    [scrollContext, onChangePage]
  )

  const tablePaginationPositions: Position[] = enablePagination
    ? getPaginationPosition()
    : []

  const selectHandler = (record: Record<string, any>, isSelected: boolean) => {
    let selectedElements: any[] = isSingleSelect ? [record] : [...selectedRows]
    if (!isSingleSelect) {
      if (isSelected) {
        selectedElements.push(record)
      } else {
        selectedElements = selectedElements.filter(
          (element) => get(element, rowKey, '') !== get(record, rowKey, '')
        )
      }
    }
    onSelectRow && onSelectRow(selectedElements)
  }

  const rowSelection: TableRowSelection<Record<string, any>> = {
    onSelectAll: (isSelected, _, recordEffected) => {
      let selectedElements: any[] = []
      if (isSelected) {
        const allRecords = [...selectedRows, ...recordEffected]
        const allIds = allRecords.map((rec) => get(rec, rowKey, ''))
        const unifyIds = [...new Set(allIds)]
        unifyIds.forEach((id) => {
          const record = allRecords.find((rec) => get(rec, rowKey, '') === id)
          selectedElements.push(record)
        })
      } else {
        selectedElements = [...selectedRows]
        recordEffected.forEach((rec) => {
          selectedElements = selectedElements.filter(
            (el) => get(el, rowKey, '') !== get(rec, rowKey, '')
          )
        })
      }
      const filteredSelected = selectedElements.filter(
        (el) => !checkIfRowExcluded({ record: el, exclude: excludeSelection })
      )
      onSelectRow && onSelectRow(filteredSelected)
    },
    selectedRowKeys: (selectedRows.length
      ? selectedRows.concat(notSelectableItems)
      : selectedRows
    ).map((row) => get(row, rowKey, '')),
    type: 'checkbox',
    fixed: 'left',
    renderCell: (value, record) => {
      const isExcluded = checkIfRowExcluded({ record, exclude: excludeSelection })
      if (isExcluded) {
        return null
      }

      const isChild = get(record, 'isChild', false)
      if (!isChild) {
        const isDisable =
          selectLimit !== null &&
          selectedRows.length >= selectLimit &&
          !selectedRows.some((data) => data[rowKey] === record[rowKey])
        return (
          <Tooltip title={isDisable ? 'Limit reached' : ''}>
            <Checkbox
              checked={value}
              onClick={() => {
                selectHandler(record, !value)
              }}
              disabled={isDisable}
            />
          </Tooltip>
        )
      }
      return (
        isChildSelect && (
          <Checkbox
            checked={value}
            onClick={() => {
              selectHandler(record, !value)
            }}
          />
        )
      )
    }
  }

  if (disableHeaderSelect || isSingleSelect) {
    rowSelection.columnTitle = <></>
  }

  const locale = {
    emptyText: emptyBody ? (
      <EmptyContentWrapper>{emptyBody}</EmptyContentWrapper>
    ) : undefined
  }

  const hasExpandConfig = !isEmpty(expandableConfig)
  let expandConfig: (ExpandableConfig<object> & ExpandableConfig<any>) | undefined
  if (hasExpandConfig) {
    expandConfig = {}
    expandConfig['defaultExpandAllRows'] = expandableConfig?.isDefaultOpen
  }

  const getRowClassName = (record: Record<string, any>) => {
    const selectedRowsIdentifiers = selectedRows.map((row) => get(row, rowKey))
    return selectedRowsIdentifiers.includes(record[rowKey]) ? 'selected-row' : ''
  }

  return (
    <TableContainer
      paginationPosition={paginationPosition}
      expandable={expandConfig}
      ref={tableRef}
      rowSelection={enableRowSelect ? rowSelection : undefined}
      rowClassName={getRowClassName}
      pagination={
        !!enablePagination && {
          ...pagination,
          position: tablePaginationPositions,
          showSizeChanger: false
        }
      }
      onChange={onChangePageHandler}
      columns={columnsWithFilters}
      dataSource={items}
      rowKey={rowKey}
      size={size}
      scroll={{ x: columns.length * 100, y: forceHeight ? tableHeight : undefined }}
      sticky={isSticky}
      loading={isLoading}
      locale={locale}
    />
  )
}
