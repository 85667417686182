import { get } from 'lodash'
import { FC, useCallback } from 'react'
import { Tag } from '../../components/Tag'
import {
  checkIsClosable,
  getChipsValueLabel,
  mapChipsLabel,
  normalizeTags
} from './keyMapChips'
import { StyledTagsContainer, StyledTagsContainerInternal } from './styled'
import { ITagsContainerProps } from './types'

export const TagsContainer: FC<ITagsContainerProps> = ({
  tags,
  inner,
  basePath,
  prefix,
  reducer,
  flowName,
  allowNoFilters,
  onRemoveTag
}) => {
  const Container = inner ? StyledTagsContainerInternal : StyledTagsContainer

  const onRemove = useCallback(
    (path: string) => {
      if (onRemoveTag) {
        return onRemoveTag(path)
      }
    },
    [onRemoveTag]
  )
  if (!tags) return null
  const normalizedTags = Object.entries(normalizeTags(tags, basePath || prefix)).filter(
    ([k, { value }]: any) =>
      value !== null && value !== undefined && k !== '_type' && k !== 'id'
  )

  return (
    <Container>
      {normalizedTags &&
        normalizedTags.map(([k, v]: any) =>
          v.value !== '' && Array.isArray(v.value) ? (
            v.value.map(
              (item: string, i: string) =>
                item && (
                  <Tag
                    key={`${k}${i}`}
                    closable={checkIsClosable(normalizedTags, reducer, k, allowNoFilters)}
                    value={`${get(mapChipsLabel, `${flowName}.${k}`, k)} : ${
                      getChipsValueLabel(flowName, k, item) || item
                    }`}
                    path={`${v.path}.${i}`}
                    onRemove={onRemove}
                  />
                )
            )
          ) : (
            <Tag
              key={k}
              closable={checkIsClosable(normalizedTags, reducer, k, allowNoFilters)}
              value={`${get(mapChipsLabel, `${flowName}.${k}`, k)} : ${
                getChipsValueLabel(flowName, k, v.value) || v.value
              }`}
              path={v.path}
              onRemove={onRemove}
            />
          )
        )}
    </Container>
  )
}
