import styled from 'styled-components'

export const FlexColorWrapper = styled.div<{ isEditing: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ isEditing }) => (isEditing ? 'center' : 'flex-end')};
  margin: 0;
  padding: ${({ isEditing }) => (isEditing ? '0 1% !important' : '1% important')};
  > div {
    padding: 0;
  }
`
