import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { threeOptions } from '../../../../features/constants'
import { selectFormulaSchemaDropdown } from '../../../../features/formulaSchema/selectors'
import { viewNullAsNullString } from '../../../../utils/taxonomiesHelper'
import { BoxWrapper } from '../../../BoxWrapper'
import InfoCell from '../../../InfoCell'
import { IFormulaFormProps } from './types'

const FormulaForm: FC<IFormulaFormProps> = ({ data = {}, onUpdate, isEditing }) => {
  const dropdownData = useSelector(selectFormulaSchemaDropdown)

  let csarRegisteredDropdown = get(dropdownData, 'csarRegistered', []).slice()
  csarRegisteredDropdown.push({ name: 'Null', value: null })
  let RSPODropdown = get(dropdownData, 'RSPO', []).slice()
  RSPODropdown.push({ name: 'Null', value: null })
  let GMODropdown = get(dropdownData, 'GMO', []).slice()
  GMODropdown.push({ name: 'Null', value: null })

  return (
    <Form layout="vertical">
      <BoxWrapper md={2} lg={3} enablePadding>
        <InfoCell
          isEditing={isEditing}
          label="Industrial Code Country Check Compliance Notes"
          value={get(data, 'countryCheckComplianceNotes', '')}
          inputConfig={{
            onChange: (value: any) => onUpdate('countryCheckComplianceNotes', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="CSAR Registered"
          value={viewNullAsNullString(data, 'csarRegistered')}
          inputType="select"
          inputConfig={{
            items: csarRegisteredDropdown,
            onChange: ({ value }: Record<string, any>) => {
              onUpdate('csarRegistered', value)
            },
            onClear: () => {
              onUpdate('csarRegistered', null)
            }
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Vegan"
          value={get(data, 'vegan', null)}
          inputType="options-button"
          tooltipConfig={{
            message: 'Formula does not contain animal origin ingredients',
            enable: true
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('vegan', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Vegan Notes"
          value={get(data, 'veganNotes', '')}
          inputConfig={{
            onChange: (value: any) => onUpdate('veganNotes', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="RSPO"
          value={viewNullAsNullString(data, 'RSPO')}
          inputType="select"
          inputConfig={{
            items: RSPODropdown,
            onChange: ({ value }: any) => {
              onUpdate('RSPO', value)
            },
            onClear: () => {
              onUpdate('RSPO', null)
            }
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of Microplastic"
          value={get(data, 'microplasticFree', null)}
          inputType="options-button"
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('microplasticFree', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of Microplastic Notes"
          value={get(data, 'microplasticFreeNotes', '')}
          inputConfig={{
            onChange: (value: any) => onUpdate('microplasticFreeNotes', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of Silicones"
          value={get(data, 'siliconesFree', null)}
          inputType="options-button"
          tooltipConfig={{
            message: 'As ingredients',
            enable: true
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('siliconesFree', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of Talc"
          value={get(data, 'talcFree', null)}
          inputType="options-button"
          tooltipConfig={{
            message: 'As INCI name',
            enable: true
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('talcFree', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of PEG"
          value={get(data, 'pegFree', null)}
          inputType="options-button"
          tooltipConfig={{
            message: 'As INCI name',
            enable: true
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('pegFree', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of D5"
          value={get(data, 'd5Free', null)}
          inputType="options-button"
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('d5Free', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of Silica"
          value={get(data, 'silicaFree', null)}
          inputType="options-button"
          tooltipConfig={{
            message: 'As INCI name',
            enable: true
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('silicaFree', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of GMO"
          value={viewNullAsNullString(data, 'GMO')}
          inputType="select"
          inputConfig={{
            items: GMODropdown,
            onChange: ({ value }: any) => {
              onUpdate('GMO', value)
            },
            onClear: () => onUpdate('GMO', null)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of Parabens"
          value={get(data, 'paraben', null)}
          inputType="options-button"
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('paraben', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean Of Preservatives"
          value={get(data, 'cleanOfPreservatives', null)}
          inputType="options-button"
          tooltipConfig={{
            message: 'InfoTooltip title="According to Annex V EC Regulation 1223/2009',
            enable: true
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('cleanOfPreservatives', value)
          }}
        />
        <InfoCell
          isEditing={isEditing}
          label="Clean of Mineral Oils"
          value={get(data, 'cleanOfMineralOils', null)}
          inputType="options-button"
          tooltipConfig={{
            message: 'Derived hydrocarbons',
            enable: true
          }}
          inputConfig={{
            options: threeOptions,
            onChange: (value: any) => onUpdate('cleanOfMineralOils', value)
          }}
        />
        <InfoCell
          value={get(data, 'cleanOfFragrance', null)}
          dataType="options-value"
          inputType="options-button"
          label={'Clean of Fragrance'}
          isEditing={isEditing}
          inputConfig={{
            options: threeOptions,
            onChange: (value: Record<string, any>) => onUpdate('cleanOfFragrance', value)
          }}
        />
      </BoxWrapper>
    </Form>
  )
}

export default FormulaForm
