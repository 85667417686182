import { createGlobalStyle } from 'styled-components'
import { ITheme } from './interfaces'

export const GlobalStyle = createGlobalStyle<{ theme: ITheme }>`
    * { 
        margin: 0;
        box-sizing: border-box;
    };

    /* Outlined Buttons */
    .ant-btn-ghost {
        border-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.main};
    }

    .ant-modal-footer {
        justify-content: flex-end;
        display: flex;
        background-color: white;
        button {
          margin: 0 5px;
        }
    }

    /* Tags and Chips */
    .ant-tag {
        background-color: #fff;
        border: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
    }
`
