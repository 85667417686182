import { FC } from 'react'
import { Table } from '../../../components'
import { columnsGeneratorHelper, rowsGeneratorHelper } from '../../../utils'
import { IFailedImportTableProps } from './types'

const FailedImportTable: FC<IFailedImportTableProps> = ({ dataList, tableConfig }) => {
  const columnGenerator = () => {
    const columnList = columnsGeneratorHelper(tableConfig)
    return columnList
  }

  const rowsGenerator = () => rowsGeneratorHelper(dataList, tableConfig)

  const columns = columnGenerator()
  const dataRow = dataList.length ? rowsGenerator() : []
  return <Table rowKey="id" columns={columns} items={dataRow} forceHeight />
}

export default FailedImportTable
