import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'

import { refactorTaxonomies } from '../../utils'
import {
  getTypeFromProdForm,
  refactoredStoreProdChildNewData,
  refactoredStoreProdNewData
} from '../../utils/entityHelper'
import { appSliceActions } from '../app/reducer'
import { cartSliceActions } from '../cart'
import { getCartItemsByAuthor } from '../cart/api'
import { createEntityApi } from '../entity/api'
import { Pagination } from '../packaging/model'
import { selectUserEmail } from '../users/selectors'
import {
  addStoreProdChild,
  deleteChildById,
  deleteStoreProductById,
  getStoreProdChildById,
  getStoreProductById,
  getStoreProductDynamicTaxonomies,
  searchStoreProducts,
  updateChildById,
  updateStoreProductById
} from './api'
import { STORE_PROD_DYNAMIC_TAXONOMIES, storeActionsType } from './constants'
import {
  ICheckItemAvailabilityByIdPayload,
  IOrderItemResult,
  IStoreFilterTaxonomiesPayload,
  IStoreProd,
  IStoreProdChild,
  IStoreProdFilters,
  ProductFormType
} from './model'
import { storeSliceActions } from './reducer'
import {
  getFilters,
  selectNewChild,
  selectNewStoreProd,
  selectProductPagination
} from './selectors'
import { filterOrderableItems } from './utils'

function* searchProductsSaga() {
  yield put(storeSliceActions.changeLoader(true))
  try {
    yield put(storeSliceActions.removeActionsAddon())
    yield call(fetchProductsSaga)
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

function* fetchProductsSaga() {
  try {
    const filters: IStoreProdFilters = yield select(getFilters)
    const { page: selectedPage, pageSize: selectedPageSize }: Pagination = yield select(
      selectProductPagination
    )
    const {
      results = [],
      totalPages,
      page,
      pageSize,
      total
    }: IOrderItemResult = yield call(searchStoreProducts, {
      ...filters,
      page: selectedPage,
      pageSize: selectedPageSize
    })

    const orderable = filterOrderableItems(results)
    yield put(storeSliceActions.setPagination({ totalPages, page, pageSize, total }))
    yield put(storeSliceActions.setProducts(orderable))
  } catch (err) {
    throw err
  }
}

function* removeFilters({ payload }: any) {
  yield put(storeSliceActions.changeLoader(true))
  try {
    yield put(storeSliceActions.removeFilter(payload.path))
    yield call(fetchProductsSaga)
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

type IFetchDetailResp = SagaReturnType<typeof fetchStoreProdDetail>
function* fetchStoreProdDetail({ id }: { id: string }) {
  try {
    const product: IStoreProd = yield call(getStoreProductById, id)
    const type = getTypeFromProdForm({
      productForm: product.productForm as ProductFormType
    })
    return { ...product, type, image: { value: product.thumbnail?.url || '' } }
  } catch (err: any) {
    throw err
  }
}

function* getDetailSaga({ payload }: PayloadAction<{ id: string }>) {
  yield put(storeSliceActions.changeLoader(true))
  const { id } = payload
  try {
    const product: IFetchDetailResp = yield call(fetchStoreProdDetail, { id })
    yield put(storeSliceActions.setDetail(product))
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

function* fetchChildDetail({ payload }: PayloadAction<{ id: string }>) {
  yield put(storeSliceActions.changeLoader(true))
  const { id } = payload
  try {
    const childData: IStoreProdChild = yield call(getStoreProdChildById, id)
    yield put(storeSliceActions.setChildDetail(childData))
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

function* checkItemAvailabilityByIdSaga({
  payload
}: PayloadAction<ICheckItemAvailabilityByIdPayload>) {
  yield put(appSliceActions.setLoader({ type: 'modal', value: true }))
  try {
    const { id } = payload
    const productDetail: IStoreProd = yield call(getStoreProductById, id)
    const owner: string = yield select(selectUserEmail)
    const { items: cartItems } = yield call(getCartItemsByAuthor, owner)
    let newAvailability = productDetail?.availableQuantity || 0
    if (cartItems) {
      const itemInCart = cartItems.find(({ item }: any) => item.id === id)
      if (itemInCart) {
        newAvailability = newAvailability - (itemInCart?.quantity || 0)
      }
    }
    yield put(
      storeSliceActions.updateDetail({ key: 'availableQuantity', value: newAvailability })
    )
  } catch (err: any) {
    yield put(cartSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'modal', value: false }))
  }
}

function* createEntitySaga() {
  yield put(storeSliceActions.changeLoader(true))
  try {
    const newEntityData: IStoreProd = yield select(selectNewStoreProd)
    const form_data = new FormData()
    const refactoredData = refactoredStoreProdNewData(newEntityData)
    if (refactoredData.image) {
      form_data.append('image', refactoredData.image)
    }
    form_data.append('document', JSON.stringify(refactoredData.document))

    yield call(createEntityApi, { form_data })
    yield put(
      storeSliceActions.setSuccess({
        title: 'The Entity was created successfully!',
        message:
          'The entity has been successfully created and saved in the Products section.'
      })
    )
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

function* updateEntitySaga() {
  yield put(storeSliceActions.changeLoader(true))
  try {
    const newEntityData: IStoreProd = yield select(selectNewStoreProd)
    const refactoredData = refactoredStoreProdNewData(newEntityData)
    const form_data = new FormData()
    if (refactoredData.image) {
      form_data.append('image', refactoredData.image)
    }
    form_data.append('document', JSON.stringify(refactoredData.document))
    const updatedEntity: IStoreProd = yield call(updateStoreProductById, {
      id: newEntityData.id!,
      form_data
    })
    const type = getTypeFromProdForm({
      productForm: updatedEntity.productForm as ProductFormType
    })
    yield put(
      storeSliceActions.setDetail({
        ...updatedEntity,
        image: { value: updatedEntity.thumbnail?.url },
        type
      })
    )
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

function* updateEntityChildSaga() {
  yield put(storeSliceActions.changeLoader(true))
  try {
    const newChildData: IStoreProd = yield select(selectNewChild)
    const refactoredData = refactoredStoreProdChildNewData(newChildData)
    const form_data = new FormData()
    form_data.append('document', JSON.stringify(refactoredData))
    const updatedChild: IStoreProd = yield call(updateChildById, {
      id: newChildData.id!,
      form_data
    })
    yield put(storeSliceActions.setChildDetail(updatedChild))
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

function* deleteEntitySaga({ payload }: AnyAction) {
  const { id } = payload
  yield put(storeSliceActions.changeLoader(true))
  try {
    yield call(deleteStoreProductById, id)
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

function* deleteEntityChildSaga({ payload }: AnyAction) {
  const { id } = payload
  yield put(storeSliceActions.changeLoader(true))
  try {
    yield call(deleteChildById, id)
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

function* addEntityChildSaga({ payload }: AnyAction) {
  const { parentId, data } = payload
  yield put(storeSliceActions.changeLoader(true))
  try {
    const form_data = new FormData()
    form_data.append('document', JSON.stringify(data))
    yield call(addStoreProdChild, { parentId, form_data })
    const product: IFetchDetailResp = yield call(fetchStoreProdDetail, { id: parentId })
    yield put(storeSliceActions.setDetail(product))
  } catch (err: any) {
    yield put(storeSliceActions.setError(err))
  } finally {
    yield put(storeSliceActions.changeLoader(false))
  }
}

function* storeFilterTaxonomiesSaga({
  payload
}: PayloadAction<IStoreFilterTaxonomiesPayload>) {
  const { filters, modalType } = payload
  try {
    yield put(appSliceActions.setLoader({ type: modalType, value: true }))
    const dynamicFilters = Object.entries(filters).reduce((acc: any, [key, value]) => {
      if (STORE_PROD_DYNAMIC_TAXONOMIES.includes(key)) {
        acc[key as any] = value
      }
      return acc
    }, {})
    const filteredTaxonomies: Record<string, any> = yield call(
      getStoreProductDynamicTaxonomies,
      dynamicFilters
    )

    const dropDown = refactorTaxonomies(filteredTaxonomies)
    yield put(storeSliceActions.updateDropdown({ dropDown }))
  } catch (err: any) {
    // NOTE - better to don't populate error
    // to avoid bad user experience
  } finally {
    yield put(appSliceActions.setLoader({ type: modalType, value: false }))
  }
}

export default function* storeSaga() {
  yield takeLatest(storeActionsType.search, searchProductsSaga)
  yield takeLatest(storeActionsType.fetch, fetchProductsSaga)
  yield takeLatest(storeActionsType.removeFilters, removeFilters)
  yield takeLatest(storeActionsType.getDetail, getDetailSaga)
  yield takeLatest(storeActionsType.getChild, fetchChildDetail)
  yield takeLatest(
    storeActionsType.checkItemAvailabiltyById,
    checkItemAvailabilityByIdSaga
  )
  yield takeLatest(storeActionsType.createEntity, createEntitySaga)
  yield takeLatest(storeActionsType.updateEntity, updateEntitySaga)
  yield takeLatest(storeActionsType.updateEntityChild, updateEntityChildSaga)
  yield takeLatest(storeActionsType.deleteEntity, deleteEntitySaga)
  yield takeLatest(storeActionsType.deleteEntityChild, deleteEntityChildSaga)
  yield takeLatest(storeActionsType.addEntityChild, addEntityChildSaga)
  yield takeLatest(
    storeActionsType.STORE_PRODUCT_FILTER_TAXONOMIES,
    storeFilterTaxonomiesSaga
  )
}
