import styled from 'styled-components'
import { IChartLegendColorProps } from './types'

export const ChartLegendContainer = styled.div`
  padding: 10px;
  padding-top: 0;
  max-width: 350px;
  overflow-y: auto;
`

export const ChartLegendCard = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 4px;
  width: 100%;
`

export const ChartLegendColor = styled.div<IChartLegendColorProps>`
  width: 45px;
  height: 15px;
  margin-top: 6px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-color: ${({ borderColor }) => borderColor};
  border-width: 2px;
  border-style: solid;
`

export const ChartLegendLabel = styled.div<{ isSelected?: boolean }>`
  width: 260px;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: ${({ isSelected }) => (isSelected ? 'line-through' : 'none')};
`

export const ChartLegendDisclaimer = styled.div`
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
`

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
