import { get } from 'lodash'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, SectionTitle } from '../../../components'
import { masterSpecActions } from '../../../features/masterSpec/actions'
import { IMasterSpecDetail } from '../../../features/masterSpec/model'
import { masterSpecSliceActions } from '../../../features/masterSpec/reducer'
import {
  selectCharacteristicList,
  selectNewMasterSpec
} from '../../../features/masterSpec/selectors'
import { cleanMasterSpec } from '../../../utils/masterAndProjSpecificationHelpers'
import MasterSpecificationDetailContent from '../../MasterSpecificationDetailsContent'
import { ButtonsWrapper } from '../AddMasterSpecificationModal/styled'
import { IMasterSpecificationEditorProps } from './types'

export const MasterSpecificationsEditor: FC<IMasterSpecificationEditorProps> = ({
  packagingDetails
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const newMasterSpecification: Partial<IMasterSpecDetail> =
    useSelector(selectNewMasterSpec)
  const characteristicList = useSelector(selectCharacteristicList)

  let { specId, packageVariantId } = useParams()

  useEffect(() => {
    dispatch(masterSpecActions.getCharacteriscticAndApplyDefault())
    if (!specId) dispatch(masterSpecSliceActions.resetNew())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // package details variables
  const imageValue = get(packagingDetails, 'image.value', '')
  const supplierName = get(packagingDetails, 'testedSupplier.name', '')
  const supplierItemCode = get(packagingDetails, 'testedSupplier.SAPCodes', '')
  const sideCollectorId = get(packagingDetails, 'id', '')

  const saveHandler = () => {
    const tweaked = {
      ...newMasterSpecification,
      supplierName: supplierName,
      supplierItemCode: supplierItemCode,
      imageValue: imageValue,
      category: get(packagingDetails, 'category', ''),
      sideCollectorId: sideCollectorId
    }
    const payload = cleanMasterSpec(tweaked, packageVariantId || '')
    dispatch(masterSpecActions.createMasterSpecification(payload || {}))
    navigate(-1)
  }

  return (
    <>
      <SectionTitle
        value={''}
        isBold
        addon={
          <ButtonsWrapper>
            <Button label="Save" onClick={saveHandler} />
          </ButtonsWrapper>
        }
      />

      <MasterSpecificationDetailContent
        packageDetails={packagingDetails || {}}
        masterSpecDetails={newMasterSpecification}
        isEditing={true}
        characteristicList={characteristicList}
      />
    </>
  )
}
