import { get } from 'lodash'
import { FC, useState } from 'react'
import { RadarChart } from '../../../components/Charts'
import { IRadarChartDataConfig } from '../../../components/Charts/RadarChart/types'
import { characteristicsMappedLabels } from '../../../features/products/constants'
import { TProductCharacteristics } from '../../../features/products/model'
import { generateRGBString } from '../../../utils/colorsHelpers'
import { defaultColorListRGB } from '../../../utils/colorsHelpers/constants'
import { LegendProductName, ProductSpiderChartContainer } from './styled'
import { IProductSpiderChartProps } from './types'

const ProductSpiderChart: FC<IProductSpiderChartProps> = ({ dataList }) => {
  const [selectedProd, setSelectedProd] = useState<string[]>([])

  const characteristicList = Object.keys(get(dataList, '[0].characteristics', {}))

  dataList.sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))

  const hasCharacteristics = !!characteristicList.length

  const labelList = characteristicList.map(
    (char) =>
      characteristicsMappedLabels[char as TProductCharacteristics].toUpperCase() ||
      char.toUpperCase()
  )

  const chartDataList: IRadarChartDataConfig[] = dataList.map((data, idx) => {
    const formulaSchemaCode = data.formulaSchemaCode || ''
    const fsString = `${formulaSchemaCode ? ` - ${formulaSchemaCode}` : ''}`

    return {
      id: data.id || '',
      dataLabel: `${data.name} (${data.productCode})`,
      dataLegendLabel: (
        <>
          <LegendProductName>{data.name}</LegendProductName>
          <div>
            {data.productCode}
            {fsString}
          </div>
        </>
      ),
      dataSet: characteristicList.map((char) => ({
        value: get(data, `characteristics.${char}`) || 0
      })),
      borderColor: generateRGBString(defaultColorListRGB[idx] || {}, 1),
      backgroundColor: generateRGBString(defaultColorListRGB[idx] || {}, 0.2)
    }
  })

  const selectHandler = (prodId: string) => {
    if (selectedProd.includes(prodId)) {
      setSelectedProd((prevState) => prevState.filter((val) => val !== prodId))
      return
    }
    setSelectedProd((prevState) => prevState.concat(prodId))
  }

  return (
    <ProductSpiderChartContainer>
      {hasCharacteristics ? (
        <RadarChart
          dataList={chartDataList}
          labels={labelList}
          onSelectLegend={(id) => selectHandler(id)}
          selectedLegends={selectedProd}
          hideLegendSelectAll
        />
      ) : (
        <div>
          There is no available characteristics for the product category and business
          segment combination
        </div>
      )}
    </ProductSpiderChartContainer>
  )
}

export default ProductSpiderChart
