import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { canUserAddVariant } from '../../../auth-msal'
import { Divider } from '../../../components/Divider'
import VariantsTable from '../../../components/Packaging/VariantsTable'
import AddVariantsModal from '../../../components/Packaging/VariantsTable/AddVariantModal'
import { IVariantAttachment } from '../../../components/Packaging/VariantsTable/AddVariantModal/types'
import { ITActionColumnConfig } from '../../../components/Table/types'
import { Text } from '../../../components/Text'
import { selectUserRole } from '../../../features/users/selectors'
import { AppRoutes } from '../../../pages/constants'
import { TitleWrapper } from '../../../pages/CreationPackage/styled'
import { EmptyWrapper } from './styled'
import { IVariantsProps } from './types'

const Variants: FC<IVariantsProps> = ({
  data,
  isEditing,
  onAddConfirm = () => {},
  deleteVariant,
  tableConfig,
  isCreating = false,
  isMulti = false,
  category = '',
  subcategory = '',
  sortByKey,
  isSortByDate,
  modalKey,
  hasInfoModal,
  hasRedirect,
  hasDelete = false
}) => {
  const userRoles = useSelector(selectUserRole)
  const list = get(data, 'packageVariants', [])
  const bottleValues = get(data, 'material', [])
  const dataList = list.map((item: any) => ({
    ...item,
    idPackaging: get(data, 'sideCollectorId', ''),
    material: bottleValues
  }))

  const hasOneOnly = dataList.length === 1

  const confirmHandlder = (
    attachmentList: IVariantAttachment[],
    vialDetails: Record<string, any>
  ) => {
    const newVariant = {
      id: Date.now().toString(),
      vialDetails,
      attachments: attachmentList
    }
    onAddConfirm(newVariant)
  }

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data: variantData }) => {
      const id = get(variantData, 'id', '')
      const variant = dataList.find((variant: any) => variant.id === id)
      const { attachments, vialDetails } = variant
      return (
        <>
          {hasInfoModal && (
            <AddVariantsModal
              btnType="text"
              iconName="InfoCircleOutlined"
              isEditing={false}
              attachments={attachments}
              vialsDetails={vialDetails}
              bottleValues={bottleValues}
              modalKey={`${modalKey}-add-details-${id}`}
              category={category}
              subcategory={subcategory}
            />
          )}
        </>
      )
    },
    del:
      !hasOneOnly && isEditing && hasDelete
        ? {
            btnVariant: 'text',
            deleteCb: deleteVariant,
            hasIcon: true
          }
        : undefined,
    detail: hasRedirect
      ? {
          hasIcon: true,
          btnVariant: 'text',
          iconName: 'InfoCircleOutlined',
          destinationPath: AppRoutes.WIKIPACK_PACKAGING_VARIANT
        }
      : undefined
  }

  const sortListHandler = (list: Record<string, any>[]) => {
    const keyExistance = list.every((item) => sortByKey && !!item[sortByKey])
    if (!sortByKey || !keyExistance) {
      return list
    } else {
      return list.sort((itemA, itemB) => {
        const itemANew = isSortByDate ? new Date(itemA[sortByKey]) : itemA[sortByKey]
        const itemBNew = isSortByDate ? new Date(itemB[sortByKey]) : itemB[sortByKey]
        return itemBNew - itemANew
      })
    }
  }

  const isAddVisible =
    (isEditing && isCreating && !!dataList.length) ||
    (isEditing && !isCreating && canUserAddVariant(userRoles))

  return (
    <>
      <TitleWrapper>
        <Text text="Variants" variant="h3" />
        {isAddVisible && (
          <AddVariantsModal
            isEditing={isEditing}
            btnLabel="Add"
            onConfirmAdd={confirmHandlder}
            btnType="text"
            iconName="PlusCircleOutlined"
            iconPosition="right"
            bottleValues={bottleValues}
            modalKey={`${modalKey}-add-variant`}
            category={category}
            subcategory={subcategory}
            isMulti={isMulti}
            isCreating={isCreating}
          />
        )}
      </TitleWrapper>
      <Divider />
      {(!!dataList.length && isEditing) || !isEditing ? (
        <VariantsTable
          dataList={sortListHandler(dataList)}
          isEditing={isEditing}
          isCreating={isCreating}
          actionConfig={actionConfig}
          tableConfig={tableConfig}
        />
      ) : (
        <EmptyWrapper>
          <Text text="There are no variants" variant="h4" />
          <AddVariantsModal
            isEditing={isEditing}
            onConfirmAdd={confirmHandlder}
            btnLabel="Add new one"
            btnType="ghost"
            bottleValues={bottleValues}
            modalKey={`${modalKey}-add-first-variant`}
            category={category}
            subcategory={subcategory}
            isMulti={isMulti}
            isCreating={isCreating}
          />
        </EmptyWrapper>
      )}
    </>
  )
}

export default Variants
