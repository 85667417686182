import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appActions, appSliceActions } from '../../../features/app'
import { fSchemaActions } from '../../../features/formulaSchema'
import { Formula } from '../../../features/formulaSchema/model'
import { selectFSchemaTempFormula } from '../../../features/formulaSchema/selectors'
import Button from '../../Button'
import { Modal } from '../../Modal'
import FormulaForm from './FormulaForm'
import { IEditFormulaModalProps } from './types'

const EditFormulaModalModal: FC<IEditFormulaModalProps> = ({ data }) => {
  const dispatch = useDispatch()
  const tempFormula = useSelector(selectFSchemaTempFormula)
  const modalKey = `edit-industrial-code-${data.id}`

  const closeModalHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
  }

  const updateFormula = (key: keyof Formula, value: any) => {
    dispatch(fSchemaActions.updateTempFormula({ key, value }))
  }

  const saveHandler = () => {
    closeModalHandler()
    dispatch(fSchemaActions.saveFSchemaFormula({ data: tempFormula as Formula }))
  }

  const openPopupHandler = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: 'Are you sure you want to save the changes?',
        key: 'formula-save-confirm',
        message:
          "Please remember that by confirming, the last Update Date present on the product page will be updated with today's date and that a notification will be sent to the BU of reference",
        footer: [
          <Button
            key="cancel"
            label="Cancel"
            onClick={() => dispatch(appSliceActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="confirm"
            label="Confirm"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              saveHandler()
            }}
          />
        ]
      })
    )
  }

  return (
    <Modal
      modalKey={modalKey}
      title="Edit Industrial Code"
      size="large"
      btnName="Edit"
      btnClicked={() => dispatch(fSchemaActions.setTempFormula(data))}
      footer={[
        <Button
          key="cancel"
          label="Cancel"
          variant="ghost"
          onClick={closeModalHandler}
        />,
        <Button key="save" label="Save" onClick={openPopupHandler} />
      ]}
    >
      <FormulaForm data={tempFormula} onUpdate={updateFormula} isEditing />
    </Modal>
  )
}

export default EditFormulaModalModal
