import { createAction } from '@reduxjs/toolkit'
import {
  GET_COUNTRIES_OF_ORIGIN_DROPDOWN,
  PACKAGING_CHECK_STATUS_ON_DELETE,
  PACKAGING_CHECK_SUPPLIER_REF,
  PACKAGING_COPY_PACKAGE,
  PACKAGING_CREATE,
  PACKAGING_CREATE_VARIANT_IN_MONO,
  PACKAGING_CREATE_VARIANT_IN_MULTI,
  PACKAGING_DELETE_ATTACHMENT,
  PACKAGING_EXPORT_MACRO,
  PACKAGING_EXPORT_MICRO,
  PACKAGING_EXPORT_TARGET_PRICE,
  PACKAGING_FETCH_ATTACHMENT_TYPES,
  PACKAGING_FURTHER_VERSION_ADD,
  PACKAGING_FURTHER_VERSION_DELETE,
  PACKAGING_GET_DETAIL,
  PACKAGING_GET_PACKAGES,
  PACKAGING_GET_TESTED_SUPPLIER_DROPDOWN,
  PACKAGING_RELATED_ADD,
  PACKAGING_RELATED_DELETE,
  PACKAGING_REMOVE_ATTACHMENT,
  PACKAGING_REMOVE_PACKAGE,
  PACKAGING_SAVE_NEW_VARIANT_VERSION,
  PACKAGING_SEARCH_WITH_RELATIONS,
  PACKAGING_UPDATE_PACKAGE,
  PACKAGING_UPDATE_VARIANT_ATTACHMENT,
  PACKAGING_VARIANT_ADD,
  PACKAGING_VARIANT_GET,
  PACKAGING_VARIANT_REMOVE,
  PACKAGING_VARIANT_REMOVE_FROM_PACKAGE,
  PACKAGING_VARIANT_UPDATE,
  SUPPLIERS_SEARCH
} from './constants'
import { PackagingDto, Pagination } from './model'

export const packagingActions = {
  getPackagingDetail: createAction<{ id: string; hasLoader?: boolean }>(
    PACKAGING_GET_DETAIL
  ),
  removePackaging: createAction<{
    value: Record<string, any>
    successCallback?: () => void
  }>(PACKAGING_REMOVE_PACKAGE),
  createPackaging: createAction(PACKAGING_CREATE),
  deleteFurtherVersion: createAction<{ value: Record<string, any> }>(
    PACKAGING_FURTHER_VERSION_DELETE
  ),
  addFurtherVersion: createAction<{ selectedPackages: string[] }>(
    PACKAGING_FURTHER_VERSION_ADD
  ),
  getAttachmenttypes: createAction(PACKAGING_FETCH_ATTACHMENT_TYPES),
  deleteRelated: createAction<{ value: Record<string, any> }>(PACKAGING_RELATED_DELETE),
  addRelated: createAction<{ selectedPackages: string[] }>(PACKAGING_RELATED_ADD),
  checkSupplierRef: createAction<{
    supplierRef: string
    nextStep: () => void
    openWarning: () => void
  }>(PACKAGING_CHECK_SUPPLIER_REF),
  addVariants: createAction<{ newVariant: Record<string, any>; packFamilyIdx: number }>(
    PACKAGING_VARIANT_ADD
  ),
  getPackageAndRelations: createAction<{
    withFurther?: boolean
    withRelated?: boolean
    hasLoader?: boolean
    pageData?: { page: number; pageSize: number }
    successCallback?: (newPageData: Pagination) => void
    failCallback?: (error: any) => void
  }>(PACKAGING_SEARCH_WITH_RELATIONS),
  getTestedSupplier: createAction(PACKAGING_GET_TESTED_SUPPLIER_DROPDOWN),
  removeAttachment: createAction<{
    value: Record<string, any>
  }>(PACKAGING_DELETE_ATTACHMENT),
  updatePackage: createAction(PACKAGING_UPDATE_PACKAGE),
  getPackages: createAction<{ hasloader: boolean } | undefined>(PACKAGING_GET_PACKAGES),
  copyPackage: createAction<{ id: string }>(PACKAGING_COPY_PACKAGE),
  getVariantDetail: createAction<{
    variantId: string
    packagingId: string
    hasLoader?: boolean
  }>(PACKAGING_VARIANT_GET),
  checkStatusOnDelete: createAction<{ value: PackagingDto }>(
    PACKAGING_CHECK_STATUS_ON_DELETE
  ),
  updateVariant: createAction<{ packagingId: string }>(PACKAGING_VARIANT_UPDATE),
  deleteVariantFromVariant: createAction<{
    value: Record<string, any>
    successCallback?: () => void
  }>(PACKAGING_VARIANT_REMOVE),
  deleteVariantFromPackage: createAction<{ value: Record<string, any> }>(
    PACKAGING_VARIANT_REMOVE_FROM_PACKAGE
  ),
  deleteAttachment: createAction<{
    attachData: Record<string, any>
    packageId: string
    variantId?: string
  }>(PACKAGING_REMOVE_ATTACHMENT),
  updateVariantAttach: createAction<{
    attachData: Record<string, any>
    packageId: string
    variantId?: string
  }>(PACKAGING_UPDATE_VARIANT_ATTACHMENT),
  saveNewVariantVersion: createAction<{
    attachData: Record<string, any>
    packageId: string
    variantId?: string
  }>(PACKAGING_SAVE_NEW_VARIANT_VERSION),
  createVariantInMono: createAction<{
    attachData: Record<string, any>
    variantId: string
    packageId: string
    isPackageDetail?: boolean
  }>(PACKAGING_CREATE_VARIANT_IN_MONO),
  createVariantInMulti: createAction<{
    attachData: Record<string, any>
    variantId: string
    packageId: string
    isPackageDetail?: boolean
  }>(PACKAGING_CREATE_VARIANT_IN_MULTI),
  exportMacro: createAction(PACKAGING_EXPORT_MACRO),
  exportMicro: createAction(PACKAGING_EXPORT_MICRO),
  exportTargetPrice: createAction(PACKAGING_EXPORT_TARGET_PRICE),
  getCountriesOfOriginDropdown: createAction(GET_COUNTRIES_OF_ORIGIN_DROPDOWN),
  searchSuppliers: createAction<{ groupName?: string }>(SUPPLIERS_SEARCH)
}
