import styled from 'styled-components'

export const TemplateListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
`

export const TemplateContainer = styled.div`
  height: 1123px;
  width: fit-content;
  border: 1px solid black;
  margin-bottom: 20px;
`
