import { get } from 'lodash'
import {
  BaseSpecificationCharacteristic,
  MasterSpecCharacteristic,
  ProjectSpecCharacteristic
} from '../../features/masterSpec/model'
import { IImageData } from '../../features/storeProducts/model'

export const convertBaseCharToMasterChar = (
  baseChar: BaseSpecificationCharacteristic & ProjectSpecCharacteristic
): MasterSpecCharacteristic => {
  const masterChar: MasterSpecCharacteristic = {
    aql: baseChar.aql,
    id: baseChar.id,
    catalog: baseChar.catalog,
    characteristicMeasurementUnit: baseChar.characteristicMeasurementUnit,
    measurementUnitRef: baseChar.measurementUnitRef,
    controlCharacteristic: baseChar.controlCharacteristic,
    controlPoint: baseChar.controlPoint,
    displayOrder: baseChar.displayOrder,
    generalStatus: baseChar.generalStatus,
    inspectionLevel: baseChar.inspectionLevel,
    map: baseChar.map,
    measurementUnit: baseChar.measurementUnit,
    method: baseChar.method,
    operationNumber: baseChar.operationNumber,
    plant: baseChar.plant,
    samplingProcedure: baseChar.samplingProcedure,
    skipRules: baseChar.skipRules,

    usage: baseChar.usage,
    workCenter: baseChar.workCenter,

    testingMethod: baseChar.method,

    standard: baseChar.catalogDescription || baseChar.standard || '',
    itemName: baseChar.characteristicDescription || baseChar.itemName,
    overrideItemName:
      baseChar.overrideItemName ||
      baseChar.characteristicDescription ||
      baseChar.itemName,
    overrideStandard:
      baseChar.overrideStandard || baseChar.catalogDescription || baseChar.standard,
    baseSpecificationCharacteristicId:
      baseChar.baseSpecificationCharacteristicId || baseChar.id,
    specificationSectionId: baseChar.specificationSectionId,
    hasUpperLimit: baseChar.hasUpperLimit,
    hasLowerLimit: baseChar.hasLowerLimit,
    allowedDecimalsLimit: baseChar.allowedDecimalsLimit
  }
  return masterChar
}

export const convertImageDataToImage = (attachData: IImageData) => {
  const formData = new FormData()
  const fileKey = get(attachData, 'value', '')
  const attachmentFile = get(window, `tmpFiles.${fileKey}`, null)
  formData.append('image', attachmentFile)
  return formData
}
