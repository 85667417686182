import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrdersTable } from '../../components'
import { ORDERLIST_STATUS_TABLE_CONFIG } from '../../components/Order/OrdersTable/constants'
import { ISortData } from '../../components/Table/types'
import { selectAppLoaderBySectionKey } from '../../features/app/selectors'
import { orderListActions } from '../../features/orderList/actions'
import {
  selectOrderListDropdown,
  selectOrderListItems,
  selectOrdersFilters,
  selectSelectedTab
} from '../../features/orderList/selectors'
import { selectPermissions, selectUserEmail } from '../../features/users/selectors'
import { TagsContainer } from '../TagsContainer'
import EmptyBody from './EmptyBody'
import OrderFilterModal from './OrdersModalFilter'
import {
  ContentContainer,
  FilterActionsContainer,
  ModalWrapper,
  TagsContainerWrapper
} from './styled'
import { IOrderListContentProps } from './types'

const OrderListContent: FC<IOrderListContentProps> = ({ type }) => {
  const dispatch = useDispatch()
  const userPermission = useSelector(selectPermissions)
  const userEmail = useSelector(selectUserEmail)

  const selectedTab = useSelector(selectSelectedTab)
  const orderList = useSelector(selectOrderListItems)
  const dropDownFilter = useSelector(selectOrderListDropdown)
  const isTableLoading = useSelector(selectAppLoaderBySectionKey('section-order-list'))
  const selectedFilters = useSelector(selectOrdersFilters)
  const ownerList = dropDownFilter?.owners?.[selectedTab]

  const changePageHandler = (paginationData: any, sortData?: ISortData) => {
    const { current } = paginationData
    dispatch(
      orderListActions.changePage({
        page: current,
        field: sortData?.field || '',
        order: sortData?.order || 'ascend'
      })
    )
  }

  const isSalaCampioni = userPermission.is?.salaCampioni || userPermission.is?.admin

  const filteredOrderlist = isSalaCampioni
    ? orderList
    : orderList.filter(({ ownerEmail }) => ownerEmail === userEmail)

  const removeTagHandler = (path: string) => {
    const cleanPath = path.replace('filters.', '')
    dispatch(orderListActions.cancelFilter({ path: cleanPath }))
  }

  const isForwarded = selectedTab === 'FORWARDED'
  const Empty = isForwarded && <EmptyBody />

  return (
    <ContentContainer isForwarded={isForwarded}>
      <FilterActionsContainer>
        <ModalWrapper>
          <OrderFilterModal type={type} ownerList={ownerList} />
        </ModalWrapper>
        <TagsContainerWrapper>
          <TagsContainer
            tags={selectedFilters}
            inner={false}
            reducer={'storeOrders'}
            flowName={'storeOrders'}
            basePath="filters"
            allowNoFilters
            onRemoveTag={removeTagHandler}
          />
        </TagsContainerWrapper>
      </FilterActionsContainer>
      <OrdersTable
        dataList={filteredOrderlist}
        tableConfig={get(ORDERLIST_STATUS_TABLE_CONFIG, selectedTab, {})}
        actionConfig={{
          columnTitle: 'Details',
          detail: {
            btnLabel: 'Order Details'
          }
        }}
        emptyBody={Empty}
        isLoading={isTableLoading}
        onChangePage={changePageHandler}
      />
    </ContentContainer>
  )
}

export default OrderListContent
