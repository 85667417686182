import { createAction } from '@reduxjs/toolkit'
import { cartActionTypes } from './constants'
import { IAddToCartPayload } from './model'

export const cartActions = {
  checkAvailability: createAction<{
    items: Record<string, any>
    warningCallback: (prods?: Record<string, any>[]) => void
    hasLoader?: boolean
  }>(cartActionTypes.CHECK_ITEM_AVAILABILITY),
  getCart: createAction(cartActionTypes.HYDRATE_CART),
  addItemToCart: createAction<IAddToCartPayload>(cartActionTypes.ADD_TO_CART),
  removeItemFromCart: createAction<{ itemId: string }>(cartActionTypes.DELETE_ITEM),
  confirmOrder: createAction(cartActionTypes.SEND_ORDER),
  resetCart: createAction(cartActionTypes.RESET_CART)
}
