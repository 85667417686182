import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components'
import { Divider } from '../../../components/Divider'
import { Loader } from '../../../components/Loader'
import { VARIANTS_TABLE_CONFIG } from '../../../components/Packaging/VariantsTable/constants'
import { Text } from '../../../components/Text'
import {
  selectNewPackagingCode,
  getIsLoading
} from '../../../features/packaging/selectors'
import { AppRoutes } from '../../../pages/constants'
import AttachmentsInPackCreate from '../../../pages/CreationPackage/AttachmentsInPackCreate'
import DetailInformation from '../DetailInformation'
import Relationships from '../Relationships'
import SideInformation from '../SideInformation'
import Variants from '../Variants'
import { ButtonWrapper, SuccessContainer } from './styled'
import { ISummaryProps } from './types'

const Summary: FC<ISummaryProps> = ({
  restartCallback,
  imageFileKey,
  data,
  isVial = false,
  isMulti = false
}) => {
  const isLoading = useSelector(getIsLoading)
  const newPackagingCode = useSelector(selectNewPackagingCode)
  const navigate = useNavigate()
  const portionText = isVial ? 'vial' : ''

  const summaryContentRender = () => {
    return (
      <>
        <DetailInformation
          imageFileKey={imageFileKey}
          data={data}
          isCreating
          isMulti={isMulti}
        />
        {isMulti && (
          <SideInformation
            data={data}
            variantTableConfig={VARIANTS_TABLE_CONFIG}
            isCreating
          />
        )}
        {isVial && (
          <Variants
            data={get(data, 'packageFamilies.0')}
            tableConfig={VARIANTS_TABLE_CONFIG}
            modalKey={'package-variant-summary'}
            hasInfoModal
          />
        )}
        {(!isVial || isMulti) && (
          <>
            <AttachmentsInPackCreate
              list={get(data, 'filteredAttachment', [])}
              isReadOnly
            />
            <Divider disableBorder />
            <Relationships data={data} />
          </>
        )}
      </>
    )
  }

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && newPackagingCode && (
        <SuccessContainer>
          <Text
            text={`The new ${portionText} packaging was succesfully created with the PK Intercos Code ${newPackagingCode}!`}
            variant="h2"
          />
          <Text text={'You can find the new packaging in "Packaging"'} variant="h4" />
          <ButtonWrapper>
            <Button
              label={`Create new ${portionText} packaging`}
              onClick={restartCallback}
            />
            <Button
              label="Go to the packaging search"
              onClick={() => {
                navigate(AppRoutes.WIKIPACK_PACKAGING)
              }}
              variant="ghost"
            />
            <Button
              label="Back to Home"
              onClick={() => {
                navigate(AppRoutes.HOME)
              }}
              variant="text"
              iconName="HomeOutlined"
            />
          </ButtonWrapper>
        </SuccessContainer>
      )}
      {!isLoading && !newPackagingCode && summaryContentRender()}
    </>
  )
}

export default Summary
