import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { toolingActions } from '../../../features/tooling'
import { ToolingTaxonomy } from '../../../features/tooling/model'
import { getToolingDropdownByKey } from '../../../features/tooling/selectors'
import { getTempToolingFilters } from '../../../features/tooling/selectors'
import { yesNoNull } from '../../ToolingInfo/utils'
import { IToolingSearchFormProps } from './types'

const ToolingSearchForm: FC<IToolingSearchFormProps> = () => {
  const dispatch = useDispatch()

  const filters = useSelector(getTempToolingFilters)
  const typeDropdown = useSelector(getToolingDropdownByKey('type'))
  const materialDropdown = useSelector(getToolingDropdownByKey('material'))
  const diameterDropdown = useSelector(getToolingDropdownByKey('diameter'))
  const heightDropdown = useSelector(getToolingDropdownByKey('height'))
  const shapeDropdown = useSelector(getToolingDropdownByKey('toolingShape'))
  const lengthDropdown = useSelector(getToolingDropdownByKey('length'))
  const widthDropdown = useSelector(getToolingDropdownByKey('width'))

  const updateHandler = (key: keyof ToolingTaxonomy, value: any) => {
    const newValue: ToolingTaxonomy = { ...filters }
    newValue[key as keyof ToolingTaxonomy] = value
    dispatch(toolingActions.updateTempFilter({ key, value }))
  }

  const separateDecimalsWithComma = (dropdownItems: Record<string, any>[]) => {
    return dropdownItems.map((item) => {
      return { ...item, name: item.name.replace(/\./g, ',') }
    })
  }

  return (
    <>
      <Form layout="vertical">
        <br />
        <BoxWrapper columnCount={3} enablePadding>
          <InfoCell
            label="Tooling Code"
            value={get(filters, 'code', '')}
            isEditing
            inputConfig={{
              placeholder: 'Tooling Code',
              onChange: (value: string) => updateHandler('code', value)
            }}
          />
          <InfoCell
            label="Tooling Type"
            value={get(filters, 'type', '')}
            inputType="select"
            isEditing
            inputConfig={{
              items: typeDropdown,
              onChange: ({ value }: Record<string, any>) => updateHandler('type', value)
            }}
          />
          <InfoCell
            label="Tooling Shape"
            value={get(filters, 'toolingShape', '')}
            inputType="select"
            isEditing
            inputConfig={{
              items: shapeDropdown,
              onChange: ({ value }: Record<string, any>) =>
                updateHandler('toolingShape', value)
            }}
          />
          <InfoCell
            label="Tooling Material"
            value={get(filters, 'material', '')}
            inputType="select"
            isEditing
            inputConfig={{
              items: materialDropdown,
              onChange: ({ value }: Record<string, any>) =>
                updateHandler('material', value)
            }}
          />
          <InfoCell
            label="Industrial or Flat Mold Code"
            value={get(filters, 'industrialCodeMold', '')}
            isEditing
            inputConfig={{
              placeholder: 'Industrial or Flat Mold Code',
              onChange: (value: string) => updateHandler('industrialCodeMold', value)
            }}
          />
          <InfoCell
            label="Tooling Diameter"
            value={get(filters, 'diameter', '')}
            inputType="select"
            isEditing
            inputConfig={{
              items: separateDecimalsWithComma(diameterDropdown),
              onChange: ({ value }: Record<string, any>) =>
                updateHandler('diameter', value)
            }}
          />
          <InfoCell
            label="Tooling Length"
            value={get(filters, 'length', '')}
            inputType="select"
            isEditing
            inputConfig={{
              items: separateDecimalsWithComma(lengthDropdown),
              onChange: ({ value }: Record<string, any>) => updateHandler('length', value)
            }}
          />
          <InfoCell
            label="Tooling Width"
            value={get(filters, 'width', '')}
            inputType="select"
            isEditing
            inputConfig={{
              items: separateDecimalsWithComma(widthDropdown),
              onChange: ({ value }: Record<string, any>) => updateHandler('width', value)
            }}
          />
          <InfoCell
            label="Tooling Height"
            value={get(filters, 'height', '')}
            inputType="select"
            isEditing
            inputConfig={{
              items: separateDecimalsWithComma(heightDropdown),
              onChange: ({ value }: Record<string, any>) => updateHandler('height', value)
            }}
          />
          <InfoCell
            label="Back Injection"
            dataType="options-value"
            value={get(filters, 'backInjection', '')}
            inputType="options-button"
            isEditing
            inputConfig={{
              options: yesNoNull,
              onChange: (value: boolean) => updateHandler('backInjection', value)
            }}
          />
        </BoxWrapper>
      </Form>
    </>
  )
}

export default ToolingSearchForm
