import { Image, Upload } from 'antd'
import styled from 'styled-components'
import Button from '../Button'

export const UploadContainer = styled.div``

export const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 137px;
    max-width: 500px;
    border: 2px dashed black;
  }
  .ant-image {
    width: 100%;
    height: 100%;
    padding: 8px;
  }
`

export const PlaceholderContainer = styled.div<{ error: boolean }>`
  width: 65%;
  ${({ error }) => error && { color: 'red' }}
`

export const TopMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const BottomMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const PreviewContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const PreviewImage = styled(Image)`
  height: 100%;
  object-fit: contain;
`

export const DeleteButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
`
