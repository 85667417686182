import { Tooltip } from 'antd'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Modal, Table } from '../../../components'
import { TOP_RIGHT } from '../../../components/Table/constants'
import { appActions } from '../../../features/app'
import { convertBaseCharToMasterChar } from '../../../pages/MasterSpecificationDetail/utils'
import { columnsGeneratorHelper } from '../../../utils'
import {
  filterCharList,
  randomizeCharacteristicId
} from '../../../utils/masterAndProjSpecificationHelpers'
import CharacteristicSearchForm from '../../Packaging/CharacteristicSearchForm'
import { ICharSearchFilters } from '../../Packaging/CharacteristicSearchForm/types'
import { CHARACTERISTIC_SEARCH_RESULT } from '../constants'
import { ModalBodyContainer } from './styled'
import { IAddCharacteristicModalProps } from './types'

const AddMasterSpecCharacteristicModal: FC<IAddCharacteristicModalProps> = ({
  onAdd = () => {},
  btnLabel,
  characteristicList,
  sectionId,
  modalKey,
  isEditing,
  readonlyFields = {}
}) => {
  const [isResult, setIsResult] = useState(false)
  const [charFilters, setCharFilters] = useState(readonlyFields)
  const columns = columnsGeneratorHelper(CHARACTERISTIC_SEARCH_RESULT)
  const dispatch = useDispatch()

  const actionConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }: any) => (
      <>
        <Tooltip title="Add to Master specification">
          <Button
            onClick={() => {
              onAdd(
                convertBaseCharToMasterChar(randomizeCharacteristicId(data)),
                sectionId
              )
              closeModalHandler()
            }}
            iconName="PlusCircleOutlined"
            variant="link"
          />
        </Tooltip>
      </>
    )
  }

  const closeModalHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
  }

  const searchHandler = () => {
    setIsResult(!isResult)
  }

  const backHandler = () => {
    setIsResult(false)
  }

  const updateHandler = (key: keyof ICharSearchFilters, value: any) => {
    setCharFilters({ ...charFilters, [key]: value })
  }

  const footer = !isResult
    ? [<Button key="search" label="Search" onClick={searchHandler} enableKeyPress />]
    : [<Button key="back" label="Back" onClick={backHandler} />]

  const resetForm = () => {
    setIsResult(false)
    setCharFilters(readonlyFields)
  }

  return (
    <Modal
      btnName={btnLabel}
      btnType="link"
      btnIcon="PlusCircleOutlined"
      modalKey={modalKey}
      title="Filter characteristics"
      footer={footer}
      btnClicked={resetForm}
      centered
      size="large"
      disabled={!isEditing}
    >
      <ModalBodyContainer>
        <>
          {!isResult && (
            <CharacteristicSearchForm
              charFilters={charFilters}
              updateHandler={updateHandler}
              readonlyFilter={readonlyFields}
            />
          )}
          {isResult && (
            <Table
              enablePagination={false}
              paginationPosition={TOP_RIGHT}
              actionColumnConfig={actionConfig}
              rowKey="id"
              columns={columns}
              items={filterCharList(characteristicList, charFilters)}
            />
          )}
        </>
      </ModalBodyContainer>
    </Modal>
  )
}

export default AddMasterSpecCharacteristicModal
