import { AnyAction } from '@reduxjs/toolkit'
import { get, unset } from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { refactorTaxonomies } from '../../utils'
import emitter from '../../utils/emitter'
import { ITaxonomies } from '../../utils/taxonomiesHelper'
import { fSchemaActions } from '../formulaSchema'
import { getFormulaSchemaTaxonomies } from '../formulaSchema/api'
import { getOrderListTaxonomies } from '../orderList/api'
import { orderListSliceActions } from '../orderList/reducer'
import { packagingSliceActions } from '../packaging'
import { getAllPackageTaxonomies, getPackageTaxonomies } from '../packaging/api'
import { getProductDynamicTaxonomies } from '../product/api'
import { prodSliceActions } from '../product/reducer'
import { storeSliceActions } from '../storeProducts'
import { getStoreProductDynamicTaxonomies } from '../storeProducts/api'
import { getToolingTaxonomies } from '../tooling/api'
import { ToolingTaxonomyDto } from '../tooling/model'
import { toolingActions as actionTooling } from '../tooling/reducer'
import { trackAppUsage } from './api'
import { appActionTypes } from './constants'

function* fetchDropdownPackaging() {
  try {
    const taxonomies: Record<string, any> = yield call(getPackageTaxonomies)
    yield put(packagingSliceActions.setDropdown(taxonomies))
  } catch (error) {}
}

function* fetchAllPackagingTaxonomies() {
  try {
    const taxonomies: Record<string, any>[] = yield call(getAllPackageTaxonomies)
    yield put(packagingSliceActions.setAllTaxonomies(taxonomies))
  } catch (error) {}
}

function* fetchDropdownTooling() {
  try {
    const taxonomies: ToolingTaxonomyDto = yield call(getToolingTaxonomies)
    // @ts-ignore
    const mappedTaxonomies = Object.entries(taxonomies).reduce(
      (acc, [k, v]) => ({
        ...acc,
        // @ts-ignore
        [k]: v.map((tax) => ({ name: tax, value: tax }))
      }),
      {}
    )
    yield put(actionTooling.setDropdown(mappedTaxonomies))
  } catch (error) {}
}

function* fetchDropdownFormulaSchema() {
  try {
    const taxonomies: Record<string, any> = yield call(getFormulaSchemaTaxonomies)
    yield put(fSchemaActions.setDropdown(taxonomies))
  } catch (error) {
    console.log(error)
  }
}

function* fetchDropdownEntity() {
  try {
    const taxonomies: ITaxonomies = yield call(getStoreProductDynamicTaxonomies)
    yield put(storeSliceActions.setDropdown(taxonomies))
  } catch (error) {
    console.log(error)
  }
}

function* fetchDropdownProduct() {
  try {
    const taxonomies: Record<string, any> = yield call(getProductDynamicTaxonomies)

    const dropDown = refactorTaxonomies(taxonomies)

    yield put(prodSliceActions.updateDropdown({ dropDown }))
  } catch (error) {
    console.log(error)
  }
}

function* fetchDropdownOrderList() {
  try {
    const taxonomies: Record<string, any> = yield call(getOrderListTaxonomies)
    yield put(orderListSliceActions.setDropDown(taxonomies))
  } catch (error) {
    console.log(error)
  }
}

function* closeModalSaga({ payload: { modalKey } }: AnyAction) {
  if (modalKey) yield emitter.emit('modal-instance-' + modalKey)
}

function* initSaga() {
  yield all([
    call(fetchDropdownPackaging),
    call(fetchAllPackagingTaxonomies),
    call(fetchDropdownFormulaSchema),
    call(fetchDropdownProduct),
    call(fetchDropdownOrderList),
    call(fetchDropdownTooling),
    call(fetchDropdownEntity)
  ])
}

function* trackUserlogin() {
  const newLogin = get(window, 'login', false)
  if (newLogin) {
    unset(window, 'login')
    yield call(trackAppUsage, {
      action: 'login'
    })
  }
}

export default function* appSaga() {
  yield takeEvery(appActionTypes.APP_INIT, initSaga)
  yield takeLatest(appActionTypes.APP_CLOSE_MODAL, closeModalSaga)
  yield takeLatest(appActionTypes.APP_TRACK_USER_LOGIN, trackUserlogin)
}
