import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { get, keyBy } from 'lodash'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { CartState, ISuccessInfo, ItemInCart, NewOrderTaxonomies } from './model'

const initialState: CartState = {
  items: [],
  itemsByIds: {},
  cartId: undefined,
  details: {
    address: '',
    country: '',
    date: ''
  },
  notes: '',
  customer: {
    type: 'CLIENT',
    additionalNote: '',
    setRequired: false
  },
  cartLoader: false,
  dropDown: {},
  error: {}
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItems(state: CartState, { payload }: PayloadAction<ItemInCart[]>) {
      state.items = payload
      state.itemsByIds = keyBy(state.items, 'id')
    },
    clearCart(state: CartState) {
      state.items = initialState.items
    },
    setCartId(state: CartState, { payload }: PayloadAction<string>) {
      state.cartId = payload
    },
    updateCartItemByIdx(
      state: CartState,
      { payload }: PayloadAction<{ idx: number; key: keyof ItemInCart; value: any }>
    ) {
      const { idx, key, value } = payload
      state.items[idx] = {
        ...get(state, `items[${idx}]`, {}),
        [key]: value
      }
    },
    updateCartItemById(
      state: CartState,
      { payload }: PayloadAction<{ id: string; itemData: ItemInCart }>
    ) {
      const { id, itemData } = payload
      const itemIdx = state.items.findIndex(({ id: itemId }) => itemId === id)
      if (itemIdx > -1) {
        state.items[itemIdx] = itemData
      }
    },
    setCustomer(
      state: CartState,
      {
        payload
      }: PayloadAction<{ reduxKey: string | undefined; value: string | boolean }>
    ) {
      const { reduxKey, value } = payload
      if (reduxKey) state.customer = { ...state.customer, [reduxKey]: value }
    },
    setDetail(
      state: CartState,
      { payload }: PayloadAction<{ reduxKey: string | undefined; value: string }>
    ) {
      const { reduxKey, value } = payload
      if (reduxKey) state.details = { ...state.details, [reduxKey]: value }
    },
    setCartLoader(state: CartState, { payload }: PayloadAction<boolean>) {
      state.cartLoader = payload
    },
    setDropdown(state: CartState, { payload }: PayloadAction<NewOrderTaxonomies>) {
      const { countries, addressedTo } = payload
      state.dropDown.addressedTo = Object.entries(addressedTo).map(([k, v]) => {
        return { name: k, value: v }
      })
      state.dropDown.countries = countries.map(({ name, code, days }) => {
        return { name, value: code, days }
      })
    },
    resetOrderOptions(state: CartState) {
      state.details = initialState.details
      state.customer = initialState.customer
    },
    updateNote: (state: CartState, { payload }: PayloadAction<string>) => {
      state.notes = payload
    },
    setError: (state: CartState, { payload }: PayloadAction<IError>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: CartState) => {
      state.error = initialState.error
    },
    setSuccess: (state: CartState, { payload }: PayloadAction<ISuccessInfo>) => {
      state.successInfo = payload
    },
    clearSuccess: (state: CartState) => {
      state.successInfo = initialState.successInfo
    }
  }
})

export const cartSliceActions = cartSlice.actions

export default cartSlice.reducer
