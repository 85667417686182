import { Form } from 'antd'
import { get } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { appActions, appSliceActions } from '../../../../features/app'
import Button from '../../../Button'
import { Divider } from '../../../Divider'
import { Modal } from '../../../Modal'
import { Text } from '../../../Text'
import AttachmentCard from '../../AttachmentCard'
import VariantInfo from '../../VariantInfo'

import {
  AttachmentCardWrapper,
  AttachmentContainer,
  MessageWrapper,
  ModalBody,
  TitleWrapper
} from './styled'
import { IAddVariantsModalProps, IVariantAttachment } from './types'

const AddVariantsModal: FC<IAddVariantsModalProps> = ({
  onConfirmAdd = () => {},
  btnType = 'primary',
  btnLabel,
  iconName = '',
  iconPosition,
  isEditing,
  attachments = [],
  vialsDetails = {},
  bottleValues = [],
  modalKey = 'package-add-variants',
  category,
  subcategory,
  isMulti = false
}) => {
  const dispatch = useDispatch()
  const [tempDetails, setTempDetails] = useState({})
  const [tempAttachments, setTempAttachments] = useState<IVariantAttachment[]>([])

  const removeStorageImage = (fileKey: string) => {
    const fileExist = !!get(window, `tmpFiles.${fileKey}`, null)
    if (fileKey && fileExist) {
      delete window.tmpFiles[fileKey]
    }
  }

  const resetHandler = (removeImages: boolean = false) => {
    setTempDetails({})
    if (removeImages) {
      tempAttachments.forEach(({ file }) => {
        removeStorageImage(get(file, 'value', ''))
      })
    }
    setTempAttachments([])
  }

  const closeModalHandler = (removeImages?: boolean) => {
    dispatch(appActions.closeModal({ modalKey }))
    resetHandler(removeImages)
  }

  const updateInfo = (key: string, value: Record<string, any>) => {
    setTempDetails({ ...tempDetails, [key]: value })
  }

  const updateAttach = (key: string, value: Record<string, any>, idx: number) => {
    setTempAttachments((prevTempAttachments) => {
      const newList = [...prevTempAttachments]
      newList[idx] = { ...newList[idx], [key]: value }
      return newList
    })
  }

  const addAttachmentHandler = () => {
    const newList = [...tempAttachments]
    newList.push({
      id: Date.now().toString(),
      contentType: '',
      description: '',
      file: {}
    })
    setTempAttachments(newList)
  }

  const deleteAtttachmentHandler = (idx: number) => {
    const fileKeyToRemove = tempAttachments[idx].file.value
    const newList = [...tempAttachments]
    newList.splice(idx, 1)
    removeStorageImage(fileKeyToRemove)
    setTempAttachments(newList)
  }

  const checkIfAddAttachDisable = () => {
    if (tempAttachments.length) {
      const attach = tempAttachments[tempAttachments.length - 1]

      const isDisable = !get(attach, 'contentType', '') || !get(attach, 'file.value', '')

      return isDisable
    }
    return false
  }

  const checkIfConfirmDisable = () => {
    const isDisable =
      !get(tempDetails, 'dipstick', '') ||
      !get(tempDetails, 'wiper', '') ||
      !get(tempDetails, 'applicator', '') ||
      !get(tempDetails, 'applicatorNumber', '')

    if (isMulti) {
      return isDisable
    }
    return isDisable || (!!tempAttachments.length && checkIfAddAttachDisable())
  }

  const closePopup = () => {
    dispatch(appSliceActions.closePopUp())
  }

  const openConfirmPopUp = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: 'You are adding a new variant',
        key: 'variant-attach-confirm',
        message: 'Are you sure you want to proceed with this operation?',
        footer: [
          <Button key="cancel" label="Cancel" onClick={closePopup} variant="ghost" />,
          <Button
            key="yes"
            label="Yes, add"
            onClick={() => {
              closePopup()
              confirmHandler()
            }}
          />
        ]
      })
    )
  }

  const confirmHandler = () => {
    onConfirmAdd(tempAttachments, tempDetails)
    closeModalHandler()
  }

  const attachmentList = isEditing ? tempAttachments : attachments
  const detailsInfo = isEditing ? tempDetails : vialsDetails

  return (
    <Modal
      btnName={btnLabel}
      size="medium"
      modalKey={modalKey}
      title="Add Variants"
      paddingBody="0px"
      btnIcon={iconName}
      iconPosition={iconPosition}
      btnType={btnType}
      onClose={resetHandler}
      footer={
        isEditing
          ? [
              <Button
                key="cancel"
                label="Cancel"
                variant="ghost"
                onClick={() => closeModalHandler(true)}
              />,
              <Button
                key="add"
                label="Add"
                onClick={openConfirmPopUp}
                disabled={checkIfConfirmDisable()}
              />
            ]
          : undefined
      }
      centered
    >
      <ModalBody>
        {isEditing && (
          <Text
            text="In this section you can add a new variant to your package"
            variant="h4"
          />
        )}
        <Divider />
        <Form layout="vertical">
          <VariantInfo
            data={detailsInfo}
            onUpdate={updateInfo}
            isEditing={isEditing}
            bottleValues={bottleValues}
            category={category}
            subcategory={subcategory}
          />
        </Form>
        {!isMulti && (
          <>
            <TitleWrapper>
              <Text text="Attachments" variant="h3" />
              {isEditing && (
                <Button
                  variant="text"
                  iconName="PlusCircleOutlined"
                  iconPosition="right"
                  onClick={addAttachmentHandler}
                  disabled={checkIfAddAttachDisable()}
                />
              )}
            </TitleWrapper>
            <Divider />
            <AttachmentContainer>
              {attachmentList.length ? (
                attachmentList.map((attach: any, i) => (
                  <AttachmentCardWrapper key={i}>
                    <AttachmentCard
                      title={`Attachment ${i + 1}`}
                      data={attach}
                      isEditing={isEditing}
                      updateData={(key, value) => updateAttach(key, value, i)}
                      onDelete={() => deleteAtttachmentHandler(i)}
                    />
                  </AttachmentCardWrapper>
                ))
              ) : (
                <MessageWrapper>
                  <Text text="There are no attachments" variant="p" />
                  {isEditing && (
                    <Button
                      label="Add new one"
                      variant="ghost"
                      onClick={addAttachmentHandler}
                    />
                  )}
                </MessageWrapper>
              )}
            </AttachmentContainer>
          </>
        )}
      </ModalBody>
    </Modal>
  )
}

export default AddVariantsModal
