import { Form, Tabs } from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { get } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { IOptions } from '../../../components/Form/RadioInput/types'
import SideSearchForm from '../../../components/Packaging/SideSearchForm'
import { Text } from '../../../components/Text'
import { packagingSliceActions } from '../../../features/packaging'
import { PACKAGE_TYPE } from '../../../features/packaging/model'
import {
  getDropdownFilteredStatus,
  getPackagingTempFilters
} from '../../../features/packaging/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import { checkFieldVisibilityByRoles } from '../../../utils'
import { TargetKey } from '../SideInformation/types'
import { SelectTypeContainer, SelectTypeWrapper, StyledAccordion } from './styled'
import { IPackSearchFormProps } from './types'

const PackagingSearchForm: FC<IPackSearchFormProps> = () => {
  const tempFilter = useSelector(getPackagingTempFilters)

  const [advancedSearch, setAdvancedSearch] = useState(false)
  const tempFilterCopy = { ...tempFilter }
  if (Object.keys(tempFilterCopy).includes('_all')) {
    delete tempFilterCopy['_all']
  }
  const hasFilter = !!Object.keys(tempFilterCopy).length

  useEffect(() => {
    setAdvancedSearch(hasFilter)
  }, [hasFilter])

  const dispatch = useDispatch()
  const statusDropdowns = useSelector(getDropdownFilteredStatus)
  const userRoles = useSelector(selectUserRole)

  const packageFamilies = get(tempFilter, 'packageFamilies', [])
  const packageTypeValue = get(tempFilter, 'type', PACKAGE_TYPE.MONO)
  const isMulti = packageTypeValue === PACKAGE_TYPE.MULTI

  const updateHandler = (key: string, value: any) => {
    dispatch(packagingSliceActions.updateTempFilter({ key, value }))
  }

  const changeTypeHandler = (value: any) => {
    const isMono = value === PACKAGE_TYPE.MONO
    dispatch(
      packagingSliceActions.setTempFilter({
        type: value,
        packageFamilies: isMono ? [{}] : [{}, {}]
      })
    )
  }

  const isFieldVisible = (key: string) => {
    let visibility = true
    visibility =
      visibility &&
      checkFieldVisibilityByRoles({
        key,
        roles: userRoles,
        isOnEdit: true,
        isOnRead: true
      })
    return visibility
  }

  const packageTypeOptions: IOptions = [
    { label: 'MONO Package', value: PACKAGE_TYPE.MONO },
    { label: 'MULTI Package', value: PACKAGE_TYPE.MULTI }
  ]

  const removeTabHandler = (targetIndex: number) => {
    const newPackageFamilies = [...packageFamilies]
    newPackageFamilies.splice(targetIndex, 1)
    updateHandler('packageFamilies', newPackageFamilies)
  }

  const onEditHandler = (targetKey: TargetKey, action: 'add' | 'remove') => {
    if (action === 'remove') {
      removeTabHandler(+targetKey)
    }
  }

  const advancedSearchHandler = () => {
    setAdvancedSearch(!advancedSearch)
    if (advancedSearch) {
      dispatch(packagingSliceActions.clearTempFilter())
    } else {
      dispatch(packagingSliceActions.updateTempFilter({ key: '_all', value: '' }))
    }
  }

  const updateMultipleSelect = (key: string, inputValue: Record<string, any>) => {
    const value = inputValue.map(({ value }: any) => value)
    updateHandler(key, value)
  }

  return (
    <Form layout="vertical">
      <BoxWrapper columnCount={4} enablePadding>
        <InfoCell
          label="Full Search"
          value={get(tempFilter, '_all', '')}
          isEditing
          inputConfig={{
            placeholder: 'search',
            disabled: advancedSearch,
            onChange: (value: string) => updateHandler('_all', value)
          }}
        />
      </BoxWrapper>
      <StyledAccordion
        collapsible="header"
        activeKey={advancedSearch ? ['1'] : undefined}
        onChange={() => advancedSearchHandler()}
        ghost
      >
        <CollapsePanel header="Advanced Search" key="1">
          <Divider disableBorder />
          <BoxWrapper columnCount={4} enablePadding>
            {statusDropdowns.length > 1 && (
              <InfoCell
                label="Status"
                value={get(tempFilter, 'status', [])}
                inputType="select"
                isEditing
                inputConfig={{
                  items: statusDropdowns,
                  onChange: (value: Record<string, any>) =>
                    updateMultipleSelect('status', value),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            <InfoCell
              label="PK Intercos Code"
              value={get(tempFilter, 'packagingCode', '')}
              isEditing
              inputConfig={{
                placeholder: 'Max 250 char',
                onChange: (value: string) => updateHandler('packagingCode', value)
              }}
            />

            {isFieldVisible('supplierRef') && (
              <InfoCell
                label="Supplier Pkg Code"
                value={get(tempFilter, 'supplierRef', '')}
                isEditing
                inputConfig={{
                  onChange: (value: string) => updateHandler('supplierRef', value)
                }}
              />
            )}
            {isFieldVisible('testedSupplier') && (
              <InfoCell
                label="Packaging Supplier"
                value={get(tempFilter, 'testedSupplier.name', '')}
                isEditing
                inputConfig={{
                  onChange: (value: string) =>
                    updateHandler('testedSupplier', {
                      name: value
                    })
                }}
              />
            )}
            <InfoCell
              label="Attachment SAP Code"
              value={get(tempFilter, 'attachmentsSAPCode', '')}
              isEditing
              inputConfig={{
                placeholder: 'Max 250 char',
                onChange: (value: string) => updateHandler('attachmentsSAPCode', value)
              }}
            />
            {isFieldVisible('isValidNote') && (
              <InfoCell
                label="Note"
                value={get(tempFilter, 'isValidNote', '')}
                isEditing
                inputConfig={{
                  onChange: (value: string) => updateHandler('isValidNote', value)
                }}
              />
            )}
          </BoxWrapper>
          <SelectTypeWrapper>
            <SelectTypeContainer>
              <Text text={'Select Type Of Packaging:'} variant={'h4'} />
              <InfoCell
                label=""
                dataType="options-value"
                value={get(tempFilter, 'type', PACKAGE_TYPE.MONO)}
                inputType="options-button"
                isEditing
                inputConfig={{
                  options: packageTypeOptions,
                  onChange: (value: string) => changeTypeHandler(value)
                }}
              />
            </SelectTypeContainer>
          </SelectTypeWrapper>
          <Divider />
          {!isMulti && (
            <SideSearchForm
              data={tempFilter}
              isEditing
              updateData={(key, value) => updateHandler(key, value)}
            />
          )}
          {isMulti && (
            <>
              <Tabs
                hideAdd
                type="editable-card"
                tabBarExtraContent={
                  isMulti && (
                    <Button
                      label="Add"
                      iconName="PlusCircleOutlined"
                      variant="text"
                      onClick={() =>
                        updateHandler('packageFamilies', packageFamilies.concat({}))
                      }
                    />
                  )
                }
                onEdit={onEditHandler}
              >
                {packageFamilies.map((_: any, index: number) => (
                  <Tabs.TabPane
                    tab={`Side ${index + 1}`}
                    key={`${index}`}
                    closable={index > 1}
                  >
                    <SideSearchForm
                      data={tempFilter}
                      isEditing
                      updateData={(key, value) => updateHandler(key, value)}
                      sideNumber={index}
                    />
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </>
          )}
        </CollapsePanel>
      </StyledAccordion>
    </Form>
  )
}

export default PackagingSearchForm
