import { Tag as TagAntd } from 'antd'
import { StyledTagBox, TagValue } from './styled'

type Props = {
  path: string
  value?: string | number | boolean
  onRemove?: (prefix: string) => void
  closable: boolean
}

export const Tag = ({ value, onRemove, path, closable }: Props) => {
  return (
    <StyledTagBox className="custom-tag-container">
      <TagAntd
        className="custom-tag"
        closable={closable}
        onClose={() => onRemove && onRemove(path)}
      >
        <TagValue closable={closable}>{value}</TagValue>
      </TagAntd>
    </StyledTagBox>
  )
}
