import styled from 'styled-components'
import { footerHeight } from '../../../assets/mixins'

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin: 0 8px;
  }
`

export const ButtonWrapper = styled.div`
  height: 44px;
  width: 100%;
  padding: 5px 20px;
  background-color: white;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  bottom: ${footerHeight}px;
`
