export interface ILink {
  label: string
  path: AppRoutes
  title: string
}

export enum AppRoutes {
  HOME = '/',
  LOGOUT = '/logout',
  VAULT_PRODUCTS_SEARCH = '/vault/products',
  VAULT_PRODUCTS_RESULTS = '/vault/products-result',
  VAULT_PRODUCT_CREATION = '/vault/creation-product',
  VAULT_PRODUCT_DETAILS = '/vault/products/:id',
  VAULT_FORMULA_SCHEMA = '/vault/formulaschema',
  VAULT_FORMULA_SCHEMA_RESULT = '/vault/formulaschema-result',
  VAULT_FORMULA_SCHEMA_DETAILS = '/vault/formulaschema/:id',
  VAULT_TOOLING_SEARCH = '/vault/tooling',
  VAULT_TOOLING_RESULTS = '/vault/tooling-result',
  VAULT_TOOLING_DETAILS = '/vault/tooling/:id',
  VAULT_TOOLING_CREATION = '/vault/tooling/new',
  VAULT_ADMINISTRATION = '/vault/administration',
  VAULT_ADMINISTRATION_MANAGEMENT = '/vault/administration/:type',
  VAULT_ADMINISTRATION_ATTACHMENT = '/vault/administration/attachment/:id',
  VAULT_ADMINISTRATION_SUPPLIER = '/vault/administration/supplier/:id',
  VAULT_ADMINISTRATION_SUPPLIER_RESULT = '/vault/administration/supplier-result',
  WIKIPACK_PACKAGING_RESULT = '/wikipack/packaging-result',
  WIKIPACK_PACKAGING = '/wikipack/packaging',
  WIKIPACK_PACKAGING_DETAILS = '/wikipack/packaging/:id',
  WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS = '/wikipack/packaging/:packageId/master-specifications',
  WIKIPACK_PACKAGING_VIAL_VARIANT_MASTER_SPECIFICATIONS = '/wikipack/packaging/:packageId/variant/:packageVariantId/master-specifications',
  WIKIPACK_PACKAGING_VIAL_VARIANT_MASTER_SPECIFICATIONS_NEW = '/wikipack/packaging/:packageId/variant/:packageVariantId/master-specifications/new',
  WIKIPACK_PACKAGING_VIAL_VARIANT_MASTER_SPECIFICATIONS_NEW_FROM_SEARCH = '/wikipack/packaging/:packageId/variant/:packageVariantId/master-specifications/new/:specId',
  WIKIPACK_PACKAGING_VIAL_VARIANT_PROJECT_SPECIFICATIONS = '/wikipack/packaging/:packageId/variant/:packageVariantId/project-specifications',
  WIKIPACK_PACKAGING_VIAL_VARIANT_PROJECT_SPECIFICATIONS_NEW = '/wikipack/packaging/:packageId/variant/:packageVariantId/project-specifications/new',
  WIKIPACK_PACKAGING_VIAL_VARIANT_PROJECT_SPECIFICATIONS_NEW_FROM_SEARCH = '/wikipack/packaging/:packageId/variant/:packageVariantId/project-specifications/new/:specId',
  WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS = '/wikipack/packaging/:packageId/project-specifications',
  WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_DETAIL = '/wikipack/packaging/:packageId/master-specifications/:specId',
  WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS_DETAIL = '/wikipack/packaging/:packageId/project-specifications/:specId',
  WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_CHARACTERISTIC = '/wikipack/packaging/:packageId/master-specifications/:specId/characteristicEditor/',
  WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS_CHARACTERISTIC = '/wikipack/packaging/:packageId/project-specifications/:specId/characteristicEditor/',
  WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_NEW = '/wikipack/packaging/:packageId/master-specifications/new',
  WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_NEW_FROM_SEARCH = '/wikipack/packaging/:packageId/master-specifications/new/:specId',
  WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS_NEW = '/wikipack/packaging/:packageId/project-specifications/new',
  WIKIPACK_PACKAGING_VARIANT = '/wikipack/packaging/:idPackaging/variant/:id',
  WIKIPACK_PACKAGE_CREATION = '/wikipack/creation-packaging',
  WIKIPACK_PACKAGE_CREATION_FLOW = '/wikipack/creation-packaging/:flowType',
  STORE_PRODUCTS = '/store/products-result',
  STORE_PRODUCTS_SEARCH = '/store/products',
  STORE_PRODUCTS_DETAILS = '/store/products/:id',
  STORE_ORDERS = '/store/orders',
  STORE_ORDER_DETAILS = '/store/orders/:id',
  STORE_CART = '/store/cart',
  STORE_ENTITY_CREATION = '/store/entity-creation',
  STORE_ENTITY_CHILD = '/store/products/:parentId/component/:id',
  TEMPLATES = '/templates'
}

export const HOME_LINKS: ILink[] = [
  {
    label: 'PRODUCT CREATION',
    path: AppRoutes.VAULT_PRODUCT_CREATION,
    title: 'Tap here to create a new product'
  },
  {
    label: 'PRODUCT PORTFOLIO',
    path: AppRoutes.VAULT_PRODUCTS_SEARCH,
    title: 'Tap here to search products'
  },
  {
    label: 'PANS ET ALIA DB',
    path: AppRoutes.VAULT_TOOLING_SEARCH,
    title: 'Tap here to search tooling'
  },
  {
    label: 'PACKAGING',
    path: AppRoutes.WIKIPACK_PACKAGING,
    title: 'Tap here to search packagings'
  },
  {
    label: 'FORMULA SCHEMA',
    path: AppRoutes.VAULT_FORMULA_SCHEMA,
    title: 'Tap here to search formula schema'
  },
  {
    label: 'PACKAGING CREATION',
    path: AppRoutes.WIKIPACK_PACKAGE_CREATION,
    title: 'Tap here to create a new packaging'
  },
  {
    label: 'PRODUCTS',
    path: AppRoutes.STORE_PRODUCTS_SEARCH,
    title: 'Tap here to search products'
  },
  {
    label: 'ENTITY CREATION',
    path: AppRoutes.STORE_ENTITY_CREATION,
    title: 'Tap here to create an entity'
  },
  {
    label: 'ORDER LIST',
    path: AppRoutes.STORE_ORDERS,
    title: 'Tap here to search orders'
  },
  {
    label: 'CART',
    path: AppRoutes.STORE_CART,
    title: 'Tap here to open the cart'
  },
  {
    label: 'ADMINISTRATION',
    path: AppRoutes.VAULT_ADMINISTRATION,
    title: ''
  }
]
