import { get } from 'lodash'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, InfoCell } from '../../components'
import { FlexSection } from '../../components/FlexSection'
import FooterControl from '../../components/FooterControl'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import FailedImportTable from '../../containers/Administration/FailedImportTable'
import {
  ANAGRAPHIC_FAILED,
  RELATIONS_FAILED,
  PRICE_FAILED,
  SUPPLIER_FAILED
} from '../../containers/Administration/FailedImportTable/constants'
import Success from '../../containers/Success'
import { administrationSliceActions } from '../../features/administration'
import { AdministrationType } from '../../features/administration/model'
import {
  selectAdminError,
  selectAdminSuccess,
  selectIsLoading,
  selectLastImported,
  selectSelectedType
} from '../../features/administration/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'
import { footerActionHeight } from '../CreationPackage/styled'
import AnagraphicImport from './AnagraphicImport'
import ManageAttachments from './ManageAttachments'
import ManageSupplier from './ManageSupplier'
import PriceImport from './PriceImport'
import PricingTable from './PricingTable'
import RelationsImport from './RelationsImport'
import SupplierImport from './SupplierImport'
import { IManagementBody } from './types'

const AdminManagement: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoading)
  const successInfo = useSelector(selectAdminSuccess)
  const adminError = useSelector(selectAdminError)
  const selectedType = useSelector(selectSelectedType)
  const lastImported = useSelector(selectLastImported)
  const { type } = useParams<{ type: AdministrationType }>()

  useEffect(() => {
    if (!type || !selectedType) {
      navigate(AppRoutes.VAULT_ADMINISTRATION)
    }
    return () => {
      dispatch(administrationSliceActions.clearError())
      dispatch(administrationSliceActions.clearSuccess())
      dispatch(administrationSliceActions.resetLastImport())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const failedList = get(lastImported, 'failed', [])
  const hasFailed = !!failedList.length

  const restartHandler = () => {
    dispatch(administrationSliceActions.resetLastImport())
    dispatch(administrationSliceActions.clearSuccess())
  }

  const FooterNewImportBtn = (
    <FooterControl>
      <Button label="New Import" onClick={restartHandler} />
    </FooterControl>
  )

  const Management: IManagementBody = {
    successBody: {
      'supplier-import': (
        <>
          <FlexSection justifyContent="center" flexDirection="column">
            <InfoCell label="Created:" value={get(lastImported, 'created', '')} />
            <InfoCell label="Updated:" value={get(lastImported, 'updated', '')} />
          </FlexSection>
          {hasFailed && (
            <FailedImportTable dataList={failedList} tableConfig={SUPPLIER_FAILED} />
          )}
          {FooterNewImportBtn}
        </>
      ),
      attachment: <Button label="Back to the list" onClick={restartHandler} />,
      supplier: <Button label="Back to search" onClick={restartHandler} />,
      'anagraphic-import': (
        <>
          {hasFailed && (
            <FailedImportTable dataList={failedList} tableConfig={ANAGRAPHIC_FAILED} />
          )}
          {FooterNewImportBtn}
        </>
      ),
      'price-import': (
        <>
          {hasFailed && (
            <FailedImportTable dataList={failedList} tableConfig={PRICE_FAILED} />
          )}
          {FooterNewImportBtn}
        </>
      ),
      pricing: (
        <>
          {hasFailed && (
            <FailedImportTable dataList={failedList} tableConfig={ANAGRAPHIC_FAILED} />
          )}
          {FooterNewImportBtn}
        </>
      ),
      'relations-import': (
        <>
          {hasFailed && (
            <FailedImportTable dataList={failedList} tableConfig={RELATIONS_FAILED} />
          )}
          {FooterNewImportBtn}
        </>
      )
    },
    body: {
      'supplier-import': <SupplierImport />,
      attachment: <ManageAttachments />,
      supplier: <ManageSupplier />,
      'anagraphic-import': <AnagraphicImport />,
      'price-import': <PriceImport />,
      pricing: <PricingTable />,
      'relations-import': <RelationsImport />
    }
  }

  return (
    <GenericLayout error={adminError}>
      <PageContent
        headerHeight={headerHeight}
        footerHeight={footerHeight + footerActionHeight}
      >
        {isLoading && <Loader />}
        {successInfo ? (
          <Success data={successInfo}>
            {Management.successBody[type as AdministrationType]}
          </Success>
        ) : (
          <>{Management.body[type as AdministrationType]}</>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default AdminManagement
