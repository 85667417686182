import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'

import { Button } from '../../components'
import { Divider } from '../../components/Divider'
import { Text } from '../../components/Text'
import { PageContent } from '../../containers'
import { administrationSliceActions } from '../../features/administration'
import { AdministrationType } from '../../features/administration/model'
import { selectAdminError } from '../../features/administration/selectors'
import { selectPermissions } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'

const AdminSelection: FC = () => {
  const adminError = useSelector(selectAdminError)
  const permission = useSelector(selectPermissions)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectAdminnistration = (type: AdministrationType) => {
    dispatch(administrationSliceActions.setType(type))
    navigate(`${AppRoutes.VAULT_ADMINISTRATION}/${type}`)
  }

  const canImportSupplier = permission.administration?.canImportSupplier || false
  const canManageAttachment = permission.administration?.canManagegAttach || false
  const canManageSupplier = permission.administration?.canManageSupplier || false
  const canImportAnagraphic = permission.administration?.canImportAnagraphic || false
  const canImportRelations = permission.administration?.canImportRelations || false
  const canAccessExchangeRates = permission.administration?.canManagePricing || false
  const canImportPrice = permission.administration?.canImportPrice || false

  return (
    <GenericLayout error={adminError}>
      <PageContent headerHeight={headerHeight} footerHeight={footerHeight}>
        <Text text="Administration" variant="h3" bold />
        <Divider disableBorder />
        {canImportSupplier && (
          <>
            <Button
              label={'Supplier Import'}
              onClick={() => selectAdminnistration('supplier-import')}
              iconName={'AppstoreAddOutlined'}
              variant={'link'}
              iconPosition="left"
            />
            <Divider disableBorder />
          </>
        )}
        {canManageAttachment && (
          <>
            <Button
              label={'Attachments management'}
              onClick={() => selectAdminnistration('attachment')}
              iconName={'AppstoreAddOutlined'}
              variant={'link'}
              iconPosition="left"
            />
            <Divider disableBorder />
          </>
        )}
        {canManageSupplier && (
          <>
            <Button
              label={'Supplier management'}
              onClick={() => selectAdminnistration('supplier')}
              iconName={'AppstoreAddOutlined'}
              variant={'link'}
              iconPosition="left"
            />
            <Divider disableBorder />
          </>
        )}
        {canImportAnagraphic && (
          <>
            <Button
              label={'DBS Anagraphic Import'}
              onClick={() => selectAdminnistration('anagraphic-import')}
              iconName={'AppstoreAddOutlined'}
              variant={'link'}
              iconPosition="left"
            />
            <Divider disableBorder />
          </>
        )}
        {canImportPrice && (
          <>
            <Button
              label={'Target Price Import'}
              onClick={() => selectAdminnistration('price-import')}
              iconName={'AppstoreAddOutlined'}
              variant={'link'}
              iconPosition="left"
            />
            <Divider disableBorder />
          </>
        )}
        {canAccessExchangeRates && (
          <>
            <Button
              label={'Exchange Rate'}
              onClick={() => selectAdminnistration('pricing')}
              iconName={'AppstoreAddOutlined'}
              variant={'link'}
              iconPosition="left"
            />
            <Divider disableBorder />
          </>
        )}
        {canImportRelations && (
          <>
            <Button
              label={'Packaging Relationship Import'}
              onClick={() => selectAdminnistration('relations-import')}
              iconName={'AppstoreAddOutlined'}
              variant={'link'}
              iconPosition="left"
            />
            <Divider disableBorder />
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default AdminSelection
