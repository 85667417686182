import { Table } from 'antd'
import styled, { css } from 'styled-components'
import { PACKAGING_STATUS_COLOR } from '../../features/packaging/constants'
import { IPreviewWrapper, ITableContainerProps } from './types'

export const TableContainer = styled(Table)<ITableContainerProps>`
  .ant-pagination {
    margin: 0;
    ${({ paginationPosition }) => {
      if (paginationPosition) {
        if (paginationPosition.includes('TOP') && paginationPosition.includes('BOTTOM')) {
          return css`
            padding-top: 10px;
            padding-bottom: 8px;
          `
        }
        if (paginationPosition.includes('TOP')) {
          return css`
            padding-top: 5px;
            padding-bottom: 8px;
          `
        }
        return css`
          padding-top: 10px;
          padding-bottom: 0px;
        `
      }
    }}
  }
  .ant-table table {
    width: 100% !important;
  }
  .ant-table table {
    border-collapse: separate;
    border-radius: 2px 2px 0 0;
    border-spacing: 0;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 5px 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 5px 8px;
  }
  .ant-table-thead > tr > th {
    padding: 8px;
  }
  .ant-table-thead > tr > th {
    white-space: nowrap;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: white;
  }
`

export const EmptyContentWrapper = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ImagePreviewWrapper = styled.div<IPreviewWrapper>`
  img {
    border: ${({ status, hasBorder }) =>
      hasBorder && status ? `2px solid ${PACKAGING_STATUS_COLOR[status]}` : 'none'};
    max-width: 60px;
    max-height: 60px;
  }
`

export const TableCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  word-break: break-word;
`
