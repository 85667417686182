import { createAction } from '@reduxjs/toolkit'
import { masterSpecActionType } from './constants'
import {
  IApplyAllSapCodeActionPayload,
  IExportMasterSpecificationPayload,
  IExportProjectSpecificationPayload,
  IFetchMasterSpecListPayload,
  IGetDetailPayload,
  IMasterSpecDetail,
  IProjectSpecDetail
} from './model'

export const masterSpecActions = {
  getList: createAction<IFetchMasterSpecListPayload>(
    masterSpecActionType.MASTER_SPEC_FETCH_ALL
  ),
  getProjectSpecificationList: createAction<IFetchMasterSpecListPayload>(
    masterSpecActionType.PROJECT_SPEC_FETCH_ALL
  ),
  getCharacteristics: createAction(masterSpecActionType.CHARACTERISTICS_FETCH_ALL),
  createMasterSpecification: createAction<IMasterSpecDetail | {}>(
    masterSpecActionType.CREATE_MASTER_SPEC
  ),
  createProjectSpecification: createAction<IMasterSpecDetail | {}>(
    masterSpecActionType.CREATE_PROJECT_SPEC
  ),
  updateMasterSpecification: createAction<Partial<IMasterSpecDetail> | {}>(
    masterSpecActionType.UPDATE_MASTER_SPEC
  ),
  uploadMasterSpecThumbnail: createAction<any>(
    masterSpecActionType.UPLOAD_MSPEC_THUMBNAIL
  ),
  uploadAndUpdateCharacteristic: createAction<any>(
    masterSpecActionType.UPLOAD_UPDATE_CHAR
  ),
  uploadAndUpdateProjectChar: createAction<any>(
    masterSpecActionType.UPLOAD_UPDATE_PROJ_CHAR
  ),
  uploadMSpecCharImage: createAction<any>(masterSpecActionType.UPLOAD_MSPEC_CHAR_IMAGE),
  uploadPSpecCharImage: createAction<any>(masterSpecActionType.UPLOAD_PSPEC_CHAR_IMAGE),
  updateProjectSpecification: createAction<Partial<IProjectSpecDetail> | {}>(
    masterSpecActionType.UPDATE_PROJECT_SPEC
  ),
  updateProjectSpecificationAndSendToSap: createAction<Partial<IProjectSpecDetail> | {}>(
    masterSpecActionType.UPDATE_PROJECT_SPEC_AND_SEND
  ),
  createProjectSpecificationAndSendToSap: createAction<Partial<IProjectSpecDetail> | {}>(
    masterSpecActionType.CREATE_PROJECT_SPEC_AND_SEND
  ),
  checkSapCodes: createAction<{
    data: Partial<IProjectSpecDetail> | {}
    openDuplicateModal: any
    openSendToSapModal: any
    isCreateNew?: boolean
  }>(masterSpecActionType.CHECK_SAP_CODES),
  deleteMasterSpecification: createAction<{ specId: string }>(
    masterSpecActionType.DELETE_MASTER_SPEC
  ),
  deleteProjetSpecification: createAction<{ specId: string }>(
    masterSpecActionType.DELETE_PROJECT_SPEC
  ),
  getDetail: createAction<{ specId: string }>(masterSpecActionType.GET_DETAIL),
  getProjectDetail: createAction<IGetDetailPayload>(
    masterSpecActionType.GET_PROJECT_DETAIL
  ),
  getSpecificationSections: createAction(masterSpecActionType.GET_SPECIFICATION_SECTIONS),
  applyAllSapCodesToCharacters: createAction<IApplyAllSapCodeActionPayload>(
    masterSpecActionType.APPLY_SAP_CODE_TO_ALL_CHARACTER
  ),
  getCharacteriscticAndApplyDefault: createAction(
    masterSpecActionType.GET_CHARACTERISTICS_AND_APPLY_DEFAULT
  ),
  exportMasterSpecification: createAction<IExportMasterSpecificationPayload>(
    masterSpecActionType.EXPORT_MASTER_SPEC
  ),
  exportProjectSpecification: createAction<IExportProjectSpecificationPayload>(
    masterSpecActionType.EXPORT_PROJECT_SPEC
  )
}
