import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { InfoCell, SectionTitle } from '../../components'
import { BoxWrapper } from '../../components/BoxWrapper'
import { Divider } from '../../components/Divider'
import { MasterSpecCharacteristic } from '../../features/masterSpec/model'
import { masterSpecSliceActions } from '../../features/masterSpec/reducer'
import { customToFixed } from '../ProjectSpecificationCharacteristicContent/utils'
import { IMasterSpecificationCharacteristicContentProps } from './types'

const MasterSpecificationCharacteristicContent: FC<
  IMasterSpecificationCharacteristicContentProps
> = ({ newCharacteristic }) => {
  const dispatch = useDispatch()

  // const imageValue = get(packageDetails, 'image.value', '')
  // const supplierName = get(packageDetails, 'testedSupplier.name', '')
  // const supplierItemCode = get(packageDetails, 'testedSupplier.SAPCodes', '')
  const onChange = (input: { key: keyof MasterSpecCharacteristic; value: any }) => {
    dispatch(masterSpecSliceActions.updateNewCharacteristic(input))
  }

  const hasLowerLimit = newCharacteristic?.hasLowerLimit
  const hasUpperLimit = newCharacteristic?.hasUpperLimit
  const hasLimit = hasLowerLimit || hasUpperLimit
  const allowedDecimalsLimit = newCharacteristic?.allowedDecimalsLimit || 0

  return (
    <Form layout="vertical">
      <SectionTitle value={'Characteristic Detail'} />
      <Divider disableBorder={false} />
      <>
        <BoxWrapper columnCount={3} enablePadding>
          <InfoCell
            isEditing
            label="Item"
            value={get(newCharacteristic, 'overrideItemName', '')}
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'overrideItemName', value })
              }
            }}
          />
          <InfoCell
            isEditing
            label="Standard"
            value={get(newCharacteristic, 'overrideStandard', '')}
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'overrideStandard', value })
              }
            }}
          />
          <InfoCell
            isEditing
            value={get(newCharacteristic, 'specificationCharacteristicImage.url')}
            label=""
            dataType="image"
            inputType="upload"
            inputConfig={{
              topMessage: 'Drop or browse image as attachment to Standard',
              fileKey: 'ms-char-image-edit',
              isUrl: true,
              onChange: (data: any) => {
                onChange({ key: 'specificationCharacteristicImage', value: data })
              }
            }}
          />
        </BoxWrapper>
        <br />

        {hasLimit && (
          <BoxWrapper columnCount={2} enablePadding>
            {hasLowerLimit && (
              <InfoCell
                isEditing
                label="Lower Limit"
                dataType="number"
                value={get(newCharacteristic, 'lowerLimit', '')}
                inputConfig={{
                  onChange: (value: string) => {
                    onChange({
                      key: 'lowerLimit',
                      value: customToFixed(value, allowedDecimalsLimit)
                    })
                  }
                }}
              />
            )}
            {hasUpperLimit && (
              <InfoCell
                isEditing
                label="Upper Limit"
                dataType="number"
                value={get(newCharacteristic, 'upperLimit', '')}
                inputConfig={{
                  onChange: (value: string) => {
                    onChange({
                      key: 'upperLimit',
                      value: customToFixed(value, allowedDecimalsLimit)
                    })
                  }
                }}
              />
            )}
          </BoxWrapper>
        )}
        <BoxWrapper enableMargin enablePadding>
          <InfoCell
            isEditing
            label="INTERCOS Quality Inspection"
            value={get(newCharacteristic, 'qualityInspection', '')}
            inputType="switch"
            inputConfig={{
              onChange: (value: boolean) => {
                onChange({ key: 'qualityInspection', value })
              }
            }}
          />
          <InfoCell
            isEditing
            label="INTERCOS Homologation"
            inputType="switch"
            value={get(newCharacteristic, 'masterHom', '')}
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'masterHom', value })
              }
            }}
          />
          <InfoCell
            isEditing
            label="INTERCOS Project Development"
            inputType="switch"
            value={get(newCharacteristic, 'customizedDev', '')}
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'customizedDev', value })
              }
            }}
          />
          <InfoCell
            isEditing
            label="SUPPLIER Certificate of Analysis"
            value={get(newCharacteristic, 'supplierCoa', '')}
            inputType="switch"
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'supplierCoa', value })
              }
            }}
          />
        </BoxWrapper>
      </>
    </Form>
  )
}

export default MasterSpecificationCharacteristicContent
