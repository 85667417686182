import { Tooltip } from 'antd'
import { filter } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Table } from '../../../components'
import Button from '../../../components/Button'
import { Modal } from '../../../components/Modal'
import { ATTACHMENTS_IN_MASTER_SPECIFICATIONS_TABLE_CONFIG } from '../../../components/Packaging/AttachmentsTable/constants'
import { TOP_RIGHT } from '../../../components/Table/constants'
import { appActions } from '../../../features/app'
import { masterSpecActions } from '../../../features/masterSpec/actions'
import { IMasterSpecSearchFields } from '../../../features/masterSpec/model'
import { selectMasterSpecAddNewList } from '../../../features/masterSpec/selectors'
import { masterSpecStatus } from '../../../pages/MasterSpecificationDetail/constants'
import { columnsGeneratorHelper } from '../../../utils'
import MasterSpecSearchForm from '../MasterSpecSearchForm'
import { ButtonsWrapper } from './styled'
import { IAddMasterSpeciModalProps } from './types'

export const AddMasterSpecificationModal: FC<IAddMasterSpeciModalProps> = ({
  packageId
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParameters, setSearchParameters] = useState<IMasterSpecSearchFields>({})
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [isResult, setIsResult] = useState<boolean>(false)
  const addNewList = useSelector(selectMasterSpecAddNewList)
  const columns = columnsGeneratorHelper(
    ATTACHMENTS_IN_MASTER_SPECIFICATIONS_TABLE_CONFIG
  )

  const { packageVariantId } = useParams()

  const filteredList = filter(
    addNewList,
    (elem) => elem.status === masterSpecStatus.COMPLETED
  )
  const modalKey = 'add-new-master-spec'

  const updateHandler = (key: keyof IMasterSpecSearchFields, value: any) => {
    let newParam = {
      ...searchParameters,
      [key]: value
    }
    if (key === 'category') {
      newParam.subcategory = ''
    }
    setSearchParameters(newParam)
  }

  const backHandler = () => {
    setIsSearch(false)
    setIsResult(false)
  }

  const closeModalHandler = () => {
    setIsResult(false)
    dispatch(appActions.closeModal({ modalKey }))
  }

  const searchHandler = () => {
    setIsResult(true)
    dispatch(masterSpecActions.getList({ filters: searchParameters, isAddNew: true }))
  }

  const actionConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }: any) => (
      <Tooltip title="Detail">
        <Button
          onClick={() => {
            dispatch(masterSpecActions.getDetail({ specId: data.id || '' }))
            if (!packageVariantId) {
              navigate(
                `/wikipack/packaging/${packageId}/master-specifications/new/${data.id}`
              )
            } else {
              navigate(
                `/wikipack/packaging/${packageId}/variant/${packageVariantId}/master-specifications/new/${data.id}`
              )
            }
            closeModalHandler()
          }}
          iconName="FormOutlined"
          variant="link"
        />
      </Tooltip>
    )
  }

  const footer = isSearch
    ? [
        <Button key="back" label="Back" variant="link" onClick={backHandler} />,
        <Button
          key="cancel"
          label="Cancel"
          variant="ghost"
          onClick={closeModalHandler}
        />,
        <Button key="search" label="Search" onClick={searchHandler} enableKeyPress />
      ]
    : [<Button key="cancel" label="Cancel" variant="ghost" onClick={closeModalHandler} />]

  return (
    <Modal
      btnName="Add"
      btnClicked={() => {
        backHandler()
        setSearchParameters({})
      }}
      btnType="ghost"
      title="Add new master specification"
      modalKey={modalKey}
      size={'medium'}
      centered
      isSmall
      footer={footer}
    >
      {isSearch ? (
        <>
          {isResult ? (
            <Table
              enablePagination
              paginationPosition={TOP_RIGHT}
              columns={columns}
              items={filteredList}
              rowKey="id"
              actionColumnConfig={actionConfig}
              forceHeight
            />
          ) : (
            <MasterSpecSearchForm data={searchParameters} onUpdate={updateHandler} />
          )}
        </>
      ) : (
        <ButtonsWrapper>
          <Button label="Search" onClick={() => setIsSearch(true)} enableKeyPress />
          <Button
            label="New"
            onClick={() => {
              closeModalHandler()
              navigate('./new')
            }}
          />
        </ButtonsWrapper>
      )}
    </Modal>
  )
}
