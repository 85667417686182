import { TABLE_DATA_TYPE } from '../../../../components/Table/constants'
import { ITableConfig } from '../../../../components/Table/types'
import { AppRoutes } from '../../../../pages/constants'

export const PRODUCTS_RTG_TABLE_CONFIG = (labelOnSelect?: string): ITableConfig => {
  return {
    id: {
      dataPath: 'id',
      hide: true
    },
    productCode: {
      label: labelOnSelect || 'Product Code',
      dataType: TABLE_DATA_TYPE.LINK,
      dataPath: 'productCode',
      fixed: 'left',
      pathname: AppRoutes.VAULT_PRODUCT_DETAILS,
      newTab: true,
      showSortable: true,
      warnTextPath: 'productCodeWarn'
    },
    image: {
      label: 'Image',
      dataType: TABLE_DATA_TYPE.IMAGE,
      dataPath: 'thumbnail.url',
      fixed: 'left',
      hasBorder: true
    },
    name: {
      label: 'Final Name',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'name',
      showSortable: true
    },
    status: {
      label: 'Status',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'status',
      showSortable: true
    },
    formulaSchemaCode: {
      label: 'Formula Schema Code',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'formulaSchemaCode'
    },
    industrialCode: {
      label: 'Industrial Code',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'industrialCode'
    },
    labReferenceCode: {
      label: 'Lab reference Code',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'labReferenceCode'
    },
    packagingCode: {
      label: 'Packaging Code',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packagingCode'
    },
    pdLaunchYear: {
      label: 'PD Launch Year',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'pdLaunchYear'
    },
    pdCollection: {
      label: 'PD Collection',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'pdCollection'
    },
    businessSegment: {
      label: 'Business Segment',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'businessSegment',
      showSortable: true
    },
    productCategory: {
      label: 'Product Category',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'productCategory'
    },
    format: {
      label: 'Format',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'format'
    },
    productForm: {
      label: 'Product Form',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'productForm'
    },
    createdBy: {
      label: 'Created By',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'createdBy',
      showSortable: true
    },
    updatedBy: {
      label: 'Updated By',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'updatedBy',
      showSortable: true
    },
    createdAt: {
      label: 'Created At',
      dataType: TABLE_DATA_TYPE.DATE,
      dataPath: 'createdAt',
      showSortable: true
    },
    updatedAt: {
      label: 'Updated At',
      dataType: TABLE_DATA_TYPE.DATE,
      dataPath: 'updatedAt',
      showSortable: true
    }
  }
}
