import { get } from 'lodash'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, PageTitle } from '../../components'
import { VARIANTS_TABLE_CONFIG } from '../../components/Packaging/VariantsTable/constants'
import { Steps } from '../../components/Steps'
import { PageContent, SideInformation } from '../../containers'
import DetailInformation from '../../containers/Packaging/DetailInformation'
import Relationships from '../../containers/Packaging/Relationships'
import Summary from '../../containers/Packaging/Summary'
import Variants from '../../containers/Packaging/Variants'
import { appSliceActions } from '../../features/app'
import { packagingActions, packagingSliceActions } from '../../features/packaging'
import {
  NEW_PACKAGE_IMAGE_KEY,
  PACKAGING_STATUS
} from '../../features/packaging/constants'
import {
  CATEGORY,
  PACKAGE_TYPE,
  PackagingCreationType
} from '../../features/packaging/model'
import {
  getNewPackage,
  selectAllTaxonomies,
  selectIfFlowTypeSelected,
  selectNewPackagingCode,
  selectPackagingError
} from '../../features/packaging/selectors'
import { GenericLayout } from '../../layouts'
import { useWindowSize } from '../../utils'
import { AppRoutes } from '../constants'
import AttachmentsInPackCreate from './AttachmentsInPackCreate'
import { footerActionHeight, FooterActions, StepContent } from './styled'
import Attachments from '../../containers/Packaging/Attachments'
import { ATTACHMENTS_TABLE_CONFIG } from '../../components/Packaging/AttachmentsTable/constants'

const CreationPackage: FC = () => {
  const { flowType } = useParams()
  const [currentStep, setCurrentStep] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const packagingError = useSelector(selectPackagingError)
  const allTaxonomies = useSelector(selectAllTaxonomies)
  const pageTitleRef = useRef<HTMLDivElement>(null)
  const stepperRef = useRef<HTMLDivElement>(null)
  const [heightToRemove, setHeightToRemove] = useState<number>(0)
  const newPackagingCode = useSelector(selectNewPackagingCode)
  const newPackage = useSelector(getNewPackage)
  const isFlowTypeSelected = useSelector(selectIfFlowTypeSelected)
  const isVial = flowType === PackagingCreationType.VIAL
  const isMulti = get(newPackage, '_flowType', '') === 'multi'
  const { width } = useWindowSize()

  const clearImage = () => {
    const imageData = get(window, `tmpFiles.${NEW_PACKAGE_IMAGE_KEY}`, null)
    if (imageData) {
      delete window.tmpFiles[NEW_PACKAGE_IMAGE_KEY]
    }
  }

  const backStepHandler = () => {
    setCurrentStep((prevStep) => --prevStep)
  }

  const nextStepHandler = () => {
    setCurrentStep((prevStep) => ++prevStep)
  }

  const resetNewPackage = () => {
    clearImage()
    dispatch(packagingSliceActions.setNewPackageCode({ packagingCode: '' }))
    dispatch(packagingSliceActions.clearNewPackage())
    dispatch(
      packagingSliceActions.updateNewPackage({
        newPackage: {
          category: !isMulti ? (isVial ? 'Vials' : '') : undefined,
          _flowType: isMulti ? PACKAGE_TYPE.MULTI : PACKAGE_TYPE.MONO,
          packageFamilies: isMulti ? [{}, {}] : [{}]
        }
      })
    )
  }

  const resetAndRestart = () => {
    resetNewPackage()
    setCurrentStep(0)
  }

  const updateNewPackage = (key: string, value: any) => {
    let tempPackage: Record<string, any> = {
      image: { ...newPackage?.image },
      status: get(newPackage, 'status', PACKAGING_STATUS.DRAFT)
    }
    if (key !== 'category') {
      tempPackage = { ...newPackage }
    }
    if (key !== 'testedSupplier' && key !== 'groupName') {
      dispatch(
        packagingSliceActions.updateNewPackage({
          newPackage: { ...tempPackage, [key]: value }
        })
      )
    }

    if (key === 'testedSupplier') {
      dispatch(
        packagingSliceActions.updateNewPackage({
          newPackage: { ...tempPackage, [key]: value, groupName: value.groupName }
        })
      )
      dispatch(packagingActions.searchSuppliers(value.groupName))
    }

    if (key === 'groupName') {
      dispatch(
        packagingSliceActions.updateNewPackage({
          newPackage: { ...tempPackage, [key]: value, testedSupplier: null }
        })
      )
    }
  }

  const openAssociatedWarning = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: 'Warning',
        key: 'package-already-associated',
        message:
          'This Supplier Code is already used in another packaging. Would you like to continue anyway?',
        footer: [
          <Button
            key="cancel"
            label="Cancel"
            onClick={() => dispatch(appSliceActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="ok"
            label="Ok"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              nextStepHandler()
            }}
          />
        ]
      })
    )
  }

  const openResetWarning = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: 'Are you sure you want to reset the actions?',
        key: 'package-creation-reset',
        message:
          'By resetting the sections, all entered data will be lost and you will return to Step 1',
        footer: [
          <Button
            key="edit"
            label="Continue To Edit"
            onClick={() => dispatch(appSliceActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="reset"
            label="Reset"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              resetAndRestart()
            }}
          />
        ]
      })
    )
  }

  const getTopAndBottomHeigts = () => {
    let totalHeight = 0
    if (pageTitleRef.current) {
      totalHeight += pageTitleRef.current.offsetHeight
    }
    if (stepperRef.current) {
      totalHeight += stepperRef.current.offsetHeight
    }
    setHeightToRemove(totalHeight)
  }

  useLayoutEffect(() => {
    getTopAndBottomHeigts()
  }, [width])

  useEffect(() => {
    if (!isFlowTypeSelected)
      navigate(AppRoutes.WIKIPACK_PACKAGE_CREATION, { replace: true })
    dispatch(packagingActions.getAttachmenttypes())
    return () => {
      dispatch(packagingSliceActions.setNewPackageCode({ packagingCode: '' }))
      dispatch(packagingSliceActions.clearNewPackage())
      dispatch(packagingSliceActions.clearError())
      clearImage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
    checks if the packaging has
      - a valid packaging supplier
      - a valid material when vial
  */
  const isFormValidStep0 = (data: Record<string, any>) => {
    const isCategoryVials = get(data, 'category', '').toLowerCase() === CATEGORY.VIALS
    const isMaterialSelected = get(data, 'packageFamilies[0].material.length')
    const isSupplierValid = get(data, 'testedSupplier.name', '') !== ''
    if (isCategoryVials) return isSupplierValid && isMaterialSelected
    else return isSupplierValid
  }

  /* 
    checks if multipackage vial has 
      - at least one vial variant
      - at least one material selected
  */
  const checkVialFieldsForMulti = (data: Record<string, any>) => {
    const isMaterialSelected = get(data, 'material.length')
    return get(data, 'packageVariants', []).length && isMaterialSelected
  }

  /*
    checks if multipackage has
      - a valid category
      - a valid subcategory
  */
  const isFormValidStep1 = (data: Record<string, any>) => {
    const categoryValue: string = get(data, 'category', '')
    const subCategoryValue = get(data, 'subcategory', '')
    const isCategoryOthers = categoryValue.toLowerCase() === CATEGORY.OTHERS
    const isCategoryVials = categoryValue.toLowerCase() === CATEGORY.VIALS
    const filteredCategory = allTaxonomies.find(
      ({ value: catValue }: any) => catValue === categoryValue
    )
    const subCategoryList = get(filteredCategory, 'children.subcategory', [])
    const isCategoryValid = !!categoryValue
    const isSubCategoryValid =
      subCategoryValue ||
      (!subCategoryValue && !subCategoryList.length && !isCategoryOthers)
    if (isCategoryVials) {
      return checkVialFieldsForMulti(data)
    }
    return isCategoryValid && isSubCategoryValid
  }

  /*
    checks if continue button should be disabled
  */
  const checkIfDisable = () => {
    if (currentStep === 0) {
      return !isFormValidStep0(newPackage)
    }

    if (currentStep === 1 && isMulti) {
      const { packageFamilies, ...rest } = newPackage
      const sideValidation = packageFamilies?.map(
        (packFamily: Record<string, any>) => !isFormValidStep1({ ...packFamily, ...rest })
      )
      const vialsList = get(newPackage, 'packageFamilies', []).filter(
        (family: any) => get(family, 'category', '').toLowerCase() === CATEGORY.VIALS
      )
      const vialsWithVariants = vialsList.filter(
        (family: any) => !!get(family, 'packageVariants', []).length
      )
      if (vialsList.length === vialsWithVariants.length) {
        return sideValidation.some((val: boolean) => val)
      }
      return true
    }
    if (currentStep === 1 && isVial) {
      return !get(newPackage, 'packageFamilies[0].packageVariants', []).length
    }
    return false
  }
  const ResetButtonWarn = (
    <Button key="reset" label="Reset" onClick={openResetWarning} variant="text" />
  )
  const ResetButton = (
    <Button key="reset" label="Reset" onClick={resetNewPackage} variant="ghost" />
  )

  const ContinueButtonWarn = (
    <Button
      key="continue"
      label="Continue"
      onClick={() => {
        const supplierRef = get(newPackage, 'supplierRef', '')
        dispatch(
          packagingActions.checkSupplierRef({
            supplierRef,
            nextStep: nextStepHandler,
            openWarning: openAssociatedWarning
          })
        )
      }}
      disabled={checkIfDisable()}
    />
  )
  const ContinueButton = (
    <Button
      key="continue"
      label="Continue"
      onClick={nextStepHandler}
      disabled={checkIfDisable()}
    />
  )

  const BackButton = (
    <Button key="back" label="Back" onClick={backStepHandler} variant="ghost" />
  )

  const resetBackAndContinue = [ResetButtonWarn, BackButton, ContinueButton]

  const VIALS_STEPS = [
    {
      title: 'Detail Information',
      subtitle: 'Insert the Genaral Information',
      body: (
        <DetailInformation
          isEditing
          imageFileKey={NEW_PACKAGE_IMAGE_KEY}
          isVial
          data={newPackage}
          updateData={updateNewPackage}
          isCategoryPreselected
          isCreating
        />
      ),
      footer: [ResetButton, ContinueButtonWarn]
    },
    {
      title: 'Variants',
      subtitle: 'Insert the variants',
      body: (
        <Variants
          data={get(newPackage, 'packageFamilies[0]', {})}
          isEditing
          isCreating
          onAddConfirm={(value) => {
            const dataList = get(newPackage, 'packageFamilies[0].packageVariants', [])
            updateNewPackage('packageFamilies', [
              {
                ...get(newPackage, 'packageFamilies[0]', {}),
                packageVariants: dataList.concat(value)
              }
            ])
          }}
          deleteVariant={packagingSliceActions.deleteNewPackageVariant}
          tableConfig={VARIANTS_TABLE_CONFIG}
          category={get(newPackage, 'category', '')}
          subcategory={get(newPackage, 'subcategory', '')}
          modalKey={'package-variant-creation'}
          hasInfoModal
          hasDelete
        />
      ),
      footer: resetBackAndContinue
    },
    {
      title: 'Summary',
      subtitle: 'Review the inserted data',
      body: (
        <Summary
          restartCallback={resetAndRestart}
          imageFileKey={NEW_PACKAGE_IMAGE_KEY}
          data={newPackage}
          isVial
        />
      ),
      footer: !newPackagingCode
        ? [
            ResetButtonWarn,
            BackButton,
            <Button
              key="continue"
              label="Continue"
              onClick={() => {
                dispatch(packagingActions.createPackaging())
              }}
            />
          ]
        : []
    }
  ]

  const NO_VIAL_STEPS = [
    {
      title: 'Detail Information',
      body: (
        <DetailInformation
          isEditing
          isCreating
          imageFileKey={NEW_PACKAGE_IMAGE_KEY}
          data={newPackage}
          updateData={updateNewPackage}
        />
      ),
      footer: [ResetButton, ContinueButtonWarn]
    },
    {
      title: 'Attachments',
      body: (
        <Attachments
          isEditing
          dataList={get(newPackage, 'filteredAttachment', [])}
          updateData={updateNewPackage}
          deleteAttach={packagingSliceActions.deleteNewPackageAttach}
          tableConfig={ATTACHMENTS_TABLE_CONFIG}
          isCreating
        />
        // <AttachmentsInPackCreate
        //   list={get(newPackage, 'filteredAttachment', [])}
        //   onAdd={(newList) => updateNewPackage('filteredAttachment', newList)}
        //   onDelete={(value) =>
        //     dispatch(
        //       packagingSliceActions.deleteNewPackageAttach({
        //         value
        //       })
        //     )
        //   }
        // />
      ),
      footer: resetBackAndContinue
    },
    {
      title: 'Relationships',
      body: <Relationships isEditing data={newPackage} />,
      footer: resetBackAndContinue
    },
    {
      title: 'Summary',
      body: (
        <Summary
          restartCallback={resetAndRestart}
          imageFileKey={NEW_PACKAGE_IMAGE_KEY}
          data={newPackage}
        />
      ),
      footer: !newPackagingCode
        ? [
            ResetButtonWarn,
            BackButton,
            <Button
              key="continue"
              label="Continue"
              onClick={() => {
                dispatch(packagingActions.createPackaging())
              }}
            />
          ]
        : []
    }
  ]

  const MULTI_STEPS = [
    {
      title: 'Detail Information',
      body: (
        <DetailInformation
          isEditing
          isCreating
          imageFileKey={NEW_PACKAGE_IMAGE_KEY}
          data={newPackage}
          updateData={updateNewPackage}
          isMulti
        />
      ),
      footer: [ResetButton, ContinueButtonWarn]
    },
    {
      title: 'Side Information',
      body: (
        <SideInformation
          data={newPackage}
          updateData={updateNewPackage}
          isEditing
          isCreating
          variantTableConfig={VARIANTS_TABLE_CONFIG}
          isSideDeletable
        />
      ),
      footer: [resetBackAndContinue]
    },
    {
      title: 'Attachments',
      body: (
        <Attachments
          isEditing
          dataList={get(newPackage, 'filteredAttachment', [])}
          updateData={updateNewPackage}
          deleteAttach={packagingSliceActions.deleteNewPackageAttach}
          tableConfig={ATTACHMENTS_TABLE_CONFIG}
          isCreating
        />
        // <AttachmentsInPackCreate
        //   list={get(newPackage, 'filteredAttachment', [])}
        //   onAdd={(newList) => updateNewPackage('filteredAttachment', newList)}
        //   onDelete={(value) =>
        //     dispatch(
        //       packagingSliceActions.deleteNewPackageAttach({
        //         value
        //       })
        //     )
        //   }
        // />
      ),
      footer: resetBackAndContinue
    },
    {
      title: 'Relationships',
      body: <Relationships isEditing data={newPackage} />,
      footer: resetBackAndContinue
    },
    {
      title: 'Summary',
      body: (
        <Summary
          restartCallback={resetAndRestart}
          imageFileKey={NEW_PACKAGE_IMAGE_KEY}
          data={newPackage}
          isMulti
        />
      ),
      footer: !newPackagingCode
        ? [
            ResetButtonWarn,
            BackButton,
            <Button
              key="continue"
              label="Continue"
              onClick={() => {
                dispatch(packagingActions.createPackaging())
              }}
            />
          ]
        : []
    }
  ]

  const PAGE_CONFIG = {
    [PackagingCreationType.VIAL]: {
      title: 'Create new Vial packaging',
      steps: VIALS_STEPS
    },
    [PackagingCreationType.NO_VIAL]: {
      title: 'Create new packaging',
      steps: NO_VIAL_STEPS
    },
    [PackagingCreationType.MULTI]: {
      title: 'Create new Multi packaging',
      steps: MULTI_STEPS
    }
  }

  const steps = get(PAGE_CONFIG, `${flowType}.steps`, null)
  const subtitle = get(PAGE_CONFIG, `${flowType}.steps[${currentStep}].subtitle`, '')
  const body = get(PAGE_CONFIG, `${flowType}.steps[${currentStep}].body`, null)
  const footer = get(PAGE_CONFIG, `${flowType}.steps[${currentStep}].footer`, [])

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight + (!newPackagingCode ? footerActionHeight : 0)}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <PageTitle
          ref={pageTitleRef}
          title={get(PAGE_CONFIG, `${flowType}.title`, '')}
          subtitle={subtitle}
        />
        {steps && (
          <Steps
            steps={steps}
            container_variables={{ current: currentStep }}
            ref={stepperRef}
          />
        )}

        <StepContent heightToRemove={heightToRemove}>{body}</StepContent>
        {!!footer.length && <FooterActions>{footer}</FooterActions>}
      </PageContent>
    </GenericLayout>
  )
}

export default CreationPackage
