import { get } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, InfoCell, Modal } from '../../../../components'
import { BoxInfo } from '../../../../components/BoxInfo'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import { Divider } from '../../../../components/Divider'
import { FormWrap } from '../../../../components/Form/FormWrap'
import { packagingSliceActions } from '../../../../features/packaging'
import {
  selectNewVersion,
  selectNewVersionRequirements
} from '../../../../features/packaging/selectors'
import { IAddAttachmentModal } from './types'

export const AddAttachmentVersionModal = ({ record, flowName }: IAddAttachmentModal) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const enableSave = useSelector(selectNewVersionRequirements)

  const recordName = get(record, 'attachmentContentType.name', '')
  const recordSapCodes = get(record, 'SAPCodes', [])

  useEffect(() => {
    dispatch(
      packagingSliceActions.saveNewVersionData({
        key: 'contentType',
        value: recordName
      })
    )
  }, [recordName, dispatch])

  const handleSave = useCallback(() => {
    dispatch({
      type: 'packaging/saveNewVersion',
      payload: { record, flowName, packageId: id }
    })
  }, [dispatch, record, flowName, id])

  const handleChange = useCallback(
    (key: string, value: any) => {
      dispatch(
        packagingSliceActions.saveNewVersionData({
          key,
          value
        })
      )
    },
    [dispatch]
  )
  const newVersion = useSelector(selectNewVersion)

  const resetDataHandler = () => {
    const fileKey = get(newVersion, 'file.value', '')
    if (fileKey) {
      const fileExist = !!get(window, `tmpFiles.${fileKey}`, null)
      if (fileKey && fileExist) {
        delete window.tmpFiles[fileKey]
      }
    }
    dispatch(packagingSliceActions.resetNewVersion())

    // Inserisco i SAPCodes provenienti dal padre
    dispatch(
      packagingSliceActions.saveNewVersionData({
        key: 'SAPCodes',
        value: recordSapCodes
      })
    )
  }
  const getIsFieldEnabled = () => {
    return recordName !== 'Project Specification'
  }
  return (
    <>
      <Modal
        modalKey={`add-new-attach-table-${record.id}`}
        btnIcon="PlusOutlined"
        btnType="link"
        title="Add new version of the attachment"
        size="medium"
        isSmall={true}
        btnClicked={resetDataHandler}
        footer={[
          <Button key="save" label="Save" onClick={handleSave} disabled={!enableSave} />
        ]}
      >
        <FormWrap title="Information" layout="vertical">
          <Divider />
          <BoxWrapper md={2} lg={2} enablePadding>
            <InfoCell
              value={get(newVersion, 'file.value', '')}
              label={'variant-attach'}
              isEditing
              dataType="image"
              inputType="upload"
              inputConfig={{
                fileKey: `variant-attach-${Date.now()}`,
                topMessage: 'Drop or browse the attachment',
                fileType: 'any',
                bottomMessage: '',
                onChange: (value: any) => {
                  if (!get(newVersion, 'name', '')) {
                    handleChange('name', value.name)
                  }
                  handleChange('file', value)
                },
                enableCharRule: true
              }}
            />
          </BoxWrapper>
          <BoxWrapper md={2} lg={3} enablePadding>
            <BoxInfo label="Content Type" value={record?.attachmentContentType?.name} />
            <InfoCell
              label="Name"
              value={get(newVersion, 'name', '')}
              isEditing
              inputConfig={{
                onChange: (value: string) => handleChange('name', value)
              }}
            />
            <InfoCell
              label="Description"
              value={get(newVersion, 'description', '')}
              isEditing
              inputConfig={{
                onChange: (value: string) => handleChange('description', value)
              }}
            />
            <InfoCell
              dataType="tags"
              inputType="input-tag"
              value={get(newVersion, 'SAPCodes', [])}
              label="Sap Codes"
              isEditing
              inputConfig={{
                onChange: (value: any) => handleChange('SAPCodes', value),
                disabled: getIsFieldEnabled()
              }}
            />
          </BoxWrapper>
          <Divider disableBorder />
        </FormWrap>
      </Modal>
    </>
  )
}
