import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js'
import { FC, useCallback, useEffect, useState } from 'react'
import { Radar } from 'react-chartjs-2'
import { generateRGBString, getRandomRGBList } from '../../../utils/colorsHelpers'
import { IColorRGB } from '../../../utils/colorsHelpers/types'
import ChartLegend from '../ChartLegend'
import { RadarChartContainer } from './styled'
import { IRadarChartProps } from './types'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

const RadarChart: FC<IRadarChartProps> = ({
  dataList = [],
  labels,
  min = 0,
  max = 10,
  onSelectLegend = () => {},
  onSelectAllLegend = () => {},
  selectedLegends = [],
  hideLegendSelectAll
}) => {
  const dataLength = dataList.length

  const [colorList, setColorList] = useState<IColorRGB[]>([])

  const getRandomColorListHandler = useCallback(() => {
    const colorList = getRandomRGBList({
      quantity: dataLength,
      excludeList: [{ r: 255, g: 255, b: 255 }]
    })
    setColorList(colorList)
  }, [dataLength])

  useEffect(() => {
    if (dataLength) {
      getRandomColorListHandler()
    }
  }, [dataLength, getRandomColorListHandler])

  const refactoredData = dataList.map((value, idx) => ({
    ...value,
    backgroundColor:
      value.backgroundColor || generateRGBString(colorList[idx] || {}, 0.2),
    borderColor: value.borderColor || generateRGBString(colorList[idx] || {}, 1)
  }))

  const filtredData = selectedLegends.length
    ? refactoredData.filter((data) => !selectedLegends.includes(data.id))
    : refactoredData

  const chartData = {
    labels,
    datasets: filtredData.map(({ dataLabel, dataSet, backgroundColor, borderColor }) => ({
      label: dataLabel,
      data: dataSet.map((item) => item.value),
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      borderWidth: 2
    }))
  }

  const options: ChartOptions<'radar'> = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 11,
            family: 'Didact Gothic',
            weight: 900
          }
        },
        angleLines: { display: true },
        suggestedMin: min,
        suggestedMax: max
      }
    }
  }

  return (
    <>
      <RadarChartContainer className="radar-chart-container">
        <Radar data={chartData} options={options} />
      </RadarChartContainer>
      <ChartLegend
        onSelect={onSelectLegend}
        onSelectAll={onSelectAllLegend}
        dataList={refactoredData.map((data) => ({
          id: data.id,
          label: data.dataLegendLabel || data.dataLabel,
          isSelected: selectedLegends.includes(data.id),
          backgroundColor: data.backgroundColor,
          borderColor: data.borderColor
        }))}
        hideSelectAll={hideLegendSelectAll}
      />
    </>
  )
}

export default RadarChart
