import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { appSliceActions } from '../../features/app'
import { selectAppPopUp } from '../../features/app/selectors'
import Button from '../Button'
import { ButtonWrapper, Message, StyledModal, Title } from './styled'

const PopUp = () => {
  const popUpConfig = useSelector(selectAppPopUp)
  const dispatch = useDispatch()

  const {
    title,
    message,
    btnConfig,
    key,
    isOpen,
    footer,
    isClosable = true,
    onClose = () => {}
  } = popUpConfig

  const closeCallBack = () => {
    onClose()
  }

  const closePopUpHandler: () => void = () => {
    dispatch(appSliceActions.closePopUp())
  }

  const buttonClickHandler: () => void = () => {
    const btnCallback = get(btnConfig, 'btnCb', () => {})
    btnCallback()
    closePopUpHandler()
  }

  const btnLabel = get(btnConfig, 'btnLabel', 'OK')
  return (
    <StyledModal
      key={key}
      width={520}
      visible={isOpen}
      destroyOnClose
      afterClose={closeCallBack}
      footer={
        footer || (
          <ButtonWrapper>
            <Button label={btnLabel} onClick={() => buttonClickHandler()} />
          </ButtonWrapper>
        )
      }
      bodyStyle={{
        padding: '20px'
      }}
      centered
      closable={isClosable}
      maskClosable
      onCancel={() => closePopUpHandler()}
    >
      {title && <Title>{title}</Title>}
      {message && <Message>{message}</Message>}
    </StyledModal>
  )
}

export default PopUp
