import { TablePaginationConfig } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components'
import { Divider } from '../../../components/Divider'
import { FlexSection } from '../../../components/FlexSection'
import { Modal } from '../../../components/Modal'
import Table from '../../../components/Table'
import { TOP_RIGHT } from '../../../components/Table/constants'
import { ITActionColumnConfig } from '../../../components/Table/types'
import { appActions } from '../../../features/app'
import { storeActions, storeSliceActions } from '../../../features/storeProducts'
import {
  PRODUCTS_TABLE_CONFIG,
  STORE_PROD_DYNAMIC_TAXONOMIES,
  storeProductFormsWithAggregateEntities
} from '../../../features/storeProducts/constants'
import {
  getFilters,
  selectLoader,
  selectProductPagination,
  selectProducts,
  selectSearchRequirements
} from '../../../features/storeProducts/selectors'
import { selectPermissions } from '../../../features/users/selectors'
import AddCartModal from '../../../pages/StoreProductsDetail/AddCartModal'
import {
  columnsGeneratorHelper,
  rowsGeneratorHelper,
  rowsGeneratorHelper_new,
  unsetKeyByPath
} from '../../../utils'
import { TagsContainer } from '../../TagsContainer'
import { SearchContainer } from '../SearchContainer'

export const ResultContainer: FC = () => {
  const permissions = useSelector(selectPermissions)
  const selectedFilters = useSelector(getFilters)
  const modalKey = 'store-products'
  const items = useSelector(selectProducts)
  const isLoading = useSelector(selectLoader)
  const pagination = useSelector(selectProductPagination)
  const { page = 1, pageSize = 10, total = 10 } = pagination
  const isActiveButton = useSelector(selectSearchRequirements)

  const canOrder = permissions.storeOrders?.canUpdate

  const navigate = useNavigate()

  const columnsGenerator = () => {
    const generatedColums = columnsGeneratorHelper(PRODUCTS_TABLE_CONFIG)
    return generatedColums
  }

  const dataRowGenerator = () =>
    rowsGeneratorHelper_new({ dataList: items, tableConfig: PRODUCTS_TABLE_CONFIG }).map(
      (data) => ({
        ...data,
        productCode: storeProductFormsWithAggregateEntities.includes(data.productForm)
          ? data.productCode
          : data.industrialCode || data.labCode
      })
    )

  const columns = columnsGenerator()
  const dataRow = items.length ? dataRowGenerator() : []
  const dispatch = useDispatch()

  const openModalFilterHandler = () => {
    dispatch(storeSliceActions.filterToTemp())
  }

  const resetFilters = () => {
    dispatch(storeSliceActions.resetTempFilters())
  }

  const searchProducts = () => {
    dispatch(storeSliceActions.tempToFilter({ resetPageData: true }))
    dispatch(storeActions.searchStoreProducts())
    dispatch(appActions.closeModal({ modalKey }))
  }

  const changePageHandler = (pageData: TablePaginationConfig) => {
    dispatch(
      storeSliceActions.setPagination({
        page: get(pageData, 'current', 1),
        pageSize: get(pageData, 'pageSize', 10),
        total: get(pageData, 'total', 10)
      })
    )
    dispatch(storeActions.searchStoreProducts())
  }
  const removeTagHandler = (path: string) => {
    dispatch(storeSliceActions.removeFilter({ path }))
    dispatch(storeActions.searchStoreProducts())
    const isDynamicTaxonomies = STORE_PROD_DYNAMIC_TAXONOMIES.some((val) =>
      path.includes(val)
    )
    if (isDynamicTaxonomies) {
      const refactorPath = path.replace('filters.', '')
      const newFilters = unsetKeyByPath(selectedFilters, refactorPath)
      dispatch(storeActions.filterTaxonomies({ filters: newFilters }))
    }
  }

  const goToDetailsHanlder = (id: string) => {
    navigate(`/store/products/${id}`)
  }

  const openAddCartHandler = (id: string) => {
    dispatch(storeActions.checkProductAvailabiltyById({ id }))
  }

  const actionConfig: ITActionColumnConfig = {
    actionRenderer: ({ data }: any) => (
      <>
        <Button
          label="Details"
          onClick={() => goToDetailsHanlder(data.id)}
          variant="ghost"
        />
        {canOrder && (
          <AddCartModal
            onOpen={() => openAddCartHandler(data.id)}
            productCode={data?.productCode}
            btnLabel=""
            btnIcon="ShoppingCartOutlined"
            modalKey={`products-results-add-to-cart-${data.id}`}
            btnType="ghost"
          />
        )}
      </>
    )
  }

  return (
    <>
      <Divider disableBorder />
      <FlexSection flexDirection="row" alignItems="center" justifyContent="start">
        <Modal
          modalKey={modalKey}
          btnName="Filter"
          size="large"
          title="Products Search"
          btnClicked={openModalFilterHandler}
          footer={[
            <Button key="reset" label="Reset" variant="link" onClick={resetFilters} />,
            <Button
              key="search"
              label="Search"
              onClick={searchProducts}
              disabled={!isActiveButton}
              enableKeyPress
            />
          ]}
        >
          <SearchContainer />
        </Modal>
        <TagsContainer
          tags={selectedFilters}
          inner={false}
          reducer={'storeProducts'}
          flowName={'storeProducts'}
          prefix={'filters'}
          allowNoFilters
          basePath={'filters'}
          onRemoveTag={removeTagHandler}
        />
      </FlexSection>
      <Table
        forceHeight
        pagination={{
          current: page,
          pageSize,
          total
        }}
        columns={columns}
        items={dataRow}
        rowKey="id"
        enablePagination
        paginationPosition={TOP_RIGHT}
        onChangePage={changePageHandler}
        isLoading={isLoading}
        actionColumnConfig={dataRow.length ? actionConfig : undefined}
      />
    </>
  )
}
