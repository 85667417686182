import { Plugin } from 'chart.js'
import { get } from 'lodash'
import { truncateStringWithDots } from '../../utils/stringHelpers'

export const drawLabelsPlugin: Plugin = {
  id: 'drawLabelsPlugin',
  afterDatasetsDraw: (chart: any) => {
    const options = chart.config.options?.plugins?.drawLabelsPlugin

    if (!options?.enabled) {
      return
    }
    const ctx = chart.ctx
    const coordinateList: any[] = []
    const groupCounterList: any[] = []
    chart.data.datasets.forEach((_: any, i: any) => {
      const meta = chart.getDatasetMeta(i)

      meta.data.forEach((point: any, index: number) => {
        const coordinateInListIdx = coordinateList.findIndex(
          ({ x, y }) => x === point.x && y === point.y
        )
        const isInList = coordinateInListIdx > -1
        if (!isInList) {
          coordinateList.push({
            x: point.x,
            y: point.y,
            counter: 1
          })
        }
        if (isInList) {
          coordinateList[coordinateInListIdx] = {
            ...coordinateList[coordinateInListIdx],
            counter: coordinateList[coordinateInListIdx].counter + 1
          }
        }
        groupCounterList.push(
          isInList ? coordinateList[coordinateInListIdx].counter - 1 : 0
        )
      })
    })
    chart.data.datasets.forEach((dataset: any, i: any) => {
      const meta = chart.getDatasetMeta(i)

      meta.data.forEach((point: any, index: number) => {
        const fontSize = 9
        const x = point.x
        let y = point.y - 8

        ctx.save()
        ctx.font = `${fontSize}px 'Didact Gothic`
        ctx.textAlign = 'left'
        ctx.fillStyle = 'black'

        const labels = `${get(dataset, `data[${index}].chartLabel`, '')}`

        const labelList = labels.split('\n')
        const groupCounterVal = groupCounterList[i] || 0
        const labelRows = labelList.length
        y -= fontSize * labelRows * groupCounterVal
        labelList.forEach((label) => {
          const truncatedLabel = truncateStringWithDots({
            text: label.trim(),
            truncateAt: 16
          })
          ctx.fillText(truncatedLabel, x, y)
          y -= fontSize
        })

        ctx.restore()
      })
    })
  }
}
