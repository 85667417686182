import { RightOutlined } from '@ant-design/icons'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { PageTitle } from '../../components'
import { PageContent } from '../../containers'
import { packagingActions, packagingSliceActions } from '../../features/packaging'
import { PackagingCreationType } from '../../features/packaging/model'
import { getNewPackage, selectPackagingError } from '../../features/packaging/selectors'
import { GenericLayout } from '../../layouts'
import { CREATION_FLOW_TYPES } from './constants'
import {
  Content,
  IconContainer,
  Subtitle,
  Title,
  TitleContainer,
  TypeCard
} from './styled'

const CreationPackagingType: FC = () => {
  const navigate = useNavigate()
  const packagingError = useSelector(selectPackagingError)
  const newPackage = useSelector(getNewPackage)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(packagingActions.getCountriesOfOriginDropdown())
    return () => {
      dispatch(packagingSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToHandler = (id: string) => {
    const isMulti = id === PackagingCreationType.MULTI
    dispatch(
      packagingSliceActions.updateNewPackage({
        newPackage: {
          ...newPackage,
          _type: id,
          _flowType: isMulti ? 'multi' : 'mono',
          packageFamilies: isMulti ? [{}, {}] : [{}]
        }
      })
    )
    navigate(`/wikipack/creation-packaging/${id}`)
  }

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <PageTitle
          title="Create new packaging"
          subtitle="Select the packaging you want to create"
        />
        {
          <Content>
            {CREATION_FLOW_TYPES.map(({ id, label, subtitle }) => (
              <TypeCard key={id} onClick={() => goToHandler(id)}>
                <TitleContainer>
                  <Title text={label} variant="h4" />
                  <Subtitle text={subtitle} variant="h5" />
                </TitleContainer>
                <IconContainer>
                  <RightOutlined />
                </IconContainer>
              </TypeCard>
            ))}
          </Content>
        }
      </PageContent>
    </GenericLayout>
  )
}

export default CreationPackagingType
