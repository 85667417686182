import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/Button'
import FooterControl from '../../components/FooterControl'
import { Loader } from '../../components/Loader'
import { Modal } from '../../components/Modal'
import { Steps } from '../../components/Steps'
import { Text } from '../../components/Text'
import { appActions, appSliceActions } from '../../features/app'
import { cartActions, cartSliceActions } from '../../features/cart'
import {
  getCartItems,
  selectCartLoader,
  selectCartSuccess,
  selectStep1Requirements,
  selectStep2Requirements,
  selectStep3Requirements
} from '../../features/cart/selectors'
import { ConfirmationModal } from './ConfirmationModal'
import { Step1Cart } from './Step1Cart'
import { Step2Cart } from './Step2Cart'
import { Step3Cart } from './Step3Cart'
import { Step4Cart } from './Step4Cart'
import { StyledModalContent } from './styled'

const orderSteps = [
  {
    id: '0',
    title: 'PRODUCTS LIST'
  },
  {
    id: '1',
    title: 'ADDITIONAL INFORMATION'
  },
  {
    id: '2',
    title: 'SHIPPING DETAILS'
  },
  {
    id: '3',
    title: 'ORDER SUMMARY'
  }
]
export const CartContent = () => {
  const dispatch = useDispatch()
  const modalKey = 'order-creation'
  const [currenStep, setCurrentStep] = useState(0)
  const [isFromStep4, setIsFromStep4] = useState(false)
  const cartIsLoading = useSelector(selectCartLoader)

  const cartSuccess = useSelector(selectCartSuccess)
  const products = useSelector(getCartItems)
  const step1Requirements = useSelector(selectStep1Requirements)
  const step2Requirements = useSelector(selectStep2Requirements)
  const step3Requirements = useSelector(selectStep3Requirements)

  const backHandlerToOne = () => {
    setIsFromStep4(true)
    setCurrentStep(0)
  }

  const backHandler = () => {
    setCurrentStep((prevStep) => --prevStep)
  }

  const openAvailabilityWarning = () => {
    dispatch(
      appSliceActions.openPopUp({
        key: 'availability-changed-warning',
        title: 'Warning',
        message: 'Some products are no more available',
        footer: [
          <Button label="Ok" onClick={() => dispatch(appSliceActions.closePopUp())} />
        ]
      })
    )
  }

  const updateItemsAvailability: (arg?: Record<string, any>[]) => void = (
    updatedProds = []
  ) => {
    if (updatedProds.length) {
      updatedProds.forEach(({ productCode, availableQuantity }: any) => {
        const prodData = products.find(
          ({ productCode: itemCode }) => productCode === itemCode
        )
        if (prodData) {
          const newprod = {
            ...prodData,
            item: {
              ...prodData.item,
              availableQuantity
            }
          }
          dispatch(
            cartSliceActions.updateCartItemById({ id: prodData.id, itemData: newprod })
          )
        }
      })
      openAvailabilityWarning()
    } else {
      setCurrentStep(3)
      setIsFromStep4(false)
    }
  }

  const pages = [
    { page: <Step1Cart />, requirements: step1Requirements },
    { page: <Step2Cart />, requirements: step2Requirements },
    { page: <Step3Cart />, requirements: step3Requirements },
    { page: <Step4Cart />, requirements: false }
  ]

  const nextHandler = () => {
    if (currenStep === 0 && isFromStep4) {
      return dispatch(
        cartActions.checkAvailability({
          items: products,
          warningCallback: updateItemsAvailability
        })
      )
    }
    setCurrentStep((prevStep) => prevStep + 1)
  }

  const resetCart = () => {
    closeModalHandler()
    dispatch(cartActions.resetCart())
    setCurrentStep(0)
  }

  const closeModalHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
  }

  const ResetBtn = (
    <Modal
      btnName="Reset"
      btnType="link"
      modalKey={modalKey}
      isSmall
      size="small"
      title=""
      footer={[
        <Button
          key="continue"
          label="Continue to edit"
          variant="link"
          onClick={closeModalHandler}
        />,
        <Button key="reset" label="Reset the cart" onClick={resetCart} />
      ]}
      centered
    >
      <StyledModalContent>
        <Text text="Are you sure you want to reset the cart?" variant="h3" bold />
        <Text text="Resetting the cart, all the entered dat will be lost" variant="h4" />
      </StyledModalContent>
    </Modal>
  )

  return (
    <>
      <Steps steps={orderSteps} container_variables={{ current: String(currenStep) }} />
      {cartIsLoading && <Loader />}
      {pages[currenStep].page}
      {!cartSuccess && (
        <FooterControl>
          {ResetBtn}
          {currenStep > 0 && (
            <Button label="Back" variant="ghost" onClick={backHandler} />
          )}
          {currenStep !== pages.length - 1 ? (
            <Button
              disabled={pages[currenStep].requirements}
              label="Continue"
              onClick={nextHandler}
            />
          ) : (
            <ConfirmationModal onBack={backHandlerToOne} />
          )}
        </FooterControl>
      )}
    </>
  )
}
