import { AppRoutes } from '../../../pages/constants'
import InfoTooltip from '../../InfoTooltip'
import { TABLE_DATA_TYPE } from '../../Table/constants'
import { ITableConfig } from '../../Table/types'

export const PRODUCT_IC_DETAIL_MONO_TABLE_CONFIG: ITableConfig = {
  industrialCode: {
    label: 'Industrial Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaCodeDEVEX'
  },
  labReferenceCode: {
    label: 'Lab Reference Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'labReferenceCode'
  },
  color: {
    label: 'Color',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'color'
  },
  icFinish: {
    label: 'IC Finish',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'finish'
  },
  countryCheckComplianceNotes: {
    label: 'Industrial Code Country Check Compliance Notes',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'countryCheckComplianceNotes'
  },
  csarRegistered: {
    label: 'CSAR Registered',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'csarRegistered'
  },
  vegan: {
    label: () => (
      <>
        Vegan <InfoTooltip title="Formula does not contain animal origin ingredients" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'vegan'
  },
  veganNotes: {
    label: 'Vegan Notes',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'veganNotes'
  },
  RSPO: {
    label: 'RSPO',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'RSPO'
  },
  microplasticFree: {
    label: 'Clean Of Microplastic',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'microplasticFree'
  },
  microplasticFreeNotes: {
    label: 'Clean Of Microplastic Notes',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'microplasticFreeNotes'
  },
  siliconesFree: {
    label: () => (
      <>
        Clean Of Silicones <InfoTooltip title="As ingredients" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'siliconesFree'
  },
  talcFree: {
    label: () => (
      <>
        Clean Of Talc <InfoTooltip title="As INCI name" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'talcFree'
  },
  pegFree: {
    label: () => (
      <>
        Clean Of PEG <InfoTooltip title="As INCI name" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'pegFree'
  },
  d5Free: {
    label: 'Clean Of D5',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'd5Free'
  },
  silicaFree: {
    label: () => (
      <>
        Clean Of Silica <InfoTooltip title="As INCI name" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'silicaFree'
  },
  GMO: {
    label: 'Clean Of GMO',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'GMO'
  },
  paraben: {
    label: 'Clean Of Parabens',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'paraben'
  },
  cleanOfPreservatives: {
    label: () => (
      <>
        Clean Of Preservatives{' '}
        <InfoTooltip title="According to Annex V EC Regulation 1223/2009" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'cleanOfPreservatives'
  },
  cleanOfMineralOils: {
    label: () => (
      <>
        Clean Of Mineral Oils
        <InfoTooltip hasMargin={false} title="Derived hydrocarbons" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'cleanOfMineralOils'
  },
  cleanOfFragrance: {
    label: 'Clean Of Fragrance',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'cleanOfFragrance'
  }
}
export const PRODUCT_IC_SUMMARY_MONO_TABLE_CONFIG: ITableConfig = {
  industrialCode: {
    label: 'Industrial Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaCodeDEVEX'
  },
  labReferenceCode: {
    label: 'Lab Reference Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'labReferenceCode'
  },
  color: {
    label: 'Color',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'color'
  },
  icFinish: {
    label: 'IC Finish',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'finish'
  },
  countryCheckComplianceNotes: {
    label: 'Industrial Code Country Check Compliance Notes',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'countryCheckComplianceNotes'
  },
  csarRegistered: {
    label: 'CSAR Registered',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'csarRegistered'
  },
  vegan: {
    label: () => (
      <>
        Vegan <InfoTooltip title="Formula does not contain animal origin ingredients" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'vegan'
  },
  veganNotes: {
    label: 'Vegan Notes',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'veganNotes'
  },
  RSPO: {
    label: 'RSPO',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'RSPO'
  },
  microplasticFree: {
    label: 'Clean Of Microplastic',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'microplasticFree'
  },
  microplasticFreeNotes: {
    label: 'Clean Of Microplastic Notes',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'microplasticFreeNotes'
  },
  siliconesFree: {
    label: () => (
      <>
        Clean Of Silicones <InfoTooltip title="As ingredients" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'siliconesFree'
  },
  talcFree: {
    label: () => (
      <>
        Clean Of Talc <InfoTooltip title="As INCI name" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'talcFree'
  },
  pegFree: {
    label: () => (
      <>
        Clean Of PEG <InfoTooltip title="As INCI name" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'pegFree'
  },
  d5Free: {
    label: 'Clean Of D5',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'd5Free'
  },
  silicaFree: {
    label: () => (
      <>
        Clean Of Silica <InfoTooltip title="As INCI name" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'silicaFree'
  },
  GMO: {
    label: 'Clean Of GMO',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'GMO'
  },
  paraben: {
    label: 'Clean Of Parabens',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'paraben'
  },
  cleanOfPreservatives: {
    label: () => (
      <>
        Clean Of Preservatives{' '}
        <InfoTooltip title="According to Annex V EC Regulation 1223/2009" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'cleanOfPreservatives'
  },
  cleanOfMineralOils: {
    label: () => (
      <>
        Clean Of Mineral Oils
        <InfoTooltip hasMargin={false} title="Derived hydrocarbons" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'cleanOfMineralOils'
  },
  cleanOfFragrance: {
    label: 'Clean Of Fragrance',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'cleanOfFragrance'
  }
}

export const FSCHEMA_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  SPFDetails: {
    dataPath: 'SPFDetails',
    hide: true
  },
  formula: {
    dataPath: 'formula',
    hide: true
  },
  status: {
    dataPath: 'status',
    hide: true
  },
  labReferenceName: {
    dataPath: 'labReferenceName',
    hide: true
  },
  updatedAt: {
    dataPath: 'updatedAt',
    hide: true
  },
  regulatoryLastUpdate: {
    dataPath: 'regulatoryLastUpdate',
    hide: true
  },
  EUCompliance: {
    dataPath: 'EUCompliance',
    hide: true
  },
  USCompliance: {
    dataPath: 'USCompliance',
    hide: true
  },
  chinaCompliance: {
    dataPath: 'chinaCompliance',
    hide: true
  },
  JPCompliance: {
    dataPath: 'JPCompliance',
    hide: true
  },
  koreaCompliance: {
    dataPath: 'koreaCompliance',
    hide: true
  },
  cleanAtSephora: {
    dataPath: 'cleanAtSephora',
    hide: true
  },
  cleanAtUlta: {
    dataPath: 'cleanAtUlta',
    hide: true
  },
  intercosCleanBL: {
    dataPath: 'intercosCleanBL',
    hide: true
  },
  naturalPercentage: {
    dataPath: 'naturalPercentage',
    hide: true
  },
  formulaSchemaCode: {
    label: 'Formula Schema Code',
    dataType: TABLE_DATA_TYPE.LINK,
    dataPath: 'formulaSchemaCode',
    pathname: AppRoutes.VAULT_FORMULA_SCHEMA_DETAILS,
    newTab: true,
    fixed: 'left'
  },
  name: {
    label: 'Formula Schema Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'name'
  },
  launchYear: {
    label: 'FS Launch Year',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'launchYear'
  },
  collection: {
    label: 'FS Collection',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'collection'
  },
  fsFinish: {
    label: 'FS Finish',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'fsFinish'
  },
  fsSpf: {
    label: 'FS SPF',
    dataType: TABLE_DATA_TYPE.BOOL,
    dataPath: 'fsSpf'
  },
  otc: {
    label: 'OTC',
    dataType: TABLE_DATA_TYPE.BOOL,
    dataPath: 'otc'
  }
}

export const ENTITY_FORMULA_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  color: {
    dataPath: 'color',
    hide: true
  },
  icFinish: {
    dataPath: 'icFinish',
    hide: true
  },
  icPao: {
    dataPath: 'icPao',
    hide: true
  },
  icShelfLife: {
    dataPath: 'icShelfLife',
    hide: true
  },
  formulaCodeSAP: {
    dataPath: 'formulaCodeSAP',
    hide: true
  },
  countryCheckComplianceNotes: {
    dataPath: 'countryCheckComplianceNotes',
    hide: true
  },
  vegan: {
    dataPath: 'vegan',
    hide: true
  },
  veganNotes: {
    dataPath: 'veganNotes',
    hide: true
  },
  RSPO: {
    dataPath: 'RSPO',
    hide: true
  },
  microplasticFree: {
    dataPath: 'microplasticFree',
    hide: true
  },
  microplasticFreeNotes: {
    dataPath: 'microplasticFreeNotes',
    hide: true
  },
  siliconesFree: {
    dataPath: 'siliconesFree',
    hide: true
  },
  talcFree: {
    dataPath: 'talcFree',
    hide: true
  },
  pegFree: {
    dataPath: 'pegFree',
    hide: true
  },
  d5Free: {
    dataPath: 'd5Free',
    hide: true
  },
  silicaFree: {
    dataPath: 'silicaFree',
    hide: true
  },
  GMO: {
    dataPath: 'GMO',
    hide: true
  },
  paraben: {
    dataPath: 'paraben',
    hide: true
  },
  cleanOfPreservatives: {
    dataPath: 'cleanOfPreservatives',
    hide: true
  },
  cleanOfMineralOils: {
    dataPath: 'cleanOfMineralOils',
    hide: true
  },
  status: {
    dataPath: 'status',
    hide: true
  },
  formulaSchemaCode: {
    label: 'Formula Schema Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaSchemaCode'
  },
  formulaCodeDEVEX: {
    label: 'Select Formula',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaCodeDEVEX'
  },
  formulaName: {
    label: 'Formula Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaName'
  }
}

export const FORMULA_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  color: {
    dataPath: 'color',
    hide: true
  },
  icFinish: {
    dataPath: 'icFinish',
    hide: true
  },
  icPao: {
    dataPath: 'icPao',
    hide: true
  },
  icShelfLife: {
    dataPath: 'icShelfLife',
    hide: true
  },
  formulaCodeSAP: {
    dataPath: 'formulaCodeSAP',
    hide: true
  },
  countryCheckComplianceNotes: {
    dataPath: 'countryCheckComplianceNotes',
    hide: true
  },
  vegan: {
    dataPath: 'vegan',
    hide: true
  },
  veganNotes: {
    dataPath: 'veganNotes',
    hide: true
  },
  RSPO: {
    dataPath: 'RSPO',
    hide: true
  },
  microplasticFree: {
    dataPath: 'microplasticFree',
    hide: true
  },
  microplasticFreeNotes: {
    dataPath: 'microplasticFreeNotes',
    hide: true
  },
  siliconesFree: {
    dataPath: 'siliconesFree',
    hide: true
  },
  talcFree: {
    dataPath: 'talcFree',
    hide: true
  },
  pegFree: {
    dataPath: 'pegFree',
    hide: true
  },
  d5Free: {
    dataPath: 'd5Free',
    hide: true
  },
  silicaFree: {
    dataPath: 'silicaFree',
    hide: true
  },
  GMO: {
    dataPath: 'GMO',
    hide: true
  },
  paraben: {
    dataPath: 'paraben',
    hide: true
  },
  cleanOfPreservatives: {
    dataPath: 'cleanOfPreservatives',
    hide: true
  },
  cleanOfMineralOils: {
    dataPath: 'cleanOfMineralOils',
    hide: true
  },
  status: {
    dataPath: 'status',
    hide: true
  },
  formulaCodeDEVEX: {
    label: 'Industrial Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaCodeDEVEX'
  },
  labReferenceCode: {
    label: 'Lab Reference Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'labReferenceCode'
  },
  formulaName: {
    label: 'Formula Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaName'
  }
}

export const FSCHEMA_DETAIL_FORMULA: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  industrialCode: {
    label: 'Industrial Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaCodeDEVEX'
  },
  labReferenceCode: {
    label: 'Lab Reference Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'labReferenceCode'
  },
  name: {
    label: 'Formula Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'name'
  },
  finish: {
    label: 'IC Finish',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'finish'
  },
  color: {
    label: 'Color',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'color'
  },
  status: {
    label: 'Status',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'status'
  },
  devexApplicationArea: {
    label: 'DevEx Application Area',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'devexApplicationArea'
  },
  icPao: {
    label: 'IC PAO',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'icPao'
  },
  icShelfLife: {
    label: 'IC Shelf Life',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'icShelfLife'
  },
  icSpf: {
    label: 'IC SPF Test Result',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'icSpf'
  },
  countryCheckComplianceNotes: {
    label: 'Industrial Code Country Check Compliance Notes',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'countryCheckComplianceNotes'
  },
  csarRegistered: {
    label: 'CSAR Registered',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'csarRegistered'
  },
  vegan: {
    label: () => (
      <>
        Vegan <InfoTooltip title="Formula does not contain animal origin ingredients" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'vegan'
  },
  veganNotes: {
    label: 'Vegan Notes',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'veganNotes'
  },
  RSPO: {
    label: 'RSPO',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'RSPO'
  },
  microplasticFree: {
    label: 'Clean Of Microplastic',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'microplasticFree'
  },
  microplasticFreeNotes: {
    label: 'Clean Of Microplastic Notes',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'microplasticFreeNotes'
  },
  siliconesFree: {
    label: () => (
      <>
        Clean Of Silicones <InfoTooltip title="As ingredients" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'siliconesFree'
  },
  talcFree: {
    label: () => (
      <>
        Clean Of Talc <InfoTooltip title="As INCI name" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'talcFree'
  },
  pegFree: {
    label: () => (
      <>
        Clean Of PEG <InfoTooltip title="As INCI name" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'pegFree'
  },
  d5Free: {
    label: 'Clean Of D5',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'd5Free'
  },
  silicaFree: {
    label: () => (
      <>
        Clean Of Silica <InfoTooltip title="As INCI name" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'silicaFree'
  },
  GMO: {
    label: 'Clean Of GMO',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'GMO'
  },
  paraben: {
    label: 'Clean Of Parabens',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'paraben'
  },
  cleanOfPreservatives: {
    label: () => (
      <>
        Clean Of Preservatives{' '}
        <InfoTooltip title="According to Annex V EC Regulation 1223/2009" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'cleanOfPreservatives'
  },
  cleanOfMineralOils: {
    label: () => (
      <>
        Clean Of Mineral Oils
        <InfoTooltip hasMargin={false} title="Derived hydrocarbons" />
      </>
    ),
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'cleanOfMineralOils'
  },
  cleanOfFragrance: {
    label: 'Clean Of Fragrance',
    dataType: TABLE_DATA_TYPE.THREESTATE,
    dataPath: 'cleanOfFragrance'
  },
  lastUpdate: {
    label: 'Last Update',
    dataType: TABLE_DATA_TYPE.DATE,
    dataPath: 'updatedAt'
  }
}
