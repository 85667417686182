import { TABLE_DATA_TYPE } from '../../components/Table/constants'
import { ITableConfig } from '../../components/Table/types'
import { AppRoutes } from '../../pages/constants'
import { IDropdown } from '../types'
import { ITypeToProdForm, RProductForms } from './model'

export const PRODUCTS_TABLE_CONFIG: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  productCode: {
    label: 'Product Code',
    dataType: TABLE_DATA_TYPE.LINK,
    dataPath: 'productCode',
    pathname: AppRoutes.STORE_PRODUCTS_DETAILS,
    newTab: true,
    fixed: 'left'
  },
  productCategory: {
    label: 'Product Category',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'productCategory'
  },
  productName: {
    label: 'Product Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'finalName'
  },
  productForm: {
    label: 'Product form',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'productForm'
  },
  launchYear: {
    label: 'Launch Year',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'launchYear'
  },
  collection: {
    label: 'Collection',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'collection'
  },
  color: {
    label: 'Color',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'color'
  },
  finish: {
    label: 'Finish',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'finish'
  },
  packagingRef: {
    label: 'PK Intercos Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'packagingRef'
  },
  industrialCode: {
    dataPath: 'industrialCode',
    hide: true
  },
  labCode: {
    dataPath: 'labCode',
    hide: true
  },
  children: {
    label: '',
    dataPath: 'children',
    hide: true
  }
}

// NOTE - still not sure which one will be definetive STORE_PRODUCTS_CHILDREN STORE_PRODUCTS_CHILDREN_NEW for detail
export const STORE_PRODUCTS_CHILDREN_NEW: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  parentId: {
    dataPath: 'parentId',
    hide: true
  },
  formulaSchema: {
    label: 'Formula Schema',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaSchemaCode'
  },
  industrialCode: {
    label: 'Industrial Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'industrialCode'
  },
  labCode: {
    label: 'Lab Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'labCode'
  },
  finalName: {
    label: 'Final Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'finalName'
  },
  applicationArea: {
    label: 'Application Area',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'applicationArea'
  },
  productCategory: {
    label: 'Product Category',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'productCategory'
  },
  color: {
    label: 'Color',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'color'
  },
  finish: {
    label: 'Finish',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'finish'
  },
  launchYear: {
    label: 'Launch Year',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'launchYear'
  },
  collection: {
    label: 'Collection',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'collection'
  },
  family: {
    label: 'Family',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'family'
  }
}
export const STORE_PRODUCTS_CHILDREN: ITableConfig = {
  productCode: {
    label: 'Product Code',
    dataType: TABLE_DATA_TYPE.LINK,
    dataPath: 'productCode',
    pathname: AppRoutes.STORE_PRODUCTS_DETAILS,
    newTab: true,
    fixed: 'left'
  },
  productName: {
    label: 'Product Name',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'finalName'
  },
  labCode: {
    label: 'Lab Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'labCode'
  },
  industrialCode: {
    label: 'Industrial Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'industrialCode'
  },
  itemCode: {
    label: 'Item Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'itemCode'
  },
  productCategory: {
    label: 'Product Category',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'productCategory'
  },
  applicationArea: {
    label: 'Application Area',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'applicationArea'
  },
  productForm: {
    label: 'Product Form',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'productForm'
  },
  usage: {
    label: 'Usage',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'usage'
  },
  launchYear: {
    label: 'Launch Year',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'launchYear'
  },
  collection: {
    label: 'Collection',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'collection'
  },
  colors: {
    label: 'Color',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'color'
  },
  finish: {
    label: 'Finish',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'finish'
  },
  packagingRef: {
    label: 'PK Intercos Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'packagingRef'
  },
  specialEffect: {
    label: 'Special Effect',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'specialEffect'
  },
  panMold: {
    label: 'Pan/Mold',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'panMold'
  },
  pmBu: {
    label: 'PM BU',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'pmBu'
  },
  formulaSchema: {
    label: 'Formula Schema',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'formulaSchema'
  },
  family: {
    label: 'Family',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'family'
  },
  id: {
    label: 'ID',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'id'
  },
  children: {
    label: '',
    dataPath: 'children',
    hide: true
  }
}

export const storeActionsType = {
  search: 'storeProducts/SEARCH',
  fetch: 'storeProducts/FETCH',
  removeFilters: 'storeProducts/REMOVE_FILTER',
  getDetail: 'storeProducts/GET_DETAIL',
  checkItemAvailabiltyById: 'storeProducts/CHECK_ITEM_AVAILABILTY_BY_ID',
  createEntity: 'storeProducts/CREATE_ENTITY',
  updateEntity: 'storeProducts/UPDATE_ENTITY',
  updateEntityChild: 'storeProducts/UPDATE_ENTITY_CHILD',
  deleteEntity: 'storeProducts/DELETE_ENTITY',
  deleteEntityChild: 'storeProducts/DELETE_ENTITY_CHILD',
  addEntityChild: 'storeProducts/ENTITY_ENTITY_CHILD',
  getChild: 'storeProducts/GET_CHILD_DETAIL',
  STORE_PRODUCT_FILTER_TAXONOMIES: 'storeProducts/STORE_PRODUCT_FILTER_TAXONOMIES'
}

export const storeProdTypeDropdown: IDropdown[] = [
  {
    name: 'PRODUCT WITH PACKAGE STANDARD',
    value: 'PRODUCT_WITH_PACKAGE_STANDARD'
  },
  {
    name: 'PRODUCT WITH MULTIPACKAGE',
    value: 'PRODUCT_WITH_MULTIPACKAGE'
  },
  {
    name: 'MULTIBULK WITH PACKAGE',
    value: 'MULTIBULK_WITH_PACKAGE'
  },
  {
    name: 'PALETTE',
    value: 'PALETTE'
  },
  {
    name: 'PACKAGE',
    value: 'PACKAGE'
  },
  {
    name: 'BULK WITHOUT PACKAGE',
    value: 'BULK_WITHOUT_PACKAGE'
  },
  {
    name: 'MULTIBULK WITHOUT PACKAGE',
    value: 'MULTIBULK_WITHOUT_PACKAGE'
  }
]

export const storeProductFormsWithAggregateEntities = [
  'PRODUCT_WITH_MULTIPACKAGE',
  'MULTIPACK',
  'MULTIBULK_WITH_PACKAGE',
  'MULTIBULK',
  'PALETTE',
  'PACKAGE',
  'MULTIBULK_WITHOUT_PACKAGE',
  'MBPANMOLD'
]

export const storeProdFormDropdown: IDropdown[] = RProductForms.map((value) => ({
  name: value,
  value
}))

export const storeProdTypeToForm: ITypeToProdForm = {
  PRODUCT_WITH_MULTIPACKAGE: 'MULTIPACK',
  MULTIBULK_WITH_PACKAGE: 'MULTIBULK',
  PALETTE: 'PALETTE',
  PACKAGE: 'PACKAGE',
  BULK_WITHOUT_PACKAGE: 'BPANMOLD',
  MULTIBULK_WITHOUT_PACKAGE: 'MBPANMOLD'
}

export const productCategoryDrpDwn: IDropdown[] = [
  {
    name: 'BAKED',
    value: 'BAKED'
  },
  {
    name: 'BAKED EXTRUDED',
    value: 'BAKED EXTRUDED'
  },
  {
    name: 'COMPACT POWDER',
    value: 'COMPACT POWDER'
  }
]

export const productFormDrpDwn: IDropdown[] = [
  {
    name: 'BAKED',
    value: 'BAKED'
  },
  {
    name: 'BAKED EXTRUDED',
    value: 'BAKED EXTRUDED'
  },
  {
    name: 'COMPACT POWDER',
    value: 'COMPACT POWDER'
  },
  {
    name: 'EMULSION',
    value: 'EMULSION'
  },
  {
    name: 'EXTRUDED',
    value: 'EXTRUDED'
  },
  {
    name: 'FLUID ANHYDROUS',
    value: 'FLUID ANHYDROUS'
  },
  {
    name: 'INK',
    value: 'INK'
  },
  {
    name: 'KIT',
    value: 'KIT'
  },
  {
    name: 'LOOSE',
    value: 'LOOSE'
  },
  {
    name: 'MATRIX POWDER',
    value: 'MATRIX POWDER'
  },
  {
    name: 'OTHER FORMS',
    value: 'OTHER FORMS'
  },
  {
    name: 'POURED ANHYDROUS',
    value: 'POURED ANHYDROUS'
  },
  {
    name: 'PRISMA',
    value: 'PRISMA'
  },
  {
    name: 'SLURRY INJECTION',
    value: 'SLURRY INJECTION'
  },
  {
    name: 'SOLID EMULSION',
    value: 'SOLID EMULSION'
  },
  {
    name: 'SOLID GEL',
    value: 'SOLID GEL'
  },
  {
    name: 'MULTIPACK',
    value: 'MULTIPACK'
  },
  {
    name: 'MULTIBULK',
    value: 'MULTIBULK'
  },
  {
    name: 'PACKAGE',
    value: 'PACKAGE'
  },
  {
    name: 'PALETTE',
    value: 'PALETTE'
  },
  {
    name: 'PACKAGE',
    value: 'PACKAGE'
  },
  {
    name: 'AQUEOUS GEL',
    value: 'AQUEOUS GEL'
  },
  {
    name: 'NAIL LACQUER',
    value: 'NAIL LACQUER'
  }
]

export const colorDropDown: IDropdown[] = [
  {
    name: 'LIGHT BLUE',
    value: 'LIGHT BLUE'
  },
  {
    name: 'BLUE',
    value: 'BLUE'
  },
  {
    name: 'BROWN',
    value: 'BROWN'
  },
  {
    name: 'GREEN',
    value: 'GREEN'
  },
  {
    name: 'INCOLOR',
    value: 'INCOLOR'
  },
  {
    name: 'BEIGE',
    value: 'BEIGE'
  },
  {
    name: 'MAGICOLOR',
    value: 'MAGICOLOR'
  },
  {
    name: 'MULTICOLOR',
    value: 'MULTICOLOR'
  },
  {
    name: 'BLACK',
    value: 'BLACK'
  },
  {
    name: 'ORANGE',
    value: 'ORANGE'
  },
  {
    name: 'PINK',
    value: 'PINK'
  },
  {
    name: 'GRAY/SILVER',
    value: 'GRAY/SILVER'
  },
  {
    name: 'RED',
    value: 'RED'
  },
  {
    name: 'VIOLET',
    value: 'VIOLET'
  },
  {
    name: 'WHITE',
    value: 'WHITE'
  },
  {
    name: 'GENERIC PIGMENT / PEARLS',
    value: 'GENERIC PIGMENT / PEARLS'
  },
  {
    name: 'YELLOW / GOLD',
    value: 'YELLOW / GOLD'
  },
  {
    name: 'NUDE',
    value: 'NUDE'
  },
  {
    name: 'FAIR BEIGE',
    value: 'FAIR BEIGE'
  },
  {
    name: 'LIGHT BEIGE',
    value: 'LIGHT BEIGE'
  },
  {
    name: 'BRONZE',
    value: 'BRONZE'
  },
  {
    name: 'DARK BROWN',
    value: 'DARK BROWN'
  },
  {
    name: 'DEEP RICHE BROWN',
    value: 'DEEP RICHE BROWN'
  }
]

export const applicationAreaDrpDwn: IDropdown[] = [
  {
    name: 'EYES',
    value: 'EYES'
  },
  {
    name: 'FACE',
    value: 'FACE'
  },
  {
    name: 'LIPS',
    value: 'LIPS'
  },
  {
    name: 'NAIL',
    value: 'NAIL'
  },
  {
    name: 'KIT',
    value: 'KIT'
  }
]

export const collectionDropDown: IDropdown[] = [
  {
    name: 'REINTEGRO',
    value: 'REINTEGRO'
  },
  {
    name: 'FLASH STORIES',
    value: 'FLASH STORIES'
  },
  {
    name: 'GEN F 1',
    value: 'GEN F 1'
  },
  {
    name: 'GEN F 2',
    value: 'GEN F 2'
  },
  {
    name: 'GEN F 3',
    value: 'GEN F 3'
  },
  {
    name: 'GEN F 4',
    value: 'GEN F 4'
  },
  {
    name: 'GEN F 5',
    value: 'GEN F 5'
  },
  {
    name: 'GEN F 6',
    value: 'GEN F 6'
  },
  {
    name: 'GEN F 7',
    value: 'GEN F 7'
  },
  {
    name: 'GEN F 8',
    value: 'GEN F 8'
  },
  {
    name: 'GEN F 9',
    value: 'GEN F 9'
  },
  {
    name: 'GEN F 10',
    value: 'GEN F 10'
  },
  {
    name: 'GEN F 11',
    value: 'GEN F 11'
  },
  {
    name: 'MULA',
    value: 'MULA'
  },
  {
    name: 'INDIE DROP',
    value: 'INDIE DROP'
  },
  {
    name: 'PRISMA MASS',
    value: 'PRISMA MASS'
  },
  {
    name: 'BEAUTY A PORTER',
    value: 'BEAUTY A PORTER'
  },
  {
    name: 'CLEAN COLLECTION',
    value: 'CLEAN COLLECTION'
  },
  {
    name: 'COLOR COLLECTION',
    value: 'COLOR COLLECTION'
  },
  {
    name: 'COLLEZIONE 2019',
    value: 'COLLEZIONE 2019'
  },
  {
    name: 'COLLEZIONE 2020',
    value: 'COLLEZIONE 2020'
  },
  {
    name: 'NATURAL COLLECTION',
    value: 'NATURAL COLLECTION'
  },
  {
    name: 'COLLEZIONE TASSELLI',
    value: 'COLLEZIONE TASSELLI'
  },
  {
    name: 'ANIMAL INSTINCT',
    value: 'ANIMAL INSTINCT'
  },
  {
    name: 'DUBAI COLLECTION',
    value: 'DUBAI COLLECTION'
  },
  {
    name: 'HALL OF FAME',
    value: 'HALL OF FAME'
  },
  {
    name: 'TREND STORIES',
    value: 'TREND STORIES'
  },
  {
    name: 'FANTASTIC COLLECTION',
    value: 'FANTASTIC COLLECTION'
  },
  {
    name: 'MASCARA ACADEMY',
    value: 'MASCARA ACADEMY'
  },
  {
    name: 'MURO POLVERI',
    value: 'MURO POLVERI'
  },
  {
    name: 'PRISMA READY MADE LIBRARY',
    value: 'PRISMA READY MADE LIBRARY'
  },
  {
    name: 'RADAR COLLECTION',
    value: 'RADAR COLLECTION'
  },
  {
    name: 'PROMO HL',
    value: 'PROMO HL'
  },
  {
    name: 'REVOLUTION',
    value: 'REVOLUTION'
  },
  {
    name: 'REVOLUTION RELOADED',
    value: 'REVOLUTION RELOADED'
  },
  {
    name: 'REVOLUTION NOW',
    value: 'REVOLUTION NOW'
  },
  {
    name: 'ASIA REVOLUTION',
    value: 'ASIA REVOLUTION'
  },
  {
    name: 'SUPERHERO ASIA',
    value: 'SUPERHERO ASIA'
  },
  {
    name: 'WOODPEDIA',
    value: 'WOODPEDIA'
  },
  {
    name: 'TREND',
    value: 'TREND'
  },
  {
    name: 'OTHER COLLECTION',
    value: 'OTHER COLLECTION'
  }
]

export const entityProductCatDropdown: IDropdown[] = [
  {
    name: 'BALM',
    value: 'BALM'
  },
  {
    name: 'SKIN TINT / BB',
    value: 'SKIN TINT / BB'
  },
  {
    name: 'BLUSH',
    value: 'BLUSH'
  },
  {
    name: 'BRONZER',
    value: 'BRONZER'
  },
  {
    name: 'CONCEALER',
    value: 'CONCEALER'
  },
  {
    name: 'EYEBROW',
    value: 'EYEBROW'
  },
  {
    name: 'EYELINER',
    value: 'EYELINER'
  },
  {
    name: 'EYESHADOW',
    value: 'EYESHADOW'
  },
  {
    name: 'FOUNDATION',
    value: 'FOUNDATION'
  },
  {
    name: 'GLOSS',
    value: 'GLOSS'
  },
  {
    name: 'KAJAL',
    value: 'KAJAL'
  },
  {
    name: 'KIT - MIX',
    value: 'KIT - MIX'
  },
  {
    name: 'KIT ONLY PANS',
    value: 'KIT ONLY PANS'
  },
  {
    name: 'LIPLINER',
    value: 'LIPLINER'
  },
  {
    name: 'LIPSTICK',
    value: 'LIPSTICK'
  },
  {
    name: 'MASCARA',
    value: 'MASCARA'
  },
  {
    name: 'POWDER FDT',
    value: 'POWDER FDT'
  },
  {
    name: 'POWDER FDT (WET AND DRY)',
    value: 'POWDER FDT (WET AND DRY)'
  },
  {
    name: 'PRESSED POWDER',
    value: 'PRESSED POWDER'
  },
  {
    name: 'PRIMER',
    value: 'PRIMER'
  },
  {
    name: 'ALLOVER',
    value: 'ALLOVER'
  },
  {
    name: 'HIGHLIGHTER',
    value: 'HIGHLIGHTER'
  },
  {
    name: 'FACE MIST',
    value: 'FACE MIST'
  },
  {
    name: 'NAIL LACQUER CLEARS',
    value: 'NAIL LACQUER CLEARS'
  },
  {
    name: 'NAIL CARE',
    value: 'NAIL CARE'
  },
  {
    name: 'NAIL LACQUER REMOVER',
    value: 'NAIL LACQUER REMOVER'
  }
]

export const STORE_PROD_DYNAMIC_TAXONOMIES = ['applicationArea', 'productForm']
