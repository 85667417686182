export const adminActionTypes = {
  ADMINISTRATION_IMPORT_SUPPLIER: 'administration/ADMINISTRATION_IMPORT_SUPPLIER',
  ADMINISTRATION_IMPORT_ANAGRAPHIC: 'administration/ADMINISTRATION_IMPORT_ANAGRAPHIC',
  ADMINISTRATION_IMPORT_PRICE: 'administration/ADMINISTRATION_IMPORT_PRICE',
  ADMINISTRATION_IMPORT_RELATIONS: 'administration/ADMINISTRATION_IMPORT_RELATIONS',
  ADMINISTRATION_GET_ATTACHMENTS: 'administration/ADMINISTRATION_GET_ATTACHMENTS',
  ADMINISTRATION_GET_ATTACHMENTS_DETAIL:
    'administration/ADMINISTRATION_GET_ATTACHMENTS_DETAIL',
  ADMINISTRATION_UPDATE_ATTACHMENT: 'administration/ADMINISTRATION_UPDATE_ATTACHMENT',
  ADMINISTRATION_CREATE_ATTACHMENTS: 'administration/ADMINISTRATION_CREATE_ATTACHMENTS',
  ADMINISTRATION_CREATE_SUPPLIER: 'administration/ADMINISTRATION_CREATE_SUPPLIER',
  ADMINISTRATION_SEARCH_SUPPLIER: 'administration/ADMINISTRATION_SEARCH_SUPPLIER',
  ADMINISTRATION_GET_SUPPLIER_DETAIL: 'administration/ADMINISTRATION_GET_SUPPLIER_DETAIL',
  ADMINISTRATION_UPDATE_SUPPLIER: 'administration/ADMINISTRATION_UPDATE_SUPPLIER',
  ADMINISTRATION_CHECK_SUPPLIER: 'administration/findSapCode',
  ADMINISTRATION_GET_CURRENCY_RATES: 'administration/getCurrencyRates',
  ADMINISTRATION_SET_CURRENCY_RATE: 'administration/setCurrencyRate',
  ADMINISTRATION_GET_GROUP_NAMES: 'administration/ADMINISTRATION_GET_GROUP_NAMES'
}
