import styled from 'styled-components'
import { flexDirection, justifyContent, borderBottom, borderTop } from 'styled-system'
import { largeSizeWidth, mediumSizeWidth, smallSizeWidth } from '../../assets/mixins'
import { TStyledBoxWrapperProps } from './types'

export const StyledBoxWrapper = styled.div<TStyledBoxWrapperProps>`
  display: flex;
  align-items: flex-start;
  ${flexDirection}
  ${justifyContent}
  ${borderBottom}
  ${borderTop}
  flex-wrap: wrap;
  z-index: 2;
  width: 100%;
  ${({ enableMargin }) => enableMargin && { marginTop: '8%' }}

  > div {
    min-width: auto;
    ${({ columnCount }) => !!columnCount && `width: ${100 / columnCount}%`};

    ${({ enablePadding }) => enablePadding && { padding: '1%' }}
    @media (min-width: ${smallSizeWidth}px) {
      ${({ size }) => `width: ${100 / (size?.sm || 2)}%`};
    }
    @media (min-width: ${mediumSizeWidth}px) {
      ${({ size }) => `width: ${100 / (size?.md || 3)}%`};
    }
    @media (min-width: ${largeSizeWidth}px) {
      ${({ size }) => `width: ${100 / (size?.lg || 4)}%`};
    }
  }

  > .ant-form-item {
    ${({ enablePadding }) => enablePadding && { padding: '0 1%' }}
  }

  ${({ sticky }) =>
    !!sticky &&
    `
      position: sticky;
      left: 0;
      z-index: 2;
  `}

  ${({ sticky }) => sticky === 'top' && 'top: 16px;'}
  ${({ sticky }) => sticky === 'bottom' && 'bottom: 0;'}
`
