import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import homeBackground from '../../assets/images/home-background.png'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { BoxWrapper } from '../../components/BoxWrapper'
import { ButtonCard } from '../../components/ButtonCard'
import { Text } from '../../components/Text'
import { PageContent } from '../../containers'
import { appSliceActions } from '../../features/app'
import { selectAppError } from '../../features/app/selectors'
import { selectPermissions } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { routeVisibilityHelper } from '../../utils'
import { HOME_LINKS } from '../constants'

const HomePage: FC = () => {
  const dispatch = useDispatch()
  const appError = useSelector(selectAppError)
  const permissions = useSelector(selectPermissions)

  useEffect(() => {
    return () => {
      dispatch(appSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredRoutes = HOME_LINKS.filter(({ path }) => {
    const isVisible = routeVisibilityHelper(path, permissions)
    return isVisible
  })

  return (
    <GenericLayout error={appError}>
      <PageContent
        backgroundImage={homeBackground}
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        contentCentered
      >
        <BoxWrapper columnCount={4} enablePadding justify="center">
          {filteredRoutes.map(({ path, label, title }, idx) => (
            <ButtonCard key={`${path}-${idx}`} path={path} title="">
              <Text text={label} variant="h4" />
              <Text text={title} variant="h6" />
            </ButtonCard>
          ))}
        </BoxWrapper>
      </PageContent>
    </GenericLayout>
  )
}

export default HomePage
