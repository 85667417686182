import { isArray } from 'lodash'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent, ProductSearchForm } from '../../containers'
import { fSchemaActions } from '../../features/formulaSchema'
import { selectProdIsLoading } from '../../features/product/selectors'
import { productsSliceActions } from '../../features/products'
import { selectProductsError, selectTempFilter } from '../../features/products/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'
import { ButtonWrapper, buttonWrapperHeight } from './styled'

const ProductRTGSearch: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const productsError = useSelector(selectProductsError)
  const tempFilter = useSelector(selectTempFilter)
  const isLoading = useSelector(selectProdIsLoading)

  const checkIfDisabled = () =>
    Object.values(tempFilter).every(
      (value) =>
        value === null ||
        value === '' ||
        value === undefined ||
        (isArray(value) && !value.length)
    ) || isLoading

  const searchHandler = () => {
    dispatch(productsSliceActions.applyFilters())
    navigate(AppRoutes.VAULT_PRODUCTS_RESULTS)
  }

  useEffect(() => {
    dispatch(fSchemaActions.getTestedClaimTaxonomy())
    dispatch(
      productsSliceActions.updateTempFilter({ key: 'status', value: 'READY_TO_GO' })
    )
    return () => {
      dispatch(productsSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GenericLayout error={productsError}>
      <PageContent
        footerHeight={footerHeight + buttonWrapperHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <SectionTitle value="Products" />
        <Divider />
        {isLoading && <Loader />}
        <ProductSearchForm />
      </PageContent>
      <ButtonWrapper>
        <Button
          label="Search"
          onClick={searchHandler}
          disabled={checkIfDisabled()}
          enableKeyPress
        />
      </ButtonWrapper>
    </GenericLayout>
  )
}

export default ProductRTGSearch
