import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isObject } from 'lodash'
import moment from 'moment'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { AppState, IPopUp, TAppLoaders } from './model'

const year = moment().format('YYYY')

const initialState: AppState = {
  loading: false,
  optionsYears: new Array(4)
    .fill('')
    .map((_, i) => ({ name: Number(year) - i, value: Number(year) - i })),
  error: {},
  loaders: {
    page: false,
    modal: false
  },
  popUp: {
    isOpen: false,
    key: ''
  }
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state: AppState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setError: (state: AppState, { payload }: PayloadAction<IError | {}>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: AppState) => {
      state.error = initialState.error
    },
    setModalKey(state, { payload }: PayloadAction<string | undefined>) {
      state.modalKey = isObject(payload) ? undefined : payload
    },
    closePopUp(state: AppState) {
      state.popUp = initialState.popUp
    },
    openPopUp(state: AppState, { payload }: PayloadAction<IPopUp>) {
      state.popUp = { ...payload, isOpen: true }
    },
    hidePopUp(state: AppState) {
      state.popUp = { ...state.popUp, isOpen: false }
    },
    showPopUp(state: AppState) {
      state.popUp = { ...state.popUp, isOpen: true }
    },
    setLoader: (
      state: AppState,
      { payload }: PayloadAction<{ type?: TAppLoaders; value: boolean }>
    ) => {
      const { type, value } = payload
      if (type) {
        state.loaders[type] = value
      }
    },
    clearLoaders: (state: AppState) => {
      state.loaders = initialState.loaders
    }
  }
})

export default appSlice.reducer

export const appSliceActions = appSlice.actions
