import Form from 'antd/lib/form/Form'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InfoCell, Select } from '../../..'
import { appActions } from '../../../../features/app'
import {
  getAttachmentContentTypesList,
  getDropdownAttachmentStatus,
  getDropdownFilteredContentTypes
} from '../../../../features/packaging/selectors'
import { selectUserEmail } from '../../../../features/users/selectors'
import { BoxWrapper } from '../../../BoxWrapper'
import { Input } from '../../../Form/Input'
import { Modal } from '../../../Modal'
import { IAddAttachmentModalProps } from './types'

const AddAttachmentModal: FC<IAddAttachmentModalProps> = ({
  btnIcon,
  btnType = 'primary',
  dataList,
  updateData = () => {},
  btnLabel = 'Add',
  fileKey
}) => {
  const modalKey = 'package-add-attachment'
  const [filteredAttachment, setFilteredAttachment] = useState({})
  const userMail = useSelector(selectUserEmail)
  const dropdownAttachmentTypes = useSelector(getDropdownFilteredContentTypes)
  const attachmentContentTypesList = useSelector(getAttachmentContentTypesList)
  const dropdownAttachmentStatus = useSelector(getDropdownAttachmentStatus)
  const dispatch = useDispatch()

  const updateFilteredAttachent = (key: string, value: any) => {
    setFilteredAttachment((prevAttachment) => ({ ...prevAttachment, [key]: value }))
  }

  const closeModalHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
    setFilteredAttachment({})
  }

  const confirmAttachments = () => {
    const newAttach = {
      id: Date.now().toString(),
      contentTypeId: get(filteredAttachment, 'contentTypeId', ''),
      contentType: get(filteredAttachment, 'contentType', ''),
      file: get(filteredAttachment, 'file', {}),

      description: get(filteredAttachment, 'description', ''),
      SAPCodes: get(filteredAttachment, 'SAPCodes', []),
      version: 1,
      date: moment(),
      status: get(filteredAttachment, 'status', ''),
      author: userMail
    }
    const newList = dataList.concat(newAttach)
    closeModalHandler()
    updateData('filteredAttachment', newList)
  }

  const fileInfos = get(filteredAttachment, 'file', {})

  const checkIfDisable: () => boolean = () => {
    const selectedContTypeId = get(filteredAttachment, 'contentTypeId', '')
    const contentTypeValue = attachmentContentTypesList.find(
      ({ id }: any) => id === selectedContTypeId
    )
    return !get(contentTypeValue, 'sapCodeEnabled', false)
  }

  const checkIfButtonDisable = () => {
    return (
      !get(filteredAttachment, 'file', false) ||
      !get(filteredAttachment, 'status', false) ||
      !get(filteredAttachment, 'contentType', false)
    )
  }

  return (
    <Modal
      btnName={btnLabel}
      size="medium"
      modalKey={modalKey}
      title="Add Attachments"
      paddingBody="0px"
      btnIcon={btnIcon}
      btnType={btnType}
      footer={[
        <Button key="cancel" label="Cancel" variant="text" onClick={closeModalHandler} />,
        <Button
          key="ok"
          label="Ok"
          onClick={confirmAttachments}
          disabled={checkIfButtonDisable()}
        />
      ]}
      centered
    >
      <Form layout="vertical">
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <InfoCell
            inputType="upload"
            label="attachment"
            value={get(fileInfos, 'value', '')}
            dataType="image"
            inputConfig={{
              fileKey,
              topMessage: 'Drop or browse the package attachment',
              fileType: 'any',
              bottomMessage: '',
              onChange: (value: any) => {
                updateFilteredAttachent('file', {
                  ...fileInfos,
                  ...value,
                  name: get(fileInfos, 'name', '') || get(value, 'name', '')
                })
              },
              enableCharRule: true
            }}
            isEditing
          />
        </BoxWrapper>
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <Input
            label="Name"
            value={get(fileInfos, 'name', '')}
            onChange={(value) =>
              updateFilteredAttachent('file', { ...fileInfos, name: value })
            }
            placeholder={get(fileInfos, 'name', '')}
            disabled={isEmpty(fileInfos)}
          />
        </BoxWrapper>
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <Input
            label="Description"
            value={get(filteredAttachment, 'description', '')}
            onChange={(value) => updateFilteredAttachent('description', value)}
            placeholder="Max 250 char"
          />
        </BoxWrapper>
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <Select
            items={dropdownAttachmentStatus}
            value={get(filteredAttachment, 'status', '')}
            label={'Status'}
            onChange={({ value }) => updateFilteredAttachent('status', value)}
            orderItems
            orderKey="name"
          />
        </BoxWrapper>
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <Select
            items={dropdownAttachmentTypes}
            value={get(filteredAttachment, 'contentType', '')}
            label={'Content Type'}
            onChange={({ value }) => {
              const selectedContType = attachmentContentTypesList.find(
                ({ id }: { id: string }) => value === id
              )
              const contentTypeLabel = get(selectedContType, 'value', '')
              updateFilteredAttachent('contentType', contentTypeLabel)
              updateFilteredAttachent('SAPCodes', [])
              updateFilteredAttachent('contentTypeId', value)
            }}
            orderItems
            orderKey="name"
          />
        </BoxWrapper>
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <InfoCell
            dataType="tags"
            inputType="input-tag"
            value={get(filteredAttachment, 'SAPCodes', [])}
            label="Sap Codes"
            isEditing
            inputConfig={{
              onChange: (value: any) => updateFilteredAttachent('SAPCodes', value),
              disabled: checkIfDisable()
            }}
          />
        </BoxWrapper>
      </Form>
    </Modal>
  )
}

export default AddAttachmentModal
