import { createAction } from '@reduxjs/toolkit'
import { adminActionTypes } from './constants'
import { INewAttachment, INewSupplier } from './model'

export const administrationActions = {
  importSupplier: createAction(adminActionTypes.ADMINISTRATION_IMPORT_SUPPLIER),
  importAnagraphic: createAction(adminActionTypes.ADMINISTRATION_IMPORT_ANAGRAPHIC),
  importPrice: createAction(adminActionTypes.ADMINISTRATION_IMPORT_PRICE),
  importRelations: createAction(adminActionTypes.ADMINISTRATION_IMPORT_RELATIONS),
  getAttachments: createAction(adminActionTypes.ADMINISTRATION_GET_ATTACHMENTS),
  getAttachmentDetail: createAction<{ id: string }>(
    adminActionTypes.ADMINISTRATION_GET_ATTACHMENTS_DETAIL
  ),
  checkSupplierSapCode: createAction<{ value: string }>(
    adminActionTypes.ADMINISTRATION_CHECK_SUPPLIER
  ),
  updateAttachment: createAction<{
    attachData: Record<string, any>
    successCb?: () => void
  }>(adminActionTypes.ADMINISTRATION_UPDATE_ATTACHMENT),
  createAttachment: createAction<{ attachData: INewAttachment }>(
    adminActionTypes.ADMINISTRATION_CREATE_ATTACHMENTS
  ),
  createSupplier: createAction<{ supplierData: INewSupplier }>(
    adminActionTypes.ADMINISTRATION_CREATE_SUPPLIER
  ),
  searchSuppliers: createAction(adminActionTypes.ADMINISTRATION_SEARCH_SUPPLIER),
  getSupplierDetail: createAction<{ id: string }>(
    adminActionTypes.ADMINISTRATION_GET_SUPPLIER_DETAIL
  ),
  updateSupplier: createAction<{
    id: string
    data: Record<string, any>
    successCb?: () => void
  }>(adminActionTypes.ADMINISTRATION_UPDATE_SUPPLIER),
  getCurrencyRates: createAction(adminActionTypes.ADMINISTRATION_GET_CURRENCY_RATES),
  setCurrencyRate: createAction<{ id: string; data: string }>(
    adminActionTypes.ADMINISTRATION_SET_CURRENCY_RATE
  ),
  getGroupNames: createAction(adminActionTypes.ADMINISTRATION_GET_GROUP_NAMES)
}
