import { FC } from 'react'
import { Tag } from '../../../../components/Tag'
import { SpiderChartModalTitleContainer } from './styled'
import { IScatterAndSpiderModalTitleProps } from './types'

const ScatterAndSpiderModalTitle: FC<IScatterAndSpiderModalTitleProps> = ({
  isSiderChart,
  filters
}) => {
  return (
    <SpiderChartModalTitleContainer>
      {isSiderChart ? 'Spider Chart' : 'Products Scatter Plot'}
      {filters.businessSegment && (
        <Tag
          path=""
          closable={false}
          value={`Business Segment: ${filters.businessSegment}`}
        />
      )}
      {filters.productCategory && (
        <Tag
          path=""
          closable={false}
          value={`Product Category: ${filters.productCategory}`}
        />
      )}
    </SpiderChartModalTitleContainer>
  )
}

export default ScatterAndSpiderModalTitle
