import { forwardRef } from 'react'
import { ScatterPlotPdfContainer } from './styled'
import { IScatterPlotPdfProps } from './types'

const ScatterPlotPdf = forwardRef<HTMLDivElement, IScatterPlotPdfProps>((props, ref) => {
  const { title, isVisible = false, chartImgSrc } = props
  return (
    <ScatterPlotPdfContainer isVisible={isVisible} ref={ref}>
      <h1>{title}</h1>
      <img
        src={chartImgSrc}
        width={'100%'}
        className="scatter-chart-image"
        alt="scatter-plot-chart"
      />
    </ScatterPlotPdfContainer>
  )
})

export default ScatterPlotPdf
