import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { SectionTitle } from '../../components'
import { PageContent } from '../../containers'
import { ResultContainer } from '../../containers/StoreProduct/ResultContainer'
import { selectAppError } from '../../features/app/selectors'
import { storeActions } from '../../features/storeProducts'
import { GenericLayout } from '../../layouts'

const StoreProducts: FC = () => {
  const appError = useSelector(selectAppError)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(storeActions.searchStoreProducts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GenericLayout error={appError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <SectionTitle value="Sala Campioni" isBold />
        <ResultContainer />
      </PageContent>
    </GenericLayout>
  )
}

export default StoreProducts
