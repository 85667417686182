import moment from 'moment'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  AZ_DBPRODOTTO_ADMIN,
  AZ_DBPRODOTTO_PACKAGING_EDIT,
  AZ_DBPRODOTTO_PACKAGING_READ,
  AZ_WIKIPACK_SRC
} from '../../../auth-msal'
import { ATTACHMENTS_TABLE_CONFIG } from '../../../components/Packaging/AttachmentsTable/constants'
import AttachSection from '../../../containers/AttachSection'
import { IAttachment } from '../../../features/packaging/model'
import { selectUserEmail, selectUserRole } from '../../../features/users/selectors'
import { IAttachmentsInPackCreateProps } from './types'

const AttachmentsInPackCreate: FC<IAttachmentsInPackCreateProps> = ({
  list,
  onAdd = () => {},
  onDelete = () => {},
  isReadOnly = false
}) => {
  const userRoles = useSelector(selectUserRole)
  const userMail = useSelector(selectUserEmail)

  const canDelete = () => {
    if (
      (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
        !userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
        !userRoles.includes(AZ_DBPRODOTTO_ADMIN)) ||
      userRoles.includes(AZ_WIKIPACK_SRC)
    ) {
      return false
    }

    if (
      (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) ||
        userRoles.includes(AZ_DBPRODOTTO_ADMIN)) &&
      !userRoles.includes(AZ_WIKIPACK_SRC)
    ) {
      return true
    }
    return false
  }

  const addNewAttachmentHandler = (attachData: Partial<IAttachment>) => {
    const newAttach = {
      id: Date.now().toString(),
      contentTypeId: attachData?.contentTypeId || '',
      contentType: attachData?.contentType || '',
      file: attachData?.file ? { ...attachData.file, name: attachData.name } : {},
      description: attachData?.description || '',
      SAPCodes: attachData?.SAPCodes || [],
      version: 1,
      date: moment(),
      status: attachData?.status || '',
      author: userMail
    }
    const newList = list.concat(newAttach)
    onAdd(newList)
  }

  return (
    <AttachSection
      titleConfig={{
        variant: 'h3'
      }}
      dataList={list}
      tableConfig={ATTACHMENTS_TABLE_CONFIG}
      hasDelBtn={!isReadOnly && canDelete()}
      onDelete={onDelete}
      addConfig={
        !isReadOnly && {
          btnLabel: 'Add new one',
          hasStatus: true,
          confirmLabel: 'Ok',
          uploadTopMess: 'Drop or browse the package attachment',
          onAddAttachment: addNewAttachmentHandler,
          hasCancel: true,
          isNameDisableByDefault: true,
          requiredFields: ['file', 'name', 'contentType', 'status'],
          topAddHasIcon: true
        }
      }
    />
  )
}

export default AttachmentsInPackCreate
