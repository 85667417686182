import { canUserSeeTargetPrice } from '../../../auth-msal'
import { replaceDotsWithCommas } from '../../../utils/replaceCharsHelpers'
import { TABLE_DATA_TYPE } from '../../Table/constants'
import { ITableConfig } from '../../Table/types'

export const getPackagingTableConfigMono = (userRoles: any) => {
  const isTargetPriceVisible = canUserSeeTargetPrice(userRoles)

  const PACKAGING_TABLE_CONFIG_MONO: ITableConfig = {
    id: {
      dataPath: 'id',
      hide: true
    },
    type: {
      dataPath: 'type',
      hide: true
    },
    packagingCode: {
      label: 'PK Intercos Code',
      dataType: TABLE_DATA_TYPE.LINK,
      dataPath: 'packagingCode',
      fixed: 'left',
      pathname: '/wikipack/packaging/:id',
      newTab: true,
      showSortable: true
    },
    image: {
      label: 'Image',
      dataType: TABLE_DATA_TYPE.IMAGE,
      dataPath: 'thumbnail.url',
      fixed: 'left',
      hasBorder: true
    },
    status: {
      label: 'Status',
      dataType: TABLE_DATA_TYPE.IMAGE,
      dataPath: 'status',
      hide: true
    },
    supplierRef: {
      label: 'Supplier Pkg Code',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'supplierRef',
      showSortable: true
    },
    testedSupplier: {
      label: 'Packaging Supplier',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'testedSupplier',
      dataIndex: 'testedSupplier.name',
      showSortable: true
    },
    category: {
      label: 'Category',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packageFamilies[0].category',
      showSortable: true
    },
    subcategory: {
      label: 'Subcategory',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packageFamilies[0].subcategory',
      showSortable: true
    },
    size: {
      label: 'Size',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packageFamilies[0].size',
      showSortable: true
    },
    material: {
      label: 'Bulk-Contact Material',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'packageFamilies[0].material',
      showSortable: true
    },
    panNumber: {
      label: 'Pan Number',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packageFamilies[0].panNumber',
      showSortable: true
    },
    tipShape: {
      label: 'Tip Shape',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packageFamilies[0].tipShape',
      showSortable: true
    },
    panShape: {
      label: 'Pan Shape',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'packageFamilies[0].panShape',
      showSortable: true
    },
    shape: {
      label: 'Ext. Shape',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'packageFamilies[0].shape',
      showSortable: true
    },
    technology: {
      label: 'Technology',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packageFamilies[0].technology',
      showSortable: true
    },
    capacityRange: {
      label: 'Capacity Range',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packageFamilies[0].capacityRange',
      showSortable: true
    },
    panSize: {
      label: 'Pan Size',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'packageFamilies[0].panSize',
      showSortable: true
    },
    applicatorAndAccessories: {
      label: 'Applicator and Accessories',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'packageFamilies[0].applicatorAndAccessories',
      showSortable: true
    },
    closure: {
      label: 'Closure',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packageFamilies[0].closure',
      showSortable: true
    },
    mirror: {
      label: 'Mirror',
      dataType: TABLE_DATA_TYPE.THREESTATE,
      dataPath: 'packageFamilies[0].mirror',
      showSortable: true
    },
    airtight: {
      label: 'Airtight',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'packageFamilies[0].airtight',
      showSortable: true
    },
    clean: {
      label: 'Clean',
      dataType: TABLE_DATA_TYPE.THREESTATE,
      dataPath: 'packageFamilies[0].clean',
      showSortable: true
    },
    cleanTypes: {
      label: 'Clean Types',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'packageFamilies[0].cleanTypes'
    },
    freeOfMaterial: {
      label: 'Free-of material',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'packageFamilies[0].freeOfMaterial'
    },
    USDTargetPrice: {
      label: 'Target Price',
      dataType: TABLE_DATA_TYPE.NODE,
      dataPath: 'USDTargetPrice',
      hide: !isTargetPriceVisible,
      renderNode: (data: string) => {
        if (data) {
          return `${replaceDotsWithCommas(data.toString())} USD`
        } else return '-'
      },
      showSortable: true
    },
    isValidNote: {
      label: 'Note',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'isValidNote',
      showSortable: true
    },
    packageFamilies: {
      dataPath: 'packageFamilies',
      hide: true
    },
    neutroName: {
      label: 'Neutro',
      dataPath: 'packageFamilies[0].neutroName',
      dataType: TABLE_DATA_TYPE.NODE,
      renderNode: (data: any, req: any) => {
        if (req.type === 'MULTI') return 'MULTIPACK'
        return data
      },
      showSortable: true
    }
  }
  return PACKAGING_TABLE_CONFIG_MONO
}

// NOTE - probably deprecated
export const getPackagingTableConfigMulti = (userRoles: any) => {
  const PACKAGING_TABLE_CONFIG_MULTI: ITableConfig = {
    id: {
      dataPath: 'id'
    },
    type: {
      dataPath: 'type'
    },
    packagingCode: {
      label: 'PK Intercos Code',
      dataType: TABLE_DATA_TYPE.LINK,
      dataPath: 'packagingCode',
      fixed: 'left',
      pathname: '/wikipack/packaging/:id',
      newTab: true,
      showSortable: true
    },
    image: {
      label: 'Image',
      dataType: TABLE_DATA_TYPE.IMAGE,
      dataPath: 'thumbnail.url',
      fixed: 'left'
    },
    status: {
      label: 'Status',
      dataType: TABLE_DATA_TYPE.IMAGE,
      dataPath: 'status',
      hide: true
    },
    supplierRef: {
      label: 'Supplier Pkg Code',
      dataType: TABLE_DATA_TYPE.IMAGE,
      dataPath: 'supplierRef',
      showSortable: true
    },
    testedSupplier: {
      label: 'Packaging Supplier',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'testedSupplier',
      dataIndex: 'testedSupplier.name',
      showSortable: true
    },
    category: {
      label: 'Category',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'category',
      showSortable: true
    },
    subcategory: {
      label: 'Subcategory',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'subcategory',
      showSortable: true
    },
    size: {
      label: 'Size',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'size',
      showSortable: true
    },
    material: {
      label: 'Material',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'material',
      showSortable: true
    },
    panNumber: {
      label: 'Pan Number',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'panNumber',
      showSortable: true
    },
    tipShape: {
      label: 'Tip Shape',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'tipShape',
      showSortable: true
    },
    panShape: {
      label: 'Pan Shape',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'panShape',
      showSortable: true
    },
    shape: {
      label: 'Ext. Shape',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'shape',
      showSortable: true
    },
    technology: {
      label: 'Technology',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'technology',
      showSortable: true
    },
    capacityRange: {
      label: 'Capacity Range',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'capacityRange',
      showSortable: true
    },
    panSize: {
      label: 'Pan Size',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'panSize',
      showSortable: true
    },
    applicatorAndAccessories: {
      label: 'Applicator and Accessories',
      dataType: TABLE_DATA_TYPE.ARRAY,
      dataPath: 'applicatorAndAccessories',
      showSortable: true
    },
    closure: {
      label: 'Closure',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'closure',
      showSortable: true
    },
    mirror: {
      label: 'Mirror',
      dataType: TABLE_DATA_TYPE.THREESTATE,
      dataPath: 'mirror',
      showSortable: true
    },
    airtight: {
      label: 'Airtight',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'airtight',
      showSortable: true
    },
    clean: {
      label: 'Clean',
      dataType: TABLE_DATA_TYPE.THREESTATE,
      dataPath: 'clean',
      showSortable: true
    },
    isValidNote: {
      label: 'Note',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'isValidNote',
      showSortable: true
    },
    packageFamilies: {
      dataPath: 'packageFamilies',
      hide: true
    }
  }
  return PACKAGING_TABLE_CONFIG_MULTI
}
