import * as msal from '@azure/msal-browser'
import { Configuration, EventType, AuthenticationResult } from '@azure/msal-browser'
import { set } from 'lodash'

const {
  REACT_APP_AZURE_CLIENTID: clientId,
  REACT_APP_AZURE_TENANT: tenantId,
  REACT_APP_BASE_URL_REDIRECT: redirectUri
} = process.env

const msalConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId}`,
    clientId: clientId || '',
    redirectUri: redirectUri,
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
}

export const loginRequest = {
  scopes: ['User.Read']
}

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphMeMemberOfEndpoint:
    'https://graph.microsoft.com/v1.0/me/memberOf?$search="displayName:AZ"'
}

export const msalInstance = new msal.PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    const account = (event.payload as AuthenticationResult).account
    msalInstance.setActiveAccount(account)
    set(window, 'login', true)
  }
})

export const getAccessToken = async () => {
  try {
    const account = msalInstance.getActiveAccount()
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      )
    }
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    })
    return response.accessToken
  } catch (err) {
    throw err
  }
}
