import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { set, unset, keyBy, get, last, isNumber, compact, isArray } from 'lodash'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { PACKAGING_STATUS } from './constants'

import { AttachmentFilter, IAttachment, PackagingDto, PackagingState } from './model'
import { statusesByRole } from './utils'

const initialState: PackagingState = {
  items: [],
  itemsByIds: {},
  filters: {},
  tempFilters: {},
  newVersion: {},
  detail: undefined,
  tempDetail: undefined,
  detailVariant: undefined,
  attachmentsFilters: {
    name: '',
    description: '',
    SAPCodes: ''
  },
  tempAttachmentsFilters: {
    name: '',
    description: '',
    SAPCodes: ''
  },
  dropDown: {
    filteredStatus: [],
    allStatus: [
      {
        name: 'DRAFT',
        value: 'DRAFT'
      },
      {
        name: 'HOMOLOGATED',
        value: 'HOMOLOGATED'
      },
      {
        name: 'AVAILABLE',
        value: 'AVAILABLE'
      },
      {
        name: 'DISCONTINUED',
        value: 'DISCONTINUED'
      }
    ],
    attachmentStatus: [
      {
        name: 'DRAFT',
        value: 'DRAFT'
      },
      {
        name: 'PUBLISHED',
        value: 'PUBLISHED'
      }
    ]
  },
  taxonomies: {},
  allTaxonomies: [],
  pagination: {
    totalPages: 0,
    page: 1,
    pageSize: 50,
    total: 0
  },
  newPackage: {},
  newPackagingCode: '',
  isLoading: false,
  error: {},
  countriesDropdown: []
}

const addOthersPackage: (a?: Record<string, any>[], c?: Record<string, any>[]) => any = (
  originList = [],
  selectedPackages = []
) => {
  const originIds: string[] = originList.map(({ id }) => id)
  const selectedIds: string[] = selectedPackages.map(({ id }) => id)
  const idList = originIds.concat(selectedIds)
  const uniqueIdList: string[] = []
  idList.forEach((id) => {
    if (!uniqueIdList.includes(id)) {
      uniqueIdList.push(id)
    }
  })
  const allList = originList.concat(selectedPackages)
  return uniqueIdList.map((selectedId) => allList.find(({ id }) => id === selectedId))
}

const assignFilteredStatus = (
  state: PackagingState,
  { payload }: PayloadAction<{ roles: string[] }>
) => {
  const { roles } = payload
  const statusList: { name: PACKAGING_STATUS }[] = state.dropDown.allStatus
  state.dropDown.filteredStatus = statusList.filter(({ name }) =>
    roles.some((role) => statusesByRole[name].includes(role))
  )
}

const packagingsSlice = createSlice({
  name: 'packaging',
  initialState,
  reducers: {
    setPackaging: (
      state: PackagingState,
      { payload }: PayloadAction<{ data: PackagingDto[] }>
    ) => {
      const { data } = payload
      state.items = data
      state.itemsByIds = keyBy(state.items, 'id')
    },
    setDropdown: (state, { payload }) => {
      // @ts-ignore
      const refactoredPayload = Object.entries(payload).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]:
            k === 'categoryAndSubcategory'
              ? v
              : // @ts-ignore
                v.map((el: string) => ({ name: el.toUpperCase(), value: el }))
        }),
        {}
      )
      state.dropDown = { ...state.dropDown, ...refactoredPayload }
    },
    updateDropdown: (
      state,
      { payload }: PayloadAction<{ key: string; value: Record<string, any>[] }>
    ) => {
      const { key, value } = payload
      state.dropDown = { ...state.dropDown, [key]: value }
    },
    setFilteredStatus: assignFilteredStatus,
    setDetail: (state: PackagingState, { payload }: PayloadAction<PackagingDto | {}>) => {
      state.detail = payload
    },
    setDetailVariant: (
      state: PackagingState,
      { payload }: PayloadAction<PackagingDto | {}>
    ) => {
      state.detailVariant = payload
    },
    setAllTaxonomies: (
      state: PackagingState,
      { payload }: PayloadAction<Record<string, any>[]>
    ) => {
      state.allTaxonomies = payload
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination
    },
    setPagination: (
      state,
      {
        payload
      }: PayloadAction<{
        page: number
        pageSize?: number
        totalPages?: number
        total?: number
      }>
    ) => {
      state.pagination = { ...state.pagination, ...payload }
    },
    saveNewVersionData: (
      state: PackagingState,
      { payload }: PayloadAction<{ key: string; value: string }>
    ) => {
      const { key, value } = payload
      state.newVersion = { ...state.newVersion, [key]: value }
    },
    removeFilter: (state: PackagingState, { payload }: any) => {
      if (payload === 'testedSupplier.name') {
        unset(state.filters, 'testedSupplier.id')
      }
      unset(state.filters, payload)
      const path = payload.split('.')
      const pathKeys = [...path]
      pathKeys.pop()
      if (isNumber(Number(last(path)))) {
        const item = get(state.filters, pathKeys)
        if (Array.isArray(item)) {
          compact(item).length
            ? set(state.filters, pathKeys, compact(item))
            : unset(state.filters, pathKeys)
        }
      }
    },
    clearFilters: (state: PackagingState) => {
      state.filters = initialState.filters
      state.tempFilters = initialState.tempFilters
      state.pagination = initialState.pagination
    },
    clearTempFilters: (state: PackagingState) => {
      state.tempFilters = initialState.tempFilters
    },
    tempToFilter: (
      state: PackagingState,
      { payload }: PayloadAction<{ name?: string }>
    ) => {
      const { name: testedSupplierName } = payload
      const data = Object.entries(state.tempFilters).filter(([k, v]) =>
        v !== '' ? [k, v] : null
      )
      const filters = data.reduce((acc, [k, v]) => {
        return { ...acc, [k]: v }
      }, {})
      state.filters = filters
      set(state, 'filters.testedSupplier.name', testedSupplierName)
    },
    applyFilters: (state: PackagingState) => {
      state.filters = state.tempFilters
    },
    setFilters: (
      state: PackagingState,
      { payload }: PayloadAction<Record<string, any>>
    ) => {
      state.filters = payload
    },
    cancelFilter: (
      state: PackagingState,
      { payload }: PayloadAction<{ path: string }>
    ) => {
      // TODO - this can be an external generic function
      const { path } = payload
      const pathArray = path.split('.')
      const pathKey = pathArray.pop()
      const parentPath = pathArray.toString().replaceAll(',', '.') || ''
      const stateCopy = { ...state }
      const parentValue = get(stateCopy, parentPath)
      if (isArray(parentValue) && pathKey) {
        parentValue.splice(+pathKey, 1)
        set(state, parentPath, parentValue)
      } else {
        unset(state, path)
      }
    },
    resetTempDetail: (state: PackagingState) => {
      state.tempDetail = {
        ...state.tempDetail,
        roomAllocation: undefined,
        expiryDate: undefined,
        packageFamilies: [
          {
            id: get(state, 'tempDetail.packageFamilies.0.id'),
            category: get(state, 'tempDetail.packageFamilies.0.category'),
            subcategory: get(state, 'tempDetail.packageFamilies.0.subcategory'),
            createdAt: get(state, 'tempDetail.packageFamilies.0.createdAt'),
            updatedAt: get(state, 'tempDetail.packageFamilies.0.updatedAt'),
            sideCollectorId: get(state, 'tempDetail.packageFamilies.0.sideCollectorId'),
            packageVariants: get(state, 'tempDetail.packageFamilies.0.packageVariants')
          }
        ]
      }
    },
    filterToTemp: (state: PackagingState) => {
      state.tempFilters = state.filters
    },
    tempPackage: (state: PackagingState) => {
      state.tempDetail = state.detail
    },
    resetTempDetails: (state: PackagingState) => {
      state.tempDetail = initialState.tempDetail
    },
    updateTempDetails: (
      state: PackagingState,
      { payload }: PayloadAction<Record<string, any>>
    ) => {
      state.tempDetail = payload
    },
    updateTempDetailsByKey: (
      state: PackagingState,
      { payload }: PayloadAction<{ key: string; value: Record<string, any> }>
    ) => {
      const { key, value } = payload
      state.tempDetail = { ...state.tempDetail, [key]: value }
    },
    setTempFilter: (
      state: PackagingState,
      { payload }: PayloadAction<Record<string, any>>
    ) => {
      state.tempFilters = payload
    },
    updateTempFilter: (
      state: PackagingState,
      { payload }: PayloadAction<{ key: string; value: any }>
    ) => {
      const { key, value } = payload
      state.tempFilters[key] = value
    },
    clearTempFilter: (state: PackagingState) => {
      state.tempFilters = initialState.tempFilters
    },
    updateNewPackage: (state: PackagingState, { payload }: PayloadAction<any>) => {
      state.newPackage = payload.newPackage
    },
    clearNewPackage: (state: PackagingState) => {
      state.newPackage = initialState.newPackage
    },
    deleteNewPackageAttach: (
      state: PackagingState,
      {
        payload
      }: PayloadAction<{
        value: Record<string, any>
      }>
    ) => {
      const { value } = payload
      const { id: attachmentId } = value
      state.newPackage.filteredAttachment = state.newPackage.filteredAttachment.filter(
        ({ id }: IAttachment) => id !== attachmentId
      )
    },
    deleteNewPackageVariant: (
      state: PackagingState,
      {
        payload
      }: PayloadAction<{
        value: Record<string, any>
      }>
    ) => {
      const { value } = payload
      const { id: variantId } = value
      const packageFamilies = get(state, 'newPackage.packageFamilies.0', {})
      const newList = get(packageFamilies, 'packageVariants', []).filter(
        ({ id }: IAttachment) => id !== variantId
      )
      state.newPackage.packageFamilies = [
        { ...packageFamilies, packageVariants: newList }
      ]
    },
    addFurtherNewPackage: (
      state: PackagingState,
      {
        payload
      }: PayloadAction<{
        selectedPackages: Record<string, any>[]
      }>
    ) => {
      state.newPackage.furtherVersions = addOthersPackage(
        state.newPackage.furtherVersions,
        payload.selectedPackages
      )
    },
    addRelatedNewPackage: (
      state: PackagingState,
      {
        payload
      }: PayloadAction<{
        selectedPackages: Record<string, any>[]
      }>
    ) => {
      state.newPackage.relatedPackages = addOthersPackage(
        state.newPackage.relatedPackages,
        payload.selectedPackages
      )
    },
    addAlternativeInTempSide: (
      state: PackagingState,
      {
        payload
      }: PayloadAction<{
        selectedPackages: Record<string, any>[]
      }>
    ) => {
      const activeSideIdx = get(state, 'newPackage.activeSide', 0)
      const others = addOthersPackage(
        get(
          state,
          `tempDetail.packageFamilies.${activeSideIdx}.alternativeStandards`,
          []
        ),
        payload.selectedPackages
      )
      set(
        state,
        `tempDetail.packageFamilies.${activeSideIdx}.alternativeStandards`,
        others
      )
    },
    deleteAlternativeInTempSide: (
      state: PackagingState,
      { payload }: PayloadAction<{ value: Record<string, any> }>
    ) => {
      const { value } = payload
      const { id: idToRemove } = value
      const activeSideIdx = get(state, 'newPackage.activeSide', 0)
      const alternativeStandards = get(
        state,
        `tempDetail.packageFamilies.${activeSideIdx}.alternativeStandards`,
        []
      )

      const filteredAlternatives = alternativeStandards.filter(
        ({ id }: Record<string, any>) => id !== idToRemove
      )
      set(
        state,
        `tempDetail.packageFamilies.${activeSideIdx}.alternativeStandards`,
        filteredAlternatives
      )
    },
    addAlternativeNewPackageSide: (
      state: PackagingState,
      {
        payload
      }: PayloadAction<{
        selectedPackages: Record<string, any>[]
      }>
    ) => {
      const activeSideIdx = get(state, 'newPackage.activeSide', 0)
      const others = addOthersPackage(
        state.newPackage.packageFamilies[activeSideIdx].alternativeStandards,
        payload.selectedPackages
      )
      state.newPackage.packageFamilies[activeSideIdx].alternativeStandards = others
    },
    deleteAlternativeNewPackageSide: (
      state: PackagingState,
      { payload }: PayloadAction<{ value: Record<string, any> }>
    ) => {
      const { value } = payload
      const { id: idToRemove } = value
      const activeSideIdx = get(state, 'newPackage.activeSide', 0)
      state.newPackage.packageFamilies[activeSideIdx].alternativeStandards =
        state.newPackage.packageFamilies[activeSideIdx].alternativeStandards.filter(
          ({ id }: Record<string, any>) => id !== idToRemove
        )
    },
    deleteFurtherNewPackage: (
      state: PackagingState,
      { payload }: PayloadAction<{ value: Record<string, any> }>
    ) => {
      const { value } = payload
      const { id: idToRemove } = value
      state.newPackage.furtherVersions = state.newPackage.furtherVersions.filter(
        ({ id }: Record<string, any>) => id !== idToRemove
      )
    },
    deleteRelatedNewPackage: (
      state: PackagingState,
      { payload }: PayloadAction<{ value: Record<string, any> }>
    ) => {
      const { value } = payload
      const { id: idToRemove } = value
      state.newPackage.relatedPackages = state.newPackage.relatedPackages.filter(
        ({ id }: Record<string, any>) => id !== idToRemove
      )
    },
    setNewPackageCode: (
      state: PackagingState,
      { payload }: PayloadAction<{ packagingCode: string }>
    ) => {
      const { packagingCode } = payload
      state.newPackagingCode = packagingCode
    },
    setCountriesOfOriginDropdown: (
      state: PackagingState,
      { payload }: PayloadAction<any>
    ) => {
      state.countriesDropdown = payload
    },
    resetNewPackageCode: (state: PackagingState) => {
      state.newPackagingCode = initialState.newPackagingCode
    },
    setAttachmentsFilters: (state: PackagingState) => {
      state.attachmentsFilters = Object.entries(state.tempAttachmentsFilters).reduce(
        (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
        {}
      ) as AttachmentFilter
      state.tempAttachmentsFilters = initialState.tempAttachmentsFilters
    },
    updateAttachmentsFilters: (
      state: PackagingState,
      { payload }: PayloadAction<{ newData: AttachmentFilter }>
    ) => {
      const { newData } = payload
      state.tempAttachmentsFilters = newData
    },
    resetNewAttachment: (state: PackagingState) => {
      state.newAttachment = undefined
    },
    setNewAttachment: (
      state: PackagingState,
      { payload }: PayloadAction<{ key: string; value: string }>
    ) => {
      const { key, value } = payload
      state.newAttachment = { ...state.newAttachment, [key]: value }
    },
    resetNewVersion: (state: PackagingState) => {
      state.newVersion = initialState.newVersion
    },
    editVariant: (state: PackagingState) => {
      state.variantEdit = state.detailVariant
    },
    updateTempVariant: (
      state: PackagingState,
      { payload }: PayloadAction<{ key: string; value: Record<string, any> }>
    ) => {
      const { key, value } = payload
      state.variantEdit = { ...state.variantEdit, [key]: value }
    },
    resetPackagingStatus: (state: PackagingState) => {
      const currentStatus = get(state.detail, 'status')
      if (state.tempDetail) set(state.tempDetail, 'status', currentStatus)
    },
    setRelatedProducts: (state: PackagingState, { payload }: PayloadAction<string[]>) => {
      set(state, 'relatedProducts', payload)
    },
    resetRelatedProducts: (state: PackagingState) => {
      unset(state, 'relatedProducts')
    },
    setIsLoading: (state: PackagingState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setTestedSupplierDropdown: (
      state: PackagingState,
      { payload }: PayloadAction<any>
    ) => {
      state.dropDown.testedSupplier = payload
    },
    setActiveSide: (state: PackagingState, { payload }: PayloadAction<number>) => {
      state.newPackage.activeSide = payload
    },
    deleteSideVariant: (
      state: PackagingState,
      {
        payload
      }: PayloadAction<{
        value: Record<string, any>
      }>
    ) => {
      const activeSideIdx = get(state, 'newPackage.activeSide', 0)
      const { value } = payload
      const { id: variantId } = value
      const sidePackageFamily = get(
        state,
        `newPackage.packageFamilies.[${activeSideIdx}]`,
        {}
      )
      const newList = get(sidePackageFamily, 'packageVariants', []).filter(
        ({ id }: IAttachment) => id !== variantId
      )
      state.newPackage.packageFamilies[activeSideIdx] = {
        ...state.newPackage.packageFamilies[activeSideIdx],
        packageVariants: newList
      }
    },
    deleteSideVariantEditing: (
      state: PackagingState,
      {
        payload
      }: PayloadAction<{
        value: Record<string, any>
      }>
    ) => {
      const activeSideIdx = get(state, 'newPackage.activeSide', 0)
      const { value } = payload
      const { id: variantId } = value
      const sidePackageFamily = get(
        state,
        `tempDetail.packageFamilies.[${activeSideIdx}]`,
        {}
      )
      const newList = get(sidePackageFamily, 'packageVariants', []).filter(
        ({ id }: IAttachment) => id !== variantId
      )
      if (state.tempDetail)
        (state.tempDetail as PackagingDto).packageFamilies[activeSideIdx] = {
          ...get(state, `tempDetail.packageFamilies[${activeSideIdx}]`, {}),
          packageVariants: newList
        }
    },
    setError: (state: PackagingState, { payload }: PayloadAction<IError | {}>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: PackagingState) => {
      state.error = initialState.error
    }
  }
})

export default packagingsSlice.reducer
// NOTE - temporary - to slim the reducer file need to move action in another file
export const packagingSliceActions = packagingsSlice.actions
