import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button } from '../../components'
import SectionTitle from '../../components/SectionTitle'
import { PackagingSearchForm, PageContent } from '../../containers'
import { packagingActions, packagingSliceActions } from '../../features/packaging'
import { PACKAGE_TYPE } from '../../features/packaging/model'
import {
  getPackagingTempFilters,
  selectPackagingError
} from '../../features/packaging/selectors'
import { selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { checkSearchFormValidation } from '../../utils'
import { AppRoutes } from '../constants'
import { ButtonWrapper, buttonWrapperHeight } from './styled'

const PackagingSearch: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const packagingError = useSelector(selectPackagingError)
  const tempFilter = useSelector(getPackagingTempFilters)
  const userRoles = useSelector(selectUserRole)

  useEffect(() => {
    dispatch(packagingSliceActions.clearTempFilter())
    dispatch(packagingSliceActions.setFilteredStatus({ roles: userRoles || [] }))
    dispatch(packagingActions.getTestedSupplier())
    return () => {
      dispatch(packagingSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetHandler = (value: any) => {
    const isMono = value === PACKAGE_TYPE.MONO
    dispatch(
      packagingSliceActions.setTempFilter({
        type: value,
        packageFamilies: isMono ? [{}] : [{}, {}]
      })
    )
  }

  const searchHandler = () => {
    dispatch(packagingSliceActions.applyFilters())
    navigate(AppRoutes.WIKIPACK_PACKAGING_RESULT)
  }

  const checkIfDisabled = () => checkSearchFormValidation(tempFilter)

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight + buttonWrapperHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <SectionTitle
          value="Packaging"
          isBold
          addon={
            <Button
              label="Reset All"
              onClick={() => resetHandler('mono')}
              variant="text"
            />
          }
        />
        <PackagingSearchForm />
      </PageContent>
      <ButtonWrapper>
        <Button
          label="Search"
          onClick={searchHandler}
          disabled={checkIfDisabled()}
          enableKeyPress
        />
      </ButtonWrapper>
    </GenericLayout>
  )
}

export default PackagingSearch
