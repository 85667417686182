import { ItemType } from 'antd/lib/menu/hooks/useItems'
import _get from 'lodash/get'
import type { MenuInfo } from 'rc-menu/lib/interface'
import { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { StyledMenu } from './styled'
import { IHeaderMenuProps } from './types'

const HeaderMenu: FC<IHeaderMenuProps> = ({ menus = [] }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onClick = (e: MenuInfo) => {
    navigate(e.key)
  }

  const getPath: () => string = () => {
    const path = menus.find((menu: ItemType) =>
      pathname.includes(_get(menu, 'key', '').toString())
    )
    return _get(path, 'key', '').toString()
  }

  return (
    <StyledMenu
      activeKey={getPath()}
      className="menu-ant"
      onClick={onClick}
      style={{ background: '#fff' }}
      mode="horizontal"
      items={menus}
    />
  )
}

export default HeaderMenu
