import { PayloadAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import { IError } from '../../containers/Error/types'
import { appSliceActions } from '../app/reducer'
import { getStoreProductById } from '../storeProducts/api'
import { selectUserEmail } from '../users/selectors'
import {
  createOrder,
  deleteFromCart,
  getCartItemsByAuthor,
  insertInCart,
  resetCartItems
} from './api'
import { cartActionTypes } from './constants'
import { IAddToCartPayload, ItemInCart, OrderBody } from './model'
import { cartSliceActions } from './reducer'
import { getCartItems, selectOrderData } from './selectors'
import { checkQuantity } from './utils'

function* addToCartSaga({ payload }: PayloadAction<IAddToCartPayload>) {
  const { productCode, quantity } = payload
  yield put(appSliceActions.setLoader({ type: 'modal', value: true }))
  try {
    const owner: string = yield select(selectUserEmail)
    const { items: cartItems } = yield call(getCartItemsByAuthor, owner)
    const totalQuantity = checkQuantity({ cartItems, quantity, productCode })
    const itemData = { owner, productCode, quantity: totalQuantity }
    const { error, items } = yield call(insertInCart, itemData)
    if (error) {
      throw new Error(error)
    }
    yield put(cartSliceActions.setCartItems(items))
    yield put(
      cartSliceActions.setSuccess({
        title: 'The product has been successfully added!',
        message: 'The product has been successfully added tho the cart for the order list'
      })
    )
  } catch (error) {
    yield put(cartSliceActions.setError(error as IError))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'modal', value: false }))
  }
}

function* removeItemById({ payload }: PayloadAction<{ itemId: string }>) {
  const { itemId } = payload
  yield put(cartSliceActions.setCartLoader(true))
  try {
    const owner: string = yield select(selectUserEmail)
    const itemsInCart: ItemInCart[] = yield select(getCartItems)
    const itemToUpdate = itemsInCart.find((item: any) => item.id === itemId)
    if (itemToUpdate) {
      const productCode = get(itemToUpdate, 'productCode')
      const itemData = { owner, productCode }
      yield call(deleteFromCart, itemData)
      const items = itemsInCart.filter(({ id }) => id !== itemId)
      yield put(cartSliceActions.setCartItems(items))
    }
  } catch (err: any) {
    yield put(cartSliceActions.setError(err))
  } finally {
    yield put(cartSliceActions.setCartLoader(false))
  }
}
function* resetCart() {
  try {
    const owner: string = yield select(selectUserEmail)
    yield call(resetCartItems, owner)
    yield hydrateCart()
  } catch (error) {
    console.log(error)
  }
}

function* hydrateCart() {
  yield put(cartSliceActions.setCartLoader(true))
  try {
    const owner: string = yield select(selectUserEmail)
    const { items } = yield call(getCartItemsByAuthor, owner)
    yield put(cartSliceActions.setCartItems(items))
  } catch (err: any) {
    yield put(cartSliceActions.setError(err))
  } finally {
    yield put(cartSliceActions.setCartLoader(false))
  }
}

function* sendOrder() {
  yield put(cartSliceActions.setCartLoader(true))
  try {
    const orderBody: OrderBody = yield select(selectOrderData)
    // bad temorary fix to exclude null roles
    orderBody['ownerRoles'] = orderBody.ownerRoles?.filter((x: string) => x)
    yield call(createOrder, orderBody)
    yield put(
      cartSliceActions.setSuccess({
        title: 'The order has been successfully submitted!',
        message: 'You can continue to view the order in the forwarded orders list'
      })
    )
    yield put(cartSliceActions.clearCart())
  } catch (err: any) {
    yield put(cartSliceActions.setError(err))
  } finally {
    yield put(cartSliceActions.setCartLoader(false))
  }
}

function* checkAvailabilitySaga({
  payload
}: PayloadAction<{
  items: Record<string, any>
  warningCallback: (prods?: Record<string, any>[]) => void
  hasLoader?: boolean
}>) {
  const { items = [], warningCallback = () => {}, hasLoader = true } = payload
  if (hasLoader) {
    yield put(cartSliceActions.setCartLoader(true))
  }
  try {
    const products: Record<string, any>[] = yield all(
      items
        .filter(({ productCode }: any) => !!productCode)
        .map(({ orderableItemId }: any) => call(getStoreProductById, orderableItemId))
    )
    const notAvailableList: Record<string, any>[] = []
    items.forEach(({ productCode: itemCode, quantity }: any) => {
      const prodData = products.find(({ productCode }) => productCode === itemCode)
      if (prodData && prodData.availableQuantity < quantity) {
        notAvailableList.push(prodData)
      }
    })
    warningCallback(notAvailableList)
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  } finally {
    if (hasLoader) {
      yield put(cartSliceActions.setCartLoader(false))
    }
  }
}

export default function* cartSaga() {
  yield takeLatest(cartActionTypes.ADD_TO_CART, addToCartSaga)
  yield takeLatest(cartActionTypes.HYDRATE_CART, hydrateCart)
  yield takeLatest(cartActionTypes.RESET_CART, resetCart)
  yield takeLatest(cartActionTypes.DELETE_ITEM, removeItemById)
  yield takeLatest(cartActionTypes.SEND_ORDER, sendOrder)
  yield takeLatest(cartActionTypes.CHECK_ITEM_AVAILABILITY, checkAvailabilitySaga)
}
