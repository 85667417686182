export const AZ_DBPRODOTTO_PRODUCT_READ = 'AZ.Dbprodotto-Product-Read'
export const AZ_DBPRODOTTO_PRODUCT_CREAT = 'AZ.Dbprodotto-Product-Creat'
export const AZ_DBPRODOTTO_PRODUCT_EDIT = 'AZ.Dbprodotto-Product-Edit'
export const AZ_DBPRODOTTO_FS_READ = 'AZ.Dbprodotto-FS-Read'
export const AZ_DBPRODOTTO_PACKAGING_READ = 'AZ.Dbprodotto-Packaging-Read'
export const AZ_DBPRODOTTO_SALES = 'AZ.Dbprodotto-Sales'
export const AZ_DBPRODOTTO_STRATEGICMARKETING = 'AZ.Dbprodotto-StrategicMarketing'
export const AZ_DBPRODOTTO_BU = 'AZ.Dbprodotto-BU'
export const AZ_DBPRODOTTO_TCS = 'AZ.Dbprodotto-TCS'
export const AZ_DBPRODOTTO_RD = 'AZ.Dbprodotto-RD'
export const AZ_DBPRODOTTO_IE = 'AZ.Dbprodotto-IE'
export const AZ_DBPRODOTTO_CQPKG = 'AZ.Dbprodotto-CQPKG'
export const AZ_DBPRODOTTO_PKG = 'AZ.Dbprodotto-PKG'
export const AZ_DBPRODOTTO_ADMIN = 'AZ.Dbprodotto-Admin'
export const AZ_DBPRODOTTO_IMPORT_FS = 'AZ.Dbprodotto-Import-FS'
export const AZ_DBPRODOTTO_IMPORT_PRODUCT = 'AZ.Dbprodotto-Import-Product'
export const AZ_DBPRODOTTO_IMPORT_PACKAGE = 'AZ.Dbprodotto-Import-Package'
export const AZ_DBS_SALA_CAMPIONI_AGREGATE = 'Az.DBS-SalaCampioniAgrate'
export const AZ_DBS_SALES_MARKETING_BU_MERCATI = 'AZ.DBS-SalesMarketingBUMercati'
export const AZ_DBPRODOTTO_PACKAGING_CREATE = 'AZ.Dbprodotto-Packaging-Creat'
export const AZ_DBPRODOTTO_PACKAGING_EDIT = 'AZ.Dbprodotto-Packaging-Edit'
export const AZ_DBPRODOTTO_PACKAGING_ADMIN = 'AZ.Dbprodotto-Packaging-Admin'
export const AZ_WIKIPACK_PKDEV = 'AZ.Wikipack.PKDEV'
export const AZ_WIKIPACK_RDF = 'AZ.Wikipack.R&DF'
export const AZ_WIKIPACK_IE = 'AZ.Wikipack.IE'
export const AZ_WIKIPACK_SRC = 'AZ.Wikipack.SRC'
export const AZ_WIKIPACK_PKACCEVO = 'AZ.Wikipack.PKAccEvO'
export const AZ_WIKIPACK_QC = 'AZ.Wikipack.QC'
export const AZ_WIKIPACK_TCS = 'AZ.Wikipack.TCS'
export const AZ_WIKIPACK_MRKT = 'AZ.Wikipack.MRKT'
export const AZ_DBPRODOTTO_REGULATORY_RW = 'AZ.Dbprodotto-Regulatory-RW'
export const AZ_DBPRODOTTO_REGULATORY_READ = 'AZ.Dbprodotto-Regulatory-READ'
export const AZ_WIKIPACK_LE_EU = 'AZ.Wikipack.LE-EU'
export const AZ_WIKIPACK_LE_CN = 'AZ.Wikipack.LE-CN'
export const AZ_WIKIPACK_LE_US = 'AZ.Wikipack.LE-US'
export const AZ_WIKIPACK_SHUB = 'AZ.Wikipack.SHUB'
export const AZ_VAULT_TOOLING_WRITE = 'AZ.Dbprodotto-ToolingWrite'
export const AZ_VAULT_TOOLING_READ = 'AZ.Dbprodotto-ToolingRead'
export const AZ_WIKIPACK_SRC_ADMIN = 'Az.WikiPack.SRC-Admin'
export const AZ_DBPRODOTTO_COMPATIBILITY_MANAGER = 'AZ-Dbprodotto-CompatibilityManager'
export const AZ_DBPRODOTTO_TEST_MANAGER = 'AZ-Dbprodotto-TestManager'
export const AZ_WIKIPACK_SRC_EDIT = 'AZ.Wikipack.SRC-Edit'
export const AZ_DBPRODOTTO_REGULATORY_NOTIFICATION =
  'AZ.DBprodotto-Regulatory-Notification'
export const AZ_DBPRODOTTO_DELIVERY_NOTIFICATION = 'AZ.DBprodotto-Delivery-Notification'
export const AZ_DBPRODOTTO_FOUNDATION_NOTIFICATION =
  'AZ.DBprodotto-Foundation-Notification'
export const AZ_DBPRODOTTO_LIPS_NOTIFICATION = 'AZ.DBprodotto-Lips-Notification'
export const AZ_DBPRODOTTO_MASCARA_NOTIFICATION = 'AZ.DBprodotto-Mascara-Notification'
export const AZ_DBPRODOTTO_POWDERNET_NOTIFICATION = 'AZ.DBprodotto-PowderWet-Notification'
export const AZ_DBPRODOTTO_PRODUCT_EDIT_NOTIFICATION_PROD =
  'AZ.DBprodotto-Product-Edit-Notification-PROD'
export const AZ_DBPRODOTTO_PRODUCT_EDIT_NOTIFICATION_DEV =
  'AZ.DBprodotto-Product-Edit-Notification-DEV'
export const AZ_DBPRODOTTO_PRODUCT_CREATE_NOTIFICATION_PROD =
  'AZ.DBprodotto-Product-Create-Notification-PROD'
export const AZ_DBPRODOTTO_PRODUCT_CREATE_NOTIFICATION_DEV =
  'AZ.DBprodotto-Product-Create-Notification-DEV'

export const userGroupId: Record<string, string> = {
  'df4a2113-aa57-4f52-9ea8-37270aad9380': AZ_DBPRODOTTO_PRODUCT_READ,
  'e3d1744e-af2b-45da-b32e-854164697405': AZ_DBPRODOTTO_PRODUCT_CREAT,
  '5b47cd00-e056-4b95-93d7-e24c34616e2c': AZ_DBPRODOTTO_PRODUCT_EDIT,
  '837cc2e2-52a1-4b34-9766-e249596f43d8': AZ_DBPRODOTTO_FS_READ,
  '057197f5-7b97-418d-9d17-fb73ccb45be8': AZ_DBPRODOTTO_PACKAGING_READ,
  '2b7e99ed-ea25-4066-8652-a862c7f52deb': AZ_DBPRODOTTO_SALES,
  '95f178f2-79cd-4a7c-95ad-e1b79cdee0be': AZ_DBPRODOTTO_STRATEGICMARKETING,
  '5406e37d-a435-4263-a742-5dade21870b6': AZ_DBPRODOTTO_BU,
  '871912e4-eeb3-42e4-b2b8-9ec970cc2aa0': AZ_DBPRODOTTO_TCS,
  'aac919f5-8641-48df-9efe-cc029ee6a14e': AZ_DBPRODOTTO_RD,
  '3a1604a8-28d8-428b-9e9c-3c3a16aab897': AZ_DBPRODOTTO_IE,
  'e6343d40-9732-426b-a555-42a87c0e299c': AZ_DBPRODOTTO_CQPKG,
  'e455866f-3cf0-40de-b932-7f2e923e42b6': AZ_DBPRODOTTO_PKG,
  'b77bb71d-bdb0-4594-a7c7-2476b4608134': AZ_DBPRODOTTO_ADMIN,
  '31d03b20-d9b7-4b0a-aeb0-045300f69898': AZ_DBPRODOTTO_IMPORT_FS,
  '90d9b1cd-aaa6-46c8-a857-f555fffb2c84': AZ_DBPRODOTTO_IMPORT_PRODUCT,
  'fddc621e-cbc2-4092-b280-d1cb1967727f': AZ_DBPRODOTTO_IMPORT_PACKAGE,
  '42403cfd-a7c8-4b18-93dc-928bd16233bd': AZ_DBS_SALA_CAMPIONI_AGREGATE,
  '50cbfd91-9319-4f7e-874a-622f01c28ea5': AZ_DBS_SALES_MARKETING_BU_MERCATI,
  'be1133fb-addb-489b-ac42-2e3afc06f3dc': AZ_DBPRODOTTO_PACKAGING_CREATE,
  'eaa3c52d-5d85-461f-a99c-b8c8d18a9b71': AZ_DBPRODOTTO_PACKAGING_EDIT,
  'bb67b42a-30c6-45c9-bacb-8c47d0442bc6': AZ_DBPRODOTTO_PACKAGING_ADMIN,
  '4d8fb436-820b-4ba4-b5db-ebe680ed0153': AZ_WIKIPACK_PKDEV,
  '46d8e541-c9af-46f7-8cd9-8c308763ee14': AZ_WIKIPACK_RDF,
  '05329079-9c06-40a3-9fd9-e19cbddb31c4': AZ_WIKIPACK_IE,
  '28483c46-f8c7-424a-9810-a70f6809902c': AZ_WIKIPACK_SRC,
  '51405d26-f2eb-4d1d-819a-bab3b357ecbc': AZ_WIKIPACK_PKACCEVO,
  '4e5eb245-e469-4990-b5fb-0d5574562116': AZ_DBPRODOTTO_REGULATORY_RW,
  'cd150553-c21b-4a79-8d23-cc4e4d3f0349': AZ_WIKIPACK_QC,
  'b8f3c068-28ca-4af8-af9e-37afad218ad8': AZ_WIKIPACK_MRKT,
  '3de5f104-5bab-4cc9-a4b3-7b6d7568f362': AZ_WIKIPACK_TCS,
  'fd297814-cae8-4e40-96c4-b993d2a8b7e5': AZ_DBPRODOTTO_REGULATORY_READ,
  '057f51f3-2f6d-487d-8a4e-b1e7305bafd5': AZ_VAULT_TOOLING_WRITE,
  'e357e5d6-8e81-441e-be83-45d15133b2a6': AZ_VAULT_TOOLING_READ,
  '5641d230-3bdb-4e0d-8168-d4334102d374': AZ_WIKIPACK_SRC_ADMIN,
  'b7785140-ec6e-48e5-9c6c-01fc4ca65a5e': AZ_WIKIPACK_SHUB,
  '225b7417-59cf-4d31-916a-cd7539210c3f': AZ_DBPRODOTTO_COMPATIBILITY_MANAGER,
  '6ec17202-724b-49b9-83e5-99f4b70641c4': AZ_DBPRODOTTO_TEST_MANAGER,
  '2ae17633-a597-417c-95a3-4c804b885296': AZ_WIKIPACK_SRC_EDIT,
  '76652ce7-31fb-4de5-8d7e-94d1b5442da1': AZ_WIKIPACK_LE_CN,
  'a204f553-0de9-4a38-8cc1-665fdcf3bbc5': AZ_WIKIPACK_LE_EU,
  '0249e694-8cd2-437d-afe7-2cd5c7f8c578': AZ_WIKIPACK_LE_US,
  '865e3649-b82f-4ead-9231-6a6f34176384': AZ_DBPRODOTTO_REGULATORY_NOTIFICATION,
  '1df4b4ce-649f-468e-b0ff-0a55e245e8ac': AZ_DBPRODOTTO_DELIVERY_NOTIFICATION,
  '287e4116-032a-4101-827a-ad4a5923a6b8': AZ_DBPRODOTTO_FOUNDATION_NOTIFICATION,
  'a054dece-f4e6-4a7a-b699-42a07685b7f0': AZ_DBPRODOTTO_LIPS_NOTIFICATION,
  '7b733b6a-5f63-4be3-a5b5-7e85da543c40': AZ_DBPRODOTTO_MASCARA_NOTIFICATION,
  'a3f5e912-a569-4115-bd4a-94288a6a7549': AZ_DBPRODOTTO_POWDERNET_NOTIFICATION,
  '2abe12df-4450-4e49-8b2b-45c5ce460a8e': AZ_DBPRODOTTO_PRODUCT_EDIT_NOTIFICATION_PROD,
  'be7e102b-761d-4f5c-a26c-d09f9a76be12': AZ_DBPRODOTTO_PRODUCT_EDIT_NOTIFICATION_DEV,
  'd97461fb-c8aa-4c32-ba4b-52efc79921ff': AZ_DBPRODOTTO_PRODUCT_CREATE_NOTIFICATION_PROD,
  'e1afa258-13ad-4fa7-af2c-6bd4e5ce3ab3': AZ_DBPRODOTTO_PRODUCT_CREATE_NOTIFICATION_DEV
}

export const BU_REFERENTS_GROUP = [
  AZ_DBPRODOTTO_BU,
  AZ_DBPRODOTTO_PRODUCT_EDIT,
  AZ_DBPRODOTTO_FS_READ
]

export const BU_GROUP = [
  AZ_DBPRODOTTO_BU,
  AZ_DBPRODOTTO_PRODUCT_READ,
  AZ_DBPRODOTTO_FS_READ
]

export const MKTG_STR_GROUP = [AZ_DBPRODOTTO_STRATEGICMARKETING]

export const RD_EU_REFERENTS_GROUP = [AZ_WIKIPACK_RDF]

export const RD_EU_GROUP = [AZ_WIKIPACK_RDF, AZ_WIKIPACK_LE_EU]
export const RD_CHINA_GROUP = [AZ_WIKIPACK_RDF, AZ_WIKIPACK_LE_CN]

export const PACKAGING_EU_GROUP = [AZ_WIKIPACK_PKDEV, AZ_WIKIPACK_LE_EU]
export const PACKAGING_CN_GROUP = [AZ_WIKIPACK_PKDEV, AZ_WIKIPACK_LE_CN]
export const PACKAGING_US_GROUP = [AZ_WIKIPACK_PKDEV, AZ_WIKIPACK_LE_US]

export const CQ_PACKAGING_GROUP = [AZ_WIKIPACK_QC]

export const SALES_GROUP = [AZ_DBPRODOTTO_SALES]

export const REGULATORY_REFERENTS_GROUP = [AZ_DBPRODOTTO_REGULATORY_RW]

export const REGULATORY_GROUP = [AZ_DBPRODOTTO_REGULATORY_READ]

export const INDUSTR_TOOLING_INFO_EU_GROUP = [AZ_WIKIPACK_IE, AZ_WIKIPACK_LE_EU]
export const INDUSTR_TOOLING_INFO_CN_GROUP = [AZ_WIKIPACK_IE, AZ_WIKIPACK_LE_CN]

export const SCIENTIFIC_HUB_GROUP = [AZ_WIKIPACK_SHUB]

export const AZ_VAULT_TOOLING_RW_GROUP = [AZ_DBPRODOTTO_ADMIN, AZ_VAULT_TOOLING_WRITE]

export const AZ_VAULT_TOOLING_R_GROUP = [
  AZ_DBPRODOTTO_ADMIN,
  AZ_VAULT_TOOLING_WRITE,
  AZ_VAULT_TOOLING_READ
]

// HELPER FUNCTIONS FOR COMPONENT VISIBILITY

export const isUserAdmin = (userRoles: any): boolean => {
  return userRoles.includes(AZ_DBPRODOTTO_ADMIN)
}

export const isUserPkDev = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_WIKIPACK_PKDEV) &&
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ)
  )
}

export const isUserSourcing = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_WIKIPACK_SRC) &&
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

export const isSalaCampioni = (userRoles: any): boolean => {
  return userRoles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE)
}

export const canUserEditOtherTestedPackagingForFormulaSchema = (
  userRoles: any
): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_COMPATIBILITY_MANAGER) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserEditTestedClaimForFormulaSchema = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_TEST_MANAGER) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeOtherTestedPackagingForProduct = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeTestedClaimForProduct = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeOtherTestedPackagingForFormulaSchema = (
  userRoles: any
): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_FS_READ) || userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeTestedClaimForFormulaSchema = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_FS_READ) || userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeTargetPrice = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_WIKIPACK_PKACCEVO) ||
    userRoles.includes(AZ_WIKIPACK_MRKT) ||
    userRoles.includes(AZ_WIKIPACK_PKDEV) ||
    userRoles.includes(AZ_WIKIPACK_SRC) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserEditTargetPrice = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_WIKIPACK_SRC_EDIT) || userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserEditPackaging = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

export const canUserDeletePackaging = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

export const canUserSeeFschemaData = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_CREAT) ||
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_EDIT)
  )
}

export const canUserEditProduct = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PRODUCT_EDIT)
  )
}

export const canUserReadFormulaSchemaData = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) || userRoles.includes(AZ_DBPRODOTTO_FS_READ)
  )
}

export const canUserReadPackagingData = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ)
  )
}

export const userCanEditAttachment = (userRoles: any): boolean => {
  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
      !userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_DBPRODOTTO_ADMIN)) ||
    userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return false
  }

  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) ||
      userRoles.includes(AZ_DBPRODOTTO_ADMIN)) &&
    !userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return true
  }
  return false
}

export const canReadMasterSpecificationDetail = (userRoles: any): boolean => {
  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
      !userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_DBPRODOTTO_ADMIN)) ||
    userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return false
  }

  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) ||
      userRoles.includes(AZ_DBPRODOTTO_ADMIN)) &&
    !userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return true
  }
  return false
}

export const canReadProjectSpecificationDetail = (userRoles: any): boolean => {
  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
      !userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_DBPRODOTTO_ADMIN)) ||
    userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return false
  }

  if (
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) ||
      userRoles.includes(AZ_DBPRODOTTO_ADMIN)) &&
    !userRoles.includes(AZ_WIKIPACK_SRC)
  ) {
    return true
  }
  return false
}

export const canCopyOrDeletePackaging = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_CREATE)
  )
}

export const canUserAddRelationships = (userRoles: any): boolean => {
  if (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_WIKIPACK_SRC))
  ) {
    return false
  }
  return true
}

export const canUserAddAttachment = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_WIKIPACK_SRC))
  )
}

export const canUserAddMasterSpecification = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_WIKIPACK_SRC))
  )
}

export const canUserAddProjectSpecification = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    (userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_WIKIPACK_SRC))
  )
}

export const canUserExportPackagingSearchResult = (userRoles: any): boolean => {
  return userRoles.includes(AZ_DBPRODOTTO_ADMIN)
}

export const canUserAddVariant = (userRoles: any): boolean => {
  return (
    userRoles?.includes(AZ_DBPRODOTTO_ADMIN) ||
    (userRoles?.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) &&
      !userRoles.includes(AZ_WIKIPACK_SRC))
  )
}

export const canUserEditRegulatory = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
  )
}

export const canUserReadRegulatory = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_ADMIN) ||
    userRoles.includes(AZ_DBPRODOTTO_REGULATORY_READ) ||
    userRoles.includes(AZ_DBPRODOTTO_REGULATORY_RW)
  )
}

export const canUserDeleteVariant = (userRoles: any): boolean => {
  return (
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_CREATE) ||
    userRoles.includes(AZ_DBPRODOTTO_ADMIN)
  )
}

export const canUserSeeEditbuttonInPackaging = (userRoles: any): boolean => {
  return (
    isUserAdmin(userRoles) ||
    isUserSourcing(userRoles) ||
    userRoles.includes(AZ_WIKIPACK_SRC_EDIT) ||
    userRoles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT)
  )
}

// USER GROUPS

export const ADMIN_GROUP = [AZ_DBPRODOTTO_ADMIN]

export const SOURCING_EU_GROUP = [
  AZ_DBPRODOTTO_PRODUCT_READ,
  AZ_DBPRODOTTO_PACKAGING_READ,
  AZ_WIKIPACK_SRC,
  AZ_WIKIPACK_SRC_EDIT,
  AZ_WIKIPACK_SRC_ADMIN,
  AZ_WIKIPACK_LE_EU,
  AZ_DBS_SALES_MARKETING_BU_MERCATI
]

export const SOURCING_CN_GROUP = [
  AZ_DBPRODOTTO_PACKAGING_READ,
  AZ_WIKIPACK_SRC,
  AZ_WIKIPACK_SRC_EDIT,
  AZ_DBS_SALES_MARKETING_BU_MERCATI
  // NOTE - not available yet
  // AZ_WIKIPACK_LE_CN,
]

export const SOURCING_US_GROUP = [
  AZ_DBPRODOTTO_PACKAGING_READ,
  AZ_WIKIPACK_SRC,
  AZ_WIKIPACK_SRC_EDIT,
  AZ_DBS_SALES_MARKETING_BU_MERCATI
  // NOTE - not available yet
  // AZ_WIKIPACK_LE_US,
]
