import styled from 'styled-components'

export const ProdScatterPlotModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  transform: translate(0px, -24px);
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const TopModalContent = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  padding-top: 24px;
  z-index: 100;
`
