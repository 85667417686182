import { DatePicker } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { palette } from '../../../assets/colors'
import { canUserEditTargetPrice, canUserSeeTargetPrice } from '../../../auth-msal'
import { selectCountryOfOriginDropDownItems } from '../../../features/packaging/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import { DatePickerWrapper } from '../../../style/styledDatePicker'
import { Label, Value } from '../../../style/styledLabels'
import { replaceDotsWithCommas } from '../../../utils/replaceCharsHelpers'
import { BoxWrapper } from '../../BoxWrapper'
import { Divider } from '../../Divider'
import InfoCell from '../../InfoCell'
import { Text } from '../../Text'
import { IGeneralInfoProps } from '../GeneralInfo/types'
import { currencyDropdownValues } from './constants'
import { isFieldVisible } from './utils'

const TargetPrice: FC<IGeneralInfoProps> = ({
  data = {},
  isEditing = false,
  updateData = () => {}
}) => {
  const userRoles = useSelector(selectUserRole)
  const countryOfOriginDropDownItems = useSelector(selectCountryOfOriginDropDownItems)

  const categoryValue: string = get(data, 'category', '')
  const subCategoryValue: string = data.subcategory || ''

  const category = categoryValue.toLowerCase()
  const subCategory = subCategoryValue.toLowerCase()

  const canEditTargetPrice = canUserEditTargetPrice(userRoles)
  const validityFromDate = moment(get(data, 'validityFrom')).utc()
  const validityToDate = moment(get(data, 'validityTo')).utc()

  return (
    <>
      <Text text="Target Price" variant="h3" />
      <Text
        text="*Target Price is a proxy, based on injection + 1 SS or HS and on MOQ of 10000 pcs. For prices under the 10K q.ty or with complex deco please open a specific request as per standard flow"
        color={palette.primary.main}
        variant="h4"
      />
      <Divider />
      <>
        <BoxWrapper columnCount={4} enablePadding>
          {isFieldVisible({
            key: 'USDTargetPrice',
            userRoles: userRoles,
            category: category,
            subCategory: subCategory
          }) && (
            <InfoCell
              label="Target Price"
              value={
                isEditing && canEditTargetPrice
                  ? get(data, 'targetPrice', '')
                  : replaceDotsWithCommas(get(data, 'USDTargetPrice', ''))
              }
              dataType="number"
              isEditing={isEditing && canEditTargetPrice}
              inputConfig={{
                placeholder: '',
                onChange: (value: string) => {
                  const pattern = /^\d+$/
                  if (value !== null && pattern.test(value)) {
                    updateData('targetPrice', parseInt(value))
                  }
                }
              }}
              tooltipConfig={{
                enable: true,
                message: 'Type code for fixed quantity + 1 injection/glass + 1 deco'
              }}
            />
          )}
          {isFieldVisible({
            key: 'currency',
            userRoles: userRoles,
            category: category,
            subCategory: subCategory
          }) && (
            <InfoCell
              label="Currency"
              value={
                !isEditing || !canEditTargetPrice ? 'USD' : get(data, 'currencyCode', '')
              }
              inputType="select"
              isEditing={isEditing && canEditTargetPrice}
              inputConfig={{
                items: currencyDropdownValues,
                onChange: ({ value }: Record<string, any>) =>
                  updateData('currencyCode', value),
                orderItems: true,
                orderKey: 'name'
              }}
            />
          )}
          {isFieldVisible({
            key: 'MOQ',
            userRoles: userRoles,
            category: category,
            subCategory: subCategory
          }) && (
            <InfoCell
              label="MOQ"
              value={'10.000'}
              isEditing={false}
              inputConfig={{
                onChange: (value: string) => updateData('MOQ', value)
              }}
            />
          )}
          {isFieldVisible({
            key: 'validityFrom',
            userRoles: userRoles,
            category: category,
            subCategory: subCategory
          }) &&
            (isEditing ? (
              <DatePickerWrapper>
                <span>Validity From</span>
                <DatePicker
                  disabledDate={(current: any) => current > validityToDate}
                  format={'DD/MM/YYYY'}
                  onChange={(value: any) => {
                    if (value) updateData('validityFrom', moment(value.utc().format()))
                  }}
                  value={validityFromDate.isValid() ? validityFromDate : moment().utc()}
                />
              </DatePickerWrapper>
            ) : (
              <DatePickerWrapper>
                <Label>Validity From</Label>
                <Value>
                  {get(data, 'validityFrom')
                    ? validityFromDate.utc().format('DD/MM/YYYY')
                    : '-'}
                </Value>
              </DatePickerWrapper>
            ))}
          {isFieldVisible({
            key: 'validityTo',
            userRoles: userRoles,
            category: category,
            subCategory: subCategory
          }) &&
            (isEditing ? (
              <DatePickerWrapper>
                <span>Validity To</span>
                <DatePicker
                  disabledDate={(current: any) => current < validityFromDate}
                  format={'DD/MM/YYYY'}
                  defaultValue={moment().endOf('year')}
                  onChange={(value: any) => {
                    if (value) updateData('validityTo', moment(value.format()))
                  }}
                  value={
                    validityToDate.isValid()
                      ? validityToDate
                      : moment().endOf('year').utc()
                  }
                />
              </DatePickerWrapper>
            ) : (
              <DatePickerWrapper>
                <Label>Validity To</Label>
                <Value>
                  {get(data, 'validityTo')
                    ? validityToDate.utc().format('DD/MM/YYYY')
                    : '-'}
                </Value>
              </DatePickerWrapper>
            ))}
          {canUserSeeTargetPrice(userRoles) && (
            <InfoCell
              label="Country of Origin"
              inputType="select"
              value={get(data, 'countryOfOriginCode', '')}
              isEditing={isEditing && canUserEditTargetPrice(userRoles)}
              inputConfig={{
                items: countryOfOriginDropDownItems,
                onChange: ({ value }: Record<string, any>) => {
                  return updateData('countryOfOriginCode', value)
                },
                orderItems: true,
                orderKey: 'name'
              }}
            />
          )}

          {isFieldVisible({
            key: 'priceNotes',
            userRoles: userRoles,
            category: category,
            subCategory: subCategory
          }) && (
            <InfoCell
              label="Note"
              value={get(data, 'priceNotes', '')}
              isEditing={isEditing && canEditTargetPrice}
              inputConfig={{
                onChange: (value: string) => updateData('priceNotes', value)
              }}
            />
          )}
        </BoxWrapper>
      </>
    </>
  )
}

export default TargetPrice
