import { createAction } from '@reduxjs/toolkit'
import {
  PRODUCT_ADD_TOOLING,
  PRODUCT_CREATE,
  PRODUCT_FILTER_TAXONOMIES,
  PRODUCT_SELECT_FSCHEMA
} from './constants'

export const prodActions = {
  addTooling: createAction<{ selected: any[] }>(PRODUCT_ADD_TOOLING),
  selectFSchema: createAction<{ idx: number; selected: any[] }>(PRODUCT_SELECT_FSCHEMA),
  createProduct: createAction(PRODUCT_CREATE),
  filterTaxonomies: createAction<{
    filters: Record<string, any>
  }>(PRODUCT_FILTER_TAXONOMIES)
}
