import { Button } from 'antd'
import styled, { css } from 'styled-components'
import { IStyledButton, TIconPosition } from './types'

export const StyledButton = styled(Button)<IStyledButton>`
  min-width: ${({ label }) => (label ? '50px' : '30px')};
  ${({ label }) =>
    label
      ? css`
          display: flex;
          align-items: center;
        `
      : css`
          padding: 0px;
        `};
  ${({ type, theme }) => type === 'text' && { color: theme.palette.primary.main }};
  color: ${({ color }) => color};
  .button-label {
    flex: 1 1 auto;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const IconContainer = styled.span<{ label?: string; iconPosition: TIconPosition }>`
  ${({ label, iconPosition }) => {
    if (label && iconPosition === 'right')
      return css`
         {
          margin-left: 8px;
        }
      `
    if (label && iconPosition === 'left')
      return css`
         {
          margin-right: 8px;
        }
      `
  }}
`
