import { isEmpty } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../components'
import { Modal } from '../../../components/Modal'
import { appActions } from '../../../features/app'
import { cartActions, cartSliceActions } from '../../../features/cart'
import {
  selectCartError,
  selectCartLoader,
  selectCartSuccess
} from '../../../features/cart/selectors'
import { getMaxQuantity } from '../../../features/storeProducts/selectors'
import AddCartModalContent from './ModalContent'
import { IAddCartModalProps } from './types'

const AddCartModal: FC<IAddCartModalProps> = ({
  onOpen = () => {},
  productCode,
  modalKey,
  btnLabel = 'Add to cart',
  btnIcon = '',
  btnType = 'primary'
}) => {
  const dispatch = useDispatch()

  const [quantity, setQuantity] = useState(1)

  const cartLoader = useSelector(selectCartLoader)
  const cartError = useSelector(selectCartError)
  const successInfo = useSelector(selectCartSuccess)
  const availableQuantity = useSelector(getMaxQuantity)

  const closeModalHandler = () => {
    setQuantity(1)
    dispatch(appActions.closeModal({ modalKey }))
  }
  const hasError = !isEmpty(cartError)

  const addCartHandler = () => {
    if (productCode) {
      dispatch(
        cartActions.addItemToCart({
          productCode,
          quantity
        })
      )
    }
  }

  const openHandler = () => {
    resetHandler()
    onOpen()
  }

  const resetHandler = () => {
    closeModalHandler()
    dispatch(cartSliceActions.clearError())
    dispatch(cartSliceActions.clearSuccess())
  }

  const footer =
    !hasError && !successInfo
      ? [
          <Button
            key="cancel"
            label="Cancel"
            onClick={closeModalHandler}
            variant="link"
            disabled={cartLoader}
          />,
          <Button
            key="add"
            label="Add"
            onClick={addCartHandler}
            disabled={quantity > availableQuantity || cartLoader}
          />
        ]
      : []

  return (
    <Modal
      btnName={btnLabel}
      btnIcon={btnIcon}
      size={!successInfo ? 'small' : 'medium'}
      title=""
      modalKey={modalKey}
      isSmall
      footer={footer}
      centered
      btnClicked={openHandler}
      btnType={btnType}
    >
      <AddCartModalContent
        availableQuantity={availableQuantity}
        quantity={quantity}
        onResetError={resetHandler}
        onUpdateQuantity={(value) => setQuantity(value)}
        onCloseModal={closeModalHandler}
      />
    </Modal>
  )
}

export default AddCartModal
