import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import {
  IMasterSpec,
  IMasterSpecDetail,
  IMasterSpecState,
  ISuccessInfo,
  BaseSpecificationCharacteristic,
  MasterSpecCharacteristic,
  IProjectSpecDetail,
  ProjectSpecCharacteristic
} from './model'

const initialState: IMasterSpecState = {
  items: [],
  projectSpecificationList: [],
  addNewList: [],
  detail: {},
  projectSpecificationDetail: {},
  characteristicList: [],
  newMasterSpecification: {
    title: '',
    supplierName: '',
    supplierItemCode: '',
    masterSpecificationCharacteristics: []
  },
  newProjectSpecification: {},
  newCharacteristic: {},
  newProjectCharacteristic: {},
  pagination: {
    totalPages: 0,
    page: 1,
    pageSize: 10,
    total: 0
  },
  isLoading: false,
  error: {},
  specificationSections: [],
  alert: ''
}

const masterSpecSlice = createSlice({
  name: 'masterSpec',
  initialState: initialState,
  reducers: {
    setList: (state: IMasterSpecState, { payload }: PayloadAction<IMasterSpec[]>) => {
      state.items = payload
    },
    setAddNewList: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpec[]>
    ) => {
      state.addNewList = payload
    },
    setProjectSpecificationList: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpec[]>
    ) => {
      state.projectSpecificationList = payload
    },
    setDetail: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpecDetail>
    ) => {
      state.detail = payload
      state.newMasterSpecification = payload
    },
    setProjectDetail: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpecDetail>
    ) => {
      state.projectSpecificationDetail = payload
      state.newProjectSpecification = payload
    },
    setProjectSpecificationDetail: (
      state: IMasterSpecState,
      { payload }: PayloadAction<IMasterSpecDetail>
    ) => {
      state.projectSpecificationDetail = payload
      state.newProjectSpecification = payload
    },
    setCharacteristics: (
      state: IMasterSpecState,
      { payload }: PayloadAction<BaseSpecificationCharacteristic[]>
    ) => {
      state.characteristicList = payload
    },
    setNewCharacteristic: (
      state: IMasterSpecState,
      { payload }: PayloadAction<Partial<MasterSpecCharacteristic>>
    ) => {
      state.newCharacteristic = payload
    },
    setNewProjectCharacteristic: (
      state: IMasterSpecState,
      { payload }: PayloadAction<Partial<ProjectSpecCharacteristic>>
    ) => {
      state.newProjectCharacteristic = payload
    },
    updateNewMasterSpecification: (
      state: IMasterSpecState,
      { payload }: PayloadAction<{ key: keyof IMasterSpecDetail; value: any }>
    ) => {
      const { key, value } = payload
      state.newMasterSpecification[key] = value
    },
    updateNewProjectSpecification: (
      state: IMasterSpecState,
      { payload }: PayloadAction<{ key: keyof IProjectSpecDetail; value: any }>
    ) => {
      const { key, value } = payload
      state.newProjectSpecification[key] = value
    },
    updateNewCharacteristic: (
      state: IMasterSpecState,
      { payload }: PayloadAction<{ key: keyof MasterSpecCharacteristic; value: any }>
    ) => {
      const { key, value } = payload
      state.newCharacteristic[key] = value
    },
    updateNewProjectCharacteristic: (
      state: IMasterSpecState,
      { payload }: PayloadAction<{ key: keyof ProjectSpecCharacteristic; value: any }>
    ) => {
      const { key, value } = payload
      state.newProjectCharacteristic[key] = value
    },
    setError: (state: IMasterSpecState, { payload }: PayloadAction<IError>) => {
      state.error = getCleanError(payload)
    },
    setAlert: (state: IMasterSpecState, { payload }: any) => {
      state.alert = payload
    },
    clearError: (state: IMasterSpecState) => {
      state.error = initialState.error
    },
    setSuccess: (state: IMasterSpecState, { payload }: PayloadAction<ISuccessInfo>) => {
      state.successInfo = payload
    },
    clearSuccess: (state: IMasterSpecState) => {
      state.successInfo = initialState.successInfo
    },
    setLoader: (state: IMasterSpecState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setSpecificationSections: (state: IMasterSpecState, { payload }: any) => {
      state.specificationSections = payload
    },
    resetNew: (state: IMasterSpecState) => {
      state.newMasterSpecification = initialState.newMasterSpecification
    }
  }
})

export const masterSpecSliceActions = masterSpecSlice.actions

export default masterSpecSlice.reducer
