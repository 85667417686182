import { AnyAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { selectUserEmail } from '../users/selectors'
import {
  createNewSupplier,
  getCurrencyRates,
  getGroupNames,
  getSuppliersByFilters,
  importAnagraphic,
  importAttachments,
  importPrice,
  importRelations,
  importSupplier,
  insertAttachment,
  searchOneSupplier,
  setCurrencyRate,
  updateAttachment,
  updateOneSupplier
} from './api'
import { adminActionTypes } from './constants'
import { AdministrationState, INewAttachment, ISupplierFilters } from './model'
import { administrationSliceActions } from './reducer'
import {
  selectAttachments,
  selectSupplierFilters,
  selectSupplierPaginationsNew
} from './selectors'

function* importAnagraphicSaga() {
  try {
    yield put(administrationSliceActions.setIsLoading(true))
    const author: string = yield select(selectUserEmail)
    const form_data = new FormData()
    const anagraphic = window.tmpFiles?.importedAnagraphic
    if (anagraphic) {
      form_data.append('file', anagraphic)
    }
    form_data.append('author', author)
    const importFile: AdministrationState = yield call(importAnagraphic, form_data)
    yield put(administrationSliceActions.setImportedFiles(importFile))
    const failedList = get(importFile, 'failed', [])
    if (!failedList.length) {
      yield put(
        administrationSliceActions.setSuccess({
          status: 'success',
          title: 'IMPORT COMPLETED'
        })
      )
    } else {
      yield put(
        administrationSliceActions.setSuccess({
          status: 'warning',
          title: 'IMPORT NOT COMPLETED'
        })
      )
    }
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* importPriceSaga() {
  try {
    yield put(administrationSliceActions.setIsLoading(true))
    const author: string = yield select(selectUserEmail)
    const form_data = new FormData()
    const price = window.tmpFiles?.importedPrice
    if (price) {
      form_data.append('file', price)
    }
    form_data.append('author', author)
    const importFile: AdministrationState = yield call(importPrice, form_data)
    yield put(administrationSliceActions.setImportedFiles(importFile))
    const failedList = get(importFile, 'failed', [])
    if (!failedList.length) {
      yield put(
        administrationSliceActions.setSuccess({
          status: 'success',
          title: 'IMPORT COMPLETED'
        })
      )
    } else {
      yield put(
        administrationSliceActions.setSuccess({
          status: 'warning',
          title: 'IMPORT NOT COMPLETED'
        })
      )
    }
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* importRelationsSaga() {
  try {
    yield put(administrationSliceActions.setIsLoading(true))
    const author: string = yield select(selectUserEmail)
    const form_data = new FormData()
    const relation = window.tmpFiles?.importedRelation
    if (relation) {
      form_data.append('file', relation)
    }
    form_data.append('author', author)
    const importFile: AdministrationState = yield call(importRelations, form_data)
    yield put(administrationSliceActions.setImportedFiles(importFile))
    const failedList = get(importFile, 'failed', [])
    if (!failedList.length) {
      yield put(
        administrationSliceActions.setSuccess({
          status: 'success',
          title: 'IMPORT COMPLETED'
        })
      )
    } else {
      yield put(
        administrationSliceActions.setSuccess({
          status: 'warning',
          title: 'IMPORT NOT COMPLETED'
        })
      )
    }
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* importSupplierSagaNew({ payload }: AnyAction) {
  yield put(administrationSliceActions.setIsLoading(true))
  try {
    const author: string = yield select(selectUserEmail)
    const form_data = new FormData()
    const supplier = window.tmpFiles?.importedSupplier
    if (supplier) {
      form_data.append('file', supplier)
    }
    form_data.append('author', author)
    const importedSupplier: AdministrationState = yield call(importSupplier, form_data)
    yield put(administrationSliceActions.setImportedFiles(importedSupplier))
    const failedList = get(importedSupplier, 'failed', [])
    if (!failedList.length) {
      yield put(
        administrationSliceActions.setSuccess({
          status: 'success',
          title: 'IMPORT COMPLETED'
        })
      )
    } else {
      yield put(
        administrationSliceActions.setSuccess({
          status: 'warning',
          title: 'IMPORT NOT COMPLETED'
        })
      )
    }
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
    yield
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* fetchAttachmentsSaga() {
  yield put(administrationSliceActions.setIsLoading(true))

  try {
    const response: Record<string, any> = yield call(importAttachments)
    const attachmentContentTypes = get(response, 'attachmentContentTypes', [])
    yield put(administrationSliceActions.setImportedAttachments(attachmentContentTypes))
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* fetchAttachmentDetail({ payload }: AnyAction) {
  const { id } = payload
  yield put(administrationSliceActions.setIsLoading(true))
  try {
    const attachmentList: Record<string, any>[] = yield select(selectAttachments)
    const attachment: Record<string, any> = yield attachmentList.find(
      (att: Record<string, any>) => att.id === id
    )
    yield put(administrationSliceActions.setAttachmentDetail(attachment))
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* saveAttachmentSaga({ payload }: AnyAction): Record<string, any> {
  const { attachData, successCb = () => {} } = payload
  yield put(administrationSliceActions.setIsLoading(true))
  try {
    const { id, name, idDbPackage, sapCodeEnabled } = attachData
    const body = {
      name,
      idDbPackage,
      isImage: false,
      sapCodeEnabled
    }
    yield call(updateAttachment, id, body)
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
    yield successCb()
  }
}

function* createAttachmentSaga({ payload }: AnyAction) {
  const { attachData } = payload
  yield put(administrationSliceActions.setIsLoading(true))
  try {
    const body: INewAttachment = {
      idDbPackage: attachData.idDbPackage,
      isImage: attachData.isImage,
      name: attachData.name || '',
      sapCodeEnabled: attachData.sapCodeEnabled || false
    }
    yield call(insertAttachment, body)
    yield put(administrationSliceActions.setSuccess({ message: 'Attachment Created' }))
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* searchSupplierSaga() {
  yield put(administrationSliceActions.setIsLoading(false))
  try {
    const filters: ISupplierFilters = yield select(selectSupplierFilters)
    const { page: selectedPage, pageSize: selectedPageSize } = yield select(
      selectSupplierPaginationsNew
    )
    const suppliers: Record<string, any> = yield call(getSuppliersByFilters, {
      ...filters,
      page: selectedPage,
      pageSize: selectedPageSize
    })

    const { results, totalPages, page, pageSize, total } = suppliers
    yield put(
      administrationSliceActions.setSupplierPaginationNew({
        totalPages,
        page,
        pageSize,
        total
      })
    )
    yield put(administrationSliceActions.setSuppliers(results))
  } catch (err: any) {
    yield put(administrationSliceActions.setSuppliers([]))
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* createSupplierSaga({ payload }: AnyAction) {
  const { supplierData } = payload
  yield put(administrationSliceActions.setIsLoading(true))
  try {
    const author: string = yield select(selectUserEmail)
    yield call(createNewSupplier, { ...supplierData, author })
    yield put(
      administrationSliceActions.setSuccess({
        title: 'Supplier created succesfully'
      })
    )
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* fetchSupplierDetail({ payload }: AnyAction) {
  const { id } = payload
  yield put(administrationSliceActions.setIsLoading(true))
  try {
    const supplierDetail: Record<string, any> = yield call(searchOneSupplier, id)
    yield put(administrationSliceActions.setSupplierDetail(supplierDetail))
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* updateSupplierSaga({ payload }: AnyAction) {
  const { data, id, successCb = () => {} } = payload
  yield put(administrationSliceActions.setIsLoading(true))
  try {
    yield call(updateOneSupplier, {
      data,
      id
    })
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
    successCb()
  }
}

function* findSapCode({ payload }: AnyAction): any {
  const { value } = payload
  try {
    yield delay(1500)
    const { results } = yield call(getSuppliersByFilters, { SAPCodes: value })
    const usedSapCodes = results.find(
      ({ SAPCodes }: Record<string, any>) => SAPCodes === value
    )
    yield put(administrationSliceActions.setSapCodeWarning(usedSapCodes))
  } catch (err) {
    console.log(err)
  }
}

function* getCurrencyRatesSaga() {
  yield put(administrationSliceActions.setIsLoading(true))

  try {
    const response: Record<string, any>[] = yield call(getCurrencyRates)
    yield put(administrationSliceActions.setCurrencyRates(response))
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* setCurrencyRateSaga({ payload }: AnyAction): Record<string, any> {
  const { id, data } = payload
  yield put(administrationSliceActions.setIsLoading(true))

  try {
    yield call(setCurrencyRate, {
      id,
      data
    })
    yield call(getCurrencyRatesSaga)
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
    yield put(administrationSliceActions.setIsLoading(false))
  }
}

function* getGroupNamesSaga() {
  try {
    const resp: Record<string, any>[] = yield call(getGroupNames)
    const result: Record<string, any>[] = resp.map((item) => {
      return { name: item.groupName, value: item.groupName }
    })
    yield put(administrationSliceActions.setGroupNamesDropdown(result))
  } catch (err: any) {
    yield put(administrationSliceActions.setError(err))
  } finally {
  }
}

export default function* administrationSaga() {
  yield takeLatest(
    adminActionTypes.ADMINISTRATION_IMPORT_ANAGRAPHIC,
    importAnagraphicSaga
  )
  yield takeLatest(adminActionTypes.ADMINISTRATION_IMPORT_PRICE, importPriceSaga)
  yield takeLatest(adminActionTypes.ADMINISTRATION_IMPORT_RELATIONS, importRelationsSaga)
  yield takeLatest(adminActionTypes.ADMINISTRATION_IMPORT_SUPPLIER, importSupplierSagaNew)
  yield takeLatest(adminActionTypes.ADMINISTRATION_GET_ATTACHMENTS, fetchAttachmentsSaga)
  yield takeLatest(adminActionTypes.ADMINISTRATION_CHECK_SUPPLIER, findSapCode)
  yield takeLatest(
    adminActionTypes.ADMINISTRATION_GET_ATTACHMENTS_DETAIL,
    fetchAttachmentDetail
  )
  yield takeLatest(adminActionTypes.ADMINISTRATION_UPDATE_ATTACHMENT, saveAttachmentSaga)
  yield takeLatest(
    adminActionTypes.ADMINISTRATION_CREATE_ATTACHMENTS,
    createAttachmentSaga
  )
  yield takeLatest(adminActionTypes.ADMINISTRATION_SEARCH_SUPPLIER, searchSupplierSaga)
  yield takeLatest(adminActionTypes.ADMINISTRATION_CREATE_SUPPLIER, createSupplierSaga)
  yield takeLatest(
    adminActionTypes.ADMINISTRATION_GET_SUPPLIER_DETAIL,
    fetchSupplierDetail
  )
  yield takeLatest(adminActionTypes.ADMINISTRATION_UPDATE_SUPPLIER, updateSupplierSaga)
  yield takeLatest(
    adminActionTypes.ADMINISTRATION_GET_CURRENCY_RATES,
    getCurrencyRatesSaga
  )
  yield takeLatest(adminActionTypes.ADMINISTRATION_SET_CURRENCY_RATE, setCurrencyRateSaga)
  yield takeLatest(adminActionTypes.ADMINISTRATION_GET_GROUP_NAMES, getGroupNamesSaga)
}
