import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appSliceActions } from '../../features/app'
import { selectIsModalLoading, selectModalKey } from '../../features/app/selectors'
import { useModalListener } from '../../utils/useModalListener'
import Button from '../Button'
import { Loader } from '../Loader'
import { LoaderWrapper, StyledModal } from './styled'
import { IModalProps } from './types'

const sizeMap = {
  small: 520,
  medium: 700,
  large: '70vw',
  xLarge: '90vw'
}

export const Modal: FC<IModalProps> = ({
  modalKey,
  title,
  children,
  size,
  btnName,
  btnType = 'primary',
  btnShape = 'round',
  btnClicked = () => {},
  onClose = () => {},
  isSmall = false,
  okText = 'Ok',
  btnIcon,
  footer,
  centered = false,
  disabled = false,
  iconPosition = 'left',
  btnTooltipConfig = null
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const dispatch = useDispatch()
  const currentModalKey = useSelector(selectModalKey)
  const isLoading = useSelector(selectIsModalLoading)

  useEffect(() => {
    if (!currentModalKey) setIsModalVisible(false)
    if (currentModalKey === modalKey) setIsModalVisible(true)
  }, [currentModalKey, modalKey])

  const showModal = () => {
    btnClicked()
    dispatch(appSliceActions.setModalKey(modalKey))
    setIsModalVisible(true)
    dispatch(appSliceActions.setModalKey(modalKey))
  }

  const handleOk = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    onClose()
    setIsModalVisible(false)
    dispatch(appSliceActions.setModalKey())
  }

  useModalListener(modalKey, handleCancel)

  return (
    <>
      <Button
        label={btnName}
        onClick={showModal}
        variant={btnType}
        shape={btnShape}
        iconName={btnIcon}
        disabled={disabled}
        iconPosition={iconPosition}
        enableTooltip={!!btnTooltipConfig?.title}
        tooltipLabel={btnTooltipConfig?.title || ''}
      />

      <StyledModal
        width={sizeMap[size]}
        size={size}
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={!!footer?.length ? footer : null}
        isSmall={isSmall}
        okText={okText}
        centered={centered}
        hasFooter={!!footer?.length}
      >
        {isLoading ? (
          <LoaderWrapper size={size}>
            <Loader />
          </LoaderWrapper>
        ) : (
          React.Children.map(children, (item: any) =>
            React.cloneElement(item, { modalKey })
          )
        )}
      </StyledModal>
    </>
  )
}
