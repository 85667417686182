import { TABLE_DATA_TYPE } from '../../Table/constants'
import { ITableConfig } from '../../Table/types'

export const PRODUCT_VARIANTS_TABLE_CONFIG = {
  idPackaging: {
    dataPath: 'idPackaging',
    hide: true
  },
  id: {
    dataPath: 'id',
    hide: true
  },
  variantCode: {
    label: 'Variant Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'variantCode'
  },
  variantSupplierCode: {
    label: 'Variant Supplier Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'variantSupplierCode'
  },
  bottle: {
    label: 'Bottle',
    dataType: TABLE_DATA_TYPE.ARRAY,
    dataPath: 'bottle'
  },
  dipstick: {
    label: 'Stem',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'dipstick'
  },
  wiper: {
    label: 'Wiper',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'wiper'
  },
  applicator: {
    label: 'Applicator',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'applicator'
  },
  applicatorNumber: {
    label: 'Applicator Number',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'applicatorNumber'
  }
}

export const VARIANTS_TABLE_CONFIG = {
  idPackaging: {
    dataPath: 'idPackaging',
    hide: true
  },
  id: {
    dataPath: 'id',
    hide: true
  },
  variantSupplierCode: {
    label: 'Variant Supplier Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.variantSupplierCode'
  },
  bottle: {
    label: 'Bottle',
    dataType: TABLE_DATA_TYPE.ARRAY,
    dataPath: 'material'
  },
  dipstick: {
    label: 'Stem',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.dipstick'
  },
  wiper: {
    label: 'Wiper',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.wiper'
  },
  applicator: {
    label: 'Applicator',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.applicator'
  },
  applicatorNumber: {
    label: 'Applicator Number',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.applicatorNumber'
  }
}

export const VARIANTS_EDIT_PAGE_TABLE_CONFIG: ITableConfig = {
  idPackaging: {
    dataPath: 'idPackaging',
    hide: true
  },
  id: {
    dataPath: 'id',
    hide: true
  },
  variantCode: {
    label: 'Variant Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'variantCode',
    showSortable: true
  },
  variantSupplierCode: {
    label: 'Variant Supplier Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.variantSupplierCode',
    showSortable: true
  },
  bottle: {
    label: 'Bottle',
    dataType: TABLE_DATA_TYPE.ARRAY,
    dataPath: 'vialDetails.bottle',
    showSortable: true
  },
  dipstick: {
    label: 'Stem',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.dipstick',
    showSortable: true
  },
  wiper: {
    label: 'Wiper',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.wiper',
    showSortable: true
  },
  applicator: {
    label: 'Applicator',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.applicator',
    showSortable: true
  },
  applicatorNumber: {
    label: 'Applicator Number',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.applicatorNumber',
    showSortable: true
  }
}

export const VARIANTS_SIDE_EDIT_PAGE_TABLE_CONFIG: ITableConfig = {
  idPackaging: {
    dataPath: 'idPackaging',
    hide: true
  },
  id: {
    dataPath: 'id',
    hide: true
  },
  variantCode: {
    label: 'Variant Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'variantCode',
    showSortable: true
  },
  variantSupplierCode: {
    label: 'Variant Supplier Code',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.variantSupplierCode',
    showSortable: true
  },
  bottle: {
    label: 'Bottle',
    dataType: TABLE_DATA_TYPE.ARRAY,
    dataPath: 'material',
    showSortable: true
  },
  dipstick: {
    label: 'Stem',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.dipstick',
    showSortable: true
  },
  wiper: {
    label: 'Wiper',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.wiper',
    showSortable: true
  },
  applicator: {
    label: 'Applicator',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.applicator',
    showSortable: true
  },
  applicatorNumber: {
    label: 'Applicator Number',
    dataType: TABLE_DATA_TYPE.TEXT,
    dataPath: 'vialDetails.applicatorNumber',
    showSortable: true
  }
}
