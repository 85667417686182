import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin: 0 10px;
  }
`
