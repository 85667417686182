import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import SupplierForm from '../../containers/Administration/SupplierForm'
import { ITempFields } from '../../containers/Administration/SupplierForm/types'
import {
  administrationActions,
  administrationSliceActions
} from '../../features/administration'
import {
  selectAdminError,
  selectUpdateSupplierData
} from '../../features/administration/selectors'
import { selectIsLoading } from '../../features/app/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'
import { footerActionHeight } from '../CreationPackage/styled'
import { ButtonWrapper } from './styled'

const SupplierDetail: FC = () => {
  const adminError = useSelector(selectAdminError)
  const isLoading = useSelector(selectIsLoading)
  const supplierData = useSelector(selectUpdateSupplierData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(administrationActions.getSupplierDetail({ id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const backHandler = () => {
    navigate(-1)
  }

  const saveHandler = () => {
    dispatch(
      administrationActions.updateSupplier({
        data: supplierData,
        id: id as string,
        successCb: () => navigate(-1)
      })
    )
  }

  const updateHandler = (key: keyof ITempFields, value: any) => {
    const newData = { ...supplierData, [key]: value }
    dispatch(administrationSliceActions.setSupplierDetail(newData))
  }

  return (
    <GenericLayout error={adminError}>
      <PageContent
        headerHeight={headerHeight}
        footerHeight={footerHeight + footerActionHeight}
      >
        <Breadcrumb
          items={[
            {
              name: 'Administration',
              href: AppRoutes.VAULT_ADMINISTRATION
            },
            {
              name: 'edit supplier'
            }
          ]}
        />
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <Divider disableBorder />
            <SectionTitle
              value="Edit Supplier"
              addon={
                <ButtonWrapper>
                  <Button label="Cancel" variant="link" onClick={backHandler} />
                  <Button label="Save" onClick={saveHandler} />
                </ButtonWrapper>
              }
            />
            <SupplierForm data={supplierData} onUpdate={updateHandler} />
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default SupplierDetail
