import { FC } from 'react'
import Button from '../../Button'
import { Text } from '../../Text'
import {
  ChartLegendCard,
  ChartLegendColor,
  ChartLegendContainer,
  ChartLegendDisclaimer,
  ChartLegendLabel,
  CheckBoxContainer
} from './styled'
import { IChartLegendProps, TSelectAllHandlerFunc } from './types'

const ChartLegend: FC<IChartLegendProps> = ({
  dataList,
  onSelect = () => {},
  onSelectAll = () => {},
  hideSelectAll
}) => {
  const orderedList = dataList.sort(({ label: labelA }, { label: labelB }) => {
    if (typeof labelA === 'string' && typeof labelB === 'string') {
      return labelA.localeCompare(labelB)
    }
    return 0
  })

  const isAllSelected = !orderedList.some(({ isSelected }) => !isSelected)
  const isAllDeselected = !orderedList.some(({ isSelected }) => isSelected)

  const selectAllHandler: TSelectAllHandlerFunc = (type) => {
    if (type === 'hide') {
      const idList = orderedList.map(({ id }) => id)
      return onSelectAll(idList)
    }
    onSelectAll([])
  }

  return (
    <ChartLegendContainer className="chart-legend-container">
      {!!orderedList.length && (
        <ChartLegendDisclaimer>
          <Text
            text="*To show or hide values on the chart, please select or deselect the legend options"
            italic
          />
          {!hideSelectAll && (
            <CheckBoxContainer>
              <Button
                label="Hide All"
                onClick={() => selectAllHandler('hide')}
                variant="link"
                disabled={isAllSelected}
              />
              <Button
                label="Show All"
                onClick={() => selectAllHandler('show')}
                variant="link"
                disabled={isAllDeselected}
              />
            </CheckBoxContainer>
          )}
        </ChartLegendDisclaimer>
      )}
      <div className="chart-legend-cards-container">
        {orderedList.map((data) => (
          <ChartLegendCard
            className="chart-legend-card"
            onClick={() => onSelect(data.id)}
          >
            <ChartLegendColor
              backgroundColor={data.backgroundColor || 'black'}
              borderColor={data.borderColor || 'transparent'}
            />
            <ChartLegendLabel isSelected={data.isSelected}>{data.label}</ChartLegendLabel>
          </ChartLegendCard>
        ))}
      </div>
    </ChartLegendContainer>
  )
}

export default ChartLegend
