import { TablePaginationConfig } from 'antd'
import {
  IExcludeSelectConfig,
  IPageData,
  ISortData,
  TTablePaginationPosition
} from '../../../../components/Table/types'

export interface IProductsTableProps {
  dataList: Record<string, any>[]
  pageData: IPageData
  isLoading: boolean
  enablePagination: boolean
  onChangePage?: (pageData: TablePaginationConfig, sortData?: ISortData) => void
  onSelect?: (dataList: Record<string, any>[]) => void
  selectedRow?: Record<string, any>[]
  exclusionConfig?: IExcludeSelectConfig
  enableRowSelect?: boolean
  disableHeaderSelect?: boolean
  labelOnSelect?: string
  selectLimit?: number | null
  paginationPosition?: TTablePaginationPosition
}

export interface WarningsJSON {
  type: STATUS_WARN
  formulaCodeDEVEX?: string
  newFormulaSchemaCode?: string
  changeNotes?: string
}

export enum STATUS_WARN {
  PACKAGE = 'PACKAGE',
  FSCHEMA = 'FORMULA_SCHEMA',
  FORMULA = 'FORMULA',
  FORMULA_FSCHEMA_LINK = 'FORMULA_FS_LINK',
  TOOLING = 'TOOLING'
}
