import { TOrderListColors, TOrderListTab } from './models'

export const orderListActionTypes = {
  SEARCH_ORDERS: 'storeOrders/SEARCH_ORDERS',
  CHANGE_TAB_AND_SEARCH: 'storeOrders/CHANGE_TAB_AND_SEARCH',
  APPLY_FILTERS_AND_SEARCH: 'storeOrders/APPLY_FILTERS_AND_SEARCH',
  REMOVE_FILTER_AND_SEARCH: 'storeOrders/removeFilterSaga',
  CHANGE_PAGE_AND_SEARCH: 'storeOrders/CHANGE_PAGE_AND_SEARCH',
  FETCH_ORDER_DETAILS: 'storeOrders/FETCH_ORDER_DETAILS',
  FETCH_LATEST_ORDER: 'storeOrders/FETCH_LATEST_ORDER',
  CONFIRM_REJECT_ORDER: 'storeOrders/CONFIRM_REJECT_ORDER',
  CONFIRM_ACCEPT_ORDER: 'storeOrders/CONFIRM_ACCEPT_ORDER',
  CONFIRM_PROPOSE_ORDER: 'storeOrders/CONFIRM_PROPOSE_ORDER',
  CONFIRM_PROCESS_ORDER: 'storeOrders/CONFIRM_PROCESS_ORDER',
  CHECK_ITEM_AVAILABILITY_FOR_PROPOSE: 'storeOrders/CHECK_ITEM_AVAILABILITY_FOR_PROPOSE'
}

export const ORDER_LIST_TABS: TOrderListTab[] = [
  {
    label: 'Forwarded',
    value: 'FORWARDED'
  },
  {
    label: 'Accepted',
    value: 'ACCEPTED'
  },
  {
    label: 'Processed',
    value: 'PROCESSED'
  },
  {
    label: 'Refused',
    value: 'REFUSED'
  }
]

export const LATEST_ORDER_COOKIE_NAME = 'intercos-latest-order'
// NOTE ===== LATEST_ORDER_COOKIE_NAME STRUCTURE ====
// {
//     FORWARDED: {
//        updatedDate: ''
// }
//     REFUSED: {
//        updatedDate: ''
// }
// }
//

export const ORDER_STATUS_COLOR: TOrderListColors = {
  FORWARDED: 'grey',
  ACCEPTED: 'green',
  PROCESSED: 'green',
  REFUSED: 'red'
}

export const REFUSED_REASONS = {
  UNDELIVERABLE_AT_ADDRESS: 'UNDELIVERABLE_AT_ADDRESS'
}
