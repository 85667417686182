import { FC, memo } from 'react'
import { StyledBoxWrapper } from './styled'
import { TBoxWrapperProps } from './types'

export const BoxWrapper: FC<TBoxWrapperProps> = memo(
  ({
    children,
    flexDirection,
    justify = 'flex-start',
    borderTop,
    borderBottom,
    columnCount,
    enablePadding,
    enableMargin,
    display,
    sm = 2,
    md = 3,
    lg = 4
  }) => {
    const size = {
      sm: sm,
      md: sm >= md ? sm : md,
      lg: sm >= lg ? (sm >= md ? sm : md) : lg
    }

    return (
      <StyledBoxWrapper
        display={display}
        enableMargin={enableMargin}
        flexDirection={flexDirection}
        justifyContent={justify}
        columnCount={columnCount}
        size={size}
        enablePadding={enablePadding}
        borderBottom={borderBottom ? '1px solid #d6d6d6' : 'none'}
        borderTop={borderTop ? '1px solid #d6d6d6' : 'none'}
      >
        {children}
      </StyledBoxWrapper>
    )
  }
)
