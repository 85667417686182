import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, SectionTitle } from '../../../components'
import { Divider } from '../../../components/Divider'
import { FlexSection } from '../../../components/FlexSection'
import { cartActions, cartSliceActions } from '../../../features/cart'
import { ItemInCart } from '../../../features/cart/model'
import { getCartItems } from '../../../features/cart/selectors'
import { AppRoutes } from '../../../pages/constants'
import { CartItemCard } from './CartItemCard'
import { EmptyCart } from './EmptyCart'
import { CardItemWrapper } from './styled'

export const Step1Cart = () => {
  const dispatch = useDispatch()
  const products = useSelector(getCartItems)
  const navigate = useNavigate()

  const goToSearch = () => {
    navigate(AppRoutes.STORE_PRODUCTS_SEARCH)
  }

  const updateCardHandler = (key: keyof ItemInCart, value: any, idx: number) => {
    dispatch(cartSliceActions.updateCartItemByIdx({ key, value, idx }))
  }

  const updateItemsAvailability: (arg?: Record<string, any>[]) => void = (
    updatedProds = []
  ) => {
    if (updatedProds.length) {
      updatedProds.forEach(({ productCode, availableQuantity }: any) => {
        const prodData = products.find(
          ({ productCode: itemCode }) => productCode === itemCode
        )
        if (prodData) {
          const newprod = {
            ...prodData,
            item: {
              ...prodData.item,
              availableQuantity
            }
          }
          dispatch(
            cartSliceActions.updateCartItemById({ id: prodData.id, itemData: newprod })
          )
        }
      })
    }
  }

  useEffect(() => {
    dispatch(
      cartActions.checkAvailability({
        items: products,
        warningCallback: updateItemsAvailability
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <FlexSection flexDirection="row" justifyContent="space-between" alignItems="center">
        <SectionTitle value="Selected Products" />
        {!products.length && (
          <Button
            iconName="PlusOutlined"
            variant="link"
            label="Add"
            onClick={goToSearch}
          />
        )}
      </FlexSection>
      <Divider />
      {!products.length ? (
        <EmptyCart />
      ) : (
        // TODO - consider to substitute this list with ProductList
        products.map((prod, idx) => (
          <CardItemWrapper key={prod.id}>
            <CartItemCard
              data={prod}
              isEditable
              onUpdate={(key, value) =>
                updateCardHandler(key as keyof ItemInCart, value, idx)
              }
            />
          </CardItemWrapper>
        ))
      )}
      <Divider disableBorder={true} />
    </>
  )
}
