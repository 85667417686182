import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, SectionTitle } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import FooterControl from '../../../components/FooterControl'
import { Upload } from '../../../components/Form/Upload'
import { Text } from '../../../components/Text'
import { Title } from '../../../components/Title'
import {
  administrationActions,
  administrationSliceActions
} from '../../../features/administration'
import { fileSaver } from '../../../utils/fileSaver'
import { AppRoutes } from '../../constants'
import { ISupplierImportProps } from './types'

const SupplierImport: FC<ISupplierImportProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [importedSupplier, setImportedSupplier] = useState(null)

  const baseUrl = process.env.REACT_APP_BASE_URL_IMG_THUMBNAIL

  const updaloadHandler = (value: any) => {
    setImportedSupplier(value)
  }

  const downloadHandler = () => {
    fileSaver(`${baseUrl}templates/supplier.xlsx`)
  }

  const backHandler = () => {
    dispatch(administrationSliceActions.clearType())
    navigate(AppRoutes.VAULT_ADMINISTRATION)
  }

  return (
    <>
      <SectionTitle value="Administration" isBold />
      <Divider disableBorder />
      <Title title={'Supplier Import'} variantTitle={'h3'} enableBold />
      <Text text={'Remember to import the related Supplier first'} variant={'h4'} alert />
      <BoxWrapper justify="space-between" columnCount={2} enablePadding>
        <Text text={'Use the attached template to import the Supplier'} variant={'h4'} />
        <Button
          label={'Download'}
          iconName={'DownloadOutlined'}
          variant={'ghost'}
          onClick={downloadHandler}
        />
      </BoxWrapper>
      <Divider disableBorder />
      <Upload
        message={'No file selected'}
        label={''}
        required={false}
        description={'Drop here or browse the .xlsx file contains the Supplier to import'}
        name={'importedSupplier'}
        value={importedSupplier}
        onChange={updaloadHandler}
      />
      <FooterControl>
        <Button label="Back" onClick={backHandler} />
        <Button
          label="Start Import"
          onClick={() => dispatch(administrationActions.importSupplier())}
          disabled={!importedSupplier}
        />
      </FooterControl>
    </>
  )
}

export default SupplierImport
