import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Loader } from '../../components/Loader'
import { Title } from '../../components/Title'
import { PageContent } from '../../containers'
import { MasterSpecificationsEditor } from '../../containers/Packaging/MasterSpecificationsEditor'
import { packagingActions, packagingSliceActions } from '../../features/packaging'
import {
  getIsLoading,
  getPackageDetail,
  selectPackagingError
} from '../../features/packaging/selectors'
import { selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'

export const MasterSpecificationsCreate: FC = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const packagingError = useSelector(selectPackagingError)
  const userRoles = useSelector(selectUserRole)
  const packageDetails = useSelector(getPackageDetail) as Record<string, any>
  const isLoading = useSelector(getIsLoading)

  useEffect(() => {
    dispatch(packagingSliceActions.setFilteredStatus({ roles: userRoles || [] }))
    id && dispatch(packagingActions.getPackagingDetail({ id }))
  }, [dispatch, id, userRoles])

  const hrefPrefix = '/wikipack/packaging'
  const breadcrumbName = `detail packaging ${packageDetails?.packagingCode}`
  const breadcrumbs = [
    { name: 'Packaging', href: hrefPrefix },
    { name: breadcrumbName, href: `${hrefPrefix}/${id}` },
    { name: 'master specifications', href: `${hrefPrefix}/${id}/master-specifications` },
    { name: 'editor' }
  ]

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Breadcrumb items={breadcrumbs} />
            <Title title="Master Specifications Editor" variantTitle="h2" />
            <MasterSpecificationsEditor packagingDetails={packageDetails} />
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}
