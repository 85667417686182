import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LinearScale,
  ChartOptions,
  ChartData
} from 'chart.js'
import { FC, useCallback, useEffect, useState } from 'react'
import { Scatter } from 'react-chartjs-2'
import { generateRGBString, getRandomRGBList } from '../../../utils/colorsHelpers'
import { IColorRGB } from '../../../utils/colorsHelpers/types'
import ChartLegend from '../ChartLegend'
import { drawLabelsPlugin } from '../plugins'
import { ScatterContainer } from './styled'
import { IScatterChartProps, IScatterData, IScatterDataSet } from './types'

ChartJS.register(drawLabelsPlugin, Title, Tooltip, Legend, PointElement, LinearScale)

const ScatterChart: FC<IScatterChartProps> = ({
  axisXLabel,
  axisYLabel,
  legendPosition = 'top',
  dataList,
  range,
  tooltipRenderer,
  onSelectLegend = () => {},
  onSelectAllLegend = () => {},
  selectedLegends = [],
  enableChartLabel = false,
  pointSize,
  pointHoverSize,
  axisXLabelToHide = [],
  axisYLabelToHide = [],
  hideLegendSelectAll,
  axisXStep = 1,
  axisYStep = 1,
  axisXLabelSize,
  axisYLabelSize
}) => {
  const dataLength = dataList.length

  const [colorList, setColorList] = useState<IColorRGB[]>([])

  const getRandomColorListHandler = useCallback(() => {
    const colorList = getRandomRGBList({
      quantity: dataLength,
      excludeList: [{ r: 255, g: 255, b: 255 }]
    })
    setColorList(colorList)
  }, [dataLength])

  useEffect(() => {
    if (dataLength) {
      getRandomColorListHandler()
    }
  }, [dataLength, getRandomColorListHandler])

  const { x: rangeX = {}, y: rangeY = {} } = range || {}

  const { min: minX, max: maxX } = rangeX
  const { min: minY, max: maxY } = rangeY

  const refactoredData: IScatterData[] = dataList.map((value, idx) => ({
    ...value,
    backgroundColor: value.backgroundColor || generateRGBString(colorList[idx] || {}, 1)
  }))

  const filtredData = selectedLegends.length
    ? refactoredData.filter((data) => !selectedLegends.includes(data.id))
    : refactoredData

  const data = {
    datasets: filtredData.map((data) => ({
      label: data.label,
      data: data.dataSets,
      backgroundColor: data.backgroundColor,
      pointRadius: pointSize,
      pointHoverRadius: pointHoverSize
    }))
  }

  const options: ChartOptions<'scatter'> = {
    scales: {
      x: {
        min: minX,
        max: maxX,
        ticks: {
          stepSize: axisXStep,
          font: {
            family: 'Didact Gothic'
          },
          callback: (value) => {
            if (axisXLabelToHide.includes(`${value}`)) {
              return null
            }
            return value
          }
        },
        title: {
          display: true,
          text: axisXLabel,
          font: {
            size: axisXLabelSize,
            family: 'Didact Gothic',
            weight: 900
          }
        }
      },
      y: {
        min: minY,
        max: maxY,
        type: 'linear',
        ticks: {
          stepSize: axisYStep,
          font: {
            family: 'Didact Gothic'
          },
          callback: (value) => {
            if (axisYLabelToHide.includes(`${value}`)) {
              return null
            }
            return value
          }
        },
        title: {
          display: true,
          text: axisYLabel,
          font: {
            size: axisYLabelSize,
            family: 'Didact Gothic',
            weight: 900
          }
        }
      }
    },
    plugins: {
      drawLabelsPlugin: {
        enabled: enableChartLabel
      },
      legend: {
        display: false,
        position: legendPosition
      },
      tooltip: {
        callbacks: {
          label: tooltipRenderer
            ? (context) => {
                const dataPoint = context.raw
                return tooltipRenderer(dataPoint as IScatterDataSet)
              }
            : undefined
        },

        yAlign: 'top',
        xAlign: 'center'
      }
    }
  }

  return (
    <>
      <ScatterContainer className="scatter-chart-container">
        <Scatter data={data as ChartData<'scatter'>} options={options} />
      </ScatterContainer>
      <ChartLegend
        onSelect={onSelectLegend}
        onSelectAll={onSelectAllLegend}
        dataList={refactoredData.map((data, idx) => ({
          id: data.id,
          label: data.dataLegendLabel || data.label,
          isSelected: selectedLegends.includes(data.id),
          backgroundColor: data.backgroundColor
        }))}
        hideSelectAll={hideLegendSelectAll}
      />
    </>
  )
}

export default ScatterChart
