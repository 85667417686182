import { WarningOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`
export const TextBox = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin: 8px 0px;
`
export const WarningWrapper = styled.div`
  border: 2px solid #d04898;
  padding: 8px;
  margin: 8px 8px;
  border-radius: 8px;
`
export const WarningIcon = styled(WarningOutlined)`
  color: #d04898;
  size: large;
  margin: 0 8px 0 0;
  svg {
    height: 20px;
    width: 20px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin: 0 8px;
  }
`

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 0 8px;
  }
`
