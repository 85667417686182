import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { threeOptions } from '../../../features/constants'
import { CATEGORY } from '../../../features/packaging/model'
import {
  getPackagingDropdown,
  selectAllTaxonomies
} from '../../../features/packaging/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import {
  checkFieldVisibilityByCategory,
  checkFieldVisibilityByRoles,
  getDynamicPackDropdownByKey
} from '../../../utils'
import { checkFieldDisability } from '../../../utils/packagingFormHelper'
import { checkRangeOptions, sortItemsRanges } from '../../../utils/sortItemsRanges'
import { BoxWrapper } from '../../BoxWrapper'
import { Divider } from '../../Divider'
import InfoCell from '../../InfoCell'

import { ISideSearchFormProps } from './types'
import { LabelContainer } from './styled'
import Button from '../../Button'

const SideSearchForm: FC<ISideSearchFormProps> = ({
  data,
  isEditing = false,
  updateData = () => {},
  isCreating = false,
  sideNumber = 0
}) => {
  const allTaxonomies = useSelector(selectAllTaxonomies)
  const indipendentDropdowns = useSelector(getPackagingDropdown)
  const userRoles = useSelector(selectUserRole)

  const packageFamily = get(data, `packageFamilies[${sideNumber}]`, {})
  const categoryValue: string = get(packageFamily, 'category', '')
  const subCategoryValue: string = get(packageFamily, 'subcategory', '')

  const catInLowercase = categoryValue.toLowerCase()
  const subCatInLowercase = subCategoryValue.toLowerCase()

  const isVials = catInLowercase === CATEGORY.VIALS

  const cleanValue = get(packageFamily, 'clean', null)
  const vialDetails = get(packageFamily, 'packageVariant.vialDetails', {})

  const isFieldVisible = (key: string) => {
    let visibility = true
    visibility =
      visibility &&
      checkFieldVisibilityByCategory({
        key,
        category: catInLowercase,
        subcategory: subCatInLowercase
      })
    if (key === 'material') {
      visibility =
        visibility &&
        catInLowercase !== CATEGORY.ACCESSORIES &&
        catInLowercase !== CATEGORY.VIALS
    }

    visibility =
      visibility &&
      checkFieldVisibilityByRoles({
        key,
        roles: userRoles,
        isOnCreate: isCreating && isEditing,
        isOnEdit: !isCreating && isEditing,
        isOnRead: !isCreating && !isEditing
      })

    return visibility
  }

  const updatePackageFamily = (key: string, value: any) => {
    let newPackageFam = [...get(data, 'packageFamilies', [])]
    newPackageFam[sideNumber] = { ...newPackageFam[sideNumber], [key]: value }
    if (key === 'clean') {
      newPackageFam[sideNumber]['cleanTypes'] = []
    }
    if (key === 'category') {
      if (value === undefined) {
        newPackageFam[sideNumber] = {}
      } else {
        newPackageFam[sideNumber] = { category: value }
      }
    }
    if (key === 'subcategory') {
      newPackageFam[sideNumber] = {
        category: newPackageFam[sideNumber].category,
        subcategory: value
      }
    }
    updateData('packageFamilies', newPackageFam)
  }

  const updateMultiSelectFamily = (
    key: string,
    inputValue: any,
    isString: boolean = false
  ) => {
    const value = isString ? inputValue : inputValue.map(({ value }: any) => value)
    updatePackageFamily(key, value)
  }

  const isFieldDisable = (key: string) => {
    return checkFieldDisability(key, catInLowercase, subCatInLowercase)
  }

  const updatePackageVariant = (key: string, value: any) => {
    const newPackageVariant = {
      ...get(packageFamily, 'packageVariant', {}),
      vialDetails: {
        ...vialDetails,
        [key]: value
      }
    }
    updatePackageFamily('packageVariant', newPackageVariant)
  }

  const getCategoryDropDown = () => {
    return allTaxonomies.map(({ value }) => ({
      name: value,
      value
    }))
  }

  const getFilteredCategory = () => {
    return allTaxonomies.find(
      ({ value: catValue }: any) => catValue.toLowerCase() === catInLowercase
    )
  }

  const getSubCategoryDropDown = () => {
    const filteredCategory = getFilteredCategory()
    const filteredSubcategory = get(filteredCategory, 'children.subcategory', [])
    return filteredSubcategory.map(({ value }: any) => ({
      name: value,
      value
    }))
  }

  const getDropDown = (key: string) => {
    return getDynamicPackDropdownByKey({
      key,
      category: categoryValue,
      subcategory: subCategoryValue,
      taxonomies: allTaxonomies
    })
  }

  const freeOfMaterialDropdown = getDropDown('freeOfMaterial')

  const subCategoryDropDown = getSubCategoryDropDown()

  const getIndipendentDropdown = (key: string) => {
    return get(indipendentDropdowns, key, [])
  }

  const isSubCategoryDisable =
    !categoryValue || (!!categoryValue && isFieldDisable('subcategory'))
  const isBottomVisible =
    (isEditing &&
      categoryValue &&
      ((subCategoryDropDown.length && subCategoryValue) ||
        !subCategoryDropDown.length)) ||
    !isEditing

  const isCatOthers = catInLowercase === CATEGORY.OTHERS
  const isSizeInput = catInLowercase === CATEGORY.AEROSOL || isCatOthers

  const FreeOfMaterialLabel = (
    <LabelContainer>
      Free-of material
      {isEditing && (
        <Button
          variant="link"
          label="Select All"
          onClick={() =>
            updateMultiSelectFamily('freeOfMaterial', freeOfMaterialDropdown)
          }
        >
          Select All
        </Button>
      )}
    </LabelContainer>
  )

  return (
    <>
      <BoxWrapper columnCount={4} enablePadding>
        <InfoCell
          label="Category"
          value={categoryValue}
          inputType="select"
          isEditing={isEditing}
          inputConfig={{
            items: getCategoryDropDown(),
            onChange: ({ value }: Record<string, any>) =>
              updatePackageFamily('category', value),
            orderItems: true,
            orderKey: 'name'
          }}
        />
        {isFieldVisible('subcategory') && (
          <InfoCell
            label="Subcategory"
            value={subCategoryValue}
            inputType={isCatOthers ? 'input' : 'select'}
            isEditing={isEditing}
            inputConfig={{
              items: subCategoryDropDown,
              onChange: (inputValue: Record<string, any>) =>
                updatePackageFamily(
                  'subcategory',
                  isCatOthers ? inputValue : inputValue.value
                ),
              orderItems: true,
              orderKey: 'name',
              disabled: isSubCategoryDisable
            }}
          />
        )}
      </BoxWrapper>
      <Divider />
      {isBottomVisible && (
        <>
          <BoxWrapper columnCount={4} enablePadding>
            {isFieldVisible('size') && (
              <InfoCell
                label="Size"
                value={get(packageFamily, 'size', isSizeInput || isCreating ? '' : [])}
                inputType={isSizeInput ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('size'),
                  onChange: (inputValue: Record<string, any>) =>
                    !isCreating
                      ? updateMultiSelectFamily('size', inputValue, isSizeInput)
                      : updatePackageFamily(
                          'size',
                          isSizeInput ? inputValue : inputValue.value
                        ),
                  orderItems: true,
                  orderKey: 'name',
                  mode: !isCreating ? 'multiple' : undefined
                }}
              />
            )}
            {isFieldVisible('material') && (
              <InfoCell
                label="Bulk-Contact Material"
                value={get(packageFamily, 'material', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('material'),
                  onChange: (value: Record<string, any>) => {
                    updateMultiSelectFamily('material', value, isCatOthers)
                  },
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('panNumber') && (
              <InfoCell
                label="Pan Number"
                value={get(packageFamily, 'panNumber', '')}
                isEditing={isEditing}
                inputConfig={{
                  onChange: (value: string) => updatePackageFamily('panNumber', value)
                }}
              />
            )}
            {isFieldVisible('tipShape') && (
              <InfoCell
                label="Tip Shape"
                value={get(packageFamily, 'tipShape', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('tipShape'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelectFamily('tipShape', value),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('panShape') && (
              <InfoCell
                label="Pan Shape"
                value={get(packageFamily, 'panShape', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('panShape'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelectFamily('panShape', value, isCatOthers),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('shape') && (
              <InfoCell
                label="Ext. Shape"
                value={get(packageFamily, 'shape', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('shape'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelectFamily('shape', value, isCatOthers),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple',
                  disabled: !categoryValue
                }}
              />
            )}

            {isFieldVisible('technology') && (
              <InfoCell
                label="Technology"
                value={get(packageFamily, 'technology', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('technology'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelectFamily('technology', value, isCatOthers),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('capacityRange') && (
              <InfoCell
                label="Capacity Range"
                value={get(
                  packageFamily,
                  'capacityRange',
                  isCatOthers || isCreating ? '' : []
                )}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: sortItemsRanges(getDropDown('capacityRange')),
                  onChange: (inputValue: Record<string, any>) =>
                    !isCreating
                      ? updateMultiSelectFamily('capacityRange', inputValue, isCatOthers)
                      : updatePackageFamily(
                          'capacityRange',
                          isCatOthers ? inputValue : inputValue.value
                        ),
                  orderItems: !checkRangeOptions(getDropDown('capacityRange')),
                  orderKey: 'name',
                  mode: !isCreating ? 'multiple' : undefined
                }}
              />
            )}
            {isFieldVisible('panSize') && (
              <InfoCell
                label="Pan Size"
                value={get(packageFamily, 'panSize', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: sortItemsRanges(getDropDown('panSize')),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelectFamily('panSize', value, isCatOthers),
                  mode: 'multiple'
                }}
              />
            )}
          </BoxWrapper>
          <BoxWrapper columnCount={4} enablePadding>
            {isFieldVisible('applicatorAndAccessories') && (
              <InfoCell
                label={isVials ? 'Accessories' : 'Applicator and Accessories'}
                value={get(
                  packageFamily,
                  'applicatorAndAccessories',
                  isCatOthers ? '' : []
                )}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('applicatorAndAccessories'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelectFamily(
                      'applicatorAndAccessories',
                      value,
                      isCatOthers
                    ),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}

            {isFieldVisible('closure') && (
              <InfoCell
                label="Closure"
                value={get(packageFamily, 'closure', isCatOthers || isCreating ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('closure'),
                  onChange: (inputValue: Record<string, any>) =>
                    !isCreating
                      ? updateMultiSelectFamily('closure', inputValue, isCatOthers)
                      : updatePackageFamily(
                          'closure',
                          isCatOthers ? inputValue : inputValue.value
                        ),
                  orderItems: true,
                  orderKey: 'name',
                  mode: !isCreating ? 'multiple' : undefined
                }}
              />
            )}
            {isFieldVisible('bottle') && (
              <InfoCell
                label="Bottle"
                value={get(packageFamily, 'bottle', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('material'),
                  onChange: (value: Record<string, any>) =>
                    updatePackageFamily(
                      'bottle',
                      value.map(({ value }: { value: string }) => value)
                    ),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('dipstick') && (
              <InfoCell
                label="Stem"
                value={get(vialDetails, 'dipstick', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: getIndipendentDropdown('dipstick'),
                  onChange: (inputValue: Record<string, any>) =>
                    updatePackageVariant(
                      'dipstick',
                      inputValue.map(({ value }: any) => value)
                    ),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('wiper') && (
              <InfoCell
                label="Wiper"
                value={get(vialDetails, 'wiper', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: getIndipendentDropdown('wiper'),
                  onChange: (inputValue: Record<string, any>) =>
                    updatePackageVariant(
                      'wiper',
                      inputValue.map(({ value }: any) => value)
                    ),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('applicator') && (
              <InfoCell
                label="Applicator"
                value={get(vialDetails, 'applicator', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: getIndipendentDropdown('applicator'),
                  onChange: (inputValue: Record<string, any>) =>
                    updatePackageVariant(
                      'applicator',
                      inputValue.map(({ value }: any) => value)
                    ),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('applicatorNumber') && (
              <InfoCell
                label="Applicator Number"
                value={get(vialDetails, 'applicatorNumber', '')}
                isEditing={isEditing}
                inputConfig={{
                  onChange: (value: string) =>
                    updatePackageVariant('applicatorNumber', value)
                }}
              />
            )}
            <InfoCell
              label="Level"
              value={get(packageFamily, 'level', [])}
              inputType="select"
              isEditing={isEditing}
              inputConfig={{
                items: getDropDown('level'),
                onChange: (inputValue: Record<string, any>) =>
                  !isCreating
                    ? updateMultiSelectFamily('level', inputValue)
                    : updatePackageFamily('level', inputValue.value),
                orderItems: true,
                orderKey: 'name',
                mode: !isCreating ? 'multiple' : undefined
              }}
            />
          </BoxWrapper>
          <BoxWrapper columnCount={4} enablePadding>
            <InfoCell
              label="Scouting"
              dataType="options-value"
              value={get(packageFamily, 'scouting', null)}
              inputType="options-button"
              isEditing={isEditing}
              inputConfig={{
                options: threeOptions,
                onChange: (value: string) => updatePackageFamily('scouting', value)
              }}
            />
            {isFieldVisible('mirror') && (
              <InfoCell
                label="Mirror"
                value={get(packageFamily, 'mirror', null)}
                inputType="options-button"
                dataType="options-value"
                isEditing={isEditing}
                inputConfig={{
                  options: threeOptions,
                  onChange: (value: any) => updatePackageFamily('mirror', value)
                }}
              />
            )}
            <InfoCell
              label="Airtight"
              value={get(packageFamily, 'airtight', '')}
              inputType="select"
              isEditing={isEditing}
              inputConfig={{
                items: getDropDown('airtight'),
                onChange: ({ value }: any) => updatePackageFamily('airtight', value),
                orderItems: true,
                orderKey: 'name'
              }}
            />
          </BoxWrapper>
          <Divider />
          <BoxWrapper columnCount={4} enablePadding>
            {isFieldVisible('clean') && (
              <InfoCell
                label="Clean"
                value={cleanValue}
                inputType="options-button"
                dataType="options-value"
                isEditing={isEditing}
                inputConfig={{
                  options: threeOptions,
                  onChange: (value: any) => updatePackageFamily('clean', value)
                }}
              />
            )}
            {isFieldVisible('cleanTypes') && (
              <InfoCell
                label="Clean Types"
                value={get(packageFamily, 'cleanTypes', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('cleanTypes'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelectFamily('cleanTypes', value),
                  orderItems: true,
                  orderKey: 'name',
                  disabled: !cleanValue,
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('freeOfMaterial') && (
              <InfoCell
                label={FreeOfMaterialLabel}
                value={get(packageFamily, 'freeOfMaterial', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: freeOfMaterialDropdown,
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelectFamily('freeOfMaterial', value),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('foodgrade') && (
              <InfoCell
                label="Foodgrade"
                value={get(packageFamily, 'foodgrade', null)}
                inputType="options-button"
                dataType="options-value"
                isEditing={isEditing}
                inputConfig={{
                  options: threeOptions,
                  onChange: (value: any) => updatePackageFamily('foodgrade', value)
                }}
              />
            )}
            <InfoCell
              label="Note"
              value={get(packageFamily, 'note', '')}
              isEditing={isEditing}
              inputConfig={{
                onChange: (value: any) => updatePackageFamily('note', value)
              }}
            />
          </BoxWrapper>
          <Divider />
        </>
      )}
    </>
  )
}

export default SideSearchForm
