import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Input as InputAnt, InputRef } from 'antd'
import { FC, forwardRef, memo } from 'react'
import { inputMinWidth } from '../../../assets/mixins'
import { Text } from '../../Text'
import { IInputProps } from './types'

export const Input: FC<IInputProps> = memo(
  forwardRef<InputRef, IInputProps>(
    (
      {
        onChange,
        value,
        label,
        placeholder,
        minLength = 0,
        maxLength = 250,
        required = false,
        error = false,
        disabled,
        onBlur,
        enableTooltip = false,
        message = '',
        reduxKey,
        typeOfSelect = 'text',
        onKeyDown,
        errorMessage
      },
      ref
    ) => {
      const hasError = error && errorMessage
      const formattedValue =
        typeOfSelect === 'date' && value ? value.split('T')[0] : value
      return (
        <Form.Item
          label={label}
          required={required}
          validateStatus={error ? 'error' : undefined}
          tooltip={enableTooltip && { title: message, icon: <InfoCircleOutlined /> }}
        >
          <InputAnt
            ref={ref}
            style={{ minWidth: inputMinWidth }}
            disabled={disabled}
            value={formattedValue}
            onBlur={onBlur}
            onChange={(e) => onChange && onChange(e.target.value, reduxKey)}
            onKeyDown={(e) => onKeyDown && onKeyDown(e)}
            placeholder={placeholder}
            maxLength={Number(maxLength)}
            minLength={Number(minLength)}
            type={typeOfSelect}
            data-cy={'input-' + label}
          />
          {hasError && <Text text={errorMessage} color="red" />}
        </Form.Item>
      )
    }
  )
)
