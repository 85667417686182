import { FC } from 'react'
import { ScatterPlotPdf } from '../../templates'
import { defaultTemplateImageData } from '../../templates/ScatterPlotPdf/contants'
import { TemplateContainer, TemplateListContainer } from './styled'
import { ITemplateListProps } from './types'

const TemplateList: FC<ITemplateListProps> = () => {
  return (
    <TemplateListContainer>
      <TemplateContainer>
        <ScatterPlotPdf
          title="Scatter Plot"
          isVisible
          chartImgSrc={defaultTemplateImageData}
        />
      </TemplateContainer>
    </TemplateListContainer>
  )
}

export default TemplateList
