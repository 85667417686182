import { get, isEmpty } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import Button from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { FormWrap } from '../../../../components/Form/FormWrap'
import InfoCell from '../../../../components/InfoCell'
import { Modal } from '../../../../components/Modal'
import { Text } from '../../../../components/Text'
import { appActions } from '../../../../features/app'
import { packagingSliceActions } from '../../../../features/packaging'
import {
  getAttachmentContentTypesList,
  getDropdownFilteredContentTypes,
  selectNewAttachment
} from '../../../../features/packaging/selectors'
import { ButtonContainer } from './styled'
import { IAddAttachmentModal } from './types'

const AddNewAttachmentModal: FC<IAddAttachmentModal> = ({ isMulti, ...rest }) => {
  const flowName = get(rest, 'flowName', '') // temp solution to use this modal inside flower
  const modalKey = 'new-attachment-add'
  const { id } = useParams()
  const dispatch = useDispatch()
  const dropdownAttachmentTypes = useSelector(getDropdownFilteredContentTypes)

  const attachmentContentTypesList = useSelector(getAttachmentContentTypesList)

  const newAttachment = useSelector(selectNewAttachment)

  const resetDataHandler = () => {
    const fileKey = get(newAttachment, 'file.value', '')
    if (fileKey) {
      const fileExist = !!get(window, `tmpFiles.${fileKey}`, null)
      if (fileKey && fileExist) {
        delete window.tmpFiles[fileKey]
      }
    }
    dispatch(packagingSliceActions.resetNewAttachment())
  }

  const updateAttachment: (k: string, value: any) => void = (key, value) => {
    dispatch(packagingSliceActions.setNewAttachment({ key, value }))
  }

  const isSaveDisable = () => {
    const hasfile = !isEmpty(get(newAttachment, 'file', {}))
    return (
      !get(newAttachment, 'contentType', '') ||
      !hasfile ||
      !get(newAttachment, 'name', '')
    )
  }

  const saveHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
    dispatch({
      type: 'packaging/saveNewAttachment',
      payload: {
        name: flowName,
        id,
        isMulti
      }
    })
  }

  const checkIfDisable: () => boolean = () => {
    const selectedContTypeId = get(newAttachment, 'contentTypeId', '')
    const contentTypeValue = attachmentContentTypesList.find(
      ({ id }: any) => id === selectedContTypeId
    )
    return !get(contentTypeValue, 'sapCodeEnabled', false)
  }

  return (
    <Modal
      modalKey={modalKey}
      btnName="Add"
      btnType="text"
      title="Add new version of the attachment"
      size="medium"
      isSmall
      btnClicked={resetDataHandler}
      footer={[
        <ButtonContainer>
          <Button label="Save" onClick={saveHandler} disabled={isSaveDisable()} />
        </ButtonContainer>
      ]}
      centered
    >
      <FormWrap title="Information" layout="vertical">
        <Text
          text="Please select the Information and add the new Attachment"
          variant="h5"
        />
        <Divider />
        <BoxWrapper md={2} lg={2} enablePadding>
          <InfoCell
            value={get(newAttachment, 'file.value', '')}
            label={'variant-attach'}
            isEditing
            dataType="image"
            inputType="upload"
            inputConfig={{
              fileKey: `variant-attach-${Date.now()}`,
              topMessage: 'Drop or browse the attachment',
              fileType: 'any',
              bottomMessage: '',
              onChange: (value: any) => {
                if (!get(newAttachment, 'name', '')) {
                  updateAttachment('name', value.name)
                }
                updateAttachment('file', value)
              },
              enableCharRule: true
            }}
          />
        </BoxWrapper>
        <BoxWrapper md={2} lg={3} enablePadding>
          <InfoCell
            label="Content Type"
            value={get(newAttachment, 'contentType', '')}
            inputType="select"
            isEditing
            inputConfig={{
              items: dropdownAttachmentTypes,
              onChange: ({ value }: Record<string, any>) => {
                const selectedContType = attachmentContentTypesList.find(
                  ({ id }: { id: string }) => value === id
                )
                const contentTypeLabel = get(selectedContType, 'value', '')
                updateAttachment('contentType', contentTypeLabel)
                updateAttachment('contentTypeId', value)
              }
            }}
          />
          <InfoCell
            label="Name"
            value={get(newAttachment, 'name', '')}
            isEditing
            inputConfig={{
              onChange: (value: string) => updateAttachment('name', value)
            }}
          />
          <InfoCell
            label="Description"
            value={get(newAttachment, 'description', '')}
            isEditing
            inputConfig={{
              onChange: (value: string) => updateAttachment('description', value)
            }}
          />
          <InfoCell
            dataType="tags"
            inputType="input-tag"
            value={get(newAttachment, 'SAPCodes', [])}
            label="Sap Codes"
            isEditing
            inputConfig={{
              onChange: (value: any) => updateAttachment('SAPCodes', value),
              disabled: checkIfDisable()
            }}
          />
        </BoxWrapper>
      </FormWrap>
    </Modal>
  )
}

export default AddNewAttachmentModal
