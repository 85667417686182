import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { InfoCell, SectionTitle, Table } from '../../components'
import { BoxWrapper } from '../../components/BoxWrapper'
import { Divider } from '../../components/Divider'
import { TOP_RIGHT } from '../../components/Table/constants'
import { ProjectSpecCharacteristic } from '../../features/masterSpec/model'
import { masterSpecSliceActions } from '../../features/masterSpec/reducer'
import { SapCodeConcerned } from '../../features/packaging/model'
import { Value } from '../../style/styledLabels'
import { columnsGeneratorHelper } from '../../utils'
import { PROJECT_SPEC_CHARACTERISTIC_SAP_CODES } from '../ProjectSpecificationDetailsContent/constants'
import AddSapCodeModal from './AddSapCodeModal'
import { ReadOnlyWrapper } from './styled'
import { IProjectSpecificationCharacteristicContentProps } from './types'
import { customToFixed } from './utils'

const ProjectSpecificationCharacteristicContent: FC<
  IProjectSpecificationCharacteristicContentProps
> = ({ newCharacteristic, concernedSapCodes }) => {
  const dispatch = useDispatch()

  const onChange = (input: { key: keyof ProjectSpecCharacteristic; value: any }) => {
    dispatch(masterSpecSliceActions.updateNewProjectCharacteristic(input))
  }

  const columns = columnsGeneratorHelper(PROJECT_SPEC_CHARACTERISTIC_SAP_CODES)

  const selectHandler = (data: SapCodeConcerned[]) => {
    onChange({
      key: 'sapCodes',
      value: data
    })
  }

  const hasLowerLimit = newCharacteristic?.hasLowerLimit
  const hasUpperLimit = newCharacteristic?.hasUpperLimit
  const allowedDecimalsLimit = newCharacteristic?.allowedDecimalsLimit || 0
  const hasLimit = hasLowerLimit || hasUpperLimit
  const measurementUnit = get(newCharacteristic, 'measurementUnitRef.description', '-')

  return (
    <Form layout="vertical">
      <SectionTitle value={'Characteristic Detail'} />
      <Divider disableBorder={false} />
      <>
        <BoxWrapper columnCount={3} enablePadding>
          <InfoCell
            isEditing
            label="Item"
            value={get(newCharacteristic, 'overrideItemName', '')}
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'overrideItemName', value })
              }
            }}
          />
          <InfoCell
            isEditing
            label="Standard"
            value={get(newCharacteristic, 'overrideStandard', '')}
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'overrideStandard', value })
              }
            }}
          />
          <InfoCell
            isEditing
            value={get(newCharacteristic, 'specificationCharacteristicImage.url')}
            label=""
            dataType="image"
            inputType="upload"
            inputConfig={{
              topMessage: 'Drop or browse image as attachment to Standard',
              fileKey: 'ps-char-image-edit',
              isUrl: true,
              onChange: (data: any) => {
                onChange({ key: 'specificationCharacteristicImage', value: data })
              }
            }}
          />
        </BoxWrapper>
        <br />
        {hasLimit && (
          <BoxWrapper enablePadding>
            {hasLowerLimit && (
              <InfoCell
                isEditing
                dataType="number"
                label="Lower Limit"
                value={get(newCharacteristic, 'lowerLimit', '')}
                inputConfig={{
                  onChange: (value: string) => {
                    onChange({
                      key: 'lowerLimit',
                      value: customToFixed(value, allowedDecimalsLimit)
                    })
                  }
                }}
              />
            )}
            {hasUpperLimit && (
              <InfoCell
                isEditing
                dataType="number"
                label="Upper Limit"
                value={get(newCharacteristic, 'upperLimit', '')}
                inputConfig={{
                  placeholder: 'Insert Value',
                  onChange: (value: string) => {
                    onChange({
                      key: 'upperLimit',
                      value: customToFixed(value, allowedDecimalsLimit)
                    })
                  }
                }}
              />
            )}
            {hasLimit && (
              <ReadOnlyWrapper>
                <span>Measurement Unit</span>
                <Value>{measurementUnit}</Value>
              </ReadOnlyWrapper>
            )}
          </BoxWrapper>
        )}
        <BoxWrapper enablePadding enableMargin>
          <InfoCell
            isEditing
            label="INTERCOS Quality Inspection"
            value={get(newCharacteristic, 'qualityInspection', '')}
            inputType="switch"
            inputConfig={{
              onChange: (value: boolean) => {
                onChange({ key: 'qualityInspection', value })
              }
            }}
          />
          <InfoCell
            isEditing
            label="INTERCOS Homologation"
            inputType="switch"
            value={get(newCharacteristic, 'masterHom', '')}
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'masterHom', value })
              }
            }}
          />
          <InfoCell
            isEditing
            label="INTERCOS Project Development"
            inputType="switch"
            value={get(newCharacteristic, 'customizedDev', '')}
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'customizedDev', value })
              }
            }}
          />
          <InfoCell
            isEditing
            label="SUPPLIER Certificate of Analysis"
            value={get(newCharacteristic, 'supplierCoa', '')}
            inputType="switch"
            inputConfig={{
              onChange: (value: string) => {
                onChange({ key: 'supplierCoa', value })
              }
            }}
          />
        </BoxWrapper>
      </>
      <br />
      <SectionTitle
        value="Sap Codes"
        addon={
          <AddSapCodeModal
            isEditing
            modalKey="add-sap-code-modal"
            sapCodeList={concernedSapCodes || []}
            btnLabel="Assign Sap Code"
            selectedRows={newCharacteristic.sapCodes || []}
            onSelect={selectHandler}
          />
        }
      />
      <Divider disableBorder={false} />
      <Table
        enablePagination={false}
        paginationPosition={TOP_RIGHT}
        rowKey="id"
        columns={columns}
        items={newCharacteristic.sapCodes || []}
      />
    </Form>
  )
}

export default ProjectSpecificationCharacteristicContent
