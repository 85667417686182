import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { footerHeight, headerHeight, footerActionHeight } from '../../../assets/mixins'
import { AZ_VAULT_TOOLING_RW_GROUP } from '../../../auth-msal'
import { Button, SectionTitle } from '../../../components'
import { Divider } from '../../../components/Divider'
import { Loader } from '../../../components/Loader'
import { Title } from '../../../components/Title'
import { PageContent } from '../../../containers'
import ToolingSearchForm from '../../../containers/Tooling/ToolingSearchForm'
import { toolingActions } from '../../../features/tooling'
import {
  selectToolingError,
  selectToolingLoader,
  getTempToolingFilters
} from '../../../features/tooling/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import { GenericLayout } from '../../../layouts'
import { AppRoutes } from '../../constants'
import { ButtonsWrapper, ButtonWrapper } from './styled'

const ToolingSearch: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toolingError = useSelector(selectToolingError)
  const isLoading = useSelector(selectToolingLoader)
  const userRoles = useSelector(selectUserRole)
  const filters = useSelector(getTempToolingFilters)
  const canEdit = userRoles.some((role) => AZ_VAULT_TOOLING_RW_GROUP.includes(role))

  const searchHandler = () => {
    dispatch(toolingActions.setToolingSection(true))
    dispatch(toolingActions.applyFilters())
    dispatch(toolingActions.getTooling())
    navigate(AppRoutes.VAULT_TOOLING_RESULTS)
  }

  const checkIfDisabled = () =>
    Object.values(filters).every((value) => value === '' || value === undefined) ||
    isLoading

  useEffect(() => {
    return () => {
      dispatch(toolingActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <GenericLayout error={toolingError}>
      <PageContent
        footerHeight={footerHeight + footerActionHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Title title="Tooling" variantTitle="h2" />
        <br />
        <SectionTitle
          value={'Tooling search and create'}
          isBold
          addon={
            canEdit && (
              <ButtonsWrapper>
                <Button
                  label="ADD PANS ET ALIA"
                  onClick={() => {
                    dispatch(toolingActions.setEditData({}))
                    navigate('./new')
                  }}
                />
              </ButtonsWrapper>
            )
          }
        />
        <Divider />
        {isLoading && <Loader />}
        <ToolingSearchForm />
      </PageContent>
      <ButtonWrapper>
        <Button
          label="Search"
          onClick={searchHandler}
          disabled={checkIfDisabled()}
          enableKeyPress
        />
      </ButtonWrapper>
    </GenericLayout>
  )
}

export default ToolingSearch
