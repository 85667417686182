import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { saveAs } from 'file-saver'
import { get } from 'lodash'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { IError } from '../../containers/Error/types'
import { productRefactorDataForUpdate } from '../../utils'
import { filterFormulaByStatus } from '../../utils/formulaSchemaHelpers'
import { checkFieldVisibilityByRoles } from '../../utils/packagingFormHelper'
import {
  getUniqueFormulaSchemaCodeList,
  refactorProductDetail
} from '../../utils/productHelpers'
import { appSliceActions } from '../app/reducer'
import { getSchemaId } from '../formulaSchema/api'
import { FormulaSchemaDto } from '../formulaSchema/model'
import { ProductEditDto } from '../product/model'
import { getProdFilteredStatusByCRUD } from '../product/selectors'
import { selectUserEmail, selectUserRole } from '../users/selectors'
import {
  getProductById,
  searchProducts,
  patchProductById,
  deleteProduct,
  extractProductExcel,
  checkProductValidation,
  fetchSpiderChartCharacteristics,
  searchProductsCharacteristics,
  searchAllProducts
} from './api'
import {
  PRODUCTS_CHANGE_PAGE,
  PRODUCTS_DELETE,
  PRODUCTS_GET_DETAILS,
  PRODUCTS_INIT_TEMP,
  PRODUCTS_SEARCH,
  PRODUCTS_UPDATE,
  PRODUCTS_EXPORT_EXCEL,
  GET_PRODUCTS_SPIDER_CHART_CHARACTERISTICS,
  GET_SCATTER_CHARACTERISTICS,
  PRODUCTS_GET_DETAILS_AND_OTHERS
} from './constants'
import {
  IGetProductDetailActionPayload,
  IGetSpiderChartCharacteristicsPayload,
  IProductsCharacteristicsResult,
  Pagination,
  ProductDto,
  ProductResultSearch,
  ProductsState
} from './model'
import { IExtractPayload } from './model'
import { productsSliceActions } from './reducer'
import {
  getFilters,
  getProductsDetail,
  getProductTemp,
  selectProductPagination
} from './selectors'

type fetchPayload = {
  flowName: string
  noNext?: boolean
  order?: 'ascend' | 'descend'
  field?: string
}
function* fetchProductsSaga({
  payload: { flowName, noNext = false, order, field }
}: PayloadAction<fetchPayload>) {
  const orderKeys = {
    ascend: 'asc',
    descend: 'desc'
  }

  const filters: ProductsState['filters'] = yield select(getFilters)
  const { page: selectedPage, pageSize: selectedPageSize }: Pagination = yield select(
    selectProductPagination
  )
  const requestBody: Record<string, any> = {
    ...filters,
    page: selectedPage,
    pageSize: selectedPageSize
  }

  if (order && field) {
    requestBody['orderBy'] = {
      [field]: orderKeys[order]
    }
  }
  try {
    const products: ProductResultSearch = yield call(searchProducts, requestBody)

    const { results, totalPages, page, pageSize, total } = products

    yield put(productsSliceActions.setPagination({ totalPages, page, pageSize, total }))
    yield put(productsSliceActions.setProducts({ data: results }))
  } catch (err) {}
}
function* fetchProducts({
  payload
}: PayloadAction<{ order?: 'ascend' | 'descend'; field?: string }>) {
  const { order, field } = payload
  const orderKeys = {
    ascend: 'asc',
    descend: 'desc'
  }
  const statusDropdownOptions: { name: string; value: string }[] = yield select(
    getProdFilteredStatusByCRUD('onRead')
  )
  const permittedStatusForUser = statusDropdownOptions.map((value) => value.value)

  const filters: ProductsState['filters'] = yield select(getFilters)
  const { page: selectedPage, pageSize: selectedPageSize }: Pagination = yield select(
    selectProductPagination
  )
  const queryStatus = filters['status'] ? filters['status'] : permittedStatusForUser

  const requestBody: Record<string, any> = {
    ...filters,
    status: queryStatus,
    packageType: get(filters, 'packageType', '').toUpperCase(),
    page: selectedPage,
    pageSize: selectedPageSize
  }

  if (order && field) {
    requestBody['orderBy'] = {
      [field]: orderKeys[order]
    }
  }
  try {
    const products: ProductResultSearch = yield call(searchProducts, requestBody)

    const { results, totalPages, page, pageSize, total } = products

    yield put(productsSliceActions.setPagination({ totalPages, page, pageSize, total }))
    yield put(productsSliceActions.setProducts({ data: results }))
  } catch (err) {
    yield put(productsSliceActions.setError(err as IError))
  }
}

function* getProductDetailSaga({
  payload
}: PayloadAction<IGetProductDetailActionPayload>) {
  const { id } = payload
  yield put(appSliceActions.setLoader({ type: 'page', value: true }))
  try {
    const productDetail: ProductDto = yield call(getProductById, id)
    const refactoredData = refactorProductDetail({ data: productDetail })
    yield put(productsSliceActions.setDetailNew(refactoredData))
  } catch (err: any) {
    yield put(productsSliceActions.setDetailNew({}))
    yield put(productsSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'page', value: false }))
  }
}

function* getProductDetailAndOtherSaga({
  payload
}: PayloadAction<IGetProductDetailActionPayload>) {
  const { id } = payload
  try {
    yield put(appSliceActions.setLoader({ type: 'page', value: true }))
    const productDetail: ProductDto = yield call(getProductById, id)
    const refactoredData = refactorProductDetail({ data: productDetail })
    yield put(productsSliceActions.setDetailNew(refactoredData))
    yield put(appSliceActions.setLoader({ type: 'page', value: false }))
    yield put(appSliceActions.setLoader({ type: 'section-other-tested', value: true }))

    const uniqueFSchemaList = getUniqueFormulaSchemaCodeList({
      data: refactoredData
    })
    const productsSearchByFSchemaPromises = uniqueFSchemaList.map((formulaSchemaCode) =>
      call(searchAllProducts, { formulaSchemaCode, status: 'READY_TO_GO' })
    )
    const productsWithSimilarFSchema: ProductDto[] = yield all(
      productsSearchByFSchemaPromises
    )
    const otherProductList = productsWithSimilarFSchema
      .flat()
      .filter((otherProd) => otherProd.id !== id)
    yield put(productsSliceActions.setOthersProducts(otherProductList))
    yield put(appSliceActions.setLoader({ type: 'section-other-tested', value: false }))
  } catch (err: any) {
    yield put(appSliceActions.setLoader({ type: 'page', value: false }))
    yield put(appSliceActions.setLoader({ type: 'section-other-tested', value: false }))
    yield put(productsSliceActions.setDetailNew({}))
    yield put(productsSliceActions.setError(err))
  }
}

function* initTempSaga() {
  yield put(productsSliceActions.setLoader(true))
  try {
    const detail: ProductDto = yield select(getProductsDetail)
    const formulaSchema: FormulaSchemaDto[] = yield all(
      get(detail, 'productSides', []).map(({ formulaSchemaId }: any) =>
        call(getSchemaId, formulaSchemaId)
      )
    )
    const newData = {
      ...detail,
      productSides: detail.productSides.map((data: any, index: number) => ({
        ...data,
        formulaSelected: filterFormulaByStatus(data.formulas),
        formulaSchema: {
          ...formulaSchema[index],
          formula: filterFormulaByStatus(formulaSchema[index].formula)
        }
      }))
    }
    yield put(productsSliceActions.setTemp({ data: newData }))
  } catch (err) {
  } finally {
    yield put(productsSliceActions.setLoader(false))
  }
}

function* updateProductSaga() {
  yield put(productsSliceActions.setLoader(true))
  try {
    const detailTemp: ProductEditDto = yield select(getProductTemp)
    const { productSides } = detailTemp
    const { id: productId } = detailTemp
    const productData = productSides.map(
      ({ packageVariantId, formulaSchemaId, productCategory }: any) => ({
        packageVariantId,
        formulaSchemaId,
        productCategory
      })
    )
    const { matchingProduct, alreadyExists } = yield call(checkProductValidation, {
      productData
    })

    const openPopUp =
      (matchingProduct.length === 1 && matchingProduct[0].id !== productId) ||
      matchingProduct.length > 1

    if (alreadyExists && openPopUp) {
      yield put(
        appSliceActions.openPopUp({
          isOpen: true,
          key: 'product-exists',
          title: 'The product you are trying to modify already exists',
          message: `The same combination of Formula Schema, PK Intercos Code and Product category is present in Product: ${matchingProduct[0].productCode}`,
          isClosable: true
        })
      )
      return
    }
    const author: string = yield select(selectUserEmail)
    const newData = productRefactorDataForUpdate(detailTemp, author)

    var form_data = new FormData()

    form_data.append('product', JSON.stringify(newData))

    const imageKey = get(detailTemp, 'image.value', '')
    const marketingProfileKey = get(detailTemp, 'marketingProfile.value', '')

    const imageExist = !!get(window, `tmpFiles.${imageKey}`)
    if (imageKey && imageExist) {
      form_data.append('image', window.tmpFiles[imageKey])
    }

    const marketExist = !!get(window, `tmpFiles.${marketingProfileKey}`)
    if (marketingProfileKey && marketExist) {
      form_data.append('marketingProfile', window.tmpFiles[marketingProfileKey])
    }
    yield call(patchProductById, detailTemp.id, form_data)
    yield put(
      productsSliceActions.setSuccess({
        message: 'Product saved successfully'
      })
    )
    if (imageKey && imageExist) {
      delete window.tmpFiles[imageKey]
    }
    if (marketingProfileKey && marketExist) {
      delete window.tmpFiles[marketingProfileKey]
    }
  } catch (err: any) {
    yield put(productsSliceActions.setError(err))
  } finally {
    yield put(productsSliceActions.setLoader(false))
  }
}

function* deleteProductSaga() {
  yield put(productsSliceActions.setLoader(true))
  try {
    const { id } = yield select(getProductsDetail)
    yield call(deleteProduct, id)
    yield put(
      productsSliceActions.setSuccess({
        message: 'Product deleted successfully'
      })
    )
  } catch (err: any) {
    yield put(productsSliceActions.setError(err))
  } finally {
    yield put(productsSliceActions.setLoader(false))
  }
}

function* clearAllFilters() {
  yield put(productsSliceActions.clearFilters())
}

function* removeFilters({ payload }: AnyAction) {
  yield put(productsSliceActions.removeFilter(payload.path))
  // @ts-ignore
  yield fetchProductsSaga({ payload: { flowName: payload.flowName, noNext: true } })
}

function* changePage({ payload }: AnyAction) {
  yield put(productsSliceActions.setLoader(true))
  try {
    const { page, field, order } = payload

    yield put(productsSliceActions.setPagination({ page }))
    // @ts-ignore
    yield fetchProducts({ payload: { field, order } })
  } finally {
    yield put(productsSliceActions.setLoader(false))
  }
}

function* changePageSaga({ payload }: AnyAction) {
  const { flowName, page, field, order } = payload

  yield put(productsSliceActions.setPagination({ page }))
  // @ts-ignore
  yield fetchProductsSaga({ payload: { flowName, noNext: true, field, order } })
}

function* removeAddon() {
  yield put(productsSliceActions.removeActionsAddon())
}

function* productsSearch() {
  yield put(productsSliceActions.setLoader(true))
  try {
    yield put(productsSliceActions.resetPagination())
    // @ts-ignore
    yield fetchProducts({ payload: {} })
  } finally {
    yield put(productsSliceActions.setLoader(false))
  }
}

function* filterToTemp() {
  yield put(productsSliceActions.filterToTemp())
}
function* tempToFilter() {
  yield put(productsSliceActions.tempToFilter())
}

function* exportProductsExcel(): any {
  try {
    yield put(productsSliceActions.setLoader(true))
    const currentFilters = yield select(getFilters)
    const type = get(currentFilters, 'packageType', '').toUpperCase()
    const roles = yield select(selectUserRole)

    const statusDropdownOptions: { name: string; value: string }[] = yield select(
      getProdFilteredStatusByCRUD('onRead')
    )
    const permittedStatusForUser = statusDropdownOptions.map((value) => value.value)
    const queryStatus = currentFilters['status']
      ? currentFilters['status']
      : permittedStatusForUser

    const data: IExtractPayload = {
      filters: {
        ...currentFilters,
        status: queryStatus,
        packageType: type || undefined
      },
      exclude: {
        sideCollector: {
          supplierRef: !checkFieldVisibilityByRoles({
            key: 'supplierRef',
            isOnRead: true,
            roles
          })
        },
        packagingSupplier: {
          name: !checkFieldVisibilityByRoles({
            key: 'testedSupplier',
            isOnRead: true,
            roles
          })
        },
        packageVariant: {
          variantSupplierCode: !checkFieldVisibilityByRoles({
            key: 'variantSupplierCode',
            isOnRead: true,
            roles
          })
        }
      }
    }

    const response = yield call(extractProductExcel, data)
    saveAs(
      new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      'products.xlsx'
    )
  } catch (e) {
    const popUp = {
      key: 'exportError',
      title: 'Something went wrong',
      message: 'Error while exporting results',
      isClosable: true
    }
    yield put(appSliceActions.openPopUp(popUp))
    console.log(e)
  } finally {
    yield put(productsSliceActions.setLoader(false))
  }
}

function* getSpiderChartCharacteristicsSaga({
  payload
}: PayloadAction<IGetSpiderChartCharacteristicsPayload>) {
  const { businessSegment, loader, productCategory } = payload
  try {
    yield put(appSliceActions.setLoader({ type: loader, value: true }))
    const charList: string[] = yield call(fetchSpiderChartCharacteristics, {
      businessSegment,
      productCategory
    })

    yield put(productsSliceActions.setAvailableCharacteristics(charList))
  } catch (e) {
  } finally {
    yield put(appSliceActions.setLoader({ type: loader, value: false }))
  }
}

function* getScatterCharacteristicsSaga() {
  yield put(appSliceActions.setLoader({ type: 'modal', value: true }))
  const filters: Record<string, any> = yield select(getFilters)

  try {
    const { products }: IProductsCharacteristicsResult = yield call(
      searchProductsCharacteristics,
      {
        ...filters,
        businessSegment: get(filters, 'businessSegment[0]'),
        productCategory: get(filters, 'productCategory[0]')
      }
    )
    const characteristics = Object.keys(get(products, '[0].characteristics', {}))
    yield put(productsSliceActions.setAvailableCharacteristics(characteristics))
    yield put(productsSliceActions.setPartialItems(products))
  } catch (err) {
    yield put(productsSliceActions.setError(err as IError))
  } finally {
    yield put(appSliceActions.setLoader({ type: 'modal', value: false }))
  }
}

export default function* productsSaga() {
  yield takeLatest(PRODUCTS_SEARCH, productsSearch)
  yield takeLatest('products/removeAddon', removeAddon)
  yield takeLatest('products/fetch', fetchProductsSaga)
  yield takeLatest(PRODUCTS_GET_DETAILS, getProductDetailSaga)
  yield takeLatest(PRODUCTS_GET_DETAILS_AND_OTHERS, getProductDetailAndOtherSaga)
  yield takeLatest(PRODUCTS_UPDATE, updateProductSaga)
  yield takeLatest(PRODUCTS_DELETE, deleteProductSaga)
  yield takeLatest('products/clearAllFilters', clearAllFilters)
  yield takeLatest('products/removeFilterSaga', removeFilters)
  yield takeLatest('products/changePage', changePageSaga)
  yield takeLatest(PRODUCTS_CHANGE_PAGE, changePage)
  yield takeLatest(PRODUCTS_INIT_TEMP, initTempSaga)
  yield takeLatest('productsSaga/filterToTemp', filterToTemp)
  yield takeLatest('productsSaga/tempToFilter', tempToFilter)
  yield takeLatest(PRODUCTS_EXPORT_EXCEL, exportProductsExcel)
  yield takeLatest(
    GET_PRODUCTS_SPIDER_CHART_CHARACTERISTICS,
    getSpiderChartCharacteristicsSaga
  )
  yield takeLatest(GET_SCATTER_CHARACTERISTICS, getScatterCharacteristicsSaga)
}
