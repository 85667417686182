import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, SectionTitle } from '../../../components'
import { Divider } from '../../../components/Divider'
import SupplierForm from '../../../containers/Administration/SupplierForm'
import { ITempFields } from '../../../containers/Administration/SupplierForm/types'
import {
  administrationActions,
  administrationSliceActions
} from '../../../features/administration'
import { ISupplierFilters } from '../../../features/administration/model'
import { selectSapCodeIsUsed } from '../../../features/administration/selectors'
import { AppRoutes } from '../../constants'
import { FooterActions } from '../../CreationPackage/styled'
import { ButtonWrapper } from './styled'

const ManageSupplier = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sapCodeIsUsed = useSelector(selectSapCodeIsUsed)
  const [isOnCreate, setIsOnCreate] = useState<boolean>(false)
  const [tempFilter, setTempFilter] = useState<ITempFields>({})
  const [tempSupplier, setTempSupplier] = useState<ITempFields>({})

  const tempData = isOnCreate ? tempSupplier : tempFilter

  useEffect(() => {
    dispatch(administrationActions.getGroupNames())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateHandler = (key: keyof ITempFields, value: any) => {
    const newData = {
      ...tempData,
      [key]: value
    }
    if (isOnCreate) {
      // TODO need to optimized when and how sapCode is checked
      if (key === 'SAPCodes') {
        dispatch(administrationActions.checkSupplierSapCode({ value }))
      }
      return setTempSupplier(newData)
    }
    setTempFilter(newData)
  }

  const clearTempSupplier = () => {
    setTempSupplier({})
    setIsOnCreate(false)
  }

  const backHandler = () => {
    dispatch(administrationSliceActions.clearType())
    navigate(AppRoutes.VAULT_ADMINISTRATION)
  }

  const searchHandler = () => {
    dispatch(
      administrationSliceActions.setSupplierFilters(tempFilter as ISupplierFilters)
    )
    navigate(AppRoutes.VAULT_ADMINISTRATION_SUPPLIER_RESULT)
  }

  const isSaveDisable =
    tempSupplier.country?.length !== 2 ||
    !tempSupplier.locality ||
    !tempSupplier.name ||
    !tempSupplier.SAPCodes ||
    sapCodeIsUsed

  return (
    <>
      <SectionTitle value="Administration" isBold />
      <Divider disableBorder />
      <SectionTitle
        value={isOnCreate ? 'Create new Supplier' : 'Supplier search'}
        addon={
          <ButtonWrapper>
            {isOnCreate ? (
              <>
                <Button label="Back" variant="link" onClick={clearTempSupplier} />
                <Button
                  label="Save"
                  onClick={() =>
                    dispatch(
                      administrationActions.createSupplier({
                        supplierData: tempSupplier
                      })
                    )
                  }
                  disabled={isSaveDisable}
                />
              </>
            ) : (
              <Button
                label="Add"
                iconName="PlusOutlined"
                iconPosition="left"
                variant="link"
                onClick={() => setIsOnCreate(true)}
              />
            )}
          </ButtonWrapper>
        }
      />
      <Divider disableBorder />
      <SupplierForm
        isOnCreate={isOnCreate}
        data={tempData}
        onUpdate={updateHandler}
        hasSapCode
      />

      {!isOnCreate && (
        <FooterActions>
          <Button label="Back" onClick={backHandler} />
          <Button label="Filter" onClick={searchHandler} />
        </FooterActions>
      )}
    </>
  )
}

export default ManageSupplier
