import { get } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import {
  canUserDeletePackaging,
  canUserEditPackaging,
  canUserSeeEditbuttonInPackaging
} from '../../auth-msal'
import { Button, InfoCell } from '../../components'
import { BoxWrapper } from '../../components/BoxWrapper'
import { Breadcrumb } from '../../components/Breadcrumb'
import { DeletePackaging } from '../../components/DeletePackaging'
import { Divider } from '../../components/Divider'
import { FlexSection } from '../../components/FlexSection'
import { Loader } from '../../components/Loader'
import { ATTACHMENTS_IN_DETAIL_TABLE_CONFIG } from '../../components/Packaging/AttachmentsTable/constants'
import {
  VARIANTS_EDIT_PAGE_TABLE_CONFIG,
  VARIANTS_SIDE_EDIT_PAGE_TABLE_CONFIG
} from '../../components/Packaging/VariantsTable/constants'
import StatusTag from '../../components/StatusTag'
import { Text } from '../../components/Text'
import { Title } from '../../components/Title'
import { PageContent, SideInformation } from '../../containers'
import Attachments from '../../containers/Packaging/Attachments'
import DetailInformation from '../../containers/Packaging/DetailInformation'
import Variants from '../../containers/Packaging/Variants'
import { appSliceActions } from '../../features/app'
import { packagingActions, packagingSliceActions } from '../../features/packaging'
import {
  PACKAGING_STATUS,
  PACKAGING_STATUS_COLOR
} from '../../features/packaging/constants'
import { CATEGORY, PACKAGE_TYPE } from '../../features/packaging/model'
import {
  getAllStatus,
  getIsLoading,
  getPackageDetail,
  selectAllTaxonomies,
  selectEditing,
  selectFilteredAttachmentsByType,
  selectPackagingError
} from '../../features/packaging/selectors'
import { selectPermissions, selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { sideFormValidation } from '../../utils'
import { orderAttachmentsByContentType } from '../../utils/attachmentsHelper'
import { AppRoutes } from '../constants'
import AttachmentsInPackDetail from './AttachmentsInPackDetail'
import RelationshipsWrapper from './RelationshipsWrapper'

const PackagingDetail: FC = () => {
  const { id: packageId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isOnEdit, setIsOnEdit] = useState(false)

  const packagingError = useSelector(selectPackagingError)
  const userRoles = useSelector(selectUserRole)
  const permissions = useSelector(selectPermissions)
  const allTaxonomies = useSelector(selectAllTaxonomies)

  useEffect(() => {
    dispatch(packagingSliceActions.setFilteredStatus({ roles: userRoles || [] }))

    if (packageId) {
      dispatch(packagingActions.getPackagingDetail({ id: packageId }))
    }
    return () => {
      dispatch(packagingSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const packageDetails = useSelector(getPackageDetail)
  const tempDetails = useSelector(selectEditing)
  const isLoading = useSelector(getIsLoading)

  const data = isOnEdit
    ? (tempDetails as Record<string, any>)
    : (packageDetails as Record<string, any>)
  const isMulti = get(data, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI
  const attachmentsByRoles = useSelector(
    selectFilteredAttachmentsByType(isMulti ? 'multi' : 'mono')
  )

  const orderedAttachments = orderAttachmentsByContentType(attachmentsByRoles)

  const status: PACKAGING_STATUS = get(data, 'status', '')

  const isVial = get(data, 'category', '').toLowerCase() === CATEGORY.VIALS

  const statusDropdowns = useSelector(getAllStatus)

  const updateDetailHandler = (key: string, value: any) => {
    let tempPackage: Record<string, any> = {
      image: { ...get(data, 'image', {}) },
      status: get(data, 'status', PACKAGING_STATUS.DRAFT)
    }
    if (key !== 'category') {
      tempPackage = { ...data }
    }
    if (isOnEdit) {
      if (key !== 'testedSupplier' && key !== 'groupName')
        dispatch(
          packagingSliceActions.updateTempDetails({ ...tempPackage, [key]: value })
        )

      if (key === 'testedSupplier') {
        dispatch(
          packagingSliceActions.updateTempDetails({
            ...tempPackage,
            [key]: value,
            groupName: value.groupName
          })
        )
        dispatch(packagingActions.searchSuppliers(value.groupName))
      }

      if (key === 'groupName') {
        dispatch(
          packagingSliceActions.updateTempDetails({
            ...tempPackage,
            [key]: value,
            testedSupplier: null
          })
        )
      }
    }
  }

  const isEditVisible = canUserSeeEditbuttonInPackaging(userRoles)

  const checkIfDisable = () => {
    if (!isMulti) return false
    return sideFormValidation(data, allTaxonomies)
  }

  const openChangeStatusWarning = ({
    value,
    related
  }: {
    value: string
    related: string[]
  }) => {
    const WarningContent = (
      <>
        {!!related.length && (
          <Text
            text={"Changing the package status the products below won't be RTG anymore"}
            variant={'h4'}
          />
        )}
        <Text
          text={`Are you sure do you want to change the status from AVAILABLE to ${value} ?`}
          variant={'h5'}
        />
        {!!related.length && (
          <>
            <Text text={'List of Products'} variant={'h5'} bold />
            <ul>
              {related.map((value, idx) => (
                <li key={`${value}-idx`}>{value}</li>
              ))}
            </ul>
          </>
        )}
      </>
    )

    dispatch(
      appSliceActions.openPopUp({
        title: '',
        key: 'change-status-warning',
        message: WarningContent,
        footer: [
          <Button
            key="confirm"
            label="Ok"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              updateDetailHandler('status', value)
            }}
          />
        ]
      })
    )
  }
  const canReadMasterSpec = permissions.wikipackMaster?.canRead || false

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Breadcrumb
              items={[
                {
                  name: 'Packaging',
                  href: '/wikipack/packaging'
                },
                {
                  name: `detail packaging ${get(data, 'packagingCode', '')} `
                }
              ]}
            />

            <FlexSection
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Title title="Packaging" variantTitle="h2" />
              <FlexSection flexDirection="row" justifyContent="end" alignItems="center">
                {canReadMasterSpec && !isVial && !isOnEdit && (
                  <>
                    <Button
                      label="Master Specifications"
                      onClick={() =>
                        window.open(
                          `/wikipack/packaging/${packageId}/master-specifications`
                        )
                      }
                    />
                    <Button
                      label="Project Specifications"
                      onClick={() =>
                        window.open(
                          `/wikipack/packaging/${packageId}/project-specifications`
                        )
                      }
                    />
                  </>
                )}
                {isEditVisible && (
                  <>
                    {!isOnEdit ? (
                      <Button
                        label="Edit"
                        variant="ghost"
                        onClick={() => {
                          dispatch(packagingSliceActions.tempPackage())
                          setIsOnEdit(!isOnEdit)
                        }}
                      />
                    ) : (
                      <>
                        {canUserDeletePackaging(userRoles) && (
                          <DeletePackaging
                            btnLabel="Delete"
                            rec={data}
                            message={'Do you want to delete this packaging?'}
                            btnType="text"
                            succcesCb={() => navigate(AppRoutes.WIKIPACK_PACKAGING)}
                          />
                        )}
                        <Button
                          label="Cancel"
                          variant="ghost"
                          onClick={() => {
                            setIsOnEdit(!isOnEdit)
                          }}
                        />
                        <Button
                          label="Save"
                          onClick={() => {
                            setIsOnEdit(!isOnEdit)
                            dispatch(packagingActions.updatePackage())
                          }}
                          disabled={checkIfDisable()}
                        />
                      </>
                    )}
                  </>
                )}
              </FlexSection>
            </FlexSection>
            {isOnEdit ? (
              <BoxWrapper columnCount={4} enablePadding>
                <InfoCell
                  label="Status"
                  value={get(data, 'status', PACKAGING_STATUS.DRAFT)}
                  inputType="select"
                  isEditing={canUserEditPackaging(userRoles)}
                  inputConfig={{
                    items: statusDropdowns,
                    onChange: ({ value }: Record<string, any>) =>
                      dispatch({
                        type: 'packaging/checkStatus',
                        payload: { value, openPopupCallback: openChangeStatusWarning }
                      }),
                    orderItems: true,
                    orderKey: 'name'
                  }}
                />
              </BoxWrapper>
            ) : (
              <StatusTag label={status} tagColor={PACKAGING_STATUS_COLOR[status]} />
            )}
            <DetailInformation
              imageFileKey={'package-update-image'}
              data={data}
              isEditing={isOnEdit}
              updateData={updateDetailHandler}
              isMulti={isMulti}
            />
            <Divider />
            {isMulti && (
              <>
                <Title title="Sides" variantTitle="h3" />
                <SideInformation
                  data={data}
                  isEditing={isOnEdit}
                  updateData={updateDetailHandler}
                  variantTableConfig={VARIANTS_SIDE_EDIT_PAGE_TABLE_CONFIG}
                />
                <Divider disableBorder />
              </>
            )}
            {isVial && !isMulti && (
              <>
                <Variants
                  isEditing={!isOnEdit}
                  data={get(data, 'packageFamilies[0]', {})}
                  tableConfig={VARIANTS_EDIT_PAGE_TABLE_CONFIG}
                  onAddConfirm={(value) => {
                    setIsOnEdit(false)
                    dispatch(
                      packagingActions.addVariants({
                        newVariant: value,
                        packFamilyIdx: 0
                      })
                    )
                  }}
                  deleteVariant={packagingActions.deleteVariantFromPackage}
                  category={get(data, 'category', '')}
                  subcategory={get(data, 'subcategory', '')}
                  modalKey={'package-variant-mono'}
                  hasRedirect
                  hasDelete={canUserEditPackaging(userRoles)}
                />
                <Divider disableBorder />
              </>
            )}
            {!isVial && (
              <>
                <Attachments
                  isEditing={!isOnEdit}
                  dataList={orderedAttachments}
                  tableConfig={ATTACHMENTS_IN_DETAIL_TABLE_CONFIG}
                  deleteAttach={packagingActions.removeAttachment}
                  isMulti={isMulti}
                />
                {/* <AttachmentsInPackDetail
                  list={orderedAttachments}
                  isReadOnly={isOnEdit}
                  isMulti={isMulti}
                /> */}
                <Divider disableBorder />
              </>
            )}
            <RelationshipsWrapper isEditing={!isOnEdit} />
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default PackagingDetail
