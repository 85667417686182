import { Form } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, InfoCell, SectionTitle } from '../../components'
import { BoxWrapper } from '../../components/BoxWrapper'
import { Divider } from '../../components/Divider'
import FooterControl from '../../components/FooterControl'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import Success from '../../containers/Success'
import { storeActions, storeSliceActions } from '../../features/storeProducts'
import { storeProdTypeToForm } from '../../features/storeProducts/constants'
import { IImageData, IStoreProd, StoreProdType } from '../../features/storeProducts/model'
import {
  selectStoreProdLoader,
  selectStoreProdError,
  selectNewStoreProd,
  getStoreProdDropdownByKey,
  selectStoreProdSuccess
} from '../../features/storeProducts/selectors'
import { GenericLayout } from '../../layouts'
import { checkMantatoryFieldInCreate } from '../../utils/entityHelper'
import { AppRoutes } from '../constants'
import { footerActionHeight } from '../CreationPackage/styled'
import EntityCreationForm from './EntityCreationForm'
import { IStoreProdCreatProps } from './types'

const StoreProductCreation: FC<IStoreProdCreatProps> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const storeProdError = useSelector(selectStoreProdError)
  const isLoading = useSelector(selectStoreProdLoader)
  const typeDropdown = useSelector(getStoreProdDropdownByKey('type'))
  const newStoreProd = useSelector(selectNewStoreProd)
  const successInfo = useSelector(selectStoreProdSuccess)
  const [entityType, setEntityType] = useState('')
  const selectedType = newStoreProd.type

  useEffect(
    () => () => {
      dispatch(storeSliceActions.clearError())
      dispatch(storeSliceActions.resetNew())
      dispatch(storeSliceActions.clearSuccess())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const updateHandler = (key: keyof IStoreProd, value: any) => {
    dispatch(storeSliceActions.updateNewEntity({ key, value }))
  }

  const resetHandler = () => {
    if (successInfo) {
      dispatch(storeSliceActions.clearSuccess())
    }
    setEntityType('')
    dispatch(storeSliceActions.resetNew())
  }

  const confirmTypeHandler = () => {
    dispatch(storeSliceActions.updateNewEntity({ key: 'type', value: entityType }))
    const productFormValue = storeProdTypeToForm[entityType]
    if (productFormValue) {
      dispatch(
        storeSliceActions.updateNewEntity({ key: 'productForm', value: productFormValue })
      )
    }
  }

  const createHandler = () => {
    dispatch(storeActions.createEntity())
  }

  const checkMantatoryField = () => {
    return !checkMantatoryFieldInCreate({ data: newStoreProd, type: newStoreProd.type })
  }

  const updateImageHandler = (data: IImageData) => {
    dispatch(storeSliceActions.updateNewEntity({ key: 'image', value: data }))
  }

  return (
    <GenericLayout error={storeProdError}>
      <PageContent
        footerHeight={footerHeight + footerActionHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <SectionTitle value="Create new entity" isBold />
        {isLoading && <Loader />}
        {selectedType ? (
          <>
            {successInfo ? (
              <Success
                data={successInfo}
                buttons={[
                  {
                    label: 'Create new entity',
                    onClick: resetHandler,
                    variant: 'ghost'
                  },
                  {
                    label: 'Go to the products selection',
                    onClick: () => navigate(AppRoutes.STORE_PRODUCTS_SEARCH)
                  },
                  {
                    label: 'Back to home',
                    onClick: () => navigate(AppRoutes.HOME),
                    iconName: 'HomeOutlined',
                    variant: 'text'
                  }
                ]}
              />
            ) : (
              <EntityCreationForm data={newStoreProd} onUpdate={updateHandler} />
            )}
          </>
        ) : (
          <Form layout="vertical">
            <BoxWrapper enablePadding>
              <InfoCell
                value={newStoreProd.image?.value || ''}
                label={'new-store-prod'}
                dataType="image"
                inputType="upload"
                isEditing
                inputConfig={{
                  fileKey: 'new-store-prod',
                  onChange: (data: IImageData) => updateImageHandler(data)
                }}
              />
            </BoxWrapper>
            <Divider disableBorder />
            <SectionTitle value="Select the entity type you want to create" />
            <Divider disableBorder />
            <BoxWrapper enablePadding>
              <InfoCell
                label="Entity Type"
                value={entityType}
                inputType="select"
                inputConfig={{
                  items: typeDropdown,
                  onChange: ({ value }: Record<string, any>) => {
                    setEntityType(value as StoreProdType)
                  }
                }}
                isEditing
              />
            </BoxWrapper>
          </Form>
        )}
        <FooterControl>
          {selectedType ? (
            <>
              <Button label="Reset" onClick={resetHandler} variant="text" />
              <Button
                label="Create"
                onClick={createHandler}
                disabled={checkMantatoryField()}
              />
            </>
          ) : (
            <Button label="Confirm" onClick={confirmTypeHandler} disabled={!entityType} />
          )}
        </FooterControl>
      </PageContent>
    </GenericLayout>
  )
}

export default StoreProductCreation
