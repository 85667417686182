import { Alert } from 'antd'
import { isEmpty } from 'lodash'
import moment, { Moment } from 'moment'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../components'
import { Modal } from '../../../components/Modal'
import GeneralInfo from '../../../components/Order/GeneralInfo'
import ProductList from '../../../components/Order/ProductList'
import { Steps } from '../../../components/Steps'
import { Error } from '../../../containers'
import { appActions } from '../../../features/app'
import { selectAppError } from '../../../features/app/selectors'
import { orderListActions } from '../../../features/orderList/actions'
import { IOrderData } from '../../../features/orderList/models'
import { orderListSliceActions } from '../../../features/orderList/reducer'
import { selectTempOrder } from '../../../features/orderList/selectors'
import { orderDataExtractor } from '../../../utils/orderHelpers'
import { AlertWrapper } from '../styled'
import { StepContent } from './styled'

const ProposeModal = () => {
  const modalKey = 'order-refused-propose'
  const [stepState, setStepState] = useState(0)
  const [isOrderTouched, setIsTouched] = useState(false)
  const error = useSelector(selectAppError)
  const dispatch = useDispatch()

  const tempOrderData = useSelector(selectTempOrder)
  const { orderItems } = orderDataExtractor(tempOrderData)

  const updateTempOrder = (key: keyof IOrderData, value: any) => {
    dispatch(orderListSliceActions.updateTempOrder({ key, value }))
  }

  const updateTouchHandlder = () => {
    if (!isOrderTouched) setIsTouched(true)
  }

  const editFullfilmentDateHandlder = (value: Moment) => {
    const newCreatedDate = moment(value).toISOString()
    updateTempOrder('fullfillmentDate', newCreatedDate)
    updateTouchHandlder()
  }

  const editInputValues = (key: keyof IOrderData, value: any) => {
    updateTempOrder(key, value)
    updateTouchHandlder()
  }

  const editProductQuantityHandler = (id: string, value: number) => {
    const newProductList = orderItems.map((product) => {
      const { id: productId, quantity, availableQuantity } = product
      const newQuantity = value > +availableQuantity ? +availableQuantity : value
      return {
        ...product,
        quantity: productId === id ? newQuantity : quantity
      }
    })
    updateTempOrder('orderItems', newProductList)
    updateTouchHandlder()
  }

  const deleteProductHandler = (id: string) => {
    const newProductList = orderItems.filter(({ id: productId }) => productId !== id)
    updateTempOrder('orderItems', newProductList)
    updateTouchHandlder()
  }

  const closeModalHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
  }

  const confirmChangesHandler = () => {
    closeModalHandler()
    dispatch(orderListActions.confirmPropose({ orderData: tempOrderData }))
  }

  const resetHandler = () => {
    if (stepState) setStepState(0)
    dispatch(orderListActions.checkAvailabilityInPropose())
  }

  const isContinueDisable =
    !!orderItems.filter(({ quantity, item }) => quantity > (item?.availableQuantity || 0))
      .length || !orderItems.length

  const footer = !stepState
    ? [
        <Button
          key="cancel"
          label="Cancel"
          onClick={closeModalHandler}
          variant="ghost"
        />,
        <Button
          key="continue"
          label="Continue"
          onClick={() => setStepState(1)}
          disabled={isContinueDisable}
        />
      ]
    : [<Button key="confirm" label="Confirm" onClick={confirmChangesHandler} />]

  const steps = [
    {
      title: 'MODIFY'
    },
    {
      title: 'SUMMARY'
    }
  ]

  const hasError = !isEmpty(error)

  return (
    <Modal
      btnName="Propose Changes"
      size="large"
      modalKey={modalKey}
      title="Propose Changes Order"
      btnType="primary"
      btnClicked={resetHandler}
      footer={!hasError ? footer : []}
      centered
    >
      <>
        {hasError ? (
          <Error error={error} />
        ) : (
          <>
            <Steps
              progressDot
              container_variables={{ current: stepState }}
              steps={steps}
            />
            <StepContent>
              {!isOrderTouched && (
                <AlertWrapper>
                  <Alert
                    message="Please modify before continue"
                    type="error"
                    showIcon
                    closable
                  />
                </AlertWrapper>
              )}
              <GeneralInfo
                data={tempOrderData}
                isEditable={!stepState}
                onEditFullfilmentDate={editFullfilmentDateHandlder}
                onEditInputValues={editInputValues}
              />
              <ProductList
                productList={orderItems}
                isEditing={!stepState}
                onQuantityChanged={editProductQuantityHandler}
                onDeleteProduct={deleteProductHandler}
              />
            </StepContent>
          </>
        )}
      </>
    </Modal>
  )
}

export default ProposeModal
