import { IOrderData, TOrderStatus } from '../features/orderList/models'
import { IStoreProdFilters } from '../features/storeProducts/model'
import { IPermissions } from '../features/users/model'

export interface IOrder {
  id: string
  createdDate: string
  addressedTo: string
  ownerEmail: string
  ownerName: string
  ownerSurname: string
  status: TOrderStatus | ''
  refuseReason: string
  refuseNote: string
  additionalNote: string
  notes: string
  required: boolean
  shipTo: string
  regionCountry: string
  orderItems: any[]
  acceptNote: string
  processNote: string
  fullfillmentDate: string
  orderCode: string
}

export const orderDataExtractor: (data: Partial<IOrderData>) => IOrder = (orderData) => ({
  id: orderData.id || '',
  orderItems: orderData.orderItems || [],
  ownerEmail: orderData.ownerEmail || '',
  ownerName: orderData.ownerName || '',
  ownerSurname: orderData.ownerSurname || '',
  createdDate: orderData.createdDate || '',
  addressedTo: orderData.addressedTo || '',
  additionalNote: orderData.additionalNote || '',
  notes: orderData.notes || '',
  required: orderData.required || false,
  shipTo: orderData.shipTo || '',
  regionCountry: orderData.regionCountry || '',
  status: orderData.status || '',
  refuseReason: orderData.refuseReason || '',
  refuseNote: orderData.refuseNote || '',
  acceptNote: orderData.acceptNote || '',
  processNote: orderData.processNote || '',
  fullfillmentDate: orderData.fullfillmentDate || '',
  orderCode: orderData.orderCode || ''
})

export const checkEntitySearchFieldVisible: (
  key: keyof IStoreProdFilters,
  permissions: IPermissions
) => boolean = (key, permissions) => {
  return permissions.storeProduct?.canSeeField[key]?.onRead || false
}
