import { Form } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InfoCell, Select } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { DATE_FORMAT } from '../../../components/Form/RangeDatePicker/contants'
import RangeDatePicker from '../../../components/Form/RangeDatePicker/RangeDatePicker'
import { Modal } from '../../../components/Modal'
import { appActions } from '../../../features/app'
import { orderListActions } from '../../../features/orderList/actions'
import { orderListSliceActions } from '../../../features/orderList/reducer'
import {
  selectOrderListDropdown,
  selectOrdersTempFilters
} from '../../../features/orderList/selectors'
import { IOrderFilterModalProps } from './types'

const OrderFilterModal: FC<IOrderFilterModalProps> = ({ ownerList, type }) => {
  const dispatch = useDispatch()
  const modalKey = `order-list-${type}-filter`

  const tempFilters = useSelector(selectOrdersTempFilters)
  const dropDownFilter = useSelector(selectOrderListDropdown)
  const addressedToDropdown = get(dropDownFilter, 'addressedTo', [])

  const updateTempFiltersHandler = (filterKey: string, value: any) =>
    dispatch(
      orderListSliceActions.setTempFilters({
        key: filterKey,
        value: value
      })
    )

  const dateChangeHandler: (a: [any, any], b: string) => void = (dates, filterKey) => {
    if (!dates) return
    const [startDate, endDate] = dates
    updateTempFiltersHandler(filterKey, {
      [`${filterKey}From`]: moment(startDate).format(DATE_FORMAT),
      [`${filterKey}To`]: moment(endDate).format(DATE_FORMAT)
    })
  }

  const dropDownChangeHandler = (value: string, filterKey: string) => {
    updateTempFiltersHandler(filterKey, value)
  }

  const applyAndSearchHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
    dispatch(orderListActions.applyFilterAndSearch())
  }

  const setTempFilterHandler = () => {
    dispatch(orderListSliceActions.filtersToTemp())
  }

  const createdDateFromVal = tempFilters.createdDate?.createdDateFrom || null
  const createdDateFromMom = createdDateFromVal && moment(createdDateFromVal, DATE_FORMAT)
  const createdDateToVal = tempFilters.createdDate?.createdDateTo || null
  const createdDateToMom = createdDateToVal && moment(createdDateToVal, DATE_FORMAT)

  const fullfillmentDateFromVal =
    tempFilters.fullfillmentDate?.fullfillmentDateFrom || null
  const fullfillmentDateFromMom =
    fullfillmentDateFromVal && moment(fullfillmentDateFromVal, DATE_FORMAT)
  const fullfillmentDateToVal = tempFilters.fullfillmentDate?.fullfillmentDateTo || null
  const fullfillmentDateToMom =
    fullfillmentDateToVal && moment(fullfillmentDateToVal, DATE_FORMAT)

  return (
    <Modal
      btnName="Filter"
      size="medium"
      modalKey={modalKey}
      title="Filter Order List"
      btnType="primary"
      paddingBody="0px"
      btnClicked={setTempFilterHandler}
      centered
      footer={[
        <Button
          key="search"
          label="Search"
          onClick={applyAndSearchHandler}
          enableKeyPress
        />
      ]}
    >
      <Form layout="vertical">
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <RangeDatePicker
            value={[createdDateFromMom, createdDateToMom]}
            label="Date of request"
            onChange={(dates) => dateChangeHandler(dates, 'createdDate')}
          />
          <RangeDatePicker
            value={[fullfillmentDateFromMom, fullfillmentDateToMom]}
            label="Requested date of fullfillment"
            onChange={(dates) => dateChangeHandler(dates, 'fullfillmentDate')}
          />
        </BoxWrapper>
        <BoxWrapper sm={2} md={2} lg={2} enablePadding>
          <Select
            value={get(tempFilters, 'addressedTo', null)}
            items={addressedToDropdown}
            onChange={({ value }) => {
              dropDownChangeHandler(value, 'addressedTo')
            }}
            required={false}
            label={'Client / Event / Other'}
          />
          <Select
            value={get(tempFilters, 'owner', null)}
            items={ownerList}
            onChange={({ value }) => {
              dropDownChangeHandler(value, 'owner')
            }}
            required={false}
            label={'User of the request'}
          />
          <InfoCell
            label="Destination Notes"
            value={get(tempFilters, 'notes', null)}
            isEditing
            inputConfig={{
              onChange: (value: string) => updateTempFiltersHandler('notes', value)
            }}
          />
          <InfoCell
            label="Order Code"
            value={get(tempFilters, 'orderCode', null)}
            isEditing
            inputConfig={{
              onChange: (value: string) => updateTempFiltersHandler('orderCode', value)
            }}
          />
        </BoxWrapper>
      </Form>
    </Modal>
  )
}

export default OrderFilterModal
