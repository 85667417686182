import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, SectionTitle } from '../../../components'
import { Divider } from '../../../components/Divider'
import FooterControl from '../../../components/FooterControl'
import { Upload } from '../../../components/Form/Upload'
import {
  administrationActions,
  administrationSliceActions
} from '../../../features/administration'
import { fileSaver } from '../../../utils/fileSaver'
import { AppRoutes } from '../../constants'

const AnagraphicImport: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_BASE_URL_IMG_THUMBNAIL
  const [importedAnagraphic, setImportedAnagraphic] = useState(null)

  const downloadHandler = () => {
    fileSaver(`${baseUrl}templates/template_store.xlsx`)
  }

  const updaloadHandler = (value: any) => {
    setImportedAnagraphic(value)
  }

  const backHandler = () => {
    dispatch(administrationSliceActions.clearType())
    navigate(AppRoutes.VAULT_ADMINISTRATION)
  }

  return (
    <>
      <SectionTitle value="Anagraphic Import" isBold />
      <Divider disableBorder />
      <SectionTitle
        value={'Use the attached template to import the Anagraphic'}
        addon={
          <Button
            label={'Download'}
            iconName={'DownloadOutlined'}
            variant={'ghost'}
            onClick={downloadHandler}
          />
        }
      />
      <Divider disableBorder />
      <Upload
        message={'No file selected'}
        label={''}
        required={false}
        description={
          'Drop here or browse the .xlsx file contains the Anagraphic to import'
        }
        name={'importedAnagraphic'}
        value={importedAnagraphic}
        onChange={updaloadHandler}
      />
      <FooterControl>
        <Button label="Back" onClick={backHandler} />
        <Button
          label="Start Import"
          onClick={() => dispatch(administrationActions.importAnagraphic())}
          disabled={!importedAnagraphic}
        />
      </FooterControl>
    </>
  )
}

export default AnagraphicImport
