import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, SectionTitle, Table, TooltipBtn } from '../../../components'
import { Divider } from '../../../components/Divider'
import { ITActionColumnConfig } from '../../../components/Table/types'
import { Text } from '../../../components/Text'
import { appActions } from '../../../features/app'
import { STORE_PRODUCTS_CHILDREN_NEW } from '../../../features/storeProducts/constants'
import { IStoreProdChild } from '../../../features/storeProducts/model'
import { AppRoutes } from '../../../pages/constants'
import { columnsGeneratorHelper, rowsGeneratorHelper } from '../../../utils'
import AddChildModal from './AddChildModal'
import { EmptyWrapper } from './styled'
import { IChildrenTableProps } from './types'

const ChildrenTable: FC<IChildrenTableProps> = ({
  list,
  onAdd,
  parentType,
  onDelete,
  hasDelete = false,
  hasAdd = false,
  isCreating = false
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const modalKeyTop = 'add-new-child-top'
  const modalKeyBottom = 'add-new-child-bottom'

  const columns = columnsGeneratorHelper(STORE_PRODUCTS_CHILDREN_NEW)
  const dataRows = list.length
    ? rowsGeneratorHelper(list, STORE_PRODUCTS_CHILDREN_NEW)
    : []

  const addChildHandler = (childData: IStoreProdChild, modalKey: string) => {
    dispatch(appActions.closeModal({ modalKey }))
    onAdd(childData)
  }

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      return (
        <>
          {hasDelete && (
            <TooltipBtn
              tooltipLabel="Delete"
              hasIcon
              iconName="DeleteOutlined"
              message="Do you want to delete this row?"
              onConfirm={() => onDelete(data.id)}
              btnVariant="link"
            />
          )}
          {!isCreating && (
            <Button
              iconName="InfoCircleOutlined"
              variant="link"
              onClick={() =>
                navigate(
                  `${AppRoutes.STORE_PRODUCTS_SEARCH}/${data.parentId}/component/${data.id}`
                )
              }
            />
          )}
        </>
      )
    }
  }

  return (
    <>
      <SectionTitle
        value="Components"
        addon={
          list.length && hasAdd ? (
            <AddChildModal
              modalKey={modalKeyTop}
              btnName="Add"
              btnType="link"
              btnIcon="PlusCircleOutlined"
              onConfirm={(data) => addChildHandler(data, modalKeyTop)}
              entityType={parentType}
            />
          ) : null
        }
      />
      <Divider />
      <Table
        columns={columns}
        items={dataRows}
        rowKey="id"
        emptyBody={
          <>
            <EmptyWrapper>
              <Text text="There are no component" variant="h4" />
              {hasAdd && (
                <AddChildModal
                  modalKey={modalKeyBottom}
                  btnName="Add Component"
                  btnType="ghost"
                  onConfirm={(data) => addChildHandler(data, modalKeyBottom)}
                  entityType={parentType}
                />
              )}
            </EmptyWrapper>
          </>
        }
        actionColumnConfig={actionConfig}
      />
    </>
  )
}

export default ChildrenTable
