import { createAction } from '@reduxjs/toolkit'
import { storeActionsType } from './constants'
import {
  ICheckItemAvailabilityByIdPayload,
  IStoreFilterTaxonomiesPayload,
  IStoreProdChild
} from './model'

export const storeActions = {
  getDetail: createAction<{ id: string }>(storeActionsType.getDetail),
  checkProductAvailabiltyById: createAction<ICheckItemAvailabilityByIdPayload>(
    storeActionsType.checkItemAvailabiltyById
  ),
  searchStoreProducts: createAction(storeActionsType.search),
  createEntity: createAction(storeActionsType.createEntity),
  updateStoreProd: createAction(storeActionsType.updateEntity),
  updateStoreProdChild: createAction(storeActionsType.updateEntityChild),
  deleteStoreProd: createAction<{ id: string }>(storeActionsType.deleteEntity),
  deleteStoreProdChild: createAction<{ id: string }>(storeActionsType.deleteEntityChild),
  addStoreProdChild: createAction<{ parentId: string; data: IStoreProdChild }>(
    storeActionsType.addEntityChild
  ),
  getChildDetail: createAction<{ id: string }>(storeActionsType.getChild),
  filterTaxonomies: createAction<IStoreFilterTaxonomiesPayload>(
    storeActionsType.STORE_PRODUCT_FILTER_TAXONOMIES
  )
}
