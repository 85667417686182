import { InputNumber } from 'antd'
import { TABLE_DATA_TYPE } from '../../../components/Table/constants'
import { ITableConfig } from '../../../components/Table/types'

export const getPricingTableColumns = (
  mappedExchangeValues: any,
  setMappedExchangeValues: any,
  editingId: string
) => {
  const PRICING_TABLE_COLUMNS: ITableConfig = {
    currency: {
      label: 'Currency',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'currency'
    },
    USDExchangeRate: {
      label: 'USD Exchange Rate',
      dataType: TABLE_DATA_TYPE.NODE,
      dataPath: 'USDExchangeRate',
      renderNode: (data: any, rec: any) => {
        return (
          <InputNumber<string>
            type="number"
            step="0.00001"
            max="9999.99999"
            stringMode
            value={mappedExchangeValues[rec.id] || data}
            min="0.00001"
            disabled={!editingId || editingId !== rec.id}
            onChange={(value) => {
              setMappedExchangeValues({
                ...mappedExchangeValues,
                [rec.id]: value
              })
            }}
          />
        )
      }
    }
  }
  return PRICING_TABLE_COLUMNS
}
