import { get, isArray, isBoolean, isEmpty, isEqual, isNumber } from 'lodash'
import { IExcludeSelectConfig } from '../../components/Table/types'
import { IColumnCell, IRowsGeneratorHelperArg, ISorterHelperArg } from './types'

export const columnsGeneratorHelper: (config: any) => IColumnCell[] = (tableConfig) => {
  if (isEmpty(tableConfig)) return []
  const generatedColumns = Object.entries(tableConfig).map(
    ([key, value]: [string, any]) => {
      const label = get(tableConfig, `${key}.label`, '')
      const dataType = get(tableConfig, `${key}.dataType`, 'text')
      const dataIndex = get(tableConfig, `${key}.dataIndex`)
      const hasBorder = get(tableConfig, `${key}.hasBorder`, false)
      return {
        ...value,
        title: label,
        dataIndex: dataIndex || key,
        dataType: dataType,
        key,
        hasBorder
      }
    }
  )

  return generatedColumns
}

export const visibilityByRolesHelper: (
  userRoles: string[],
  notAllowedRoles: string[]
) => boolean = (userRoles, notAllowedRoles = []) =>
  !notAllowedRoles.some((role: string) => userRoles.includes(role))

export const rowsGeneratorHelper: (
  dataList: Record<string, any>[],
  config: any
) => Record<string, any>[] = (dataList, tableConfig) => {
  if (isEmpty(tableConfig)) return []
  const newDataList = dataList.map((data: any) => {
    const newData: Record<string, any> = {}
    Object.keys(tableConfig).forEach((key: keyof typeof newData) => {
      newData[key] = get(data, tableConfig[key].dataPath, '')
    })
    return newData
  })
  return newDataList
}

export const rowsGeneratorHelper_new: <T>(
  arg: IRowsGeneratorHelperArg<T>
) => Record<string, any>[] = ({ dataList, tableConfig }) => {
  if (isEmpty(tableConfig)) return []
  const newDataList = dataList.map((data: any) => {
    const newData: Record<string, any> = { ...data }
    Object.keys(tableConfig).forEach((key: keyof typeof newData) => {
      newData[key] = get(data, tableConfig[key].dataPath, '')
    })
    return newData
  })
  return newDataList
}

const fieldsWithSpecialSorting = ['packagingCode']

const sortHelper: (a: any, b: any) => number = (aValue, bValue) =>
  aValue.localeCompare(bValue, undefined, {
    numeric: true,
    sensitivity: 'base'
  })

const nullUndefinedSorter: (a: any, b: any) => number = (aValue, bValue) => {
  if (!aValue && !bValue) return 0
  if (!aValue) return 1
  if (!bValue) return -1
  return 0
}

const convertBoolAndSort: (a: any, b: any) => number = (a, b) => {
  const aValue = a ? 'Yes' : 'No'
  const bValue = b ? 'Yes' : 'No'
  return sortHelper(aValue, bValue)
}

const convertNumAndSort: (a: any, b: any) => number = (a, b) => {
  const aValue = a.toString()
  const bValue = b.toString()
  return sortHelper(aValue, bValue)
}

const convertListAndSort: (a: any, b: any) => number = (a, b) => {
  const aValue = a.join(', ')
  const bValue = b.join(', ')
  return sortHelper(aValue, bValue)
}

const sortByField: (key: string, a: any, b: any) => number = (key, a, b) => {
  if (key === 'packagingCode') {
    const aValue = a.slice(5)
    const bValue = b.slice(5)
    return sortHelper(aValue, bValue)
  }
  return sortHelper(a, b)
}

export const sorterHelper: (arg: ISorterHelperArg) => number = ({ key, values }) => {
  const { aValue, bValue } = values

  // NOTE "==" is intentional to catch undefined as well
  if (aValue == null || bValue == null) {
    return nullUndefinedSorter(aValue, bValue)
  }

  if (fieldsWithSpecialSorting.includes(key)) {
    return sortByField(key, aValue, bValue)
  }

  if (isBoolean(aValue) && isBoolean(bValue)) {
    return convertBoolAndSort(aValue, bValue)
  }

  if (isNumber(aValue) && isNumber(bValue)) {
    return convertNumAndSort(aValue, bValue)
  }

  if (isArray(aValue) && isArray(bValue)) {
    return convertListAndSort(aValue, bValue)
  }

  return sortHelper(aValue, bValue)
}

export const checkIfRowExcluded = ({
  record,
  exclude
}: {
  record: Record<string, any>
  exclude: IExcludeSelectConfig
}) => {
  const keysToExcludes = Object.keys(exclude)
  const isExcluded = keysToExcludes.some((key) =>
    exclude[key].some((val) => isEqual(val, record[key]))
  )
  return isExcluded
}
