import { Tabs } from 'antd'
import { isEmpty } from 'lodash'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { canUserAddRelationships } from '../../../auth-msal'
import { RelationshipsTable } from '../../../components'
import AddRelationModal from '../../../components/Packaging/RelationshipsTable/AddRelationModal'
import { Title } from '../../../components/Title'
import { packagingActions } from '../../../features/packaging'
import { OTHER_PACKAGING_TABS } from '../../../features/packaging/constants'
import { OTHER_PACKAGES } from '../../../features/packaging/model'
import { getPackageDetail } from '../../../features/packaging/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import { IRelationshipsWrapperProps } from './types'

const RelationshipsWrapper: FC<IRelationshipsWrapperProps> = ({ isEditing }) => {
  const packagingDetails = useSelector(getPackageDetail)
  const userRoles = useSelector(selectUserRole)

  const [selectedTab, setSelectedTab] = useState(OTHER_PACKAGES.FURTHER)

  const changeTabHandler = (activeStatus: string) => {
    const selectedTab = OTHER_PACKAGING_TABS[+activeStatus]
    setSelectedTab(selectedTab.value)
  }

  const isFurther = selectedTab === OTHER_PACKAGES.FURTHER

  const getTabDefault = () => {
    const index = Object.values(OTHER_PACKAGING_TABS).findIndex(
      ({ value }) => value === selectedTab
    )
    return index
  }

  const getAddVisibilityByRules = () => {
    return canUserAddRelationships(userRoles)
  }

  return (
    <>
      {!isEmpty(packagingDetails) && (
        <>
          <Title
            className="boxinfo-title"
            title="Relationships"
            variantTitle="h3"
            borderBottom
            enableBold={true}
          />
          <Tabs
            defaultActiveKey={getTabDefault().toString()}
            onTabClick={changeTabHandler}
            tabBarExtraContent={
              isEditing &&
              !getAddVisibilityByRules() && (
                <AddRelationModal
                  confirmAdd={
                    isFurther
                      ? packagingActions.addFurtherVersion
                      : packagingActions.addRelated
                  }
                  relationType={selectedTab}
                  modalKey={`package-relation-add-${selectedTab}`}
                />
              )
            }
          >
            {OTHER_PACKAGING_TABS.map(({ label }, index) => (
              <Tabs.TabPane tab={label} key={`${index}`}>
                <RelationshipsTable
                  showOnlyInfo={getAddVisibilityByRules()}
                  data={packagingDetails}
                  onDelete={
                    isFurther
                      ? packagingActions.deleteFurtherVersion
                      : packagingActions.deleteRelated
                  }
                  onAdd={
                    isFurther
                      ? packagingActions.addFurtherVersion
                      : packagingActions.addRelated
                  }
                  relationType={selectedTab}
                  isEditing={isEditing}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </>
      )}
    </>
  )
}

export default RelationshipsWrapper
