import { DatePicker, Form, Input, Radio, Select, Upload } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { get } from 'lodash'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTestedClaimTaxonomy } from '../../../features/formulaSchema/selectors'
import { DatePickerWrapper } from '../../../style/styledDatePicker'
import { InputWrapper, RadioInputWrapper } from '../../../style/styledInput'
import { BoxWrapper } from '../../BoxWrapper'
import Button from '../../Button'
import { TEST_EXTENSION_OPTIONS, dropdownify } from './utils'

const FILE_NAME_CONVENTION =
  'The following special characters are not allowed into file name: \\ / : * ? " < > | # %'

const TestedClaimForm: FC<any> = ({
  data,
  formInstance,
  onFinish,
  formulaCodes,
  setAttachment
}) => {
  const [type, setType] = useState('TEST')

  const claimTaxonomy = useSelector(selectTestedClaimTaxonomy)
  const [disableImportPdf, setDisableImportPdf] = useState(!!data.attachmentId)

  const claimDropdown = dropdownify(claimTaxonomy?.claim || [])
  const testTypeDropdown = dropdownify(claimTaxonomy?.testType || [])
  const testMethodDropdown = dropdownify(claimTaxonomy?.testMethod || [])
  const formulaDropdown = formulaCodes
  const values: any = useWatch([], formInstance)

  useEffect(() => {
    if (formInstance !== null) {
      formInstance.resetFields()
      formInstance.setFieldsValue({
        type: data?.type,
        testedCode: data.testedCode,
        claim: data?.claim,
        testType: data?.testType,
        claimResult: data?.claimResult,
        reportDate: data.reportDate && moment(data.reportDate),
        testStatesCustomerName: data?.testStatesCustomerName,
        reportNumber: data?.reportNumber,
        testMethod: data?.testMethod,
        formulaId: data?.formulaId,
        note: data?.note
      })
    }
  }, [data, formInstance])

  const isAttachmentRequired = (fields: any) => {
    return !fields.attachmentId
  }

  const checkAndSetAttachment = (fileRequestData: any) => {
    const fileName = fileRequestData.file.name
    const SPECIAL_CHARACTERS = /[”#*:"\\|<>/?%]+/
    if (SPECIAL_CHARACTERS.test(fileName)) {
      formInstance.setFieldsValue({ attachment: null })
    } else {
      setAttachment(fileRequestData)
      setDisableImportPdf(true)
    }
  }

  return (
    <Form form={formInstance} layout="vertical" name="tested_claim" onFinish={onFinish}>
      <BoxWrapper columnCount={4} enablePadding>
        <>
          <InputWrapper>
            <Form.Item rules={[{ required: true }]} label="Test / Extension" name="type">
              <Select
                value={type}
                options={TEST_EXTENSION_OPTIONS}
                onSelect={(value: any) => setType(value)}
                status={values?.type ? undefined : 'error'}
              />
            </Form.Item>
          </InputWrapper>
          <InputWrapper>
            {type === 'TEST' ? (
              <Form.Item
                rules={[{ required: true }]}
                label="Tested Code"
                name="testedCode"
              >
                <Select
                  value={get(data, 'testedCode')}
                  options={formulaDropdown}
                  onSelect={(value: any, option: any) => {
                    formInstance.setFieldsValue({ formulaId: option.id })
                  }}
                  status={values?.testedCode ? undefined : 'error'}
                />
              </Form.Item>
            ) : (
              <Form.Item
                rules={[{ required: true }]}
                label="Tested Code"
                name="testedCode"
              >
                <Input
                  value={get(data, 'testedCode')}
                  placeholder="Max 250 char"
                  status={values?.testedCode ? undefined : 'error'}
                />
              </Form.Item>
            )}
          </InputWrapper>

          <InputWrapper>
            <Form.Item rules={[{ required: true }]} label="Claim" name="claim">
              <Select
                value={get(data, 'claim')}
                showSearch={true}
                options={claimDropdown}
                status={values?.claim ? undefined : 'error'}
              />
            </Form.Item>
          </InputWrapper>
          <InputWrapper>
            <Form.Item rules={[{ required: true }]} label="Test Type" name="testType">
              <Select
                showSearch
                value={get(data, 'testType')}
                options={testTypeDropdown}
                status={values?.testType ? undefined : 'error'}
              />
            </Form.Item>
          </InputWrapper>
          <InputWrapper>
            <Form.Item
              rules={[{ required: true }]}
              label="Claim Result"
              name="claimResult"
            >
              <Input
                value={get(data, 'claimResult')}
                placeholder="Max 250 char"
                status={values?.claimResult ? undefined : 'error'}
              />
            </Form.Item>
          </InputWrapper>
          <DatePickerWrapper>
            <Form.Item rules={[{ required: true }]} label="Report Date" name="reportDate">
              <DatePicker status={values?.reportDate ? undefined : 'error'} />
            </Form.Item>
          </DatePickerWrapper>
          <RadioInputWrapper>
            <Form.Item
              rules={[{ required: true }]}
              label="Test States Customer Name"
              name="testStatesCustomerName"
            >
              <Radio.Group value={get(data, 'testStatesCustomerName')}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </RadioInputWrapper>
          <InputWrapper>
            <Form.Item
              rules={[{ required: true }]}
              label="Report Number"
              name="reportNumber"
            >
              <Input
                value={get(data, 'reportNumber')}
                placeholder="Max 250 char"
                status={values?.reportNumber ? undefined : 'error'}
              />
            </Form.Item>
          </InputWrapper>
          <InputWrapper>
            <Form.Item rules={[{ required: true }]} label="Test Method" name="testMethod">
              <Select
                value={get(data, 'testMethod')}
                options={testMethodDropdown}
                status={values?.testMethod ? undefined : 'error'}
              />
            </Form.Item>
          </InputWrapper>
          <InputWrapper>
            <Form.Item label="Note" name="note">
              <Input value={get(data, 'note')} placeholder="Max 250 char" />
            </Form.Item>
          </InputWrapper>

          <Form.Item
            rules={[{ required: isAttachmentRequired(data) }]}
            label="Attachment"
            name="attachment"
            tooltip={FILE_NAME_CONVENTION}
          >
            <InputWrapper>
              <Upload
                name={'file'}
                accept=".pdf"
                disabled={!!data?.attachmentId}
                multiple={false}
                showUploadList={false}
                customRequest={(file) => {
                  checkAndSetAttachment(file)
                }}
              >
                <Button
                  iconName="UploadOutlined"
                  variant="ghost"
                  label="Upload Attachment"
                  disabled={disableImportPdf || !!data?.attachmentId}
                />
              </Upload>
            </InputWrapper>
          </Form.Item>
          <Form.Item
            rules={[{ required: data.type === 'TEST' }]}
            hidden={true}
            name="formulaId"
            shouldUpdate={(prev, curr) => {
              return curr.type === 'TEST' && prev.testedCode !== curr.testedCode
            }}
          />
        </>
      </BoxWrapper>
    </Form>
  )
}

export default TestedClaimForm
