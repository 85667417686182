import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { appSliceActions } from '../../features/app'
import Button from '../Button'
import { Modal } from '../Modal'
import { Text } from '../Text'
import { ISync } from './types'

export const SyncButton = ({ sagaOnSync, record, flowName }: ISync) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const handleSync = useCallback(() => {
    dispatch({ type: sagaOnSync, payload: { record, flowName, packageId: id } })
  }, [dispatch, sagaOnSync, record, flowName, id])
  const closeModal = useCallback(() => {
    dispatch(appSliceActions.setModalKey())
  }, [dispatch])
  const isDisabled = record.status === 'PUBLISHED'
  return (
    <Modal
      modalKey={`add-sync-${record.id}`}
      btnIcon="SyncOutlined"
      btnType="link"
      title=""
      size="small"
      isSmall={true}
      disabled={isDisabled}
      footer={[
        <Button key="no" label="No" onClick={closeModal} variant="ghost" />,
        <Button key="yes" label="Yes, change" onClick={handleSync} />
      ]}
    >
      <Text
        variant="h4"
        text="You are changing the status of the attachments from draft to published"
      />
      <Text variant="h5" text="Are you sure you want to procede with this operation?" />
    </Modal>
  )
}
