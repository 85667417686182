import { FC } from 'react'
import { Modal, Table } from '../../../components'
import { TOP_RIGHT } from '../../../components/Table/constants'
import { columnsGeneratorHelper } from '../../../utils'
import { PROJECT_SPEC_CHARACTERISTIC_SAP_CODES } from '../../ProjectSpecificationDetailsContent/constants'
import { ModalBodyContainer } from './styled'

import { IAddSapCodeModalProps } from './types'

const AddSapCodeModal: FC<IAddSapCodeModalProps> = ({
  btnLabel,
  modalKey,
  sapCodeList,
  isEditing,
  selectedRows,
  onSelect
}) => {
  const columns = columnsGeneratorHelper(PROJECT_SPEC_CHARACTERISTIC_SAP_CODES)

  return (
    <Modal
      btnName={btnLabel}
      btnType="link"
      btnIcon="PlusCircleOutlined"
      modalKey={modalKey}
      title="Filter characteristics"
      centered
      size="large"
      disabled={!isEditing}
    >
      <ModalBodyContainer>
        <>
          <Table
            enablePagination={false}
            paginationPosition={TOP_RIGHT}
            enableRowSelect
            rowKey="id"
            columns={columns}
            items={sapCodeList}
            selectedRows={selectedRows}
            onSelectRow={onSelect}
          />
        </>
      </ModalBodyContainer>
    </Modal>
  )
}

export default AddSapCodeModal
